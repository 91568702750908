import {
  PRODUCT_SEARCH,
  PRODUCT_VIEW,
  PRODUCT_VIEW_LOADED,
  PRODUCT_UNLOAD,
  PRODUCT_ATTR_LOAD,
  PRODUCT_ATTR_LOADED,
  PRODUCT_CATEGORY_LOAD,
  PRODUCT_CATEGORY_LOADED,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_GENERATE_VARIANTS,
  PRODUCT_GENERATE_VARIANTS_SUCCESS,
  PRODUCT_REGENERATE_DESC,
  PRODUCT_REGENERATE_DESC_SUCCESS,
  PRODUCT_UPDATE_ATTRIBUTES,
  PRODUCT_UPDATE_ATTRIBUTES_SUCCESS
} from './action_types';

const searchProduct = payload => ({
  type: PRODUCT_SEARCH,
  payload: payload
});

const load = payload => ({
  type: PRODUCT_VIEW,
  payload: payload
});

const loaded = payload => ({
  type: PRODUCT_VIEW_LOADED,
  payload: payload
});

const unload = () => ({
  type: PRODUCT_UNLOAD,
  payload: {}
});

const loadAttrs = productId => ({
  type: PRODUCT_ATTR_LOAD,
  payload: { productId: productId }
});

const loadCategories = productId => ({
  type: PRODUCT_CATEGORY_LOAD,
  payload: { productId: productId }
});

const loadedAttrs = payload => ({
  type: PRODUCT_ATTR_LOADED,
  payload: payload
});

const loadedCategories = payload => ({
  type: PRODUCT_CATEGORY_LOADED,
  payload: payload
});

const update = payload => ({
  type: PRODUCT_UPDATE,
  payload: payload
});

const updateSuccess = payload => ({
  type: PRODUCT_UPDATE_SUCCESS,
  payload: payload
});

const generateVariants = payload => ({
  type: PRODUCT_GENERATE_VARIANTS,
  payload: payload
});

const generateVariantsSuccess = payload => ({
  type: PRODUCT_GENERATE_VARIANTS_SUCCESS,
  payload: payload
});

const regenerateDesc = id => ({
  type: PRODUCT_REGENERATE_DESC,
  payload: { id: id }
});

const regenerateDescSuccess = payload => ({
  type: PRODUCT_REGENERATE_DESC_SUCCESS,
  payload: payload
});

const updateAttrs = payload => ({
  type: PRODUCT_UPDATE_ATTRIBUTES,
  payload: payload
});

const updateAttrsSuccess = payload => ({
  type: PRODUCT_UPDATE_ATTRIBUTES_SUCCESS,
  payload: payload
});

export {
  searchProduct,
  load,
  loaded,
  unload,
  loadAttrs,
  loadCategories,
  loadedAttrs,
  loadedCategories,
  update,
  updateSuccess,
  generateVariants,
  generateVariantsSuccess,
  regenerateDesc,
  regenerateDescSuccess,
  updateAttrs,
  updateAttrsSuccess
};
