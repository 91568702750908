// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tableListSelector from 'redux/selectors/tableList';
import { Row, Col } from 'antd';
import * as userSessionActions from 'redux/actions/userSession';
import * as listActions from 'redux/actions/list';
import { toDateString } from 'Utils/utils';
import { WrapperTable } from 'components';

const { Fragment } = React;

class UserSessionList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            <WrapperTable
              title="Customers Online"
              filters={filters}
              columns={columns}
              data={this.props.listData}
              api={this.props.search}
              defaultFilters={defaultFilters}
              tableAction={tableAction}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true
  },
  {
    title: 'Created On',
    dataIndex: 'createdOn',
    sorter: true,
    render: d => toDateString(d)
  },
  {
    title: 'Updated On',
    dataIndex: 'updatedOn',
    sorter: true,
    defaultSortOrder: 'descend',
    render: d => toDateString(d)
  }
];

const filters = [
  {
    field: 'name',
    type: 'TEXT'
  }
];

let tableAction = <Fragment></Fragment>;

const defaultFilters = {
  status: {
    _field: 'roleName',
    value: 'CUSTOMER',
    operator: 'EQUAL'
  }
};

const mapDispatchToProps = {
  search: userSessionActions.search,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSessionList);
