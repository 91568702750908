import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider, Descriptions, Row, Col } from 'antd';
import { Spinner, Navbar, ImageUploader } from 'components';
import * as phoneSelector from 'redux/selectors/phone';
import * as userSelector from 'redux/selectors/user';
import * as phoneActions from 'redux/actions/phone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PHONE_CREATE_UPDATE_SUCCESS } from 'redux/actions/phone/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './phoneview.module.less';

class PhoneView extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.find(this.props.match.params.id);
    this.subscriptions.push(
      this.props.eventBusSubscribe(PHONE_CREATE_UPDATE_SUCCESS, action => {
        this.props.find(this.props.match.params.id);
      })
    );
  }

  componentWillUnmount() {
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  uploadSmallImage = data => {
    this.props.uploadSmallImage(data);
  };

  uploadLargeImage = data => {
    this.props.uploadLargeImage(data);
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      var c = this.props.phone;
      content = (
        <Row gutter={24}>
          <Col span={24}>
            <Descriptions bordered size="small">
              <Descriptions.Item label="Name">{c.name}</Descriptions.Item>
              <Descriptions.Item label="Short name">{c.shortName}</Descriptions.Item>
              <Descriptions.Item label="Url">{c.url}</Descriptions.Item>
              <Descriptions.Item label="Discontinued">{c.discontinued ? 'Yes' : 'No'}</Descriptions.Item>
              <Descriptions.Item label="Show in latest devices">{c.latest ? 'Yes' : 'No'}</Descriptions.Item>
              <Descriptions.Item label="Device type">{c.deviceType}</Descriptions.Item>
              <Descriptions.Item label="Year of Launch">{c.yearOfLaunch}</Descriptions.Item>
              <Descriptions.Item label="Group">{c.group && c.group.name}</Descriptions.Item>
              <Descriptions.Item label="Sort Order">{c.sortOrder}</Descriptions.Item>
              <Descriptions.Item label="Created By">
                {c.createdBy && c.createdBy.name ? c.createdBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Created On">{c.createdOn}</Descriptions.Item>
              <Descriptions.Item label="Updated By">
                {c.updatedBy && c.updatedBy.name ? c.updatedBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Updated On">{c.updatedOn}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <ImageUploader
              key={c.id}
              id={c.id}
              data={c.asset}
              uploadImage={this.uploadSmallImage}
              eventBusSubscribe={this.props.eventBusSubscribe}
            ></ImageUploader>
          </Col>
          <Col span={12}>
            <ImageUploader
              key={c.id}
              id={c.id}
              data={c.largeImg}
              uploadImage={this.uploadLargeImage}
              eventBusSubscribe={this.props.eventBusSubscribe}
            ></ImageUploader>
          </Col>
        </Row>
      );
    }

    return (
      <div className={styles.container}>
        <Navbar title={this.props.phone.name}>
          {!this.props.isUserContentCreator && (
            <Fragment>
              <Link type="primary" shape="circle" to={'/catalog/phone/create/'}>
                <Tooltip placement="rightTop" title="Create Phone">
                  <FontAwesomeIcon icon="plus-square" />
                </Tooltip>
              </Link>
              <Divider type="vertical" />
              <Link type="primary" to={`/catalog/phone/edit/${this.props.match.params.id}/`}>
                <Tooltip placement="rightTop" title="Edit Phone">
                  <FontAwesomeIcon icon="edit" />
                </Tooltip>
              </Link>
            </Fragment>
          )}
          <Divider type="vertical" />
          <Link type="primary" to={'/catalog/phone/'}>
            <Tooltip placement="rightTop" title="List Phone">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = {
  find: phoneActions.find,
  uploadSmallImage: phoneActions.uploadSmallImage,
  uploadLargeImage: phoneActions.uploadLargeImage,
  unload: phoneActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: phoneSelector.isLoaded(state),
    phone: phoneSelector.get(state),
    isUserContentCreator: userSelector.isUserContentCreator(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(PhoneView));
