import {
  PHONE_BRAND_LOAD,
  PHONE_BRAND_LOADED,
  PHONE_BRAND_ALL_LOAD,
  PHONE_BRAND_ALL_LOADED,
  PHONE_BRAND_UNLOADED,
  PHONE_BRAND_UPDATE,
  PHONE_BRAND_SEARCH,  
  PHONE_BRAND_CREATE_UPDATE_SUCCESS,
  PHONE_BRAND_IMAGE_UPLOAD,
  PHONE_BRAND_CREATE } from './action_types';

const find = payload => ({
  type: PHONE_BRAND_LOAD,
  payload: payload
});

const phoneBrandLoaded = payload => ({
  type: PHONE_BRAND_LOADED,
  payload: payload
});

const findAll = () => ({
  type: PHONE_BRAND_ALL_LOAD,
  payload: {}
});

const phoneBrandAllLoaded = payload => ({
  type: PHONE_BRAND_ALL_LOADED,
  payload: payload
});

const unload = () => ({
  type: PHONE_BRAND_UNLOADED,
  payload: {}
});

const update = (payload) => ({
  type: PHONE_BRAND_UPDATE,
  payload: payload
});

const phoneBrandCreateUpdateSucess = payload => ({
  type: PHONE_BRAND_CREATE_UPDATE_SUCCESS,
  payload: payload
});

const create = (payload) => ({
  type: PHONE_BRAND_CREATE,
  payload: payload
});

const search = (payload) => ({
  type: PHONE_BRAND_SEARCH,
  payload: payload
});

const uploadImage = (payload) => ({
  type: PHONE_BRAND_IMAGE_UPLOAD,
  payload: payload
});


export {
  find,
  findAll,
  phoneBrandLoaded,
  phoneBrandAllLoaded,
  unload,
  update,
  phoneBrandCreateUpdateSucess,
  search,
  create,
  uploadImage
};
