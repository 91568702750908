import React, { Component } from 'react';
import { Select } from 'antd';
import { fetchApi } from 'Utils/utils';
import _ from 'lodash';
import styles from './autocomplete.module.less';

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    };
  }

  fetchOptions = value => {
    if (!value || value.trim() === '') {
      return;
    }
    let response = fetchApi.get(`${this.props.searchUrl}?q=${value}`);
    response.then(res => {
      let labelKey = _.defaultTo(this.props.labelKey, 'label');
      let valueKey = _.defaultTo(this.props.valueKey, 'value');
      this.setState({
        dataSource: res.map(r => ({
          value: _.get(r, valueKey),
          label: _.get(r, labelKey)
        }))
      });
    });
  };

  handleChange = (value, option) => {
    if (this.props.onChange) {
      this.props.onChange(value, option);
    }
  };

  render() {
    return (
      <div className={styles.autocomplete}>
        <Select
          style={{ width: '100%' }}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={_.debounce(this.fetchOptions, 500)}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
        >
          {this.state.dataSource.map(o => (
            <Select.Option key={o.value}>{o.label}</Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default Autocomplete;
