import {
  ADDRESS_LOAD,
  ADDRESS_LOADED,
  ADDRESS_UNLOAD,
  ADDRESS_LOAD_PROVINCES,
  ADDRESS_LOADED_PROVINCES,
  ADDRESS_SAVE,
  ADDRESS_SAVED,
  ADDRESSES_LOAD,
  ADDRESSES_LOADED,
  ADDRESS_UPDATE_ESHIPPER_ID,
  ADDRESS_SUBMITTED_OK
} from './action_types';

const load = () => ({
  type: ADDRESS_LOAD,
  payload: {}
});

const loaded = payload => ({
  type: ADDRESS_LOADED,
  payload: payload
});

const unload = () => ({
  type: ADDRESS_UNLOAD,
  payload: {}
});

const loadProvinces = () => ({
  type: ADDRESS_LOAD_PROVINCES,
  payload: {}
});

const loadedProvinces = payload => ({
  type: ADDRESS_LOADED_PROVINCES,
  payload: payload
});

const save = payload => ({
  type: ADDRESS_SAVE,
  payload: payload
});

const saved = payload => ({
  type: ADDRESS_SAVED,
  payload: payload
});

const watchSearchAddresses = payload => ({
  type: ADDRESSES_LOAD,
  payload: payload
});

const searchAddressesResult = payload => ({
  type: ADDRESSES_LOADED,
  payload: payload
});

const updateEShipperId = payload => ({
  type: ADDRESS_UPDATE_ESHIPPER_ID,
  payload: payload
});

const submittedOk = payload => ({
  type: ADDRESS_SUBMITTED_OK,
  payload: payload
});

export {
  load,
  loaded,
  unload,
  loadProvinces,
  loadedProvinces,
  save,
  saved,
  watchSearchAddresses,
  searchAddressesResult,
  updateEShipperId,
  submittedOk
};
