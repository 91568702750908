import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { TextFilter, OptionFilter, NumberFilter, BooleanFilter, DateFilter } from './FilterOptions';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button } from 'antd';

class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: this.props.filters,
    };
  }

  onClearForm = () => {
    this.props.form.resetFields();
    this.props.onSearch({});
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSearch(this.cleanValues(values));
      }
    });
  };

  cleanValues = (values) => {
    let filtered = Object.values(values).filter(
      (v) =>
        this.transformValue(v.value) !== '' ||
        (v.values && v.values.filter((vr) => vr !== undefined && this.transformValue(vr) !== '').length > 0)
    );
    let result = {};
    filtered.forEach((f) => {
      let value = this.transformValue(f.value);
      if (value !== '') {
        result[f._field] = Object.assign({}, f, { value: value, values: [] });
      } else {
        result[f._field] = Object.assign({}, f, {
          value: '',
          values: f.values.filter((v) => v !== undefined).map((v) => this.transformValue(v)),
        });
      }
    });
    return result;
  };

  transformValue = (value) => {
    if (_.isUndefined(value) || _.isNull(value)) {
      return '';
    } else if (_.isNumber(value)) {
      return value;
    } else if (_.isBoolean(value)) {
      return value;
    } else if (_.isString(value)) {
      return value.trim();
    } else if (moment.isMoment(value)) {
      return value
        .hour(0)
        .minutes(0)
        .seconds(0)
        .format('YYYY-MM-DD');
    } else {
      return '';
    }
  };

  render() {
    let items = this.state.filters.map((item, key) => {
      switch (item.type) {
        case 'OPTION':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <OptionFilter
                form={this.props.form}
                options={item.options}
                field={item.field}
                operator={item.operator}
              ></OptionFilter>
            </Col>
          );
        case 'NUMBER':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <NumberFilter form={this.props.form} field={item.field} operator={item.operator}></NumberFilter>
            </Col>
          );
        case 'YESNO':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <BooleanFilter form={this.props.form} field={item.field} operator={item.operator}></BooleanFilter>
            </Col>
          );
        case 'DATE':
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <DateFilter form={this.props.form} field={item.field} operator={item.operator}></DateFilter>
            </Col>
          );
        default:
          return (
            <Col span={8} key={key} style={{ paddingBottom: 8 }}>
              <TextFilter form={this.props.form} field={item.field} operator={item.operator}></TextFilter>
            </Col>
          );
      }
    });
    return (
      <Form id="filterForm" onSubmit={this.onSubmit}>
        <Row gutter={24} style={{ padding: 16 }}>
          {items}
          <Col span={24} style={{ float: 'right' }}>
            <div style={{ float: 'right' }}>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
              <span>&nbsp;&nbsp;</span>
              <Button onClick={this.onClearForm}>Clear</Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: '' })(FilterForm);
