import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Select } from 'antd';

const { Option } = Select;

export default class TextFilter extends Component {
  filterOperator = [
    { id: 'CONTAIN', label: 'Contain' },
    { id: 'EQUAL', label: 'Equal' },
    { id: 'NOT_EQUAL', label: 'Not Equal' },
    { id: 'DOES_NOT_CONTAIN', label: 'Does not Contain' },
    { id: 'STARTS_WITH', label: 'Start With' },
    { id: 'ENDS_WITH', label: 'End With' }
  ];

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={24}>
        <Col span={8}>
          {getFieldDecorator(`${this.props.field}._field`, {
            initialValue: this.props.field
          })(<Input type="hidden" />)}
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.operator`, {
              initialValue: this.props.operator ? this.props.operator : this.filterOperator[0].id,
              rules: [{ required: true }]
            })(
              <Select style={{ width: '100%' }}>
                {this.filterOperator.map(item => (
                  <Option key={item.id}>{item.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.value`, {
              rules: []
            })(<Input placeholder={this.props.field} allowClear />)}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}
