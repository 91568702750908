import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Autocomplete } from 'components';
import { Row, Col, Space, Button, Popconfirm, Table } from 'antd';
import { PRICE_OVERRIDE_DELETE_SUCCESS } from 'redux/actions/priceOverride/action_types';
import { toDateString, toPrice } from 'Utils/utils';
import { withEventBus, withScreenLoader } from 'context';
import * as priceOverrideActions from 'redux/actions/priceOverride';
import * as priceOverrideSelectors from 'redux/selectors/priceOverride';
import styles from './priceOverride.module.less';

class PriceOverride extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: this.props.customerId
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRICE_OVERRIDE_DELETE_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.props.loadByCustomerId(this.state.customerId);
      })
    );
    this.handleChange(this.state.customerId);
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  edit = row => {
    this.props.history.push(`/marketing/priceOverride/edit/customer/${this.state.customerId}/price/${row.id}/`);
  };

  handleChange = value => {
    if (value && value.trim() !== '') {
      this.setState({
        customerId: value
      });
      this.props.loadByCustomerId(value);
    }
  };

  handleDelete = id => {
    this.props.showScreenLoader();
    this.props.deleteById(id);
  };

  render() {
    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'productName'
      },
      {
        title: 'Attribute Criteria',
        dataIndex: 'attributes',
        render: record => JSON.stringify(record)
      },
      {
        title: 'Price',
        dataIndex: 'price',
        render: p => toPrice(p)
      },
      {
        title: 'Updated On',
        dataIndex: 'updatedOn',
        render: p => toDateString(p)
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (id, row) => (
          <Space>
            <Button type="link" onClick={() => this.edit(row)}>
              edit
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(id)}>
              <Button type="link">delete</Button>
            </Popconfirm>
          </Space>
        )
      }
    ];
    return (
      <div className={styles.priceOverride}>
        <h2 className="page-title">Price Overrides</h2>
        <div className={styles.form}>
          <Row gutter={24}>
            <Col span={12}>
              <Autocomplete.Customer onChange={this.handleChange} />
            </Col>
          </Row>
          {this.props.isLoaded && (
            <React.Fragment>
              <Row gutter={24}>
                <Col span={24}>
                  <div className={styles.btnContainer}>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.props.history.push(`/marketing/priceOverride/add/customer/${this.state.customerId}/`)
                      }
                    >
                      Add Price
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} style={{ overflowX: 'auto' }}>
                  <Table
                    rowKey="id"
                    className={styles.pricesTable}
                    dataSource={this.props.priceOverrides}
                    columns={columns}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadByCustomerId: priceOverrideActions.loadByCustomerId,
  unload: priceOverrideActions.unload,
  deleteById: priceOverrideActions.deleteById
};

const mapStateToProps = (state, ownProps) => ({
  priceOverride: priceOverrideSelectors.getPriceOverride(state),
  priceOverrides: priceOverrideSelectors.getPriceOverrides(state),
  isLoaded: priceOverrideSelectors.isLoaded(state),
  customerId: ownProps.match.params.customerId || ''
});

export default connect(mapStateToProps, mapDispatchToProps)(withScreenLoader(withEventBus(PriceOverride)));
