import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
  PHONE_LOAD,
  PHONE_SEARCH,
  PHONE_UPDATE,
  PHONE_CREATE,
  PHONE_IMAGE_SMALL_UPLOAD,
  PHONE_IMAGE_LARGE_UPLOAD,
  PHONE_LOAD_ALL
} from '../actions/phone/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as phoneService from '../services/phone';
import * as phoneActions from 'redux/actions/phone';
import * as listActions from 'redux/actions/list';
import * as phoneSelectors from 'redux/selectors/phone';

function* search() {
  yield takeLatest(PHONE_SEARCH, function*(action) {
    try {
      let phones = yield call(phoneService.search, action.payload);
      yield put(listActions.listLoaded(phones));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findById() {
  yield takeLatest(PHONE_LOAD, function*(action) {
    try {
      let phone = yield call(phoneService.find, action.payload);
      yield put(phoneActions.phoneLoaded(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* save() {
  yield takeLatest(PHONE_CREATE, function*(action) {
    try {
      let phone = yield call(phoneService.create, action.payload);
      yield put(phoneActions.phoneCreateUpdateSucess(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* uploadSmallImage() {
  yield takeLatest(PHONE_IMAGE_SMALL_UPLOAD, function*(action) {
    try {
      let phone = yield call(phoneService.uploadSmallImage, action.payload);
      yield put(phoneActions.phoneCreateUpdateSucess(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* uploadLargeImage() {
  yield takeLatest(PHONE_IMAGE_LARGE_UPLOAD, function*(action) {
    try {
      let phone = yield call(phoneService.uploadLargeImage, action.payload);
      yield put(phoneActions.phoneCreateUpdateSucess(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* update() {
  yield takeLatest(PHONE_UPDATE, function*(action) {
    try {
      let phone = yield call(phoneService.update, action.payload);
      yield put(phoneActions.phoneCreateUpdateSucess(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* loadAll() {
  yield takeLatest(PHONE_LOAD_ALL, function*(action) {
    try {
      let phones = yield select(phoneSelectors.getAll);
      if (phones && phones.length > 0) {
        return;
      }
      phones = yield call(phoneService.loadAll, action.payload);
      yield put(phoneActions.loadedAll(phones));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* imageSliderSagas() {
  yield all([search(), findById(), save(), update(), uploadSmallImage(), uploadLargeImage(), loadAll()]);
}
