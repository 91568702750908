import { fetchApi } from 'Utils/utils';

let userLogin = function(userLoginData) {
  return fetchApi.post('/api/v1/login/', userLoginData);
};

let userLogout = function() {
  return fetchApi.post('/api/v1/logout/', {});
};

export { userLogin, userLogout };
