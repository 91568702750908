import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider } from 'antd';
import * as phoneBrandSelector from 'redux/selectors/phoneBrand';
import * as phoneBrandActions from 'redux/actions/phoneBrand';
import { Spinner, Navbar } from 'components';
import PhoneBrandForm from '../Form/phoneBrandForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PHONE_BRAND_CREATE_UPDATE_SUCCESS } from 'redux/actions/phoneBrand/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './phoneBrandEdit.module.less';

class PhoneBrandEdit extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.find(this.props.match.params.id);
    this.subscriptions.push(
      this.props.eventBusSubscribe(PHONE_BRAND_CREATE_UPDATE_SUCCESS, action => {
        this.props.history.push(`/catalog/phoneBrand/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.update(formData);
  };

  componentWillUnmount() {
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    let content;
    if (this.props.isLoaded) {
      content = (
        <PhoneBrandForm
          key={0}
          brands={this.props.allBrands}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={this.props.phoneBrand}
        ></PhoneBrandForm>
      );
    } else {
      content = (
        <div>
          <Spinner />
        </div>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={this.props.phoneBrand.name}>
          <Link type="primary" shape="circle" to={'/catalog/phoneBrand/create/'}>
            <Tooltip placement="rightTop" title="Create Phone Brand">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/catalog/phonephoneBrand/'}>
            <Tooltip placement="rightTop" title="List Phone Brand">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  find: phoneBrandActions.find,
  update: phoneBrandActions.update,
  unload: phoneBrandActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: phoneBrandSelector.isLoaded(state),
    phoneBrand: phoneBrandSelector.get(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(PhoneBrandEdit));
