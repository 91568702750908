import { fetchApi } from 'Utils/utils';

let addCustomer = function(payload) {
  return fetchApi.put(`/api/v1/store/group/${payload.id}/`, payload);
};

let search = function(payload) {
  return fetchApi.post('/api/v1/store/group/search/', payload);
};

let create = function(payload) {
  return fetchApi.post('/api/v1/store/group/', payload);
};

let remove = function(id) {
  return fetchApi.delete(`/api/v1/store/group/${id}/`);
};

let removeCustomer = function(groupId, customerId) {
  return fetchApi.delete(`/api/v1/store/group/${groupId}/customer/${customerId}/`);
};

let find = function(id) {
  return fetchApi.get(`/api/v1/store/group/${id}/`);
};

export { create, search, addCustomer, remove, removeCustomer, find };
