import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch, Row, Col, Select, Input, Button, InputNumber } from 'antd';
import _ from 'lodash';
import { Autocomplete } from 'components';
import styles from './productVariantSelector.module.less';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

class ProductVariantSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: {},
      not: {}
    };
  }

  componentDidUpdate(nextProps) {
    let { initialPhoneBrands } = this.props;
    if (initialPhoneBrands !== nextProps.initialPhoneBrands) {
      this.props.loadPhonesByBrandIds(this.props.initialPhoneBrands);
    }
  }

  toggleEmpty = code => {
    this.setState({ disabled: Object.assign({}, this.state.disabled, { [code]: !this.state.disabled[code] }) });
  };

  toggleNot = code => {
    this.setState({
      not: Object.assign({}, this.state.not, { [code]: !this.state.not[code] })
    });
  };

  requiredCriteria = code => this.state.not[code] && !this.state.disabled[code];

  isBrandSelected = () => {
    let brand = this.props.form.getFieldValue('selectionCriteria.phoneBrand');
    return brand != null && brand.length > 0;
  };

  renderList = (getFieldDecorator, a, attrVals) => {
    let attrVal = attrVals.find(av => a.code === av.code) || {};
    return (
      <Col sm={24} md={12} key={a.code} className={styles[a.code]}>
        <Form.Item label={a.label}>
          {getFieldDecorator(`selectionCriteria.attributes.${a.code}.values`, {
            initialValue: attrVal.values,
            rules: [{ required: this.requiredCriteria(a.code), message: `${a.code} is required.` }]
          })(
            <Select mode="multiple" disabled={this.state.disabled[a.code]}>
              {a.value.map((v, idx) => (
                <Option value={v} key={idx}>
                  {v}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Row>
          <Col sm={6}>
            <Form.Item {...formItemLayout} label="Except">
              {getFieldDecorator(`selectionCriteria.attributes.${a.code}.not`, {
                initialValue: attrVal.not,
                valuePropName: 'checked'
              })(<Switch onChange={() => this.toggleNot(a.code)} />)}
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item {...formItemLayout} label="Is Empty">
              {getFieldDecorator(`selectionCriteria.attributes.${a.code}.isEmpty`, {
                initialValue: attrVal.isEmpty,
                valuePropName: 'checked'
              })(<Switch onChange={() => this.toggleEmpty(a.code)} />)}
            </Form.Item>
          </Col>
        </Row>
      </Col>
    );
  };

  renderOther = (getFieldDecorator, a, attrVals) => {
    let attrVal = attrVals.find(av => a.code === av.code) || {};
    return (
      <Col sm={24} md={12} key={a.code}>
        <Form.Item label={a.label}>
          {getFieldDecorator(`selectionCriteria.attributes.${a.code}.values[0]`, {
            initialValue: attrVal.values && attrVal.values.length > 0 ? attrVal.values[0] : '',
            rules: [{ required: this.requiredCriteria(a.code), message: `${a.code} is required.` }]
          })(<Input disabled={this.state.disabled[a.code]} />)}
        </Form.Item>
        <Row>
          <Col sm={6}>
            <Form.Item {...formItemLayout} label="Except">
              {getFieldDecorator(`selectionCriteria.attributes.${a.code}.not`, {
                initialValue: attrVal.not,
                valuePropName: 'checked'
              })(<Switch onChange={() => this.toggleNot(a.code)} />)}
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item {...formItemLayout} label="Is Empty">
              {getFieldDecorator(`selectionCriteria.attributes.${a.code}.isEmpty`, {
                initialVal: attrVal.isEmpty,
                valuePropName: 'checked'
              })(<Switch onChange={() => this.toggleEmpty(a.code)} />)}
            </Form.Item>
          </Col>
        </Row>
      </Col>
    );
  };

  loadPhones = () => {
    this.props.form.setFields({
      'selectionCriteria.attributes.phone.values': {
        value: []
      }
    });
    let phoneBrandIds = this.props.form.getFieldValue('selectionCriteria.phoneBrand');
    if (phoneBrandIds && phoneBrandIds.length > 0) this.props.loadPhonesByBrandIds(phoneBrandIds);
  };

  renderPhone = (getFieldDecorator, attr, attrVals, initialPhoneBrands) => {
    let attrVal = attrVals.find(av => av.code === 'phone') || {};
    return (
      <Col sm={24} md={24} key={attr.code}>
        <Row gutter={24}>
          <Col sm={24} md={12} key="phoneBrand">
            <Form.Item label="Phone Brand">
              <Row gutter={4}>
                <Col span={20}>
                  {getFieldDecorator('selectionCriteria.phoneBrand', { initialValue: initialPhoneBrands })(
                    <Select mode="multiple" showSearch optionFilterProp="children">
                      {this.props.allPhoneBrands.map(pb => (
                        <Option value={pb.id} key={pb.id}>
                          {pb.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Col>
                <Col span={4}>
                  <Button onClick={this.loadPhones}>Load Phones</Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col sm={24} md={12} key="phone">
            <Form.Item label="Phone">
              {getFieldDecorator('selectionCriteria.attributes.phone.values', {
                initialValue: attrVal.values,
                rules: [
                  {
                    required: this.requiredCriteria('phone') || this.isBrandSelected(),
                    message: 'Phone is required.'
                  }
                ]
              })(
                <Select mode="multiple" showSearch optionFilterProp="children" disabled={this.state.disabled['phone']}>
                  {this.props.phones.map(p => (
                    <Option value={p.id} key={p.id}>
                      {p.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Row>
              <Col sm={6}>
                <Form.Item {...formItemLayout} label="Except">
                  {getFieldDecorator('selectionCriteria.attributes.phone.not', {
                    initialValue: attrVal.not,
                    valuePropName: 'checked'
                  })(<Switch onChange={() => this.toggleNot('phone')} />)}
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item {...formItemLayout} label="Is Empty">
                  {getFieldDecorator('selectionCriteria.attributes.phone.isEmpty', {
                    initialValue: attrVal.isEmpty,
                    valuePropName: 'checked'
                  })(<Switch onChange={() => this.toggleEmpty('phone')} />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  renderSelector = (getFieldDecorator, attr, attrVals, initialPhoneBrands) => {
    switch (attr.type) {
      case 'LIST':
        return this.renderList(getFieldDecorator, attr, attrVals);
      case 'PHONE':
        return this.renderPhone(getFieldDecorator, attr, attrVals, initialPhoneBrands);
      default:
        return this.renderOther(getFieldDecorator, attr, attrVals);
    }
  };

  render() {
    let attrs = _.chunk(
      this.props.attributes.sort((a, b) => a.order - b.order),
      2
    );
    const getFieldDecorator = this.props.getFieldDecorator;
    let attrVals = this.props.attributeValues || [];
    let initialPhoneBrands = this.props.initialPhoneBrands || [];
    return (
      <div className={styles.productVariantSelector}>
        {this.props.productId === 0 && (
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Product">
                {getFieldDecorator('selectionCriteria.productId', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Product is required.'
                    }
                  ]
                })(<Autocomplete.Product onChange={this.props.handleProductChange} />)}
              </Form.Item>
            </Col>
          </Row>
        )}
        {this.props.productId !== 0 && (
          <React.Fragment>
            {attrs.map((attr, i) => (
              <Row gutter={24} key={i}>
                {attr.map(a => this.renderSelector(getFieldDecorator, a, attrVals, initialPhoneBrands))}
              </Row>
            ))}
            {!this.props.disableVariantNumber && (
              <Row gutter={24}>
                <Col sm={24} md={12} key="variantNumber">
                  <Form.Item label="Variant Number">
                    {getFieldDecorator('selectionCriteria.variantNumber', { initialValue: 0 })(
                      <InputNumber min={0} step={1} precision={0} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const parseSelectionCriteria = (payload, attributes, productId) => {
  delete payload['selectionCriteria']['phoneBrand'];
  payload['selectionCriteria']['productId'] = productId;
  let attrs = [];
  attributes.forEach(a => {
    let attr = {};
    let selectionAttr = payload['selectionCriteria']['attributes'][a.code];
    attr.code = a.code;
    attr.isEmpty = selectionAttr.isEmpty || false;
    attr.not = selectionAttr.not || false;
    if (!attr.isEmpty) {
      if (!selectionAttr['values'] || !selectionAttr['values'][0]) {
        attr.values = [];
      } else {
        attr.values = selectionAttr.values;
      }
    } else {
      attr.values = [];
    }
    attrs.push(attr);
  });
  payload['selectionCriteria']['attributes'] = attrs;
  return payload;
};

export default ProductVariantSelector;
export { parseSelectionCriteria };
