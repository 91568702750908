import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { Modal, Space, Input, Row, Col, DatePicker, Button, Table, Form, Descriptions } from 'antd';
import { get } from 'redux/services/dormantCustomersReport';
import { findRecentOrders } from 'redux/services/customerOrderReport';
import { GlobalError } from 'components';
import { handleErrors } from 'Utils/errorHandler';
import { toPrice, toDateString, toDateWithoutTimeString } from 'Utils/utils';
import { useHistory } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export default () => {
  const disabledDate = current => current > moment().endOf('day');
  const [form] = Form.useForm();
  const [reportData, setReportData] = useState([]);
  const [sorter, setSorter] = useState({});
  const [searchText, setSearchText] = useState({});
  const [globalErrors, setGlobalErrors] = useState([]);
  const history = useHistory();
  const [details, setDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);

  const loadReport = values => {
    let selectedDate = values.dateRange;
    let fromDate = toDateString(selectedDate[0]).split(' ')[0];
    let toDate = toDateString(selectedDate[1]).split(' ')[0];
    (async () => {
      try {
        let request = {
          startDate: fromDate,
          endDate: toDate
        };
        let response = await get(request);
        setReportData(
          response.map((d, idx) =>
            Object.assign({}, d, {
              id: idx + 1
            })
          )
        );
      } catch (err) {
        setGlobalErrors(handleErrors(err, history));
      }
    })();
  };

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEntner={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: text =>
      searchText[dataIndex] ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText[dataIndex]]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(Object.assign({}, searchText, { [dataIndex]: selectedKeys[0] }));
  };

  const handleReset = (dataIndex, clearFilters) => {
    clearFilters();
    let cleared = Object.assign({}, searchText);
    delete cleared[dataIndex];
    setSearchText(cleared);
  };

  const strColumn = (title, key) => ({
    title: title,
    dataIndex: key,
    key: key,
    sorter: (a, b) => (a[key] ? a[key].localeCompare(b[key]) : -1),
    sortOrder: sorter.columnKey === key && sorter.order,
    ...getColumnSearchProps(key, title)
  });

  const numColumn = (title, key) => ({
    title: title,
    dataIndex: key,
    key: key,
    sorter: (a, b) => (a[key] ? a[key] - b[key] : -1),
    sortOrder: sorter.columnKey === key && sorter.order,
    ...getColumnSearchProps(key, title)
  });

  const viewDetails = row => {
    (async () => {
      let recentOrders = await findRecentOrders(row.customerId, row.eshipperId);
      setOrderDetails(recentOrders);
      setDetails(row);
    })();
  };

  const columns = [
    numColumn('#', 'id'),
    strColumn('First Name', 'firstName'),
    strColumn('Middle Name', 'middleName'),
    strColumn('Last Name', 'lastName'),
    numColumn('Customer Id', 'eshipperId'),
    strColumn('Company', 'company'),
    strColumn('Phone', 'phone'),
    strColumn('Street 1', 'street1'),
    strColumn('Province', 'province'),
    strColumn('Customer Since', 'customerSince'),
    numColumn('# Orders', 'noOfOrders'),
    {
      title: 'Actions',
      render: (_, row) => (
        <Fragment>
          <Button onClick={() => viewDetails(row)}>View details</Button>
        </Fragment>
      )
    }
  ];

  const handleTableChange = (_pagination, _filters, sorter) => {
    setSorter(sorter);
  };

  return (
    <div>
      <h2 className="page-title">Dormant Customers Report</h2>
      <GlobalError errors={globalErrors} />
      <Form form={form} onFinish={loadReport}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              name="dateRange"
              label="Date range"
              rules={[{ required: true, message: 'Date range is required.' }]}
            >
              <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" disabledDate={disabledDate} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={2}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Load Report
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {reportData && (
            <Table
              rowKey="id"
              columns={columns}
              dataSource={reportData}
              onChange={handleTableChange}
              pagination={{ pageSize: 500 }}
              sticky={true}
              scroll={{ x: true, y: 'calc(100vh - 210px)' }}
            />
          )}
        </Col>
      </Row>
      {details && (
        <Modal
          footer={null}
          visible={true}
          onCancel={() => setDetails(null)}
          centered
          width={1000}
          title="Detailed Report"
        >
          <>
            <h2>Customer Details</h2>
            <Descriptions bordered column={2}>
              <Descriptions.Item label="Name">
                {details.firstName} {details.middleName} {details.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{details.email}</Descriptions.Item>
              <Descriptions.Item label="Phone">{details.phone}</Descriptions.Item>
              <Descriptions.Item label="Company">{details.company}</Descriptions.Item>
              <Descriptions.Item label="Customer Id">{details.eshipperId}</Descriptions.Item>
              <Descriptions.Item label="Province">{details.province}</Descriptions.Item>
              <Descriptions.Item label="Customer Since">{details.customerSince}</Descriptions.Item>
            </Descriptions>
            <h2>Customer Order Details</h2>
            <Descriptions bordered column={2}>
              <Descriptions.Item label="No. of orders">{details.noOfOrders}</Descriptions.Item>
              <Descriptions.Item label="Lifetime invoice total">
                {toPrice(details.lifeTimeInvoiceTotal)}
              </Descriptions.Item>
              <Descriptions.Item label="Total invoice discount">
                {toPrice(details.totalInvoiceDiscount)}
              </Descriptions.Item>
              <Descriptions.Item label="Avg. order total">{toPrice(details.avgOrderTotal)}</Descriptions.Item>
              <Descriptions.Item label="Avg. shipping charges">{toPrice(details.avgShippingCharges)}</Descriptions.Item>
            </Descriptions>
            {orderDetails.length > 0 && (
              <>
                <h2>Most recent order details</h2>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Order Date</th>
                      <th>Order Code</th>
                      <th>Invoice Code</th>
                      <th>Invoice Amt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails.map(d => (
                      <tr key={d.orderCode}>
                        <td>{toDateWithoutTimeString(d.orderDate)}</td>
                        <td>{d.orderCode}</td>
                        <td>{d.invoiceCode}</td>
                        <td style={{ textAlign: 'right' }}>${toPrice(d.invoiceTotal - d.invoiceTaxes)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </>
        </Modal>
      )}
    </div>
  );
};
