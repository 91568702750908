const displayValidationErrors = (form, payload) => {
  let errors = flattenObject(payload.entityErrors);
  let fields = [];
  Object.keys(errors).forEach(key => {
    let obj = {
      name: key,
      value: form.getFieldValue(key),
      errors: errors[key]
    };
    fields.push(obj);
  });
  if (fields.length > 0) form.setFields(fields);
  return payload.globalErrors;
};

const getGlobalErrors = err => {
  return err.response.globalErrors;
};

const flattenObject = obj => {
  var toReturn = {};

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;

    if (typeof obj[i] == 'object' && obj[i] !== null && !Array.isArray(obj[i])) {
      var flatObject = flattenObject(obj[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = obj[i];
    }
  }
  return toReturn;
};

export { displayValidationErrors, getGlobalErrors };
