import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, Button, Popconfirm } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import {
  SALES_COMM_REMOVE_RATE_SUCCESS,
  SALES_COMM_LOAD_ALL_RATES_SUCCESS,
  SALES_COMM_ADD_PRODUCT_RATE_SUCCESS
} from 'redux/actions/salesComm/action_types';
import * as salesCommActions from 'redux/actions/salesComm';
import * as salesCommSelectors from 'redux/selectors/salesComm';
import * as userSelectors from 'redux/selectors/user';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { toDateString } from 'Utils/utils';
import Add from './components/Add';
import styles from './rates.module.less';

class Rates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      showForm: false
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.loadAllRates();
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_LOAD_ALL_RATES_SUCCESS, action => {
        this.props.hideScreenLoader();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_REMOVE_RATE_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.props.loadAllRates();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_ADD_PRODUCT_RATE_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.setState({ showForm: false });
        this.props.loadAllRates();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        this.props.hideScreenLoader();
        const { form } = this.formRef.props;
        let globalErrors = displayValidationErrors(form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.showScreenLoader();
        this.props.addProductRate(values);
      }
    });
  };

  loadAllRates = () => {
    this.props.showScreenLoader();
    this.props.loadAllRates();
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  columns = () => {
    let cols = [
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName'
      },
      {
        title: 'Sku',
        dataIndex: 'sku',
        key: 'sku'
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        render: (rate, row) => (row.rateType === 'FIXED' ? rate : `${rate}%`)
      },
      {
        title: 'Valid From',
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: d => toDateString(d)
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (val, row) => row.createdBy.name
      }
    ];
    if (!this.props.isSalesRep) {
      cols.push({
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Popconfirm title="Sure to remove?" onConfirm={() => this.handleRemove(id)}>
            <a href="/">Remove</a>
          </Popconfirm>
        )
      });
    }
    return cols;
  };

  handleRemove = id => {
    this.props.showScreenLoader();
    this.props.removeRate(id);
  };

  render() {
    const dataSource = this.props.rates;
    return (
      <div className={styles.rates}>
        <h2 className="page-title">Commission Rates</h2>
        {!this.props.isSalesRep && (
          <Row>
            <Col span={24}>
              <Button size="large" type="primary" onClick={() => this.setState({ showForm: true })}>
                Add Product
              </Button>
            </Col>
          </Row>
        )}
        <Table
          className={styles.ratesTable}
          pagination={false}
          rowKey="id"
          columns={this.columns()}
          dataSource={dataSource}
        />
        <Add
          wrappedComponentRef={this.saveFormRef}
          handleSubmit={this.handleSubmit}
          handleClose={() => this.setState({ showForm: false })}
          visible={this.state.showForm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadAllRates: salesCommActions.loadAllRates,
  removeRate: salesCommActions.removeRate,
  addProductRate: salesCommActions.addProductRate,
  unload: salesCommActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    rates: salesCommSelectors.getRates(state),
    isSalesRep: userSelectors.isSalesRep(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withScreenLoader(withEventBus(Rates)));
