export const getEmployeeContent = store => (store.employee ? store.employee : {});
export const getEmployee = store => {
  return getEmployeeContent(store).employee;
};
export const isLoaded = store => {
  let content = getEmployeeContent(store);
  return content.isLoaded != null && content.isLoaded;
};
export const getStatus = store => {
  return store.employee.employee.status != null && store.employee.employee.status.id;
};
