import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner, Navbar } from 'components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Tooltip, Input, InputNumber, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as widgetSelectors from 'redux/selectors/homepage/widgets';
import { withEventBus, withScreenLoader } from 'context';
import { HOMEPAGE_WIDGETS_SAVE_SUCCESS } from 'redux/actions/homepage/widgets/action_types';
import * as widgetActions from 'redux/actions/homepage/widgets';
import styles from './edit.module.less';

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(HOMEPAGE_WIDGETS_SAVE_SUCCESS, action => {
        this.props.history.push('/options/homepageWidgets/');
      })
    );
    this.props.load(this.props.id);
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.save(values);
      }
    });
  };

  render() {
    if (!this.props.isLoaded) {
      return <Spinner />;
    }
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.editForm}>
        <Navbar title="Edit Widget">
          <Button shape="circle" onClick={() => this.props.history.push('/options/homepageWidgets/')}>
            <Tooltip placement="top" title="List Products">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Button>
        </Navbar>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', { initialValue: this.props.widget.id })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  initialValue: this.props.widget.title,
                  rules: [
                    {
                      required: true,
                      message: 'Title is required.'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Display Title">
                {getFieldDecorator('displayTitle', {
                  initialValue: this.props.widget.displayTitle,
                  valuePropName: 'checked',
                  rules: [
                    {
                      required: true,
                      message: 'Display Title is required.'
                    }
                  ]
                })(<Switch />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Order">
                {getFieldDecorator('order', {
                  initialValue: this.props.widget.order,
                  rules: [
                    {
                      required: true,
                      message: 'Order is required.'
                    }
                  ]
                })(<InputNumber />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  save: widgetActions.save,
  load: widgetActions.load
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: widgetSelectors.isLoaded(state),
    widget: widgetSelectors.getWidget(state),
    id: props.match.params.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(Form.create({ name: 'widgetEditForm' })(Edit))));
