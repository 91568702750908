import { call, put, takeLatest, all } from 'redux-saga/effects';
import { USER_SESSION_SEARCH } from 'redux/actions/userSession/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as userSessionService from '../services/userSession';
import * as listActions from 'redux/actions/list';

function* search() {
  yield takeLatest(USER_SESSION_SEARCH, function*(action) {
    try {
      let userSessions = yield call(userSessionService.search, action.payload);
      yield put(listActions.listLoaded(userSessions));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

export default function* userSessionSagas() {
  yield all([search()]);
}
