import { call, put, takeLatest, all } from 'redux-saga/effects';
import { CONFIG_LOAD } from '../actions/config/action_types';
import * as configService from '../services/config';
import * as configActions from 'redux/actions/config';
import * as userActions from 'redux/actions/user';

function* fetchConfig() {
  yield takeLatest(CONFIG_LOAD, function*(action) {
    let config = yield call(configService.fetchConfig);
    localStorage.setItem('token', config.user.token);
    yield put([userActions.updateUserInfo(config.user), configActions.configLoaded(config)]);
  });
}

export default function* configSagas() {
  yield all([fetchConfig()]);
}
