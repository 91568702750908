import { fetchApi } from 'Utils/utils';

let searchInvoices = function(params) {
  return fetchApi.post('/api/v1/invoice/search/', params);
};

let findOne = function(id) {
  return fetchApi.get(`/api/v1/invoice/${id}/`);
};

let approveInvoice = function(payload) {
  return fetchApi.put('/api/v1/invoice/update-shipment-to-approve/',payload);
};

let markInvoicePaid = function(payload) {
  return fetchApi.put('/api/v1/invoice/update-to-paid/',payload);
};

export { searchInvoices, findOne, approveInvoice, markInvoicePaid };
