export const getRoleContent = store => (store.role ? store.role : {});
export const getRole = store => {
  return getRoleContent(store).role;
};
export const getAllRoles = store => {
  return getRoleContent(store).roles;
};
export const isLoaded = store => {
  let content = getRoleContent(store);
  return content.isLoaded != null && content.isLoaded;
};

export const foundRoleById = store => (store ? store : { role: {} });
export const foundAllRoles = store => (store ? store : { roles: [] });
