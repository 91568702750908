import React from 'react';
import styles from './imagegallery.module.less';

export default class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: props.images[0],
      idx: 0
    };
  }

  changeImage = (img, idx) => this.setState({ current: img, idx: idx });

  render() {
    return (
      <div className={styles.imageGallery}>
        <div className={styles.activeImage}>
          <img src={this.state.current.path} alt={this.state.current.alt} />
        </div>
        <div className={styles.thumbnails}>
          {this.props.images.map((img, idx) => (
            <img
              src={img.path}
              alt={img.alt}
              key={idx}
              onClick={() => this.changeImage(img, idx)}
              className={idx === this.state.idx ? styles.active : ''}
            />
          ))}
        </div>
      </div>
    );
  }
}
