import { HOMEPAGE_CONTENT_LOAD, HOMEPAGE_SET_MESSAGES, HOMEPAGE_UNSET_MESSAGES } from './action_types';

const loadHomepageContent = () => ({
  type: HOMEPAGE_CONTENT_LOAD,
  payload: {}
});

const setMessages = payload => ({
  type: HOMEPAGE_SET_MESSAGES,
  payload: payload
});

const unsetMessages = () => ({
  type: HOMEPAGE_UNSET_MESSAGES,
  payload: {}
});

export { loadHomepageContent, setMessages, unsetMessages };
