import { SLIDER_VIEW_LOADED, SLIDER_UNLOAD } from 'redux/actions/imageSlider/action_types';

const initialStore = {
  isLoaded: false,
  imageSlide: {}
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    imageSlide: payload
  });

export default function imageSlide(state = initialStore, action) {
  switch (action.type) {
    case SLIDER_VIEW_LOADED:
      return load(state, action.payload);
    case SLIDER_UNLOAD:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
