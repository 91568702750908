import { ERROR_401, ERROR_403, ERROR_404, ERROR_422, ERROR_500 } from './action_types';

const error401 = payload => ({
  type: ERROR_401,
  payload: payload
});

const error403 = payload => ({
  type: ERROR_403,
  payload: payload
});

const error404 = payload => ({
  type: ERROR_404,
  payload: payload
});

const error422 = payload => ({
  type: ERROR_422,
  payload: payload
});

const error500 = payload => ({
  type: ERROR_500,
  payload: payload
});

const handle = err => {
  switch (err.status) {
    case 401:
      return error401(err);
    case 403:
      return error403(err);
    case 404:
      return error404(err);
    case 422:
      return error422(err.response);
    case 500:
      return error500(err);
    default:
      throw err;
  }
};
export { handle };
