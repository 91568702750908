import { fetchApi } from 'Utils/utils';

let find = function(payload) {
  return fetchApi.get(`/api/v1/phone/${payload}/`);
};

let update = function(payload) {
  return fetchApi.put(`/api/v1/phone/${payload.id}/`, payload);
};

let search = function(payload) {
  return fetchApi.post('/api/v1/phone/search/', payload);
};

let create = function(payload) {
  return fetchApi.post('/api/v1/phone/', payload);
};

let uploadSmallImage = function(payload) {
  return fetchApi.fileUpload('/api/v1/phone/upload-asset/', payload);
};

let uploadLargeImage = function(payload) {
  return fetchApi.fileUpload('/api/v1/phone/upload-large-asset/', payload);
};

let loadAll = function() {
  return fetchApi.get('/api/v1/phone/all/');
};

export { find, update, create, search, uploadSmallImage, uploadLargeImage, loadAll };
