import { fetchApi } from 'Utils/utils';

let searchOrders = function(params) {
  return fetchApi.post('/api/v1/order/search/', params);
};

let findOne = function(id) {
  return fetchApi.get(`/api/v1/order/${id}/`);
};

let cancelOrder = function(payload) {
  return fetchApi.delete(`/api/v1/order/cancel/${payload.id}/`);
};

let holdOrder = function(payload) {
  return fetchApi.put(`/api/v1/order/hold/${payload.id}/`);
};

let cancelHold = function(payload) {
  return fetchApi.put(`/api/v1/order/cancel-hold/${payload.id}/`);
};

export { searchOrders, findOne, cancelOrder, holdOrder, cancelHold };
