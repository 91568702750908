import { fetchApi } from 'Utils/utils';

let findById = function(id) {
  return fetchApi.get(`/api/v1/taxClass/${id}/`);
};

let findAll = function() {
  return fetchApi.get('/api/v1/taxClass/all/');
};

export { findById, findAll }
