import {
  SALES_COMM_LOAD_ALL_REPS_SUCCESS,
  SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS,
  SALES_COMM_UNLOAD,
  SALES_COMM_LOAD_ALL_RATES_SUCCESS,
  SALES_COMM_LOAD_REPORT_SUCCESS,
  SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS
} from 'redux/actions/salesComm/action_types';

const initialStore = {
  isLoaded: false,
  salesReps: [],
  customers: [],
  rates: [],
  report: [],
  commission: {}
};

const loadedAllSalesRep = (state, salesReps) =>
  Object.assign({}, state, {
    isLoaded: true,
    salesReps: salesReps
  });

const loadedRepCustomers = (state, payload) =>
  Object.assign({}, state, {
    customers: payload,
    isLoaded: true
  });

const loadedAllRates = (state, payload) =>
  Object.assign({}, state, {
    rates: payload,
    isLoaded: true
  });

const loadedReport = (state, payload) =>
  Object.assign({}, state, {
    report: payload,
    isLoaded: true
  });

const loadedCommissionDetails = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    commission: payload
  });

export default function salesComm(state = initialStore, action) {
  switch (action.type) {
    case SALES_COMM_LOAD_ALL_REPS_SUCCESS:
      return loadedAllSalesRep(state, action.payload);
    case SALES_COMM_UNLOAD:
      return Object.assign({}, state, initialStore);
    case SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS:
      return loadedRepCustomers(state, action.payload);
    case SALES_COMM_LOAD_ALL_RATES_SUCCESS:
      return loadedAllRates(state, action.payload);
    case SALES_COMM_LOAD_REPORT_SUCCESS:
      return loadedReport(state, action.payload);
    case SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS:
      return loadedCommissionDetails(state, action.payload);
    default:
      return state;
  }
}
