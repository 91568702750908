import {
  HOMEPAGE_WIDGETS_SEARCH,
  HOMEPAGE_WIDGETS_SAVE,
  HOMEPAGE_WIDGETS_SAVE_SUCCESS,
  HOMEPAGE_WIDGETS_REMOVE,
  HOMEPAGE_WIDGETS_LOAD,
  HOMEPAGE_WIDGETS_LOADED,
  HOMEPAGE_WIDGETS_UNLOAD,
  HOMEPAGE_WIDGETS_SAVE_STATIC_IMAGES
} from './action_types';

const load = id => ({
  type: HOMEPAGE_WIDGETS_LOAD,
  payload: { id: id }
});

const loaded = payload => ({
  type: HOMEPAGE_WIDGETS_LOADED,
  payload: payload
});

const search = payload => ({
  type: HOMEPAGE_WIDGETS_SEARCH,
  payload: payload
});

const save = payload => ({
  type: HOMEPAGE_WIDGETS_SAVE,
  payload: payload
});

const saveStaticImages = payload => ({
  type: HOMEPAGE_WIDGETS_SAVE_STATIC_IMAGES,
  payload: payload
});

const saveSuccess = payload => ({
  type: HOMEPAGE_WIDGETS_SAVE_SUCCESS,
  payload: payload
});

const remove = id => ({
  type: HOMEPAGE_WIDGETS_REMOVE,
  payload: { id: id }
});

const unload = payload => ({
  type: HOMEPAGE_WIDGETS_UNLOAD,
  payload: payload
});

export { saveStaticImages, load, loaded, search, save, saveSuccess, remove, unload };
