import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import * as couponSelector from 'redux/selectors/coupon';
import * as couponActions from 'redux/actions/coupon';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as customerGroupSelector from 'redux/selectors/customerGroup';
import { Navbar, Spinner } from 'components';
import CouponForm from '../Form/couponForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COUPON_CREATE_UPDATE_OK } from 'redux/actions/coupon/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './couponAdd.module.less';

class CouponAdd extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.findAllCustGrp();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(COUPON_CREATE_UPDATE_OK, action => {
        this.props.history.push(`/marketing/coupons/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.create(formData);
  };

  componentWillUnmount() {
    this.props.unloadCustGrp();
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    let content;
    var coupon = {
      id: 0,
      version: 0,
      couponFor: {},
      discountRateType: {},
      groupId: {},
      appliesToList: []
    };

    if (!this.props.isCustGrpLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <CouponForm
          key={0}
          data={coupon}
          customerGroups={this.props.allCustGrp}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
        ></CouponForm>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={'Create Coupon'}>
          <Link type="primary" to={'/marketing/coupons/'}>
            <Tooltip placement="top" title="List Coupon">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  findAllCustGrp: customerGroupActions.findAll,
  create: couponActions.create,
  unload: couponActions.unload,
  unloadCustGrp: customerGroupActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    coupon: couponSelector.getCoupon(state),
    allCustGrp: customerGroupSelector.getAllCustomerGrp(state),
    isCustGrpLoaded: customerGroupSelector.isLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(CouponAdd));
