import React from 'react';
import { Navbar } from 'components';
import { LogoutOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, message } from 'antd';

/*const { Header } = Layout;
 */
export default class AppHeader extends React.Component {
  handleButtonClick = e => {
    message.info('Click on left button.');
  };

  handleMenuClick = e => {
    if (e.key === '3') {
      this.props.logout();
    }
  };

  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">
          <ProfileOutlined />
          My Profile
        </Menu.Item>
        <Menu.Item key="3">
          <LogoutOutlined />
          Log out
        </Menu.Item>
      </Menu>
    );

    return (
      <Navbar>
        <Dropdown.Button overlay={menu} icon={<UserOutlined />}>
          {this.props.user.name}
        </Dropdown.Button>
      </Navbar>
    );
  }
}
