import { call, put, takeLatest, all } from 'redux-saga/effects';
import { ROLE_VIEW, ROLE_LIST_ALL } from '../actions/role/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as roleService from '../services/role';
import * as roleActions from 'redux/actions/role';

function* findAllRoles() {
  yield takeLatest(ROLE_LIST_ALL, function*(action) {
    try {
      let role = yield call(roleService.findAllRoles, action.payload);
      yield put(roleActions.foundAllRoles(role));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* findRoleById() {
  yield takeLatest(ROLE_VIEW, function*(action) {
    try {
      let role = yield call(roleService.findRoleById, action.payload);
      yield put(roleActions.foundRoleById(role));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* employeeSagas() {
  yield all([findAllRoles(), findRoleById()]);
}
