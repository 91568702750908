import React, { Component } from 'react';
import _ from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Upload, Row, Col, Button, Input, InputNumber, Switch, Select } from 'antd';

class SelectedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: '4',
      images: [null, null, null, null]
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let imgs = this.state.images.filter(i => i);
        if (imgs.length + '' !== this.state.limit) {
          Modal.error({
            title: 'Image Upload',
            content: 'Please select all images.'
          });
          return;
        }
        const formData = new FormData();
        formData.append('id', 0);
        formData.append('title', values.title);
        formData.append('displayTitle', values.displayTitle);
        formData.append('order', values.order);
        formData.append('type', 'STATIC_IMAGES');
        imgs.forEach(i => formData.append('images', i));
        _.range(this.state.limit).forEach(i => formData.append('urls', values['attributes']['url'][i]));
        this.props.save(formData);
      }
    });
  };

  beforeUpload = (idx, file) => {
    let imgs = Array.from(this.state.images);
    imgs[idx] = file;
    this.setState({
      images: imgs
    });
    return false;
  };

  onRemove = idx => {
    this.beforeUpload(idx, null);
  };

  onCountChange = newCount => {
    let imgs = Array.from(this.state.images);
    let c = _.toInteger(newCount);
    while (c < 4) {
      imgs[c] = null;
      c++;
    }
    this.setState({
      limit: newCount,
      images: imgs
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: 0
          })(<Input type="hidden" />)}
          {getFieldDecorator('type', {
            initialValue: 'STATIC_IMAGES'
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'Title is required.'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="Display Title">
                {getFieldDecorator('displayTitle', {
                  valuePropName: 'checked',
                  initialValue: true,
                  rules: [
                    {
                      required: true,
                      message: 'Display Title is required.'
                    }
                  ]
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="Order">
                {getFieldDecorator('order', {
                  rules: [
                    {
                      required: true,
                      message: 'Order is required.'
                    }
                  ]
                })(<InputNumber min={0} precision={0} />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="Count">
                {getFieldDecorator('attributes.count', {
                  initialValue: this.state.limit,
                  rules: [
                    {
                      required: true,
                      message: 'Count is required.'
                    }
                  ]
                })(
                  <Select onChange={this.onCountChange}>
                    <Select.Option key="1">1</Select.Option>
                    <Select.Option key="2">2</Select.Option>
                    <Select.Option key="3">3</Select.Option>
                    <Select.Option key="4">4</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            {_.range(this.state.limit).map(idx => (
              <Col sm={24} md={6} key={idx}>
                File {idx + 1}:{' '}
                <Upload
                  name={`attributes.images.${idx}`}
                  multiple={false}
                  beforeUpload={file => this.beforeUpload(idx, file)}
                  onRemove={() => this.onRemove(idx)}
                >
                  <Button>
                    <UploadOutlined />
                    Click to upload
                  </Button>
                </Upload>
                <Form.Item label={`Url ${idx + 1}`}>
                  {getFieldDecorator(`attributes.url.${idx}`, {
                    rules: [
                      {
                        required: true,
                        message: 'Url is required'
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create()(SelectedProducts);
