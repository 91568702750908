import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as pageSelectors from 'redux/selectors/staticPages';
import * as pageActions from 'redux/actions/staticPages';
import { PageView, PageEdit } from './components';
import { PAGE_CREATE_UPDATE_SUCCESS } from 'redux/actions/staticPages/action_types';
import { Spinner } from 'components';
import { List, Row, Col, Button } from 'antd';
import { withEventBus } from 'context/eventbus';
import styles from './page.module.less';

class StaticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      mode: 'view'
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.load();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(PAGE_CREATE_UPDATE_SUCCESS, action => {
        this.props.load();
        this.toggleMode();
        this.setState({
          selected: action.payload
        });
      })
    );
  }

  componentWillUnmount() {
    this.props.unload();
  }

  submitForm = values => {
    if (values.id === 0) {
      this.props.create(values);
    } else {
      this.props.update(values);
    }
  };

  createNew = () => {
    this.setState({
      selected: {
        id: 0,
        title: '',
        url: '',
        body: ''
      },
      mode: 'edit'
    });
  };

  toggleMode = () => {
    this.setState({
      mode: this.state.mode === 'view' ? 'edit' : 'view'
    });
  };

  onClickEdit = item => {
    this.setState({
      selected: item,
      mode: 'view'
    });
  };

  selectDefaultPage = () => {
    return this.state.selected != null
      ? this.state.selected
      : this.props.pages.length === 0
      ? { id: 0, body: '' }
      : this.props.pages[0];
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      let selected = this.selectDefaultPage();
      content = (
        <Row gutter={24}>
          <Col md={8} lg={6}>
            <div>
              <List
                header={
                  <div className={styles.buttonContainer}>
                    <h2 className="page-title">Manage Pages</h2>
                    <Button type="primary" size="small" onClick={this.createNew}>
                      Create New
                    </Button>
                  </div>
                }
                bordered
                dataSource={this.props.pages}
                renderItem={item => (
                  <List.Item onClick={() => this.onClickEdit(item)}>
                    <List.Item.Meta title={item.title} />
                  </List.Item>
                )}
              />
            </div>
          </Col>
          <Col md={16} lg={18}>
            {this.state.mode === 'view' && <PageView key={selected.id} page={selected} toggleMode={this.toggleMode} />}
            {this.state.mode === 'edit' && (
              <PageEdit
                key={selected.id}
                page={selected}
                toggleMode={this.toggleMode}
                eventBusSubscribe={this.props.eventBusSubscribe}
                onSubmit={this.submitForm}
              />
            )}
          </Col>
        </Row>
      );
    }
    return <div className={styles.category}>{content}</div>;
  }
}

const mapDispatchToProps = {
  load: pageActions.load,
  unload: pageActions.unload,
  update: pageActions.update,
  create: pageActions.create
};

const mapStateToProps = (state, ownProps) => ({
  isLoaded: pageSelectors.isLoaded(state),
  pages: pageSelectors.getPages(state),
  isUpdated: pageSelectors.isUpdated(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(StaticPage));
