import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import { SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS } from 'redux/actions/salesComm/action_types';
import * as salesCommActions from 'redux/actions/salesComm';
import * as salesCommSelectors from 'redux/selectors/salesComm';
import moment from 'moment';
import styles from './details.module.less';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS, action => {
        this.props.hideScreenLoader();
      })
    );
    this.props.loadCommissionDetails(this.props.commissionId);
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  columns = [
    {
      title: '#',
      dataIndex: 'id',
      render: (value, row, idx) => idx + 1
    },
    {
      title: 'Product Name',
      dataIndex: 'productName'
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      render: value => value.toFixed(0)
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: value => '$' + value.toFixed(2)
    },
    {
      title: 'Commission Rate',
      dataIndex: 'rate',
      render: (value, row) => (row.rateType === 'FIXED' ? '$' + value.toFixed(2) : value.toFixed(2) + '%')
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmt',
      render: value => '$' + value.toFixed(2)
    }
  ];

  render() {
    const c = this.props.commission;
    const dataSource = c.items ? c.items : [];
    return (
      <div className={styles.form}>
        <h2 className="page-title">Commission Details: {this.props.commission.code}</h2>
        <table className={styles.mainTable}>
          <tbody>
            <tr>
              <td>Order #</td>
              <td>{c.orderCode}</td>
              <td>Customer Name</td>
              <td>{c.customerName}</td>
            </tr>
            <tr>
              <td>Invoice #</td>
              <td>{c.invoiceCode}</td>
              <td>Invoice Date</td>
              <td>{moment(c.invoiceDate).format('YYYY-MM-DD')}</td>
            </tr>
            <tr>
              <td>Payment Date</td>
              <td>{c.invoicePaymentDate ? moment(c.invoicePaymentDate).format('YYYY-MM-DD') : ''}</td>
              <td>Payment Status</td>
              <td>{c.status === 'PAID' ? 'Payment Received' : 'Payment Pending'}</td>
            </tr>
            <tr>
              <td>Total Invoice Amt</td>
              <td className={styles.boldText}>${c.totalInvoiceAmt}</td>
              <td>Total Commission Amt</td>
              <td className={styles.boldText}>${c.totalCommissionAmt}</td>
            </tr>
          </tbody>
        </table>
        <Table
          className={styles.detailsTable}
          pagination={false}
          rowKey="id"
          columns={this.columns}
          dataSource={dataSource}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadCommissionDetails: salesCommActions.loadCommissionDetails,
  unload: salesCommActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    commission: salesCommSelectors.getCommission(state),
    commissionId: props.match.params.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(Details)));
