import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Upload, Alert } from 'antd';
import { NO_IMG_URL } from 'Utils/constants';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './imageUploader.module.less';
const { Dragger } = Upload;

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    this.setState({
      globalErrors: []
    });
    const formData = new FormData();
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err && this.state.image != null) {
        formData.append('id', values.id);
        formData.append('image', this.state.image);
        this.props.uploadImage(formData);
      }
    });
  };

  render() {
    let imgUrl = this.props.data ? this.props.data.path : NO_IMG_URL;
    let title = this.props.data ? this.props.data.title : 'No Image';

    const props = {
      name: 'image',
      multiple: false,
      onRemove: file => {
        this.setState({
          image: null
        });
      },
      beforeUpload: file => {
        this.setState({
          image: file
        });
        return false;
      }
    };

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Col span={24}>
          {this.state.globalErrors.map(error => (
            <Alert message={error} type="error" />
          ))}
        </Col>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: this.props.id
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col span={24}>
              <Dragger {...props}>
                <Row gutter={24} className={styles.view}>
                  <Col xs={24} sm={24} md={24} className={styles.imgContainer}>
                    <img src={imgUrl} alt={title} />
                  </Col>
                </Row>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Dragger>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!this.state.image}>
              Change
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: '' })(ImageUploader);
