export const getContent = store => (store.phone ? store.phone : {});
export const get = store => {
  return getContent(store).phone;
};
export const isLoaded = store => {
  let content = getContent(store);
  return content.isLoaded != null && content.isLoaded;
};
export const allLoaded = store => getContent(store).allPhonesLoaded;
export const getAll = store => (allLoaded(store) ? getContent(store).allPhones : []);
