import { call, put, takeLatest, all } from 'redux-saga/effects';
import { CART_SEARCH } from 'redux/actions/cart/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as cartService from '../services/cart';
import * as listActions from 'redux/actions/list';

function* searchCarts() {
  yield takeLatest(CART_SEARCH, function*(action) {
    try {
      let carts = yield call(cartService.search, action.payload);
      yield put(listActions.listLoaded(carts));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

export default function* cartSagas() {
  yield all([searchCarts()]);
}
