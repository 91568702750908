import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Modal } from 'antd';
import ReactQuill from 'react-quill'; // ES6
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './edit.module.less';
import 'react-quill/dist/quill.snow.css';

const { confirm } = Modal;

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  cancel = () => {
    let toggleMode = this.props.toggleMode;
    confirm({
      title: 'Confirm',
      content: 'Do you want to cancel editing?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        toggleMode();
      },
      onCancel() {}
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.edit} style={{ padding: 8 }}>
        <h2 className="page-title">
          {this.props.page.id === 0 ? 'Create New Page' : 'Update page: ' + this.props.page.title}
        </h2>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: this.props.page.id
          })(<Input type="hidden" />)}
          {getFieldDecorator('version', {
            initialValue: this.props.page.version
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'title is required.'
                    }
                  ],
                  initialValue: this.props.page.title
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={6}>
              <Form.Item label="Url">
                {getFieldDecorator('url', {
                  rules: [
                    {
                      required: true,
                      message: 'Url is required.'
                    }
                  ],
                  initialValue: this.props.page.url
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={24}>
              <Form.Item label="Page">
                {getFieldDecorator('body', {
                  rules: [
                    {
                      required: true,
                      message: 'Page is required.'
                    }
                  ],
                  initialValue: this.props.page.body
                })(<ReactQuill onChange={this.handleChange} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item className="form-footer-buttons">
                <Button type="primary" htmlType="submit">
                  {this.props.page.id === 0 ? 'Create' : 'Update'}
                </Button>
                {this.props.page.id !== 0 && <Button onClick={this.cancel}>Cancel</Button>}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: '' })(Edit);
