import {
  PRODUCT_VIEW_LOADED,
  PRODUCT_ATTR_LOADED,
  PRODUCT_CATEGORY_LOADED,
  PRODUCT_UNLOAD
} from 'redux/actions/product/action_types';
import { PRODUCT_VARIANT_VIEW_LOADED } from 'redux/actions/productVariant/action_types';

const initialStore = {
  isLoaded: false,
  product: {},
  categories: [],
  attributes: []
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    product: payload
  });

const loadedAttrs = (state, payload) =>
  Object.assign({}, state, {
    attributes: payload
  });

const loadedCategories = (state, payload) =>
  Object.assign({}, state, {
    categories: payload
  });

export default function product(state = initialStore, action) {
  switch (action.type) {
    case PRODUCT_VIEW_LOADED:
      return load(state, action.payload);
    case PRODUCT_ATTR_LOADED:
      return loadedAttrs(state, action.payload);
    case PRODUCT_CATEGORY_LOADED:
      return loadedCategories(state, action.payload);
    case PRODUCT_UNLOAD:
      return Object.assign({}, state, initialStore);
    case PRODUCT_VARIANT_VIEW_LOADED:
      return load(state, action.payload.product);
    default:
      return state;
  }
}
