import { call, takeLatest, all, put } from 'redux-saga/effects';
import {
  PRODUCT_SEARCH,
  PRODUCT_VIEW,
  PRODUCT_ATTR_LOAD,
  PRODUCT_CATEGORY_LOAD,
  PRODUCT_UPDATE,
  PRODUCT_GENERATE_VARIANTS,
  PRODUCT_REGENERATE_DESC,
  PRODUCT_UPDATE_ATTRIBUTES
} from 'redux/actions/product/action_types';
import * as productService from 'redux/services/product';
import * as productActions from 'redux/actions/product';
import * as listActions from 'redux/actions/list';
import * as apiErrorActions from 'redux/actions/apiError';

function* searchProduct() {
  yield takeLatest(PRODUCT_SEARCH, function*(action) {
    let productResponse = yield call(productService.searchProducts, action.payload);
    yield put(listActions.listLoaded(productResponse));
  });
}

function* load() {
  yield takeLatest(PRODUCT_VIEW, function*(action) {
    let product = yield call(productService.findOne, action.payload);
    yield put(productActions.loaded(product));
  });
}

function* getAttrs() {
  yield takeLatest(PRODUCT_ATTR_LOAD, function*(action) {
    let attrs = yield call(productService.getAttrs, action.payload.productId);
    yield put(productActions.loadedAttrs(attrs));
  });
}

function* getCategories() {
  yield takeLatest(PRODUCT_CATEGORY_LOAD, function*(action) {
    let categories = yield call(productService.getCategories, action.payload.productId);
    yield put(productActions.loadedCategories(categories));
  });
}

function* update() {
  yield takeLatest(PRODUCT_UPDATE, function*(action) {
    try {
      let product = yield call(productService.update, action.payload);
      yield put(productActions.updateSuccess(product));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* generateVariants() {
  yield takeLatest(PRODUCT_GENERATE_VARIANTS, function*(action) {
    try {
      let attributes = yield call(productService.generateVariants, action.payload);
      yield put(productActions.generateVariantsSuccess(attributes));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* regenerateDesc() {
  yield takeLatest(PRODUCT_REGENERATE_DESC, function*(action) {
    try {
      yield call(productService.regenerateDesc, action.payload);
      yield put(productActions.regenerateDescSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateAttrs() {
  yield takeLatest(PRODUCT_UPDATE_ATTRIBUTES, function*(action) {
    try {
      let product = yield call(productService.updateAttributes, action.payload);
      yield put(productActions.updateAttrsSuccess(product));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* productSagas() {
  yield all([
    updateAttrs(),
    searchProduct(),
    load(),
    getAttrs(),
    getCategories(),
    update(),
    generateVariants(),
    regenerateDesc()
  ]);
}
