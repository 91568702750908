import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Tooltip, Divider, Descriptions, Menu, Dropdown, List, Button, Modal, Input } from 'antd';
import { Spinner, Navbar } from 'components';
import * as attributeSelector from 'redux/selectors/attribute';
import * as attributeActions from 'redux/actions/attribute';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withEventBus } from 'context/eventbus';
import { ATTRIBUTE_SUBMIT_OK } from 'redux/actions/attribute/action_types';
import styles from './attributeView.module.less';

const Fragment = React.Fragment;
const { confirm } = Modal;

class AttributeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      listItem: null,
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.findOne({
      id: this.props.match.params.id,
      type: this.props.match.params.type
    });
    this.subscriptions.push(
      this.props.eventBusSubscribe(ATTRIBUTE_SUBMIT_OK, action => {
        this.props.findOne({
          id: this.props.match.params.id,
          type: this.props.match.params.type
        });
        this.setState({
          visible: false
        });
      })
    );
  }

  remove = i => {
    var vm = this;
    confirm({
      title: 'Confirm',
      content: 'Do you want to proceed without saving?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        vm.props.removeAttribute({
          id: vm.props.match.params.id,
          label: i
        });
      },
      onCancel() {}
    });
  };

  inputBinder(event) {
    this.setState({ listItem: event.target.value });
  }

  add = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = i => {
    this.props.addAttribute({
      id: this.props.match.params.id,
      label: this.state.listItem
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    let content;
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Link to={'/catalog/attributes/create/STRING/'}>String attribute</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to={'/catalog/attributes/create/LONG/'}>Number attribute</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to={'/catalog/attributes/create/DOUBLE/'}>Decimal attribute</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to={'/catalog/attributes/create/LIST/'}>List attribute</Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to={'/catalog/attributes/create/YES_NO/'}>Yes no attribute</Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to={'/catalog/attributes/create/DATE/'}>Date attribute</Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to={'/catalog/attributes/create/DATETIME/'}>DateTime attribute</Link>
        </Menu.Item>
      </Menu>
    );
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      var c = this.props.attribute;
      content = (
        <Fragment>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Code">{c.code}</Descriptions.Item>
            <Descriptions.Item label="Label">{c.label}</Descriptions.Item>
            <Descriptions.Item label="Type">{c.type}</Descriptions.Item>
            <Descriptions.Item label="Order">{c.order}</Descriptions.Item>

            <Descriptions.Item label="Is required">{c.required ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Is unique">{c.unique ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Use In Filtering">{c.useInFiltering ? 'Yes' : 'No'}</Descriptions.Item>

            <Descriptions.Item label="System">{c.system ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Use In Frontend">{c.useInFrontend ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Is Configurable">{c.isConfigurable ? 'Yes' : 'No'}</Descriptions.Item>
          </Descriptions>
          {c.type === 'LONG' || c.type === 'DOUBLE' ? (
            <Descriptions bordered size="small">
              <Descriptions.Item label="Minimum">{c.min}</Descriptions.Item>
              <Descriptions.Item label="Maximum">{c.max}</Descriptions.Item>
              <Descriptions.Item label="Decimal">{c.decimal}</Descriptions.Item>
            </Descriptions>
          ) : (
            <div></div>
          )}
          <Descriptions bordered size="small">
            <Descriptions.Item label="Created By">
              {c.createdBy && c.createdBy.name ? c.createdBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Created On">{c.createdOn}</Descriptions.Item>
            <Descriptions.Item label="Updated By">
              {c.updatedBy && c.updatedBy.name ? c.updatedBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Updated On">{c.updatedOn}</Descriptions.Item>
          </Descriptions>
          {c.type === 'LIST' ? (
            <List
              header={
                <div style={{ paddingRight: 10 }}>
                  List Values
                  <Button
                    style={{ float: 'right' }}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={this.add}
                  />
                </div>
              }
              bordered
              dataSource={c.value.filter(v => v !== null)}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      size="small"
                      onClick={() => this.remove(item)}
                    />
                  ]}
                >
                  {item}
                </List.Item>
              )}
            />
          ) : (
            <div></div>
          )}
        </Fragment>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={this.props.attribute.code}>
          <Dropdown type="primary" overlay={menu}>
            <Tooltip placement="rightTop" title="Create attribute">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Dropdown>
          <Divider type="vertical" />
          <Link type="primary" to={'/catalog/attributes/'}>
            <Tooltip placement="top" title="List Attribute">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        {content}
        {
          <Modal title="Add new item" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
            <Input placeholder="value" onChange={e => this.inputBinder(e)}></Input>
          </Modal>
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  findOne: attributeActions.findOne,
  removeAttribute: attributeActions.removeAttribute,
  addAttribute: attributeActions.addAttribute,
  unload: attributeActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: attributeSelector.isLoaded(state),
    attribute: attributeSelector.foundOne(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(AttributeView));
