import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Select, Input } from 'antd';

const { Option } = Select;

export default class OptionFilter extends Component {
  filterOperator = [
    { id: 'IN', label: 'In' },
    { id: 'NOT_IN', label: 'Not In' }
  ];

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={24}>
        <Col span={8}>
          {getFieldDecorator(`${this.props.field}._field`, {
            initialValue: this.props.field
          })(<Input type="hidden" />)}
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.operator`, {
              initialValue: this.props.operator ? this.props.operator : this.filterOperator[0].id,
              rules: [{ required: true }]
            })(
              <Select style={{ width: '100%' }}>
                {this.filterOperator.map(item => (
                  <Option key={item.id}>{item.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.values`, {
              rules: []
            })(
              <Select mode="tags" placeholder={this.props.field} style={{ width: '100%' }}>
                {this.props.options.map(item => (
                  <Option key={item.id}>{item.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}
