import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { Space, Input, Row, Col, DatePicker, Button, Table } from 'antd';
import * as backOrderProductVariantService from 'redux/services/backOrderProductVariant';
import * as productVariantService from 'redux/services/productVariant';
import { GlobalError } from 'components';
import { handleErrors } from 'Utils/errorHandler';
import { toDateString } from 'Utils/utils';
import { useHistory } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export default props => {
  const disabledDate = current => current > moment().endOf('day');
  const [selectedDate, setSelectedDate] = useState(null);
  const [backOrderProductVariants, setBackOrderProductVariants] = useState(null);
  const [sorter, setSorter] = useState({});
  const [searchText, setSearchText] = useState({});
  const [globalErrors, setGlobalErrors] = useState([]);
  const history = useHistory();

  const loadReport = () => {
    if (!selectedDate) {
      return;
    }
    let fromDate = toDateString(selectedDate[0]).split(' ')[0];
    let toDate = toDateString(selectedDate[1]).split(' ')[0];
    (async () => {
      try {
        let response = await backOrderProductVariantService.getReport(fromDate, toDate);
        setBackOrderProductVariants(response);
      } catch (err) {
        setGlobalErrors(handleErrors(err, history));
      }
    })();
  };

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: text =>
      searchText[dataIndex] ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText[dataIndex]]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(Object.assign({}, searchText, { [dataIndex]: selectedKeys[0] }));
  };

  const handleReset = (dataIndex, clearFilters) => {
    clearFilters();
    let cleared = Object.assign({}, searchText);
    delete cleared[dataIndex];
    setSearchText(cleared);
  };

  const columns = [
    {
      title: 'Order Code',
      dataIndex: 'orderCode',
      key: 'orderCode',
      sorter: (a, b) => (a.orderCode ? a.orderCode.localeCompare(b.orderCode) : -1),
      sortOrder: sorter.columnKey === 'orderCode' && sorter.order,
      ...getColumnSearchProps('orderCode', 'Order Code')
    },
    {
      title: 'Variant Number',
      dataIndex: 'variantNumber',
      key: 'variantNumber',
      sorter: (a, b) => (a.variantNumber ? a.variantNumber - b.variantNumber : -1),
      sortOrder: sorter.columnKey === 'variantNumber' && sorter.order,
      ...getColumnSearchProps('variantNumber', 'Variant Number')
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sorter.columnKey === 'productName' && sorter.order,
      ...getColumnSearchProps('productName', 'Product Name')
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: (a, b) => (a.sku ? a.sku.localeCompare(b.sku) : -1),
      sortOrder: sorter.columnKey === 'sku' && sorter.order,
      ...getColumnSearchProps('sku', 'SKU')
    },
    {
      title: 'Rack',
      dataIndex: 'rack',
      key: 'rack',
      sorter: (a, b) => (a.rack ? a.rack.localeCompare(b.rack) : -1),
      sortOrder: sorter.columnKey === 'rack' && sorter.order,
      ...getColumnSearchProps('rack', 'Rack')
    },
    {
      title: 'Processed By',
      dataIndex: 'processedBy',
      key: 'processedBy',
      sorter: (a, b) => (a.processedBy ? a.processedBy.localeCompare(b.processedBy) : -1),
      sortOrder: sorter.columnKey === 'processedBy' && sorter.order,
      ...getColumnSearchProps('processedBy', 'Processed By')
    },
    {
      title: 'Actions',
      dataIndex: 'productVariantId',
      key: 'productVariantId',
      render: (_, row) => (
        <Fragment>
          {row.enabled && row.stockStatus === 'OUT_OF_STOCK' && (
            <Button onClick={() => putInStock(row.productId, row.productVariantId)}>Back In-Stock</Button>
          )}
          {row.enabled && (
            <Button
              type="danger"
              style={{ marginLeft: '8px' }}
              onClick={() => disableProduct(row.productId, row.productVariantId)}
            >
              Disable
            </Button>
          )}
        </Fragment>
      )
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setSorter(sorter);
  };

  const putInStock = (productId, variantId) => {
    (async () => {
      try {
        await productVariantService.updateStatus({
          productId: productId,
          variantId: variantId,
          stockStatus: 'IN_STOCK',
          enabled: true
        });
        loadReport();
      } catch (err) {
        setGlobalErrors(handleErrors(err, history));
      }
    })();
  };

  const disableProduct = (productId, variantId) => {
    (async () => {
      try {
        await productVariantService.updateStatus({
          productId: productId,
          variantId: variantId,
          stockStatus: 'OUT_OF_STOCK',
          enabled: false
        });
        loadReport();
      } catch (err) {
        setGlobalErrors(handleErrors(err, history));
      }
    })();
  };
  return (
    <div>
      <h2 className="page-title">Backorder Products Report</h2>
      <GlobalError errors={globalErrors} />
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <RangePicker
            style={{ width: '100%' }}
            format="YYYY-MM-DD"
            defaultValue={selectedDate}
            disabledDate={disabledDate}
            onChange={date => setSelectedDate(date)}
          />
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={() => loadReport()}>
            Load Report
          </Button>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {backOrderProductVariants && (
            <Table
              rowKey="id"
              columns={columns}
              dataSource={backOrderProductVariants}
              onChange={handleTableChange}
              pagination={{ defaultPageSize: 50 }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
