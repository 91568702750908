import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, InputNumber, Input, Switch, Tooltip, Button, Alert, Descriptions } from 'antd';
import * as attributeActions from 'redux/actions/attribute';
import * as attributeSelector from 'redux/selectors/attribute';
import { Navbar, Spinner } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ATTRIBUTE_SUBMIT_OK } from 'redux/actions/attribute/action_types';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { withEventBus } from 'context/eventbus';

class AttributeEdit extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.findOne({
      id: this.props.match.params.id,
      type: this.props.match.params.type
    });
    this.subscriptions.push(
      this.props.eventBusSubscribe(ATTRIBUTE_SUBMIT_OK, action => {
        this.props.history.push(`/catalog/attributes/${action.payload.id}/${action.payload.type}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.update(formData);
  };

  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <WrappedAttributeEditForm
          key={this.props.attribute.id}
          type={this.props.attribute.type}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={this.props.attribute}
        ></WrappedAttributeEditForm>
      );
    }
    return (
      <div>
        <Navbar title={'Edit attribute - ' + this.props.attribute.label}>
          <Link type="primary" to={'/catalog/attributes/'}>
            <Tooltip placement="top" title="List attributes">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

class AttributeEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.submitHandle(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    getFieldDecorator('value', { initialValue: [] });

    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('type', {
          initialValue: this.props.data.type,
          rules: []
        })(<Input type="hidden" placeholder="type" />)}
        {getFieldDecorator('id', {
          initialValue: this.props.data.id,
          rules: []
        })(<Input type="hidden" placeholder="id" />)}
        <Row gutter={24}>
          <Col span={24}>
            {this.state.globalErrors.map(error => (
              <Alert message={error} type="error" />
            ))}
          </Col>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Code">{this.props.data.code}</Descriptions.Item>
            <Descriptions.Item label="Label">{this.props.data.label}</Descriptions.Item>
            <Descriptions.Item label="Type">{this.props.data.type}</Descriptions.Item>

            <Descriptions.Item label="Is required">{this.props.data.required ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Is unique">{this.props.data.unique ? 'Yes' : 'No'}</Descriptions.Item>

            <Descriptions.Item label="System">{this.props.data.system}</Descriptions.Item>

            <Descriptions.Item label="Is Configurable">
              {this.props.data.isConfigurable ? 'Yes' : 'No'}
            </Descriptions.Item>
          </Descriptions>
          <Col span={8}>
            <Form.Item label="Order">
              {getFieldDecorator('order', {
                rules: [{ required: true, message: 'order is required.' }],
                initialValue: this.props.data.order
              })(<InputNumber style={{ width: '100%' }} placeholder="Order" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Is used in Filtering">
              {getFieldDecorator('useInFiltering', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'this is required.' }],
                initialValue: this.props.data.useInFiltering
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Use in frontend">
              {getFieldDecorator('useInFrontend', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'this is required.' }],
                initialValue: this.props.data.useInFrontend
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedAttributeEditForm = Form.create({ name: 'AttributeEditForm' })(AttributeEditForm);

const mapDispatchToProps = {
  findOne: attributeActions.findOne,
  update: attributeActions.update,
  unload: attributeActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: attributeSelector.isLoaded(state),
    attribute: attributeSelector.foundOne(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(AttributeEdit));
