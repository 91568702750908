import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Descriptions, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { Spinner, Navbar } from 'components';
import * as imageSliderSelectors from 'redux/selectors/imageSlider';
import * as imageSliderActions from 'redux/actions/imageSlider';
import { NO_IMG_URL } from 'Utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ImageSlideView extends Component {
  componentDidMount() {
    this.props.findByUid(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unload();
  }

  generateSlideView = o => {
    let imgUrl = o.asset ? o.asset.path : NO_IMG_URL;
    return (
      <div>
        <Descriptions bordered column={4} size="small">
          <Descriptions.Item label="Forward Link">{o.forwardLink}</Descriptions.Item>
          <Descriptions.Item label="Start Date">{new Date(o.startDate).toLocaleDateString('en-US')}</Descriptions.Item>
          <Descriptions.Item label="End Date">{new Date(o.endDate).toLocaleDateString('en-US')}</Descriptions.Item>
          <Descriptions.Item label="Order">{o.order}</Descriptions.Item>
          <Descriptions.Item label="Created By">
            {o.createdBy && o.createdBy.name ? o.createdBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Created On">{o.createdOn}</Descriptions.Item>
          <Descriptions.Item label="Updated By">
            {o.updatedBy && o.updatedBy.name ? o.updatedBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Updated On">{o.updatedOn}</Descriptions.Item>
        </Descriptions>
        <Divider />
        <Row gutter={24}>
          <Col xs={24} sm={10} md={8}>
            <img src={imgUrl} alt={o.asset} width="1200" />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    let slide = this.props.slide;
    let content;

    if (slide) {
      content = (
        <div>
          <Navbar title={'Slide Image'}>
          <Link type="primary" shape="circle" to={'/options/slides/create/'}>
            <Tooltip placement="rightTop" title="Create Slide">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={`/options/slides/edit/${this.props.match.params.id}/`}>
            <Tooltip placement="rightTop" title="Edit Slide">
              <FontAwesomeIcon icon="edit" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />            
            <Link type="primary" to={'/options/slides/'}>
              <Tooltip placement="top" title="List Slides">
                <FontAwesomeIcon icon="th-list" />
              </Tooltip>
            </Link>
          </Navbar>

          {this.generateSlideView(slide)}
        </div>
      );
    } else {
      content = <Spinner />;
    }
    return <div id="print-mount">{content}</div>;
  }
}

const mapDispatchToProps = {
  findByUid: imageSliderActions.find,
  unload: imageSliderActions.unload
};

const mapStateToProps = (state, ownProps) => ({
  slide: imageSliderSelectors.get(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageSlideView);
