import { call, takeLatest, all, put } from 'redux-saga/effects';
import {
  PRICE_OVERRIDE_BY_CUSTOMER_LOAD,
  PRICE_OVERRIDE_DELETE,
  PRICE_OVERRIDE_UPDATE,
  PRICE_OVERRIDE_LOAD
} from 'redux/actions/priceOverride/action_types';
import * as priceOverrideService from 'redux/services/priceOverride';
import * as priceOverrideActions from 'redux/actions/priceOverride';
import * as apiErrorActions from 'redux/actions/apiError';

function* loadByCustomerId() {
  yield takeLatest(PRICE_OVERRIDE_BY_CUSTOMER_LOAD, function*(action) {
    let priceOverrides = yield call(priceOverrideService.loadByCustomerId, action.payload);
    yield put(priceOverrideActions.loadedByCustomerId(priceOverrides));
  });
}

function* deleteById() {
  yield takeLatest(PRICE_OVERRIDE_DELETE, function*(action) {
    yield call(priceOverrideService.deleteById, action.payload);
    yield put(priceOverrideActions.deleteSuccess());
  });
}

function* update() {
  yield takeLatest(PRICE_OVERRIDE_UPDATE, function*(action) {
    try {
      let priceOverride = yield call(priceOverrideService.update, action.payload);
      yield put(priceOverrideActions.updateSuccess(priceOverride));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* load() {
  yield takeLatest(PRICE_OVERRIDE_LOAD, function*(action) {
    try {
      let priceOverride = yield call(priceOverrideService.load, action.payload);
      yield put(priceOverrideActions.loaded(priceOverride));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* productVariantSagas() {
  yield all([load(), loadByCustomerId(), deleteById(), update()]);
}
