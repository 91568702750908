export const getCustomerGrpContent = store => (store.customerGrp ? store.customerGrp : {});
export const getCustomerGrp = store => {
  return getCustomerGrpContent(store).custGrp;
};
export const getAllCustomerGrp = store => {
  return getCustomerGrpContent(store).custGrps;
};
export const isLoaded = store => {
  let content = getCustomerGrpContent(store);
  return content.isLoaded != null && content.isLoaded;
};
