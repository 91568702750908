export const getTaxClassContent = store => (store.taxClass ? store.taxClass : {});
export const getTaxClass = store => {
  return getTaxClassContent(store).taxClass;
};
export const getAllTaxClass = store => {
  return getTaxClassContent(store).taxClasses;
};
export const isLoaded = store => {
  let content = getTaxClassContent(store);
  return content.isLoaded != null && content.isLoaded;
};
