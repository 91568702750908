import {
  CATEGORY_LOAD,
  CATEGORY_LOADED,
  CATEGORY_UNLOAD,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_IMAGE_UPLOAD
} from './action_types';

const load = () => ({
  type: CATEGORY_LOAD,
  payload: {}
});

const loaded = categories => ({
  type: CATEGORY_LOADED,
  payload: categories
});

const unload = () => ({
  type: CATEGORY_UNLOAD,
  payload: {}
});

const update = payload => ({
  type: CATEGORY_UPDATE,
  payload: payload
});

const updateSuccess = payload => ({
  type: CATEGORY_UPDATE_SUCCESS,
  payload: payload
});

const uploadImage = payload => ({
  type: CATEGORY_IMAGE_UPLOAD,
  payload: payload
});

export { load, loaded, unload, update, updateSuccess, uploadImage };
