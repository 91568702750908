// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Tooltip } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as customerActions from 'redux/actions/customer';
import * as listActions from 'redux/actions/list';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './customerlist.module.less';

const Fragment = React.Fragment;

class CustomerList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    let tableAction = (
      <Fragment>
        <Link shape="circle" type="primary" to={'/customers/create/'}>
          <Tooltip placement="rightTop" title="Create Customer">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Link>
      </Fragment>
    );
    return (
      <div className={style.container}>
        <Row>
          <Col span={24} className={style.accountInfoButtonsContainer}>
            <WrapperTable
              title="Customer List"
              filters={filters}
              tableAction={tableAction}
              columns={columns}
              data={this.props.listData}
              api={this.props.searchCustomer}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const filters = [
  {
    field: 'code',
    type: 'TEXT'
  },
  {
    field: 'firstName',
    type: 'TEXT'
  },
  {
    field: 'lastName',
    type: 'TEXT'
  },
  {
    field: 'email',
    type: 'TEXT'
  },
  {
    field: 'preferredDocType',
    type: 'OPTION',
    options: [
      { id: 'FULL_INVOICE', name: 'Full Invoice' },
      { id: 'DISPATCH_NOTE', name: 'Disptach Note' }
    ]
  },
  {
    field: 'defaultDisc',
    type: 'NUMBER'
  },
  {
    field: 'referredBy',
    type: 'TEXT'
  },
  {
    field: 'status',
    type: 'OPTION',
    options: [
      { id: 'ACTIVE', name: 'Active' },
      { id: 'INACTIVE', name: 'Inactive' },
      { id: 'VERIFICATION_PENDING', name: 'Verification Pending' }
    ]
  },
  {
    field: 'createdOn',
    type: 'DATE',
    operator: 'BETWEEN'
  }
];

const columns = [
  {
    title: 'Customer Id',
    dataIndex: 'code',
    defaultSortOrder: 'descend',
    sorter: true
  },
  {
    title: 'Name',
    dataIndex: 'firstName',
    render: (firstName, obj) => {
      return `${firstName} ${obj.lastName}`;
    },
    sorter: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status, obj) => {
      return `${status.name}`;
    },
    sorter: true
  },
  {
    title: 'Default Disc',
    dataIndex: 'defaultDisc',
    sorter: true
  },
  {
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    render: id => (
      <Fragment>
        <Link to={`/customers/${id}/`}>view</Link>
        <Divider type="vertical" />
        <Link to={`/customers/edit/${id}/`}>edit</Link>
      </Fragment>
    )
  }
];

const mapDispatchToProps = {
  searchCustomer: customerActions.watchSearchCustomers,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
