import { ADDRESS_LOADED, ADDRESS_UNLOAD, ADDRESS_LOADED_PROVINCES } from 'redux/actions/address/action_types';

const initialStore = {
  isLoaded: false,
  addresses: [],
  provinces: []
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    addresses: payload
  });

const loadedProvinces = (state, payload) => Object.assign({}, state, { provinces: payload });

const unload = (state, payload) => Object.assign({}, state, initialStore);

export default function addressReducer(state = initialStore, action) {
  switch (action.type) {
    case ADDRESS_LOADED:
      return load(state, action.payload);
    case ADDRESS_UNLOAD:
      return unload(state, action.payload);
    case ADDRESS_LOADED_PROVINCES:
      return loadedProvinces(state, action.payload);
    case 'MY_ERROR_ACTION':
      return state;
    default:
      return state;
  }
}
