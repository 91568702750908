import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import * as customerSelector from 'redux/selectors/customer';
import * as customerActions from 'redux/actions/customer';
import * as addressActions from 'redux/actions/address';
import * as addressSelectors from 'redux/selectors/address';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as customerGroupSelector from 'redux/selectors/customerGroup';
import * as taxClassActions from 'redux/actions/taxClass';
import * as taxClassSelector from 'redux/selectors/taxClass';
import { Navbar, Spinner } from 'components';
import CustForm from '../Form/custForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CUSTOMER_CREATE_UPDATE_OK } from 'redux/actions/customer/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './customerAdd.module.less';

class CustomerAdd extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.loadProvinces();
      this.props.findAllCustGrp();
      this.props.findAllTaxCls();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_CREATE_UPDATE_OK, action => {
        this.props.history.push(`/customers/${this.props.customer.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.create(formData);
  };

  componentWillUnmount() {
    this.props.unloadCustGrp();
    this.props.unloadTaxCls();
    this.props.unload();
  }

  render() {
    let content;
    var cust = {
      id: 0,
      preferredDocType: {},
      orderType: {},
      customerGroup: {},
      taxClassId: {},
      groupId: {}
    };

    if (!this.props.isTaxesLoaded && !this.props.isCustGrpLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <CustForm
          key={0}
          provinces={this.props.provinces}
          taxClasses={this.props.allTaxCls}
          customerGroups={this.props.allCustGrp}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={cust}
        ></CustForm>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={'Create Customer'}>
          <Link type="primary" to={'/customers/allCustomers/'}>
            <Tooltip placement="top" title="List Customer">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadProvinces: addressActions.loadProvinces,
  findAllCustGrp: customerGroupActions.findAll,
  findAllTaxCls: taxClassActions.findAll,
  create: customerActions.customerCreate,
  unload: customerActions.customerUnload,
  unloadTaxCls: taxClassActions.unload,
  unloadCustGrp: customerGroupActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    provinces: addressSelectors.getProvinces(state),
    customer: customerSelector.getCustomer(state),
    allCustGrp: customerGroupSelector.getAllCustomerGrp(state),
    allTaxCls: taxClassSelector.getAllTaxClass(state),
    isTaxesLoaded: taxClassSelector.isLoaded(state),
    isCustGrpLoaded: customerGroupSelector.isLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(CustomerAdd));
