import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as addressActions from 'redux/actions/address';
import * as listActions from 'redux/actions/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withEventBus, withScreenLoader } from 'context';
import UpdateCustomerId from 'components/UpdateCustomerId';
import { WrapperTable } from 'components';
import { ADDRESS_SUBMITTED_OK } from 'redux/actions/address/action_types';
import styles from './addressList.module.less';

class AddressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      address: null
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ADDRESS_SUBMITTED_OK, action => {
        this.setState({ address: null });
        this.props.hideScreenLoader();
        this.props.reload();
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    return (
      <div className={styles.container}>
        <Row>
          <Col span={24} className={styles.addressList}>
            <WrapperTable
              title="Customer Address List"
              columns={this.columns}
              data={this.props.listData}
              api={this.props.searchAddresses}
            />
          </Col>
        </Row>
        <UpdateCustomerId
          wrappedComponentRef={this.saveFormRef}
          handleSubmit={this.handleSubmit}
          address={this.state.address}
          handleClose={() => this.setState({ address: null })}
          visible={this.state.address !== null}
        />
      </div>
    );
  }

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: true
    },
    {
      title: 'Company',
      dataIndex: 'company',
      sorter: true
    },
    {
      title: 'Street 1',
      dataIndex: 'street1'
    },
    {
      title: 'Province',
      dataIndex: 'province',
      sortKey: 'provinceId',
      sorter: true
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      sorter: true
    },
    {
      title: 'Customer Id',
      dataIndex: 'eShipperId',
      sorter: true
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id, address) => (
        <Fragment>
          <Button shape="circle" onClick={() => this.setState({ address: address })}>
            <Tooltip placement="bottom" title="Update Customer Id">
              <FontAwesomeIcon icon="edit" />
            </Tooltip>
          </Button>
        </Fragment>
      )
    }
  ];

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.showScreenLoader();
        this.props.updateEShipperId({
          addressId: this.state.address.id,
          eShipperId: values.eShipperId.trim()
        });
      }
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };
}

const mapDispatchToProps = {
  searchAddresses: addressActions.watchSearchAddresses,
  updateEShipperId: addressActions.updateEShipperId,
  reload: listActions.reload,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withScreenLoader(withEventBus(AddressList)));
