export const CUSTOMERS_LOAD = 'CUSTOMERS_LOAD';
export const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED';
export const CUSTOMER_VIEW = 'CUSTOMER_VIEW';
export const CUSTOMER_VIEW_LOADED = 'CUSTOMER_VIEW_LOADED';
export const CUSTOMER_ACTIVATE = 'CUSTOMER_ACTIVATE';
export const CUSTOMER_INACTIVATE = 'CUSTOMER_INACTIVATE';
export const CUSTOMER_CREATE = 'CUSTOMER_CREATE';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_CREATE_UPDATE_OK = 'CUSTOMER_CREATE_UPDATE_OK';
export const CUSTOMER_UNLOAD = 'CUSTOMER_UNLOAD';
