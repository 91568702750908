import React, { Component } from 'react';
import ProductForm from './../Form/productForm';
import { connect } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { PRODUCT_UPDATE_SUCCESS } from 'redux/actions/product/action_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, Spinner } from 'components';
import * as categorySelectors from 'redux/selectors/category';
import * as categoryActions from 'redux/actions/category';
import * as attributeSelectors from 'redux/selectors/attribute';
import * as attributeActions from 'redux/actions/attribute';
import * as productActions from 'redux/actions/product';
import * as productSelector from 'redux/selectors/product';
import * as taxClassActions from 'redux/actions/taxClass';
import * as taxClassSelector from 'redux/selectors/taxClass';
import { withEventBus } from 'context/eventbus';
import styles from './productEdit.module.less';

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.load(this.props.productId);
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_UPDATE_SUCCESS, action => {
        this.props.history.push(`/catalog/products/${action.payload.id}/`);
      })
    );
    this.props.loadAllCategories();
    this.props.loadAllAttributes();
    this.props.loadTaxCls();
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unloadCategories();
    this.props.unloadAttributes();
    this.props.unloadTaxCls();
    this.props.unload();
  }

  render() {
    let content = <Spinner />;
    if (this.props.isLoaded) {
      let p = this.props.product;
      content = (
        <div className={styles.productadd}>
          <Navbar title="Edit Product">
            <Button shape="circle" onClick={() => this.props.history.push('/catalog/products/')}>
              <Tooltip placement="top" title="List Products">
                <FontAwesomeIcon icon="th-list" />
              </Tooltip>
            </Button>
          </Navbar>
          <ProductForm
            eventBusSubscribe={this.props.eventBusSubscribe}
            product={p}
            categories={this.props.categories}
            categoriesLoaded={this.props.categoriesLoaded}
            attributes={this.props.attributes}
            update={this.props.update}
            taxClasses={this.props.taxClasses}
          />
        </div>
      );
    }
    return content;
  }
}

const mapDispatchToProps = {
  loadAllCategories: categoryActions.load,
  unloadCategories: categoryActions.unload,
  loadAllAttributes: attributeActions.loadAll,
  unloadAttributes: attributeActions.unload,
  update: productActions.update,
  unload: productActions.unload,
  load: productActions.load,
  loadTaxCls: taxClassActions.findAll,
  unloadTaxCls: taxClassActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    categories: categorySelectors.getCategories(state),
    categoriesLoaded: categorySelectors.isLoaded(state),
    attributes: attributeSelectors.getAll(state),
    taxClasses: taxClassSelector.getAllTaxClass(state),
    isLoaded: productSelector.isLoaded(state),
    product: productSelector.getProduct(state),
    productId: props.match.params.id
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(ProductEdit));
