import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Switch } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './phoneBrandForm.module.less';

class PhoneBrandForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.submitHandle(values);
      }
    });
  };

  render() {
    var editMode = this.props.data.id !== 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Name" className={styles.row}>
              {getFieldDecorator('name', {
                initialValue: this.props.data.name,
                rules: [{ required: true, message: 'Name is required' }]
              })(<Input placeholder="Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Short Name" className={styles.row}>
              {getFieldDecorator('shortName', {
                initialValue: this.props.data.shortName,
                rules: [{ required: true, message: 'Short name is required' }]
              })(<Input placeholder="Short Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Discontinued">
              {getFieldDecorator('discontinued', {
                valuePropName: 'checked',
                rules: [],
                initialValue: this.props.data.discontinued
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            {!editMode ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'phoneBrandForm' })(PhoneBrandForm);
