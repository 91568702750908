import { fetchApi } from 'Utils/utils';

let findRoleById = function(id) {
  return fetchApi.get(`/api/v1/role/${id}/`);
};

let findAllRoles = function() {
  return fetchApi.get('/api/v1/role/all/');
};

export { findRoleById, findAllRoles }
