import React, { Component } from 'react';
import { Button, Row, Col, Descriptions, Alert } from 'antd';
//import { NO_IMG_URL } from 'Utils/constants';
import { ImageUploader } from 'components';
import styles from './view.module.less';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  uploadImage = formData => {
    this.props.uploadImage(formData);
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    return (
      <div className={styles.view} style={{ padding: 8 }}>
        <div className="actionBar">
          <Button type="primary" onClick={this.props.toggleMode}>
            Edit
          </Button>
          <Button type="primary" onClick={() => this.props.deleteMenu(this.props.menu.id)}>
            Delete
          </Button>
        </div>
        <h2 className="page-title">{this.props.menu.name}</h2>
        <Row gutter={24}>
          <Col span={24}>
            {this.state.globalErrors.map(error => (
              <Alert key={error} message={error} type="error" />
            ))}
          </Col>
          <Col xs={24} sm={10} md={8} className={styles.categoryImg}>
            <ImageUploader
              id={this.props.menu.id}
              key={this.props.menu.id}
              uploadImage={this.uploadImage}
              data={this.props.menu.image}
              eventBusSubscribe={this.props.eventBusSubscribe}
            ></ImageUploader>
          </Col>
          <Col xs={24} sm={14} md={16}>
            <Descriptions bordered size="large" column={2}>
              <Descriptions.Item label="Name">{this.props.menu.name}</Descriptions.Item>
              <Descriptions.Item label="Display Style">{this.props.menu.displayStyle}</Descriptions.Item>
              <Descriptions.Item label="Values">{this.props.menu.values.join(', ')}</Descriptions.Item>
              <Descriptions.Item label="Is Enabled">
                {this.props.menu.enabled ? 'Enabled' : 'Disabled'}
              </Descriptions.Item>
              <Descriptions.Item label="Order">{this.props.menu.order}</Descriptions.Item>
              <Descriptions.Item label="item Type">{this.props.menu.itemType}</Descriptions.Item>
              <Descriptions.Item label="Created By">
                {this.props.menu.createdBy && this.props.menu.createdBy.name ? this.props.menu.createdBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Created On">{this.props.menu.createdOn}</Descriptions.Item>
              <Descriptions.Item label="Updated By">
                {this.props.menu.updatedBy && this.props.menu.updatedBy.name ? this.props.menu.updatedBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Updated On">{this.props.menu.updatedOn}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </div>
    );
  }
}

export default View;
