import { INVOICE_VIEW_LOADED, INVOICE_UNLOAD } from 'redux/actions/invoice/action_types';

const initialStore = {
  isLoaded: false,
  invoice: {}
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    invoice: payload
  });

export default function invoice(state = initialStore, action) {
  switch (action.type) {
    case INVOICE_VIEW_LOADED:
      return load(state, action.payload);
    case INVOICE_UNLOAD:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
