import React, { Component } from 'react';
import UpdateCustomerId from 'components/UpdateCustomerId';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './addressform.module.less';
import * as addressService from 'redux/services/address';

export default class AddressView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      eShipperId: props.address ? props.address.eShipperId : 0
    };
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  componentWillUnmount() {
    this.setState({
      address: null,
      eShipperId: 0
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        (async () => {
          await addressService.updateEShipperId({
            addressId: this.state.address.addressId,
            eShipperId: values.eShipperId.trim()
          });
          this.setState({
            eShipperId: values.eShipperId.trim(),
            address: null
          });
        })();
      }
    });
  };

  render() {
    return (
      <div className={styles.addContainer}>
        {this.props.address && (
          <div>
            {this.props.title && <span className={styles.addressTitle}>{this.props.title}</span>}
            <span className={styles.addName}>{this.props.address.name}</span>
            {this.props.address.company && <span className={styles.addLine}>{this.props.address.company}</span>}
            <span className={styles.addLine}>{this.props.address.street1}</span>
            {this.props.address.street2 && <span className={styles.addLine}>{this.props.address.street2}</span>}
            <span className={styles.addLine}>
              {this.props.address.city}, {this.props.address.provinceId} {this.props.address.postalCode}
            </span>
            {this.props.address.phoneNo && <span className={styles.addLine}>{this.props.address.phoneNo}</span>}
            {this.props.address.eShipperId && (
              <span className={styles.addLine}>
                <strong>Customer ID: </strong>
                {this.state.eShipperId}
              </span>
            )}
            <Button shape="circle" onClick={() => this.setState({ address: this.props.address })}>
              <Tooltip placement="bottom" title="Update Customer Id">
                <FontAwesomeIcon icon="edit" />
              </Tooltip>
            </Button>
          </div>
        )}
        <UpdateCustomerId
          wrappedComponentRef={this.saveFormRef}
          handleSubmit={this.handleSubmit}
          address={this.state.address}
          handleClose={() => this.setState({ address: null })}
          visible={this.state.address !== null}
        />
      </div>
    );
  }
}
