// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Tooltip, Modal, Input, Button } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as employeeActions from 'redux/actions/employee';
import * as listActions from 'redux/actions/list';
import * as employeeService from 'redux/services/employee';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './employeeList.module.less';

const Fragment = React.Fragment;

class EmployeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordResetId: null,
      password: ''
    };
  }

  componentWillUnmount() {
    this.props.unload();
  }

  updatePassword() {
    if (!this.state.password || this.state.password.length < 6) {
      return;
    }
    (async () => {
      try {
        await employeeService.resetPassword(this.state.passwordResetId, this.state.password);
        this.setState({
          password: '',
          passwordResetId: null
        });
      } catch (ex) {
        this.setState({
          password: '',
          passwordResetId: null
        });
        alert('Some error occurred.');
      }
    })();
  }

  getColumns() {
    return [
      {
        title: 'Employee Id',
        dataIndex: 'code',
        defaultSortOrder: 'DESC',
        sorter: true
      },
      {
        title: 'Name',
        dataIndex: 'firstName',
        render: (firstName, obj) => {
          return `${firstName} ${obj.lastName}`;
        },
        defaultSortOrder: 'DESC',
        sorter: true
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: true
      },
      {
        title: 'User name',
        dataIndex: 'username',
        sorter: true
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status, obj) => {
          return `${status.name}`;
        },
        sorter: true
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Fragment>
            <Link to={`/employees/${id}/`}>view</Link>
            <Divider type="vertical" />
            <Link to={`/employees/edit/${id}/`}>edit</Link>
            <Divider type="vertical" />
            <Button type="link" onClick={() => this.setState({ passwordResetId: id })}>
              Reset Password
            </Button>
          </Fragment>
        )
      }
    ];
  }

  render() {
    let tableAction = (
      <Fragment>
        <Link type="primary" to={'/employees/create/'}>
          <Tooltip placement="rightTop" title="Create Employee">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Link>
      </Fragment>
    );

    return (
      <div className={style.container}>
        <Row>
          <Col span={24} className={style.accountInfoButtonsContainer}>
            <WrapperTable
              title="Employee List"
              filters={filters}
              columns={this.getColumns()}
              tableAction={tableAction}
              data={this.props.listData}
              api={this.props.searchEmployees}
            />
          </Col>
        </Row>
        {this.state.passwordResetId && (
          <Modal
            title="Reset Password"
            visible={this.state.passwordResetId}
            onCancel={() => this.setState({ passwordResetId: null, password: '' })}
            onOk={() => this.updatePassword()}
          >
            <p>Enter new password (atleast 6 chars):</p>
            <Input
              type="password"
              onChange={e => this.setState({ password: e.target.value })}
              value={this.state.password}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const filters = [
  {
    field: 'code',
    type: 'TEXT'
  },
  {
    field: 'firstName',
    type: 'TEXT'
  },
  {
    field: 'lastName',
    type: 'TEXT'
  },
  {
    field: 'email',
    type: 'TEXT'
  },
  {
    field: 'username',
    type: 'TEXT'
  },
  {
    field: 'status',
    type: 'OPTION',
    options: [
      { id: 'ACTIVE', name: 'Active' },
      { id: 'INACTIVE', name: 'Inactive' }
    ]
  }
];

const mapDispatchToProps = {
  searchEmployees: employeeActions.watchSearchEmployees,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
