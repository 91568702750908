import {
  ORDERS_LOAD,
  VIEW_ORDER_LOAD,
  VIEW_ORDER_LOADED,
  ORDER_CANCEL,
  ORDER_UPDATE_SUCCESS,
  ORDER_CANCEL_HOLD,
  ORDER_HOLD
} from './action_types';

const watchSearchOrders = payload => ({
  type: ORDERS_LOAD,
  payload: payload
});

const watchFindOne = payload => ({
  type: VIEW_ORDER_LOAD,
  payload: payload
});

const findOne = payload => ({
  type: VIEW_ORDER_LOADED,
  payload: payload
});

const orderCancel = id => ({
  type: ORDER_CANCEL,
  payload: { id: id }
});

const updateSuccess = payload => ({
  type: ORDER_UPDATE_SUCCESS,
  payload: payload
});

const holdOrder = id => ({
  type: ORDER_HOLD,
  payload: { id: id }
});

const cancelHold = id => ({
  type: ORDER_CANCEL_HOLD,
  payload: { id: id }
});

export { watchSearchOrders, watchFindOne, findOne, orderCancel, updateSuccess, holdOrder, cancelHold };
