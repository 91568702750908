// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Row, Col, Tooltip, Menu, Dropdown } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as widgetActions from 'redux/actions/homepage/widgets';
import * as listActions from 'redux/actions/list';
import * as userSelector from 'redux/selectors/user';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './homepageWidgetsList.module.less';
import { withEventBus, withScreenLoader } from 'context';
import { HOMEPAGE_WIDGETS_SAVE_SUCCESS } from 'redux/actions/homepage/widgets/action_types';

const Fragment = React.Fragment;

class HomepageWidgetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(HOMEPAGE_WIDGETS_SAVE_SUCCESS, () => {
        this.props.hideScreenLoader();
        this.setState({
          key: this.state.key + 1
        });
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Link to={'/options/homepageWidgets/create/LATEST_PRODUCTS/'}>Latest products</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to={'/options/homepageWidgets/create/SELECTED_PRODUCTS/'}>Selected Products</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to={'/options/homepageWidgets/create/STATIC_IMAGES/'}>Static Images</Link>
        </Menu.Item>
      </Menu>
    );

    let tableAction = (
      <Fragment>
        <Dropdown type="primary" overlay={menu}>
          <Tooltip placement="rightTop" title="Create Widget">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Dropdown>
      </Fragment>
    );

    return (
      <div className={style.container}>
        <Row>
          <Col span={24} key={this.state.key} className={style.listContainer}>
            <WrapperTable
              title="Homepage widget List"
              columns={this.columns}
              tableAction={tableAction}
              data={this.props.listData}
              api={this.props.searchWidgets}
            />
          </Col>
        </Row>
      </div>
    );
  }

  remove = (id, title) => {
    Modal.confirm({
      title: 'Delete Widget',
      content: `Do you want to delete widget ${title}?`,
      onOk: () => {
        this.props.showScreenLoader();
        this.props.remove(id);
      }
    });
  };

  columns = [
    {
      title: 'Order',
      dataIndex: 'order',
      defaultSortOrder: 'ASC',
      sorter: true
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: 'Display Title',
      dataIndex: 'displayTitle',
      render: val => (val ? 'Yes' : 'No'),
      sorter: true
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Fragment>
          <Link to={`/options/homepageWidgets/edit/${id}/`}>edit</Link>
          <Button type="link" onClick={() => this.remove(id, record.title)}>
            delete
          </Button>
        </Fragment>
      )
    }
  ];
}

const mapDispatchToProps = {
  searchWidgets: widgetActions.search,
  unload: listActions.listUnload,
  remove: widgetActions.remove
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state),
    isUserContentCreator: userSelector.isUserContentCreator(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withScreenLoader(HomepageWidgetList)));
