import { call, put, takeLatest, all } from 'redux-saga/effects';
import { USER_LOGIN, USER_LOGOUT } from '../actions/user/action_types';
import * as userService from 'redux/services/user';
import * as userActions from 'redux/actions/user';

function* userLogin() {
  yield takeLatest(USER_LOGIN, function*(action) {
    try {
      yield call(userService.userLogin, action.payload);
      window.location.reload();
    } catch (err) {
      if (err.status === 401) {
        yield put(userActions.loginError());
      } else {
        throw err;
      }
    }
  });
}

function* userLogout() {
  yield takeLatest(USER_LOGOUT, function*(action) {
    try {
      yield call(userService.userLogout);
      localStorage.removeItem('token');
    } catch (err) {}
    window.location.reload();
  });
}

export default function* configSagas() {
  yield all([userLogin(), userLogout()]);
}
