import configSagas from './config';
import homepageSagas from './homepage';
import userSagas from './user';
import categorySagas from './category';
import menuSagas from './menu';
import customerSagas from './customer';
import employeeSagas from './employee';
import roleSagas from './role';
import taxClassSagas from './taxClass';
import customerGroupSagas from './customerGroup';
import addressSagas from './address';
import orderSagas from './order';
import invoiceSagas from './invoice';
import attributeSagas from './attribute';
import productSagas from './product';
import couponSagas from './coupon';
import productVariantSagas from './productVariant';
import staticPageSagas from './staticPage';
import imageSliderSagas from './imageSlider';
import phoneSagas from './phone';
import phoneBrandSagas from './phoneBrand';
import priceOverride from './priceOverride';
import salesComm from './salesComm';
import cartSagas from './cart';
import userSessionSagas from './userSession';
import widgetSagas from './homepage/widgets';
import storeGroupSagas from './storeGroup';
import deviceGroupSagas from './deviceGroup';

export function run(sagaMiddleware) {
  sagaMiddleware.run(configSagas);
  sagaMiddleware.run(homepageSagas);
  sagaMiddleware.run(userSagas);
  sagaMiddleware.run(categorySagas);
  sagaMiddleware.run(menuSagas);
  sagaMiddleware.run(customerSagas);
  sagaMiddleware.run(employeeSagas);
  sagaMiddleware.run(roleSagas);
  sagaMiddleware.run(taxClassSagas);
  sagaMiddleware.run(customerGroupSagas);
  sagaMiddleware.run(addressSagas);
  sagaMiddleware.run(orderSagas);
  sagaMiddleware.run(invoiceSagas);
  sagaMiddleware.run(attributeSagas);
  sagaMiddleware.run(productSagas);
  sagaMiddleware.run(couponSagas);
  sagaMiddleware.run(productVariantSagas);
  sagaMiddleware.run(staticPageSagas);
  sagaMiddleware.run(imageSliderSagas);
  sagaMiddleware.run(phoneSagas);
  sagaMiddleware.run(phoneBrandSagas);
  sagaMiddleware.run(priceOverride);
  sagaMiddleware.run(salesComm);
  sagaMiddleware.run(cartSagas);
  sagaMiddleware.run(userSessionSagas);
  sagaMiddleware.run(widgetSagas);
  sagaMiddleware.run(storeGroupSagas);
  sagaMiddleware.run(deviceGroupSagas);
}
