import { call, takeLatest, all, put } from 'redux-saga/effects';
import {
  SALES_COMM_LOAD_ALL_REPS,
  SALES_COMM_LOAD_REP_CUSTOMERS,
  SALES_COMM_ASSIGN_CUSTOMER,
  SALES_COMM_REMOVE_CUSTOMER,
  SALES_COMM_LOAD_ALL_RATES,
  SALES_COMM_REMOVE_RATE,
  SALES_COMM_ADD_PRODUCT_RATE,
  SALES_COMM_LOAD_REPORT,
  SALES_COMM_LOAD_COMMISSION_DETAILS
} from 'redux/actions/salesComm/action_types';
import * as salesCommService from 'redux/services/salesComm';
import * as salesCommActions from 'redux/actions/salesComm';
import * as apiErrorActions from 'redux/actions/apiError';

function* loadAllReps() {
  yield takeLatest(SALES_COMM_LOAD_ALL_REPS, function*(action) {
    try {
      let salesReps = yield call(salesCommService.loadAllReps, action.payload);
      yield put(salesCommActions.allRepsLoaded(salesReps));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* loadRepCustomers() {
  yield takeLatest(SALES_COMM_LOAD_REP_CUSTOMERS, function*(action) {
    try {
      let customers = yield call(salesCommService.loadRepCustomers, action.payload);
      yield put(salesCommActions.loadRepCustomersSuccess(customers));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* assignCustomer() {
  yield takeLatest(SALES_COMM_ASSIGN_CUSTOMER, function*(action) {
    try {
      let customer = yield call(salesCommService.assignCustomer, action.payload);
      yield put(salesCommActions.assignCustomerSuccess(customer));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* removeCustomer() {
  yield takeLatest(SALES_COMM_REMOVE_CUSTOMER, function*(action) {
    try {
      yield call(salesCommService.removeCustomer, action.payload);
      yield put(salesCommActions.removeCustomerSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* loadAllRates() {
  yield takeLatest(SALES_COMM_LOAD_ALL_RATES, function*(action) {
    try {
      let rates = yield call(salesCommService.loadAllRates, action.payload);
      yield put(salesCommActions.loadAllRatesSuccess(rates));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* removeRate() {
  yield takeLatest(SALES_COMM_REMOVE_RATE, function*(action) {
    try {
      yield call(salesCommService.removeRate, action.payload);
      yield put(salesCommActions.removeRateSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* addProductRate() {
  yield takeLatest(SALES_COMM_ADD_PRODUCT_RATE, function*(action) {
    try {
      let productRate = yield call(salesCommService.addProductRate, action.payload);
      yield put(salesCommActions.addProductRateSuccess(productRate));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* loadReport() {
  yield takeLatest(SALES_COMM_LOAD_REPORT, function*(action) {
    try {
      let report = yield call(salesCommService.loadReport, action.payload);
      yield put(salesCommActions.loadReportSuccess(report));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* loadCommissionDetails() {
  yield takeLatest(SALES_COMM_LOAD_COMMISSION_DETAILS, function*(action) {
    try {
      let commission = yield call(salesCommService.loadCommissionDetails, action.payload);
      yield put(salesCommActions.loadCommissionDetailsSuccess(commission));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* salesCommSagas() {
  yield all([
    loadAllReps(),
    loadRepCustomers(),
    assignCustomer(),
    removeCustomer(),
    loadAllRates(),
    removeRate(),
    addProductRate(),
    loadReport(),
    loadCommissionDetails()
  ]);
}
