export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const PRODUCT_VIEW = 'PRODUCT_VIEW';
export const PRODUCT_VIEW_LOADED = 'PRODUCT_VIEW_LOADED';
export const PRODUCT_ATTR_LOAD = 'PRODUCT_ATTR_LOAD';
export const PRODUCT_ATTR_LOADED = 'PRODUCT_ATTR_LOADED';
export const PRODUCT_CATEGORY_LOAD = 'PRODUCT_CATEGORY_LOAD';
export const PRODUCT_CATEGORY_LOADED = 'PRODUCT_CATEGORY_LOADED';
export const PRODUCT_UNLOAD = 'PRODUCT_UNLOAD';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_GENERATE_VARIANTS = 'PRODUCT_GENERATE_VARIANTS';
export const PRODUCT_GENERATE_VARIANTS_SUCCESS = 'PRODUCT_GENERATE_VARIANTS_SUCCESS';
export const PRODUCT_REGENERATE_DESC = 'PRODUCT_REGENERATE_DESC';
export const PRODUCT_REGENERATE_DESC_SUCCESS = 'PRODUCT_REGENERATE_DESC_SUCCESS';
export const PRODUCT_UPDATE_ATTRIBUTES = 'PRODUCT_UPDATE_ATTRIBUTES';
export const PRODUCT_UPDATE_ATTRIBUTES_SUCCESS = 'PRODUCT_UPDATE_ATTRIBUTES_SUCCESS';
