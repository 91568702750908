import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, Button, Row, Col, DatePicker, Alert } from 'antd';
import moment from 'moment';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import styles from './variantsInformation.module.less';

class VariantsInformation extends Component {
  constructor(props) {
    super(props);
    let groups = {};
    props.allCustGrp.forEach(cg => (groups[cg.id] = []));
    this.state = {
      attrs: props.attributes.sort((a1, a2) => a1.order - a2.order),
      groups: groups,
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = action.payload.globalErrors.slice();
        let keys = Object.keys(action.payload.entityErrors);
        let errorsToDisplay = ['weight', 'price'];
        keys.forEach(k => {
          let errObj = action.payload.entityErrors[k];
          Object.keys(errObj).forEach(errKey => {
            if (errorsToDisplay.includes(errKey)) {
              let obj = {};
              obj[errKey] = {
                value: this.props.form.getFieldValue('weight'),
                errors: errObj[errKey].map(e => new Error(e))
              };
              this.props.form.setFields(obj);
            } else {
              if (errKey === 'attributes') {
                let attributesErr = errObj[errKey];
                Object.keys(attributesErr).forEach(k => attributesErr[k].forEach(e => globalErrors.push(e)));
              } else {
                errObj[errKey].forEach(e => globalErrors.push(e));
              }
            }
          });
        });
        this.setState({
          globalErrors: globalErrors
        });
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let vals = Object.assign({}, values);
        if (values.newTo) {
          vals.newTo = values.newTo
            .hour(0)
            .minutes(0)
            .seconds(0)
            .toISOString();
        }
        this.setState({
          globalErrors: []
        });
        this.props.saveVariants(vals);
      }
    });
  };

  renderPhoneAttr = (getFieldDecorator, attr, generatedVariant, idx) => {
    return (
      <div className={styles.listAttr}>
        {this.props.phones
          .filter(p => p.id === generatedVariant[attr.code][0])
          .map(p => p.name)
          .join(', ')}
        {getFieldDecorator(`attributes[${idx}]${attr.code}[0]`, {
          initialValue: generatedVariant[attr.code][0]
        })(<Input type="hidden" />)}
      </div>
    );
  };

  renderListAttr = (getFieldDecorator, attr, generatedVariant, idx) => {
    return (
      <div className={styles.listAttr}>
        {generatedVariant[attr.code]}
        {getFieldDecorator(`attributes[${idx}]${attr.code}[0]`, {
          initialValue: generatedVariant[attr.code][0]
        })(<Input type="hidden" />)}
      </div>
    );
  };

  renderTextAttr = (getFieldDecorator, attr, generatedVariant, idx) => {
    return (
      <div className={styles.textAttr}>
        <Form.Item>
          {getFieldDecorator(`attributes[${idx}]${attr.code}[0]`, {
            initialValue: generatedVariant[attr.code][0],
            rules: this.getValidationRules(attr)
          })(
            <Input
              addonAfter={
                <Button type="link" onClick={() => this.copyAttrValueToAll(idx, attr.code)}>
                  Copy
                </Button>
              }
            />
          )}
        </Form.Item>
      </div>
    );
  };

  getValidationRules = attr => {
    let rules = [];
    if (attr.required) {
      rules.push({
        required: true,
        message: `${attr.label} is required.`
      });
    }
    return rules;
  };

  copyAttrValueToAll = (idx, code) => {
    let value = this.props.form.getFieldValue(`attributes[${idx}]${code}[0]`);
    if (value && value.trim() === '') {
      return;
    }
    let size = this.props.generatedVariants.length;
    for (let i = 0; i < size; i++) {
      let obj = {};
      obj[`attributes[${i}]${code}[0]`] = {
        value: value
      };
      this.props.form.setFields(obj);
    }
  };

  renderAttr = (getFieldDecorator, attr, generatedVariant, idx) => {
    switch (attr.type) {
      case 'PHONE':
        return this.renderPhoneAttr(getFieldDecorator, attr, generatedVariant, idx);
      case 'LIST':
        return this.renderListAttr(getFieldDecorator, attr, generatedVariant, idx);
      default:
        return this.renderTextAttr(getFieldDecorator, attr, generatedVariant, idx);
    }
  };

  renderTierPrice = (custGrp, getFieldDecorator) => {
    var cGrp = this.state.groups[custGrp.id];
    return (
      <table className={styles.priceQtyTable}>
        <tbody>
          {cGrp.map((c, idx) => (
            <tr key={idx}>
              <td>
                <Form.Item>
                  {getFieldDecorator(`tierPrices[${custGrp.id}][${idx}].qty`, {
                    initialValue: c.qty,
                    rules: [
                      {
                        type: 'number',
                        required: true,
                        asyncValidator: (rule, value) => {
                          return new Promise((resolve, reject) => {
                            if (value <= 0) {
                              reject('Should be greater than 0');
                            } else {
                              resolve();
                            }
                          });
                        }
                      }
                    ]
                  })(<InputNumber placeholder="Qty" min={1} precision={0} />)}
                </Form.Item>
              </td>
              <td>
                <Form.Item>
                  {getFieldDecorator(`tierPrices[${custGrp.id}][${idx}].price`, {
                    initialValue: c.price,
                    rules: [
                      {
                        type: 'number',
                        required: true,
                        asyncValidator: (rule, value) => {
                          return new Promise((resolve, reject) => {
                            if (value <= 0) {
                              reject('Should be greater than 0.0');
                            } else {
                              resolve();
                            }
                          });
                        }
                      }
                    ]
                  })(<InputNumber placeholder="Price" min={0} precision={2} />)}
                </Form.Item>
              </td>
              <td>
                <Button type="danger" onClick={() => this.removePrice(custGrp, idx)}>
                  X
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  getTierPriceSize = groupId => {
    let grp = this.state.group[groupId];
    return grp ? grp.length : 0;
  };

  addPrice = custGrp => {
    let grps = this.state.groups;
    grps[custGrp.id].push({
      qty: 1,
      price: 0.0
    });
    this.setState({
      groups: grps
    });
  };

  removePrice = (custGrp, idx) => {
    let grps = this.state.groups;
    grps[custGrp.id].splice(idx, 1);
    this.setState({
      groups: grps
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let newTo = moment().add(30, 'days');
    return (
      <div className={styles.variantsInformation}>
        <h2 className="title">Variants Information</h2>
        {this.state.globalErrors.length > 0 && (
          <Alert
            type="error"
            showIcon
            description={
              <ul>
                {this.state.globalErrors.map((e, idx) => (
                  <li key={idx}>{e}</li>
                ))}
              </ul>
            }
          />
        )}
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('productId', {
            initialValue: this.props.productId
          })(<Input type="hidden" />)}
          <Row>
            <Col span={24}>
              <table className={styles.attrTable}>
                <thead>
                  <tr>
                    {this.state.attrs.map(a => (
                      <th key={a.code}>{a.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.props.generatedVariants.map((gv, idx) => (
                    <tr key={idx}>
                      {this.state.attrs.map((a, aIdx) => (
                        <td key={aIdx}>{this.renderAttr(getFieldDecorator, a, gv, idx)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={8}>
              <Form.Item label="Weight">
                {getFieldDecorator('weight', {
                  initialValue: 0.0,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      asyncValidator: (rule, value) => {
                        return new Promise((resolve, reject) => {
                          if (value <= 0) {
                            reject('Should be greater than 0.0');
                          } else {
                            resolve();
                          }
                        });
                      }
                    }
                  ]
                })(<InputNumber min={0} precision={2} />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="New To">
                {getFieldDecorator('newTo', { initialValue: newTo })(<DatePicker />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="Price">
                {getFieldDecorator('price', {
                  initialValue: 0.0,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      asyncValidator: (rule, value) => {
                        return new Promise((resolve, reject) => {
                          if (value <= 0) {
                            reject('Should be greater than 0.0');
                          } else {
                            resolve();
                          }
                        });
                      }
                    }
                  ]
                })(<InputNumber min={0} precision={2} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <table className={styles.tierPriceTable}>
                <thead>
                  <tr>
                    <th colSpan="3">Tier Prices</th>
                  </tr>
                  <tr>
                    <th>Customer Group</th>
                    <th>Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.allCustGrp.map(c => (
                    <tr key={c.id}>
                      <td>{c.name}</td>
                      <td>{this.renderTierPrice(c, getFieldDecorator)}</td>
                      <td>
                        <Button onClick={() => this.addPrice(c, getFieldDecorator)}>Add</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(VariantsInformation);
