import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Select, Switch, InputNumber } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import * as deviceGroupService from 'redux/services/deviceGroup';
import styles from './phoneForm.module.less';

class PhoneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      deviceGroups: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
    (async () => {
      let groups = await deviceGroupService.findAll();
      this.setState({ deviceGroups: groups });
    })();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.submitHandle(values);
      }
    });
  };

  render() {
    var editMode = this.props.data.id !== 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Name" className={styles.row}>
              {getFieldDecorator('name', {
                initialValue: this.props.data.name,
                rules: [{ required: true, message: 'Name is required' }]
              })(<Input placeholder="Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Short Name" className={styles.row}>
              {getFieldDecorator('shortName', {
                initialValue: this.props.data.shortName,
                rules: [{ required: true, message: 'Short name is required' }]
              })(<Input placeholder="Short Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Brand" className={styles.row}>
              {getFieldDecorator('brandId', {
                initialValue: this.props.data.brandId,
                rules: [{ required: true, message: 'Select a Brand' }]
              })(
                <Select placeholder="Brand">
                  {this.props.brands.map(p => (
                    <Select.Option key={p} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Discontinued">
              {getFieldDecorator('discontinued', {
                valuePropName: 'checked',
                rules: [],
                initialValue: this.props.data.discontinued
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Show in latest devices">
              {getFieldDecorator('latest', {
                valuePropName: 'checked',
                rules: [],
                initialValue: this.props.data.latest
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Device Type">
              {getFieldDecorator('deviceType', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(
                <Select>
                  <Select.Option key="PHONE">Phone</Select.Option>
                  <Select.Option key="TABLET">Tablet</Select.Option>
                  <Select.Option key="WATCH">Watch</Select.Option>
                  <Select.Option key="LAPTOP">Laptop</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Year of Launch" className={styles.row}>
              {getFieldDecorator('yearOfLaunch', {
                initialValue: this.props.data.yearOfLaunch,
                rules: [{ required: true, message: 'Year of launch is required' }]
              })(<InputNumber placeholder="Year of Launch" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Sort Order" className={styles.row}>
              {getFieldDecorator('sortOrder', {
                initialValue: this.props.data.sortOrder,
                rules: [{ required: true, message: 'Sort order is required' }]
              })(<InputNumber placeholder="Sort Order" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Device Group" className={styles.row}>
              {getFieldDecorator('groupId', {
                initialValue: this.props.data.groupId,
                rules: [{ required: true, message: 'Device group is required' }]
              })(
                <Select placeholder="Device Group">
                  {this.state.deviceGroups.map(p => (
                    <Select.Option key={p} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            {!editMode ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'phoneForm' })(PhoneForm);
