import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, Row, Col } from 'antd';
import styles from './tierPrices.module.less';

class TierPrices extends Component {
  constructor(props) {
    super(props);
    let groups = {};
    this.allCustGrp = props.allCustGrp.filter(c => !c.applyToAll);
    this.allCustGrp.forEach(cg => (groups[cg.id] = []));
    this.state = {
      groups: groups
    };
  }

  renderTierPrice = (custGrp, getFieldDecorator) => {
    var cGrp = this.state.groups[custGrp.id];
    return (
      <table className={styles.priceQtyTable}>
        <tbody>
          {cGrp.map((c, idx) => (
            <tr key={idx}>
              <td>
                <Form.Item>
                  {getFieldDecorator(`tierPrices[${custGrp.id}][${idx}].qty`, {
                    initialValue: c.qty,
                    rules: [
                      {
                        type: 'number',
                        required: true,
                        asyncValidator: (rule, value) => {
                          return new Promise((resolve, reject) => {
                            if (value <= 0) {
                              reject('Should be greater than 0');
                            } else {
                              resolve();
                            }
                          });
                        }
                      }
                    ]
                  })(<InputNumber placeholder="Qty" min={1} precision={0} />)}
                </Form.Item>
              </td>
              <td>
                <Form.Item>
                  {getFieldDecorator(`tierPrices[${custGrp.id}][${idx}].price`, {
                    initialValue: c.price,
                    rules: [
                      {
                        type: 'number',
                        required: true,
                        asyncValidator: (rule, value) => {
                          return new Promise((resolve, reject) => {
                            if (value <= 0) {
                              reject('Should be greater than 0.0');
                            } else {
                              resolve();
                            }
                          });
                        }
                      }
                    ]
                  })(<InputNumber placeholder="Price" min={0} precision={2} />)}
                </Form.Item>
              </td>
              <td>
                <Button type="danger" onClick={() => this.removePrice(custGrp, idx)}>
                  X
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  getTierPriceSize = groupId => {
    let grp = this.state.group[groupId];
    return grp ? grp.length : 0;
  };

  addPrice = custGrp => {
    let grps = this.state.groups;
    grps[custGrp.id].push({
      qty: 1,
      price: 0.0
    });
    this.setState({
      groups: grps
    });
  };

  removePrice = (custGrp, idx) => {
    let grps = this.state.groups;
    grps[custGrp.id].splice(idx, 1);
    this.setState({
      groups: grps
    });
  };

  render() {
    const getFieldDecorator = this.props.getFieldDecorator;
    return (
      <div className={styles.tierPrices}>
        <Row>
          <Col span={24}>
            <table className={styles.tierPriceTable}>
              <thead>
                <tr>
                  <th colSpan="3">Tier Prices</th>
                </tr>
                <tr>
                  <th>Customer Group</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.allCustGrp.map(c => (
                  <tr key={c.id}>
                    <td>{c.name}</td>
                    <td>{this.renderTierPrice(c, getFieldDecorator)}</td>
                    <td>
                      <Button onClick={() => this.addPrice(c, getFieldDecorator)}>Add</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TierPrices;
