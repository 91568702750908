import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider } from 'antd';
import * as employeeSelector from 'redux/selectors/employee';
import * as employeeActions from 'redux/actions/employee';
import * as roleActions from 'redux/actions/role';
import * as roleSelector from 'redux/selectors/role';
import { Spinner, Navbar } from 'components';
import EmpForm from '../Form/empForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMPLOYEE_CREATE_UPDATE_OK } from 'redux/actions/employee/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './employeeEdit.module.less';

class EmployeeEdit extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isRolesLoaded) {
      this.props.findAllRoles();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(EMPLOYEE_CREATE_UPDATE_OK, action => {
        this.props.history.push(`/employees/${this.props.match.params.id}/`);
      })
    );
    this.props.findByEmployeeId(this.props.match.params.id);
  }

  submitHandle = formData => {
    this.props.update(formData);
  };

  componentWillUnmount() {
    this.props.roleUnload();
    this.props.unload();
  }

  render() {
    let content;
    var emp = this.props.employee;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <EmpForm
          key={emp.id}
          roles={this.props.allroles}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={emp}
        ></EmpForm>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={'Edit ' + this.props.employee.code}>
          <Link type="primary" to={'/employees/create/'}>
            <Tooltip placement="top" title="Create Employee">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={`/employees/${this.props.match.params.id}/`}>
            <Tooltip placement="top" title="View Employee">
              <FontAwesomeIcon icon="search" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/employees/'}>
            <Tooltip placement="top" title="List Employee">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  findByEmployeeId: employeeActions.findEmployeeById,
  findAllRoles: roleActions.findAllRoles,
  update: employeeActions.employeeUpdate,
  unload: employeeActions.employeeUnload,
  roleUnload: roleActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: employeeSelector.isLoaded(state),
    employee: employeeSelector.getEmployee(state),
    allroles: roleSelector.getAllRoles(state),
    isRolesLoaded: roleSelector.isLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(EmployeeEdit));
