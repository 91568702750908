import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Alert, Descriptions } from 'antd';
import { handleErrors } from 'Utils/errorHandler';
import * as deviceGroupService from 'redux/services/deviceGroup';

export default props => {
  const [deviceGroup, setDeviceGroup] = useState({});
  const [globalErrors, setGlobalErrors] = useState([]);
  const history = useHistory();
  const { groupId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setDeviceGroup(await deviceGroupService.find(groupId));
      } catch (err) {
        setGlobalErrors(handleErrors(err, history));
      }
    })();
  }, [groupId, history]);

  return (
    <div>
      <h2 className="page-title">View Device Group: {deviceGroup.name}</h2>
      {globalErrors.length > 0 && (
        <Alert
          type="error"
          showIcon
          description={
            <ul>
              {globalErrors.map((e, idx) => (
                <li key={idx}>{e}</li>
              ))}
            </ul>
          }
        />
      )}
      <Descriptions bordered size="small" column={2}>
        <Descriptions.Item label="Name">{deviceGroup.name}</Descriptions.Item>
        <Descriptions.Item label="Sort Order">{deviceGroup.sortOrder}</Descriptions.Item>
        <Descriptions.Item label="Image">
          {deviceGroup.image && <img src={deviceGroup.image.path} alt="group" style={{ maxWidth: '500px' }} />}
        </Descriptions.Item>
      </Descriptions>
      <Link to={`/catalog/deviceGroup/edit/${deviceGroup.id}/`}>edit</Link>
    </div>
  );
};
