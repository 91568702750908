import { fetchApi } from 'Utils/utils';

const loadAllReps = () => {
  return fetchApi.get('/api/v1/salesComm/getAllReps/');
};

const loadRepCustomers = payload => {
  return fetchApi.get(`/api/v1/salesComm/salesRep/${payload.salesRepId}/customers/`);
};

const assignCustomer = payload => {
  return fetchApi.post('/api/v1/salesComm/salesRep/assignCustomer/', payload);
};

const removeCustomer = payload => {
  return fetchApi.delete(`/api/v1/salesComm/salesRep/removeCustomer/${payload.id}/`);
};

const loadAllRates = payload => {
  return fetchApi.get('/api/v1/salesComm/commissionRate/getAll/');
};

const removeRate = payload => {
  return fetchApi.delete(`/api/v1/salesComm/commissionRate/${payload.id}/`);
};

const addProductRate = payload => {
  return fetchApi.post('/api/v1/salesComm/commissionRate/', payload);
};

const loadReport = payload => {
  return fetchApi.post('/api/v1/salesComm/getReport/', payload);
};

const loadCommissionDetails = payload => {
  return fetchApi.get(`/api/v1/salesComm/commission/${payload.id}/`);
};

export {
  loadAllReps,
  loadRepCustomers,
  assignCustomer,
  removeCustomer,
  loadAllRates,
  removeRate,
  addProductRate,
  loadReport,
  loadCommissionDetails
};
