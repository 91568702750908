import { ROLE_LISTED_ALL, ROLE_VIEW_LOADED, ROLE_UNLOAD } from '../actions/role/action_types';

const initialStore = {
  isLoaded: false,
  role: {},
  roles: []
};

const loadRole = (state, role) =>
  Object.assign({}, state, {
    isLoaded: true,
    role: role,
    roles: []
  });

const loadRoles = (state, roles) =>
  Object.assign({}, state, {
    isLoaded: true,
    role: {},
    roles: roles
  });

export default function role(state = initialStore, action) {
  switch (action.type) {
    case ROLE_VIEW_LOADED:
      return loadRole(state, action.payload);
    case ROLE_LISTED_ALL:
      return loadRoles(state, action.payload);
    case ROLE_UNLOAD:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
