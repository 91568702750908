import { CONFIG_LOAD, CONFIG_LOADED } from './action_types';

const loadConfig = () => ({
  type: CONFIG_LOAD,
  payload: {}
});

const configLoaded = payload => ({
  type: CONFIG_LOADED,
  payload: payload
});

export { loadConfig, configLoaded };
