import { PHONE_LOADED, PHONE_UNLOADED, PHONE_LOADED_ALL } from 'redux/actions/phone/action_types';

const initialStore = {
  isLoaded: false,
  phone: {},
  allPhonesLoaded: false,
  allPhones: []
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    phone: payload
  });

const unload = (state, payload) =>
  Object.assign({}, state, initialStore, { allPhonesLoaded: state.allPhonesLoaded, allPhones: state.allPhones });

const loadedAll = (state, payload) => Object.assign({}, state, { allPhonesLoaded: true, allPhones: payload });

export default function phoneSlide(state = initialStore, action) {
  switch (action.type) {
    case PHONE_LOADED:
      return load(state, action.payload);
    case PHONE_LOADED_ALL:
      return loadedAll(state, action.payload);
    case PHONE_UNLOADED:
      return unload(state, action.payload);
    default:
      return state;
  }
}
