import { fetchApi } from 'Utils/utils';

let search = function(params) {
  return fetchApi.post('/api/v1/product/variant/search/', params);
};

let findOne = function(payload) {
  return fetchApi.get(`/api/v1/product/${payload.productId}/variant/${payload.variantId}/`);
};

let saveAll = function(payload) {
  return fetchApi.post(`/api/v1/product/${payload.productId}/saveVariants/`, payload);
};

let countSelection = function(payload) {
  return fetchApi.post('/api/v1/product/variants/count/', payload);
};

let updatePrices = function(payload) {
  return fetchApi.post('/api/v1/product/variants/updatePrices/', payload);
};

let updateStatus = function(payload) {
  return fetchApi.put('/api/v1/product/variant/updateStatus/', payload);
};

let updateBulkStatus = function(payload) {
  return fetchApi.post('/api/v1/product/variants/updateStatus/', payload);
};

let refreshImages = function(payload) {
  return fetchApi.post('/api/v1/product/variants/refreshImages/', payload);
};

let updateAttrs = function(variantId, payload) {
  return fetchApi.put(`/api/v1/product/variant/${variantId}/updateAttrs/`, payload);
};

let updateName = function(variantId, payload) {
  return fetchApi.put(`/api/v1/product/variant/${variantId}/updateName/`, payload);
};

export {
  search,
  findOne,
  saveAll,
  countSelection,
  updatePrices,
  updateStatus,
  updateBulkStatus,
  refreshImages,
  updateAttrs,
  updateName
};
