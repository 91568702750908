import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, InputNumber, Input, Switch, Tooltip, Button, Alert } from 'antd';
import * as attributeActions from 'redux/actions/attribute';
import { Navbar, Spinner } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ATTRIBUTE_SUBMIT_OK } from 'redux/actions/attribute/action_types';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { withEventBus } from 'context/eventbus';
let Fragment = React.Fragment;

class AttributeAdd extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ATTRIBUTE_SUBMIT_OK, action => {
        this.props.history.push(`/catalog/attributes/${action.payload.id}/${action.payload.type}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.create(formData);
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    let content;
    var attribute = {
      id: 0,
      type: this.props.match.params.type,
      required: false,
      unique: false,
      useInFiltering: false,
      order: 0,
      system: false,
      useInFrontend: false,
      isConfigurable: false
    };
    if (this.props.match.params.type === 'LIST') {
      attribute.values = [];
      attribute.multiSelect = false;
    }

    if (this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <WrappedAttributeForm
          key={0}
          type={this.props.match.params.type}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={attribute}
        ></WrappedAttributeForm>
      );
    }
    return (
      <div>
        <Navbar title={'Create Attribute'}>
          <Link type="primary" to={'/catalog/attributes/'}>
            <Tooltip placement="top" title="List attributes">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

let id = 0;
class AttributeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        const finalValues = Object.assign({}, values, {
          values: keys.map(key => names[key])
        });
        delete finalValues['keys'];
        delete finalValues['names'];
        this.props.submitHandle(finalValues);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    };
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? 'Values' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`names[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Please input a value or delete this field.'
            }
          ]
        })(<Input placeholder="value" style={{ width: '60%', marginRight: 8 }} />)}
        {keys.length > 1 ? <MinusCircleOutlined onClick={() => this.remove(k)} /> : null}
      </Form.Item>
    ));

    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('type', {
          initialValue: this.props.type,
          rules: []
        })(<Input type="hidden" placeholder="Id" />)}
        <Row gutter={24}>
          <Col span={24}>
            {this.state.globalErrors.map(error => (
              <Alert message={error} type="error" />
            ))}
          </Col>
          <Col span={8}>
            <Form.Item label="Code">
              {getFieldDecorator('code', {
                initialValue: this.props.data.code,
                rules: [{ required: true, message: 'Code is required' }]
              })(<Input placeholder="Code" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Label">
              {getFieldDecorator('label', {
                initialValue: this.props.data.label,
                rules: [{ required: true, message: 'id is required' }]
              })(<Input placeholder="Label" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Order">
              {getFieldDecorator('order', {
                rules: [{ required: true, message: 'order is required.' }],
                initialValue: this.props.data.order
              })(<InputNumber style={{ width: '100%' }} placeholder="Order" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label="Is unique">
              {getFieldDecorator('unique', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'Unique is required.' }],
                initialValue: this.props.data.unique
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Is used in Filtering">
              {getFieldDecorator('useInFiltering', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'this is required.' }],
                initialValue: this.props.data.useInFiltering
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label="Use in frontend">
              {getFieldDecorator('useInFrontend', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'this is required.' }],
                initialValue: this.props.data.useInFrontend
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Is configurable">
              {getFieldDecorator('isConfigurable', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'this is required.' }],
                initialValue: this.props.data.isConfigurable
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Is required">
              {getFieldDecorator('required', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'this is required.' }],
                initialValue: this.props.data.required
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>
          </Col>
        </Row>
        {this.props.type === 'LONG' || this.props.type === 'DOUBLE' ? (
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Mininum">
                {getFieldDecorator('min', {
                  rules: [{ required: true, message: 'min is required.' }],
                  initialValue: this.props.data.min
                })(<InputNumber style={{ width: '100%' }} placeholder="Mininum" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Maximum">
                {getFieldDecorator('max', {
                  rules: [{ required: true, message: 'Maximum is required.' }],
                  initialValue: this.props.data.max
                })(<InputNumber style={{ width: '100%' }} placeholder="Maximum" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Decimal">
                {getFieldDecorator('decimal', {
                  rules: [{ required: true, message: 'Decimal is required.' }],
                  initialValue: this.props.data.decimal
                })(<InputNumber style={{ width: '100%' }} placeholder="Decimal" />)}
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <div></div>
        )}
        {this.props.type === 'LIST' ? (
          <Fragment>
            {/*  <Row gutter={24}>
                      <Col span={8}>
                        <Form.Item label="Is multiSelect">
                          {getFieldDecorator('multiSelect', {
                            valuePropName: 'checked',
                            rules: [{required: true,message: 'multiSelect is required.'}],
                            initialValue: this.props.data.multiSelect
                          })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
                        </Form.Item>
                      </Col>
                      <Col span={13}>

                      </Col>
                      <Col span={3}>
                      </Col>
                    </Row>
                  */}
            <Row>
              <Col span={8}>
                {formItems}
                <Form.Item {...formItemLayoutWithOutLabel}>
                  <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                    <PlusOutlined /> Add field
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <div></div>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedAttributeForm = Form.create({ name: 'attributeForm' })(AttributeForm);

const mapDispatchToProps = {
  create: attributeActions.create,
  unload: attributeActions.unload
};

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(AttributeAdd));
