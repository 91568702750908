import { LIST_LOAD, LIST_LOADED, LIST_UNLOADED, LIST_RELOAD } from './action_types';

const listLoad = payload => ({
  type: LIST_LOAD,
  payload: payload
});

const listLoaded = payload => ({
  type: LIST_LOADED,
  payload: payload
});

const listUnload = () => ({
  type: LIST_UNLOADED,
  payload: {}
});

const reload = () => ({
  type: LIST_RELOAD,
  payload: {}
});

export { listLoad, listLoaded, listUnload, reload };
