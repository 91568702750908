import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Modal, Row, Col, Select, Button, Input } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './attributesEditForm.module.less';

const { Option } = Select;

class AttributesEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        this.props.hideScreenLoader();
        let errors = {
          globalErrors: action.payload.globalErrors || [],
          entityErrors: action.payload.entityErrors.attributes || {}
        };
        let globalErrors = displayValidationErrors(this.props.form, errors);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
    if (this.props.currentPhone.brandId) {
      this.props.onPhoneBrandChange([this.props.currentPhone.brandId]);
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let finalVals = {};
        Object.keys(values).forEach(k => (finalVals[k] = values[k] === undefined ? null : values[k]));
        this.props.onSubmit(finalVals);
      }
    });
  };

  renderText = (getFieldDecorator, a) => {
    let vals = this.props.attrValues[a.code];
    let initialValue = vals && vals.length ? vals[0] : '';
    return (
      <Col sm={24} key={a.code}>
        <Form.Item label={a.label}>
          {getFieldDecorator(a.code, {
            rules: [
              {
                required: a.required,
                message: `${a.label} is required.`
              }
            ],
            initialValue: initialValue
          })(<Input />)}
        </Form.Item>
      </Col>
    );
  };

  renderList = (getFieldDecorator, a) => {
    let vals = this.props.attrValues[a.code];
    let initialValue = vals && vals.length ? vals[0] : '';
    return (
      <Col sm={24} key={a.code}>
        <Form.Item label={a.label}>
          {getFieldDecorator(a.code, {
            rules: [
              {
                required: a.required,
                message: 'Select atleast 1 option.'
              }
            ],
            initialValue: initialValue
          })(
            <Select style={{ width: '100%' }} showSearch optionFilterProp="children" allowClear={!a.required}>
              {a.value.map((v, idx) => (
                <Option value={v} key={idx}>
                  {v}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
    );
  };

  loadPhones = () => {
    this.props.form.setFields({
      phone: {
        value: []
      }
    });
    let phoneBrandIds = this.props.form.getFieldValue('phoneBrand');
    if (phoneBrandIds) this.props.onPhoneBrandChange([phoneBrandIds]);
  };

  renderPhone = (getFieldDecorator, attr) => {
    let vals = this.props.attrValues[attr.code];
    let initialValue = vals && vals.length ? vals[0] : '';
    return (
      <React.Fragment key={attr.code}>
        <Col sm={24} md={24} key="phoneBrand">
          <Form.Item label="Phone Brand">
            <Row gutter={4}>
              <Col span={18}>
                {getFieldDecorator('phoneBrand', {
                  rules: [
                    {
                      required: attr.required,
                      message: 'Select atleast 1 option.'
                    }
                  ],
                  initialValue: this.props.currentPhone.brandId
                })(
                  <Select style={{ width: '100%' }} showSearch optionFilterProp="children">
                    {this.props.phoneBrands.map(pb => (
                      <Option value={pb.id} key={pb.id}>
                        {pb.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
              <Col span={6}>
                <Button onClick={this.loadPhones}>Load Phones</Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col sm={24} md={24} key="phone">
          <Form.Item label="Phone">
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: attr.required,
                  message: 'Select atleast 1 option.'
                }
              ],
              initialValue: initialValue
            })(
              <Select style={{ width: '100%' }} showSearch optionFilterProp="children">
                {this.props.phones.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </React.Fragment>
    );
  };

  renderSelector = (getFieldDecorator, attr) => {
    switch (attr.type) {
      case 'LIST':
        return this.renderList(getFieldDecorator, attr);
      case 'PHONE':
        return this.renderPhone(getFieldDecorator, attr);
      default:
        return this.renderText(getFieldDecorator, attr);
    }
  };

  render() {
    let attrs = this.props.attrs.sort((a, b) => a.order - b.order);
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={true}
        title="Update Attributes"
        okText="Update"
        onOk={this.handleSubmit}
        onCancel={this.props.onCancel}
        className={styles.editForm}
        maskClosable={false}
      >
        <Row gutter={24}>
          <Col span={24}>
            {this.state.globalErrors.map(error => (
              <Alert key={error} message={error} type="error" />
            ))}
          </Col>
        </Row>
        <Row gutter={24}>{attrs.map(a => this.renderSelector(getFieldDecorator, a))}</Row>
      </Modal>
    );
  }
}

export default withScreenLoader(withEventBus(Form.create({})(AttributesEditForm)));
