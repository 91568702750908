export const getPage = store => (store.staticPage ? store.staticPage : {});
export const isLoaded = store => {
  let page = getPage(store);
  return page.loaded != null && page.loaded;
};
export const getPages = store => getPage(store).pages;
export const isUpdated = store => {
  let page = getPage(store);
  return page.updated != null && page.updated;
};
