import {
  EMPLOYEE_VIEW_LOADED,
  EMPLOYEE_CREATE_UPDATE_OK,
  EMPLOYEE_STAUS_UPDATE_OK,
  EMPLOYEE_UNLOAD
} from '../actions/employee/action_types';

const initialStore = {
  isLoaded: false,
  employee: {},
  isSaved: false
};

const loaded = (state, employee) =>
  Object.assign({}, state, {
    isLoaded: true,
    employee: employee,
    isSaved: false
  });

const updated = (state, employee) =>
  Object.assign({}, state, {
    isLoaded: false,
    employee: employee,
    isSaved: true
  });

const unload = (state, payload) => Object.assign({}, state, initialStore);

export default function employee(state = initialStore, action) {
  switch (action.type) {
    case EMPLOYEE_VIEW_LOADED:
      return loaded(state, action.payload);
    case EMPLOYEE_CREATE_UPDATE_OK:
    case EMPLOYEE_STAUS_UPDATE_OK:
      return updated(state, action.payload);
    case EMPLOYEE_UNLOAD:
      return unload(state, initialStore);
    case 'MY_ERROR_ACTION':
      return state;
    default:
      return state;
  }
}
