import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider, Modal, Button, Descriptions } from 'antd';
import { Spinner, Navbar } from 'components';
import * as employeeSelector from 'redux/selectors/employee';
import * as employeeActions from 'redux/actions/employee';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMPLOYEE_STAUS_UPDATE_OK } from 'redux/actions/employee/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './employeeview.module.less';

const { confirm } = Modal;

class EmployeeView extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(EMPLOYEE_STAUS_UPDATE_OK, action => {
        this.props.findByEmployeeId(this.props.match.params.id);
      })
    );
    if (!this.props.isLoaded) {
      this.props.findByEmployeeId(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.unload();
  }

  updateStatus = (id, status) => {
    if (status === 'INACTIVE') {
      this.props.activate(id);
    } else {
      this.props.inActivate(id);
    }
  };

  onStatusUpdate = () => {
    var status = this.props.employee.status;
    var id = this.props.employee.id;
    let updateStatus = this.updateStatus;
    confirm({
      title: 'Confirm',
      content: 'Are you sure to ' + status.name,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateStatus(id, status.id);
      },
      onCancel() {}
    });
  };

  render() {
    let content;
    let status;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      status = this.props.employee.status;
      var c = this.props.employee;
      content = (
        <Descriptions bordered size="small">
          <Descriptions.Item label="First Name">{c.firstName}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{c.lastName}</Descriptions.Item>
          <Descriptions.Item label="Username">{c.username}</Descriptions.Item>
          <Descriptions.Item label="Email">{c.email}</Descriptions.Item>
          <Descriptions.Item label="Status">{c.status.name}</Descriptions.Item>
          <Descriptions.Item label="Role">{c.role.name}</Descriptions.Item>
          <Descriptions.Item label="Created By">
            {c.createdBy && c.createdBy.name ? c.createdBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Created On">{c.createdOn}</Descriptions.Item>
          <Descriptions.Item label="Updated By">
            {c.updatedBy && c.updatedBy.name ? c.updatedBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Updated On">{c.updatedOn}</Descriptions.Item>
        </Descriptions>
      );
    }

    return (
      <div className={styles.container}>
        <Navbar title={this.props.employee.code}>
          <Button type="primary" shape="circle" onClick={this.onStatusUpdate}>
            <Tooltip
              placement="rightTop"
              title={status ? (status.id === 'ACTIVE' ? 'Deactivate employee' : 'Activate employee') : ''}
            >
              <FontAwesomeIcon icon={status && status.id === 'ACTIVE' ? 'times' : 'check'} />
            </Tooltip>
          </Button>
          <Divider type="vertical" />
          <Link type="primary" shape="circle" to={'/employees/create/'}>
            <Tooltip placement="rightTop" title="Create Employee">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={`/employees/edit/${this.props.match.params.id}/`}>
            <Tooltip placement="rightTop" title="Edit Employee">
              <FontAwesomeIcon icon="edit" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/employees/'}>
            <Tooltip placement="rightTop" title="List Employee">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = {
  findByEmployeeId: employeeActions.findEmployeeById,
  unload: employeeActions.employeeUnload,
  activate: employeeActions.employeeActivate,
  inActivate: employeeActions.employeeInactivate
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: employeeSelector.isLoaded(state),
    employee: employeeSelector.getEmployee(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(EmployeeView));
