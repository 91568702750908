// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tableListSelector from 'redux/selectors/tableList';
import { Row, Col, Button, Tooltip } from 'antd';
import * as productVariantAction from 'redux/actions/productVariant';
import * as userSelector from 'redux/selectors/user';
import * as listActions from 'redux/actions/list';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Fragment = React.Fragment;

class ProductVariantList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    const tableAction = !this.props.isUserContentCreator && (
      <Fragment>
        <Button shape="circle" onClick={() => this.props.history.push('/catalog/productVariants/refreshImages/')}>
          <Tooltip placement="top" title="Refresh Images">
            <FontAwesomeIcon icon="sync-alt" />
          </Tooltip>
        </Button>
      </Fragment>
    );

    return (
      <div>
        <Row>
          <Col span={24}>
            <WrapperTable
              title="Product Variant List"
              filters={filters}
              columns={columns}
              data={this.props.listData}
              api={this.props.search}
              tableAction={tableAction}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
const columns = [
  {
    title: 'Variant No',
    dataIndex: 'variantNumber',
    defaultSortOrder: 'descend',
    sorter: true
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true
  },
  {
    title: 'Sku',
    dataIndex: 'sku'
  },
  {
    title: 'Rack',
    dataIndex: 'rack',
    render: (_, rec) => <Fragment>{rec.attributes['rack'] || ''}</Fragment>
  },
  {
    title: 'UPC',
    dataIndex: 'upc',
    render: (_, rec) => <Fragment>{rec.attributes['upc'] || ''}</Fragment>
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    render: enabled => <Fragment>{enabled ? 'Yes' : 'No'}</Fragment>
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (id, row) => (
      <Fragment>
        <Link to={`/catalog/productVariants/product/${row.productId}/variant/${id}/`}>view</Link>
      </Fragment>
    )
  }
];

const filters = [
  {
    field: 'sku',
    type: 'TEXT'
  },
  {
    field: 'name',
    type: 'TEXT'
  },
  {
    field: 'variantNumber',
    type: 'NUMBER'
  },
  {
    field: 'enabled',
    type: 'YESNO'
  }
];

const mapDispatchToProps = {
  search: productVariantAction.search,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state),
    isUserContentCreator: userSelector.isUserContentCreator(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantList);
