import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Divider, Descriptions, Tooltip, Modal, Button, Input, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { AddressView, Spinner, Navbar } from 'components';
import * as invoiceSelectors from 'redux/selectors/invoice';
import * as invoiceActions from 'redux/actions/invoice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { INVOICE_CREATE_UPDATE_OK } from 'redux/actions/invoice/action_types';
import { withEventBus } from 'context/eventbus';
import moment from 'moment';
import { toPrice, toDateString, wrapUrlWithAuthToken } from 'Utils/utils';
import * as queryString from 'query-string';
import style from './invoiceview.module.less';

const { TextArea } = Input;

class InvoiceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaymentForm: false
    };
    this.subscriptions = [];
  }

  componentWillUnmount() {
    this.props.unloadInvoice();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  componentDidMount() {
    this.props.findByUid(this.props.match.params.id);
    this.subscriptions.push(
      this.props.eventBusSubscribe(INVOICE_CREATE_UPDATE_OK, action => {
        this.setState({
          showPaymentForm: false
        });
        this.props.findByUid(this.props.match.params.id);
      })
    );
  }

  onStatusUpdate = () => {
    this.setState({
      showPaymentForm: true
    });
  };

  onCancelModel = () => {
    this.setState({
      showPaymentForm: false
    });
  };

  submitHandle = data => {
    this.props.markPaid(data);
  };

  loadProducts = lines => {
    if (lines)
      return lines.map((item, key) => (
        <tr key={key} className={style.row}>
          <td className={style.product}> {key + 1}</td>
          <td className={style.product}> {item.name}</td>
          <td className={style.cell}> {item.sku}</td>
          <td className={style.number}> {item.qtyInvoiced}</td>
          <td className={style.total}> {item.price}</td>
          <td className={style.total}> {toPrice(item.subTotal)}</td>
        </tr>
      ));
  };

  generateInvoiceView = (o, lines) => {
    return (
      <div>
        <Descriptions bordered column={2} size="small">
          <Descriptions.Item label="Customer Name">
            {o.customer.firstName} {o.customer.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Customer Email">{o.customer.email}</Descriptions.Item>
          <Descriptions.Item label="Invoice Date">{toDateString(o.invoiceDate)}</Descriptions.Item>
          <Descriptions.Item label="Status">{o.status.name}</Descriptions.Item>
          <Descriptions.Item span={2} label="Order">
            {o.orderCode}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Method">
            {o.paymentMethod == null ? '' : o.paymentMethod.name}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Date">{toDateString(o.paymentDate)}</Descriptions.Item>
          <Descriptions.Item label="Shipping Method">
            {o.orderShippingMethod == null ? '' : o.orderShippingMethod.name}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Description">{o.shippingDescription}</Descriptions.Item>
          <Descriptions.Item label="Billing Address">
            <AddressView address={o.billingAddress} key={o.billingAddress.id} />
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Address">
            <AddressView address={o.shippingAddress} key={o.shippingAddress.id} />
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Notes">
            {o.notes}
          </Descriptions.Item>
          <Descriptions.Item label="Created By">
            {o.createdBy && o.createdBy.name ? o.createdBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Created On">{toDateString(o.createdOn)}</Descriptions.Item>
          <Descriptions.Item label="Updated By">
            {o.updatedBy && o.updatedBy.name ? o.updatedBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Updated On">{toDateString(o.updatedOn)}</Descriptions.Item>
        </Descriptions>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <h3>Status Hsitory</h3>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <table className={style.table}>
              <thead>
                <tr className={style.row}>
                  <th className={style.header}>Status</th>
                  <th>Time stamp</th>
                  <th className={style.header}>Comment</th>
                  <th className={style.header}>Visible To User</th>
                  <th className={style.header}>User</th>
                </tr>
              </thead>
              <tbody>
                {o.statusHistory.map((item, key) => (
                  <tr key={key} className={style.row}>
                    <td className={style.product}> {item.status.name}</td>
                    <td className={style.product}> {item.dateTime}</td>
                    <td className={style.product}> {item.comment}</td>
                    <td className={style.product}> {item.visibleToUser ? 'Yes' : 'No'}</td>
                    <td className={style.product}> {item.userId.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <h3>Shipment Details</h3>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <table className={style.table}>
              <thead>
                <tr className={style.row}>
                  <th className={style.header}>Name</th>
                  <th>Tracking #</th>
                  <th>Method</th>
                  <th className={style.header_number}>Amount</th>
                  <th className={style.header_number}>Weight</th>
                  <th className={style.header_number}>Length</th>
                  <th className={style.header_number}>Width</th>
                  <th className={style.header_number}>Height</th>
                </tr>
              </thead>
              <tbody>
                {o.shipments.map((item, key) => (
                  <tr key={key} className={style.row}>
                    <td className={style.product}> {item.name}</td>
                    <td className={style.cell}> {item.tracking}</td>
                    <td className={style.cell}> {item.method !== null ? item.method.name : ''}</td>
                    <td className={style.total}> {toPrice(item.amount)}</td>
                    <td className={style.total}> {item.weight}</td>
                    <td className={style.total}> {item.length}</td>
                    <td className={style.total}> {item.width}</td>
                    <td className={style.total}> {item.height}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <h3>Shipped Items</h3>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <table className={style.table}>
              <thead>
                <tr className={style.row}>
                  <th className={style.header}>SR #</th>
                  <th className={style.header}>PRODUCT NAME</th>
                  <th>SKU</th>
                  <th className={style.header_number}>QTY</th>
                  <th className={style.header_number}>PRICE</th>
                  <th className={style.header_number}>SUBTOTAL</th>
                </tr>
              </thead>
              <tbody>{lines}</tbody>
            </table>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={18} />
          <Col span={6}>
            <table className={style.table}>
              <tbody>
                <tr className={style.row}>
                  <td>Sub Total</td>
                  <td className={style.total}>{toPrice(o.subTotal)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Shipping Amt</td>
                  <td className={style.total}>{toPrice(o.shippingAmt)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Tax</td>
                  <td className={style.total}>{toPrice(o.taxes)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Discount</td>
                  <td className={style.total}>{toPrice(o.discountAmt)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Shipping Disc</td>
                  <td className={style.total}>{toPrice(o.shippingDisc)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Total</td>
                  <td className={style.total}>{toPrice(o.total)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Divider />
        {o.backOrders.length > 0 && (
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <h3>Back order</h3>{' '}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <table className={style.table}>
                  <thead>
                    <tr className={style.row}>
                      <th className={style.header}>SR #</th>
                      <th className={style.header}>PRODUCT NAME</th>
                      <th className={style.header_number}>ORDERED QTY</th>
                      <th className={style.header_number}>SHIPPED QTY</th>
                      <th className={style.header_number}>BALANCE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {o.backOrders.map((item, key) => (
                      <tr key={key} className={style.row}>
                        <td className={style.product}> {key + 1}</td>
                        <td className={style.product}> {item.name}</td>
                        <td className={style.number}> {item.qtyOrdered}</td>
                        <td className={style.total}> {item.qtyInvoiced}</td>
                        <td className={style.total}> {item.qtyOrdered - item.qtyInvoiced}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  render() {
    let invoice = this.props.invoice;
    let lines = this.loadProducts(invoice.items);
    let content;
    if (invoice.code) {
      content = (
        <div>
          <Navbar title={invoice.code}>
            {!this.props.showArchive && invoice.status.id === 'UNPAID' ? (
              <Button type="primary" shape="circle" onClick={this.onStatusUpdate}>
                <Tooltip placement="rightTop" title={'Mark paid'}>
                  <FontAwesomeIcon icon="money-check-alt" />
                </Tooltip>
              </Button>
            ) : (
              <div></div>
            )}
            {(invoice.status.id === 'UNPAID' || invoice.status.id === 'PAID') && (
              <a
                href={wrapUrlWithAuthToken(`/api/v1/invoice/print/shipping_doc/?id=${invoice.id}`)}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex="-1"
              >
                Shipping Document
              </a>
            )}
            <Link type="primary" to={`/sales/invoices/?showArchive=${this.props.showArchive}`}>
              <Tooltip placement="top" title="List Invoices">
                <FontAwesomeIcon icon="th-list" />
              </Tooltip>
            </Link>
            <a
              href={wrapUrlWithAuthToken(`/api/v1/invoice/print/?id=${invoice.id}`)}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="-1"
            >
              <Tooltip placement="top" title="Print Invoice">
                <FontAwesomeIcon icon="print" />
              </Tooltip>
            </a>
          </Navbar>
          {this.generateInvoiceView(invoice, lines)}
          <Modal
            title={'Mark invoice paid'}
            centered
            visible={this.state.showPaymentForm}
            footer={null}
            onCancel={this.onCancelModel}
            width="850px"
            destroyOnClose={true}
          >
            <WrappedPaymentStatusForm
              submitHandle={this.submitHandle}
              eventBusSubscribe={this.props.eventBusSubscribe}
              data={this.props.invoice}
            ></WrappedPaymentStatusForm>
          </Modal>
        </div>
      );
    } else {
      content = <Spinner />;
    }
    return <div id="print-mount">{content}</div>;
  }
}

class PaymentStatusForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.submitHandle(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Payment Date">
              {getFieldDecorator('paymentDate', {
                initialValue: moment()
              })(<DatePicker placeholder="Payment Date" rows={4} cols={24} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Comment">
              {getFieldDecorator('comment', {})(<TextArea placeholder="Comment" rows={4} cols={24} />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Mark Paid
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedPaymentStatusForm = Form.create({ name: 'shipmentForm' })(PaymentStatusForm);

const mapDispatchToProps = {
  findByUid: invoiceActions.findOne,
  markPaid: invoiceActions.paid,
  unloadInvoice: invoiceActions.unload
};

const mapStateToProps = (state, ownProps) => ({
  invoice: invoiceSelectors.get(state),
  showArchive: queryString.parse(ownProps.location.search).showArchive === 'true'
});

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(InvoiceView));
