import {
  PRICE_OVERRIDE_BY_CUSTOMER_LOAD,
  PRICE_OVERRIDE_BY_CUSTOMER_LOADED,
  PRICE_OVERRIDE_UNLOAD,
  PRICE_OVERRIDE_LOADED
} from 'redux/actions/priceOverride/action_types';

const initialStore = {
  isLoaded: false,
  priceOverrides: [],
  priceOverride: { price: 0, attributes: [] }
};

const loadedByCustomerId = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    priceOverrides: payload
  });

const loaded = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    priceOverride: payload
  });

export default function priceOverride(state = initialStore, action) {
  switch (action.type) {
    case PRICE_OVERRIDE_BY_CUSTOMER_LOAD:
      return Object.assign({}, state, initialStore);
    case PRICE_OVERRIDE_BY_CUSTOMER_LOADED:
      return loadedByCustomerId(state, action.payload);
    case PRICE_OVERRIDE_UNLOAD:
      return Object.assign({}, state, initialStore);
    case PRICE_OVERRIDE_LOADED:
      return loaded(state, action.payload);
    default:
      return state;
  }
}
