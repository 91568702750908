import { fetchApi } from 'Utils/utils';

let create = function(params) {
  return fetchApi.post('/api/v1/attribute/', params);
};

let update = function(params) {
  return fetchApi.put(`/api/v1/attribute/${params.id}/`, params);
};

let list = function(params) {
  return fetchApi.post('/api/v1/attribute/search/', params);
};

let remove = function(id) {
  return fetchApi.delete(`/api/v1/attribute/${id}/`);
};

let removeListAttribute = function(params) {
  return fetchApi.put(`/api/v1/attribute/remove/${params.id}/`, { attributeId: params.id, listValue: params.label });
};

let addListAttribute = function(params) {
  return fetchApi.put(`/api/v1/attribute/add/${params.id}/`, { attributeId: params.id, listValue: params.label });
};

let findOneByType = function(params) {
  return fetchApi.get(`/api/v1/attribute/${params.id}/${params.type}/`);
};

let loadAll = function() {
  return fetchApi.get('/api/v1/attribute/all/');
};

let loadAllPhoneBrands = function() {
  return fetchApi.get('/api/v1/attribute/phoneBrands/');
};

let loadPhonesByBrandIds = function(payload) {
  return fetchApi.post('/api/v1/attribute/phoneBrands/phones/', payload);
};

export {
  create,
  update,
  list,
  findOneByType,
  remove,
  loadAll,
  loadAllPhoneBrands,
  loadPhonesByBrandIds,
  removeListAttribute,
  addListAttribute
};
