import Customer from './Customer/customer';
import Rates from './Rates/rates';
import Report from './Report/report';
import Details from './Details/details';

const obj = {
  Customer: Customer,
  Rates: Rates,
  Report: Report,
  Details: Details
};

export default obj;
