import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Switch, TreeSelect, Modal } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './edit.module.less';

const { confirm } = Modal;

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.update(values);
      }
    });
  };

  treeData = cat => {
    let opts = cat.children.flatMap(c => this.treeData(c));
    return {
      title: cat.category.name,
      value: cat.category.id,
      key: cat.category.id,
      children: opts,
      disabled: !cat.category.isAnchor
    };
  };

  cancel = () => {
    let toggleMode = this.props.toggleMode;
    confirm({
      title: 'Confirm',
      content: 'Do you want to cancel editing?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        toggleMode();
      },
      onCancel() {}
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.edit}>
        <h2 className="page-title">
          {this.props.category.id === 0 ? 'Create New Category' : 'Update Category: ' + this.props.category.name}
        </h2>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: this.props.category.id
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Name is required.'
                    }
                  ],
                  initialValue: this.props.category.name
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Url">
                {getFieldDecorator('url', {
                  rules: [
                    {
                      required: true,
                      message: 'Url is required.'
                    }
                  ],
                  initialValue: this.props.category.url
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Parent Category">
                {getFieldDecorator('parentId', {
                  initialValue: this.props.category.parentId,
                  rules: [
                    {
                      required: true,
                      message: 'Parent Category is required.'
                    }
                  ]
                })(
                  <TreeSelect
                    treeData={[this.treeData(this.props.categories)]}
                    treeDefaultExpandedKeys={[this.props.categories.category.id]}
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={12} md={6}>
              <Form.Item label="Status">
                {getFieldDecorator('status', {
                  valuePropName: 'checked',
                  rules: [
                    {
                      required: true,
                      message: 'Status is required.'
                    }
                  ],
                  initialValue: this.props.category.status === 'ENABLED'
                })(<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />)}
              </Form.Item>
            </Col>
            <Col sm={12} md={6}>
              <Form.Item label="Is Anchor">
                {getFieldDecorator('isAnchor', {
                  valuePropName: 'checked',
                  rules: [
                    {
                      required: true,
                      message: 'Is Anchor is required.'
                    }
                  ],
                  initialValue: this.props.category.isAnchor || false
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={24}>
              <Form.Item label="Description">
                {getFieldDecorator('description', {})(<Input.TextArea rows={8} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item className="form-footer-buttons">
                <Button type="primary" htmlType="submit">
                  {this.props.category.id === 0 ? 'Create' : 'Update'}
                </Button>
                {this.props.category.id !== 0 && <Button onClick={this.cancel}>Cancel</Button>}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: '' })(Edit);
