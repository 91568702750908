import {
  PAGE_LOAD,
  PAGE_LOADED,
  PAGE_UNLOADED,
  PAGE_UPDATE,
  PAGE_CREATE_UPDATE_SUCCESS,
  PAGE_CREATE
} from './action_types';

const load = () => ({
  type: PAGE_LOAD,
  payload: {}
});

const loaded = payload => ({
  type: PAGE_LOADED,
  payload: payload
});

const unload = () => ({
  type: PAGE_UNLOADED,
  payload: {}
});

const update = payload => ({
  type: PAGE_UPDATE,
  payload: payload
});

const pageCreateUpdateSucess = payload => ({
  type: PAGE_CREATE_UPDATE_SUCCESS,
  payload: payload
});

const create = payload => ({
  type: PAGE_CREATE,
  payload: payload
});

export { load, loaded, unload, update, pageCreateUpdateSucess, create };
