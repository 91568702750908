import { fetchApi } from 'Utils/utils';

let searchProducts = function(params) {
  return fetchApi.post('/api/v1/product/search/', params);
};

let findOne = function(id) {
  return fetchApi.get(`/api/v1/product/${id}/`);
};

let getAttrs = function(id) {
  return fetchApi.get(`/api/v1/product/${id}/attributes/`);
};

let getCategories = function(id) {
  return fetchApi.get(`/api/v1/product/${id}/categories/`);
};

let update = function(payload) {
  if (!payload.id || payload.id === '') {
    return fetchApi.post('/api/v1/product/', payload);
  } else {
    return fetchApi.put(`/api/v1/product/${payload.id}/`, payload);
  }
};

let generateVariants = function(payload) {
  return fetchApi.post(`/api/v1/product/${payload.productId}/generateVariants/`, payload);
};

let regenerateDesc = function(payload) {
  return fetchApi.post(`/api/v1/product/${payload.id}/regenerateDesc/`);
};

let updateAttributes = function(payload) {
  return fetchApi.put(`/api/v1/product/${payload.id}/updateAttributes/`, payload);
};

export { updateAttributes, searchProducts, findOne, getAttrs, getCategories, update, generateVariants, regenerateDesc };
