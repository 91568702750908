import React, { Component } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import * as configSelectors from 'redux/selectors/config';
import * as userSelectors from 'redux/selectors/user';
import * as userActions from 'redux/actions/user';
import styles from './login.module.less';

class LoginForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.loginBtnClickHandler(values.username, values.password);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {this.props.errMsg !== null && (
          <Alert message="Error" description={this.props.errMsg} type="error" showIcon className={styles.errorMsg} />
        )}
        <Form.Item className={styles.row}>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }]
          })(<Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />)}
        </Form.Item>
        <Form.Item className={styles.row}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit" block className="login-form-button">
            Log in
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

class Login extends Component {
  render() {
    return (
      <div className={styles.loginForm}>
        <div className={styles.loginFormContainer}>
          <div className={styles.logo}>
            <img src={this.props.logo} alt="Wplus" />
          </div>
          <div className={styles.information}>Please login</div>
          <WrappedLoginForm {...this.props} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loginBtnClickHandler: userActions.userLogin
};

const mapStateToProps = (state, ownProps) => ({
  logo: configSelectors.getLogo(state),
  errMsg: userSelectors.getErrMsg(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
