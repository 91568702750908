export const getHomepageContent = store => (store.homepage ? store.homepage : {});
export const isHomepageContentLoaded = store => {
  let content = getHomepageContent(store);
  return content.loaded != null && content.loaded;
};
export const getMessages = store => {
  let content = getHomepageContent(store);
  return {
    errorMsg: content.errorMsg,
    infoMsg: content.infoMsg,
    successMsg: content.successMsg,
    warnMsg: content.warnMsg
  };
};
