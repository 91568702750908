import { call, put, takeLatest, all } from 'redux-saga/effects';
import { CATEGORY_LOAD, CATEGORY_UPDATE, CATEGORY_IMAGE_UPLOAD } from '../actions/category/action_types';
import * as categoryService from '../services/category';
import * as categoryActions from 'redux/actions/category';
import * as apiErrorActions from 'redux/actions/apiError';

function* fetchAll() {
  yield takeLatest(CATEGORY_LOAD, function*(action) {
    let categories = yield call(categoryService.fetchAll);
    yield put(categoryActions.loaded(categories));
  });
}

function* update() {
  yield takeLatest(CATEGORY_UPDATE, function*(action) {
    try {
      let cat = yield call(categoryService.update, action.payload);
      yield put(categoryActions.updateSuccess(cat));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* uploadImage() {
  yield takeLatest(CATEGORY_IMAGE_UPLOAD, function*(action) {
    try {
      let menu = yield call(categoryService.uploadImage, action.payload);
      yield put(categoryActions.updateSuccess(menu));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* configSagas() {
  yield all([fetchAll(), update(), uploadImage()]);
}
