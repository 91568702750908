import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Select } from 'antd';
import styles from './attributeSelector.module.less';

const { Option } = Select;

class AttributeSelector extends Component {
  toDataSource = attr => {
    return attr.value.map((a, idx) =>
      Object.assign(
        {},
        {
          key: idx,
          name: a
        }
      )
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = Object.assign({}, values);
        delete payload['phoneBrand'];
        this.props.attributes
          .filter(a => a.type === 'LIST' || a.type === 'PHONE')
          .forEach(a => {
            if (!payload[a.code]) {
              payload[a.code] = [];
            }
          });
        this.props.generateAttributes(payload);
      }
    });
  };

  renderList = (getFieldDecorator, a) => {
    return (
      <Col sm={24} md={12} key={a.code}>
        <Form.Item label={a.label}>
          {getFieldDecorator(a.code, {
            rules: [
              {
                required: a.required,
                message: 'Select atleast 1 option.'
              }
            ]
          })(
            <Select mode="multiple">
              {a.value.map((v, idx) => (
                <Option value={v} key={idx}>
                  {v}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
    );
  };

  loadPhones = () => {
    this.props.form.setFields({
      phone: {
        value: []
      }
    });
    let phoneBrandIds = this.props.form.getFieldValue('phoneBrand');
    if (phoneBrandIds && phoneBrandIds.length > 0) this.props.onPhoneBrandChange(phoneBrandIds);
  };

  renderPhone = (getFieldDecorator, attr) => {
    return (
      <Col sm={24} md={24} key={attr.code}>
        <Row gutter={24}>
          <Col sm={24} md={12} key="phoneBrand">
            <Form.Item label="Phone Brand">
              <Row gutter={4}>
                <Col span={20}>
                  {getFieldDecorator('phoneBrand', {
                    rules: [
                      {
                        required: attr.required,
                        message: 'Select atleast 1 option.'
                      }
                    ]
                  })(
                    <Select mode="multiple" showSearch optionFilterProp="children">
                      {this.props.phoneBrands.map(pb => (
                        <Option value={pb.id} key={pb.id}>
                          {pb.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Col>
                <Col span={4}>
                  <Button onClick={this.loadPhones}>Load Phones</Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col sm={24} md={12} key="phone">
            <Form.Item label="Phone">
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: attr.required,
                    message: 'Select atleast 1 option.'
                  }
                ]
              })(
                <Select mode="multiple" showSearch optionFilterProp="children">
                  {this.props.phones.map(p => (
                    <Option value={p.id} key={p.id}>
                      {p.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Col>
    );
  };

  renderSelector = (getFieldDecorator, attr) => {
    switch (attr.type) {
      case 'LIST':
        return this.renderList(getFieldDecorator, attr);
      case 'PHONE':
        return this.renderPhone(getFieldDecorator, attr);
      default:
        return <div key={attr.code}></div>;
    }
  };

  render() {
    let attrs = this.props.attributes.sort((a, b) => a.order - b.order);
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.attributeSelector}>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={24}>{attrs.map(a => this.renderSelector(getFieldDecorator, a))}</Row>
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create()(AttributeSelector);
