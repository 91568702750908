import { VIEW_ORDER_LOADED } from 'redux/actions/order/action_types';

const initialStore = {
  isLoaded: false,
  order: {}
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    order: payload
  });

export default function order(state = initialStore, action) {
  switch (action.type) {
    case VIEW_ORDER_LOADED:
      return load(state, action.payload);
    case 'MY_ERROR_ACTION':
      return state;
    default:
      return state;
  }
}
