import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, InputNumber, Button, Switch, TreeSelect, Modal, Select } from 'antd';
import { Autocomplete } from 'components';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import _ from 'lodash';
import styles from './edit.module.less';

const { confirm } = Modal;

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      globalErrors: [],
      itemType: props.menu.itemType
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.loadAllPhoneBrands();
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  loadPhones = phoneBrandId => {
    this.props.form.setFieldsValue({ values: null });
    if (phoneBrandId) this.props.loadPhonesByBrandIds([phoneBrandId]);
  };

  itemTypeChanged = value => {
    this.setState({ itemType: value });
    this.props.form.setFieldsValue({ values: null });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = Object.assign({}, values);
        if (!_.isArray(payload.values)) {
          payload.values = [payload.values];
        }
        this.props.onSubmit(payload);
      }
    });
  };

  treeData = item => {
    let opts = item.children.flatMap(c => this.treeData(c));
    return { title: item.menu.name, value: item.menu.id, key: item.menu.id, children: opts };
  };

  categoryTreeData = (cat, disable) => {
    let opts = cat.children.flatMap(c => this.categoryTreeData(c, disable));
    return {
      title: cat.category.name,
      value: cat.category.id,
      key: cat.category.id,
      children: opts,
      disabled: disable(cat)
    };
  };

  generateTreeNodes = nodes => {
    var tree = [];
    nodes.map((item, key) => tree.push(this.treeData(item)));
    return tree;
  };

  cancel = () => {
    let toggleMode = this.props.toggleMode;
    confirm({
      title: 'Confirm',
      content: 'Do you want to cancel editing?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        toggleMode();
      },
      onCancel() {}
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const urlValue = this.props.menu.values && this.props.menu.values.length === 1 ? this.props.menu.values[0] : null;
    return (
      <div className={styles.edit} style={{ padding: 8 }}>
        <h2 className="page-title">
          {this.props.menu.id === 0 ? 'Create New Menu' : 'Update Menu: ' + this.props.menu.name}
        </h2>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: this.props.menu.id
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Name is required.'
                    }
                  ],
                  initialValue: this.props.menu.name
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={6}>
              <Form.Item label="Order">
                {getFieldDecorator('order', {
                  rules: [
                    {
                      required: true,
                      message: 'Order is required.'
                    }
                  ],
                  initialValue: this.props.menu.order
                })(<InputNumber />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Parent Menu">
                {getFieldDecorator('parent', {
                  initialValue: this.props.menu.parent,
                  rules: []
                })(
                  <TreeSelect
                    treeData={this.generateTreeNodes(this.props.menus)}
                    treeDefaultExpandedKeys={[this.props.menu.parent]}
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={12} md={6}>
              <Form.Item label="Status">
                {getFieldDecorator('enabled', {
                  valuePropName: 'checked',
                  rules: [
                    {
                      required: true,
                      message: 'Status is required.'
                    }
                  ],
                  initialValue: this.props.menu.enabled
                })(<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />)}
              </Form.Item>
            </Col>
            <Col sm={12} md={6}>
              <Form.Item label="Show View All">
                {getFieldDecorator('showViewAll', {
                  valuePropName: 'checked',
                  rules: [
                    {
                      required: true,
                      message: 'Show view all is required.'
                    }
                  ],
                  initialValue: this.props.menu.showViewAll
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Display Style" className={styles.row}>
                {getFieldDecorator('displayStyle', {
                  rules: [
                    {
                      required: true,
                      message: 'Select a Display Style'
                    }
                  ],
                  initialValue: this.props.menu.displayStyle
                })(
                  <Select placeholder="Display Style">
                    {[
                      { id: 'LIST', name: 'List' },
                      { id: 'GRID', name: 'Grid' }
                    ].map(p => (
                      <Select.Option key={p} value={p.id}>
                        {p.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="Item Type" className={styles.row}>
                {getFieldDecorator('itemType', {
                  rules: [{ required: true, message: 'Select a Item Type' }],
                  initialValue: this.props.menu.itemType
                })(
                  <Select placeholder="Item Type" onChange={this.itemTypeChanged}>
                    {[
                      { id: 'CATEGORY', name: 'Category' },
                      { id: 'PRODUCT_LIST', name: 'Product List' },
                      { id: 'PHONE_BRAND', name: 'Phone Brand' },
                      { id: 'PHONE', name: 'Phone' },
                      { id: 'LINK', name: 'Link' }
                    ].map(p => (
                      <Select.Option key={p} value={p.id}>
                        {p.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="New Marker">
                {getFieldDecorator('newMarker', {
                  valuePropName: 'checked',
                  rules: [],
                  initialValue: this.props.menu.newMarker
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>
            </Col>
          </Row>
          {this.state.itemType === 'LINK' && (
            <Row gutter={24}>
              <Col sm={24} md={6}>
                <Form.Item label="Url">
                  {getFieldDecorator('values', {
                    rules: [
                      {
                        required: true,
                        message: 'Url is required.'
                      }
                    ],
                    initialValue: urlValue
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          )}
          {this.state.itemType === 'PRODUCT' && (
            <Row gutter={24}>
              <Col sm={24} md={8}>
                <Form.Item label="Product">
                  {getFieldDecorator('values', {
                    rules: [
                      {
                        required: true,
                        message: 'Product is required.'
                      }
                    ]
                  })(<Autocomplete.Product />)}
                </Form.Item>
              </Col>
            </Row>
          )}
          {this.state.itemType === 'CATEGORY' && (
            <Row gutter={24}>
              <Col sm={24} md={12}>
                <Form.Item label="Categories">
                  {getFieldDecorator('values', {
                    rules: [
                      {
                        required: true,
                        message: 'Atleast 1 category is required.'
                      }
                    ]
                  })(
                    <TreeSelect
                      treeData={[
                        this.categoryTreeData(
                          this.props.categories,
                          cat => cat.category.parentId == null || !cat.category.isAnchor
                        )
                      ]}
                      multiple
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
          {this.state.itemType === 'PRODUCT_LIST' && (
            <Row gutter={24}>
              <Col sm={24} md={12}>
                <Form.Item label="Category">
                  {getFieldDecorator('values', {
                    rules: [
                      {
                        required: true,
                        message: 'Category is required.'
                      }
                    ]
                  })(
                    <TreeSelect
                      treeData={[this.categoryTreeData(this.props.categories, cat => cat.category.isAnchor)]}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
          {this.state.itemType === 'PHONE_BRAND' && (
            <Row gutter={24}>
              <Col sm={24} md={12}>
                <Form.Item label="Phone Brand">
                  {getFieldDecorator('values', {
                    rules: [
                      {
                        required: true,
                        message: 'Phone Brand is required.'
                      }
                    ]
                  })(
                    <Select>
                      {this.props.phoneBrands.map(pb => (
                        <Select.Option key={pb.id}>{pb.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
          {this.state.itemType === 'PHONE' && (
            <Row gutter={24}>
              <Col sm={24} md={12}>
                <Form.Item label="Phone Brand">
                  {getFieldDecorator('phoneBrand', {
                    rules: [
                      {
                        required: true,
                        message: 'Phone Brand is required.'
                      }
                    ]
                  })(
                    <Select onChange={this.loadPhones}>
                      {this.props.phoneBrands.map(pb => (
                        <Select.Option key={pb.id}>{pb.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item label="Phone">
                  {getFieldDecorator('values', {
                    rules: [
                      {
                        required: true,
                        message: 'Phone is required.'
                      }
                    ]
                  })(
                    <Select>
                      {this.props.phones.map(p => (
                        <Select.Option key={p.id}>{p.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item className="form-footer-buttons">
                <Button type="primary" htmlType="submit">
                  {this.props.menu.id === 0 ? 'Create' : 'Update'}
                </Button>
                {this.props.menu.id !== 0 && <Button onClick={this.cancel}>Cancel</Button>}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: '' })(Edit);
