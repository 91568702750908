export const SALES_COMM_ASSIGN_CUSTOMER = 'SALES_COMM_ASSIGN_CUSTOMER';
export const SALES_COMM_ASSIGN_CUSTOMER_SUCCESS = 'SALES_COMM_ASSIGN_CUSTOMER_SUCCESS';
export const SALES_COMM_LOAD_ALL_REPS = 'SALES_COMM_LOAD_ALL_REPS';
export const SALES_COMM_LOAD_ALL_REPS_SUCCESS = 'SALES_COMM_LOAD_ALL_REPS_SUCCESS';
export const SALES_COMM_LOAD_REP_CUSTOMERS = 'SALES_COMM_LOAD_REP_CUSTOMERS';
export const SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS = 'SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS';
export const SALES_COMM_REMOVE_CUSTOMER = 'SALES_COMM_REMOVE_CUSTOMER';
export const SALES_COMM_REMOVE_CUSTOMER_SUCCESS = 'SALES_COMM_REMOVE_CUSTOMER_SUCCESS';
export const SALES_COMM_LOAD_ALL_RATES = 'SALES_COMM_LOAD_ALL_RATES';
export const SALES_COMM_LOAD_ALL_RATES_SUCCESS = 'SALES_COMM_LOAD_ALL_RATES_SUCCESS';
export const SALES_COMM_REMOVE_RATE = 'SALES_COMM_REMOVE_RATE';
export const SALES_COMM_REMOVE_RATE_SUCCESS = 'SALES_COMM_REMOVE_RATE_SUCCESS';
export const SALES_COMM_ADD_PRODUCT_RATE = 'SALES_COMM_ADD_PRODUCT_RATE';
export const SALES_COMM_ADD_PRODUCT_RATE_SUCCESS = 'SALES_COMM_ADD_PRODUCT_RATE_SUCCESS';
export const SALES_COMM_LOAD_REPORT = 'SALES_COMM_LOAD_REPORT';
export const SALES_COMM_LOAD_REPORT_SUCCESS = 'SALES_COMM_LOAD_REPORT_SUCCESS';
export const SALES_COMM_LOAD_COMMISSION_DETAILS = 'SALES_COMM_LOAD_COMMISSION_DETAILS';
export const SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS = 'SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS';
export const SALES_COMM_UNLOAD = 'SALES_COMM_UNLOAD';
