import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider } from 'antd';
import * as phoneSelector from 'redux/selectors/phone';
import * as phoneActions from 'redux/actions/phone';
import * as phoneBrandSelector from 'redux/selectors/phoneBrand';
import * as phoneBrandActions from 'redux/actions/phoneBrand';
import { Spinner, Navbar } from 'components';
import PhoneForm from '../Form/phoneForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PHONE_CREATE_UPDATE_SUCCESS } from 'redux/actions/phone/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './phoneEdit.module.less';

class PhoneEdit extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isAllBrandLoaded) {
      this.props.loadAllBrands();
    }
    this.props.find(this.props.match.params.id);
    this.subscriptions.push(
      this.props.eventBusSubscribe(PHONE_CREATE_UPDATE_SUCCESS, action => {
        this.props.history.push(`/catalog/phone/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.update(formData);
  };

  componentWillUnmount() {
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    let content;
    if (this.props.isLoaded && this.props.isAllBrandLoaded) {
      content = (
        <PhoneForm
          key={0}
          brands={this.props.allBrands}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={this.props.phone}
        ></PhoneForm>
      );
    } else {
      content = (
        <div>
          <Spinner />
        </div>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={this.props.phone.name}>
          <Link type="primary" shape="circle" to={'/catalog/phone/create/'}>
            <Tooltip placement="rightTop" title="Create Phone">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/catalog/phone/'}>
            <Tooltip placement="rightTop" title="List Phone">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  find: phoneActions.find,
  update: phoneActions.update,
  unload: phoneActions.unload,
  loadAllBrands: phoneBrandActions.findAll
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: phoneSelector.isLoaded(state),
    phone: phoneSelector.get(state),
    allBrands: phoneBrandSelector.getAll(state),
    isAllBrandLoaded: phoneBrandSelector.isAllLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(PhoneEdit));
