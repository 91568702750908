import React from 'react';
import { Alert } from 'antd';

export default props => {
  return (
    <React.Fragment>
      {props.errors && props.errors.length > 0 && (
        <Alert
          type="error"
          showIcon
          description={
            <ul>
              {props.errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          }
          style={{ marginTop: '24px', marginBottom: '24px' }}
        />
      )}
    </React.Fragment>
  );
};
