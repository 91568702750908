import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, InputNumber, notification } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import { PRODUCT_VARIANT_REFRESH_IMAGES_SUCCESS } from 'redux/actions/productVariant/action_types';
import * as productVariantsActions from 'redux/actions/productVariant';
import styles from './refreshImages.module.less';

class RefreshImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      count: 1
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_VARIANT_REFRESH_IMAGES_SUCCESS, action => {
        this.props.hideScreenLoader();
        notification['success']({
          message: 'Success',
          description: 'The job has been schedule to refresh images. Images will soon be updated.'
        });
        this.props.history.push('/catalog/productVariants/');
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  renderRangeInput = getFieldDecorator => {
    return _.range(this.state.count).map(idx => (
      <Row key={idx} gutter={24}>
        <Col sm={24} md={8}>
          <Form.Item label="Start">
            {getFieldDecorator(`ranges[${idx}].start`, {
              rules: [
                {
                  required: true,
                  validator: (rule, value) =>
                    this.props.form.getFieldValue(`ranges[${idx}].start`) <=
                    this.props.form.getFieldValue(`ranges[${idx}].end`),
                  message: 'Start is required and should be greater or equal to end.'
                }
              ]
            })(<InputNumber min={1} style={{ width: '250px' }} />)}
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Form.Item label="End">
            {getFieldDecorator(`ranges[${idx}].end`, {
              rules: [
                {
                  required: true,
                  validator: (rule, value) =>
                    this.props.form.getFieldValue(`ranges[${idx}].start`) <=
                    this.props.form.getFieldValue(`ranges[${idx}].end`),
                  message: 'End is required and should be less or equal to start.'
                }
              ]
            })(<InputNumber min={1} style={{ width: '250px' }} />)}
          </Form.Item>
        </Col>
      </Row>
    ));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showScreenLoader();
        this.props.refreshImages(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.refreshImages}>
        <h2>Refresh Product Variant Images</h2>
        <div className={styles.form}>
          <Row gutter={24}>
            <Col span={24} className={styles.btnContainer}>
              <Button onClick={() => this.setState({ count: this.state.count + 1 })}>Add Range</Button>
              <Button onClick={() => this.setState({ count: this.state.count - 1 })}>Remove Range</Button>
            </Col>
          </Row>
          <Form onSubmit={this.handleSubmit}>
            {this.renderRangeInput(getFieldDecorator)}
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  refreshImages: productVariantsActions.refreshImages
};

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(Form.create({})(RefreshImages))));
