import { call, takeLatest, all, put } from 'redux-saga/effects';
import {
  PRODUCT_VARIANT_SEARCH,
  PRODUCT_VARIANT_VIEW,
  PRODUCT_VARIANT_SAVE_ALL,
  PRODUCT_VARIANT_SELECTION_COUNT,
  PRODUCT_VARIANT_UPDATE_PRICES,
  PRODUCT_VARIANT_UPDATE_STATUS,
  PRODUCT_VARIANT_UPDATE_BULK_STATUS,
  PRODUCT_VARIANT_REFRESH_IMAGES,
  PRODUCT_VARIANT_UPDATE_ATTRIBUTES,
  PRODUCT_VARIANT_UPDATE_NAME
} from 'redux/actions/productVariant/action_types';
import * as productVariantService from 'redux/services/productVariant';
import * as productVariantActions from 'redux/actions/productVariant';
import * as listActions from 'redux/actions/list';
import * as apiErrorActions from 'redux/actions/apiError';

function* searchProduct() {
  yield takeLatest(PRODUCT_VARIANT_SEARCH, function*(action) {
    let response = yield call(productVariantService.search, action.payload);
    yield put(listActions.listLoaded(response));
  });
}

function* load() {
  yield takeLatest(PRODUCT_VARIANT_VIEW, function*(action) {
    let product = yield call(productVariantService.findOne, action.payload);
    yield put(productVariantActions.loaded(product));
  });
}

function* saveVariants() {
  yield takeLatest(PRODUCT_VARIANT_SAVE_ALL, function*(action) {
    try {
      let variants = yield call(productVariantService.saveAll, action.payload);
      yield put(productVariantActions.saveSuccess(variants));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* countSelection() {
  yield takeLatest(PRODUCT_VARIANT_SELECTION_COUNT, function*(action) {
    try {
      let count = yield call(productVariantService.countSelection, action.payload);
      yield put(productVariantActions.countSelectionSuccess(count));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updatePrices() {
  yield takeLatest(PRODUCT_VARIANT_UPDATE_PRICES, function*(action) {
    try {
      let success = yield call(productVariantService.updatePrices, action.payload);
      yield put(productVariantActions.updatePricesSuccess(success));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateStatus() {
  yield takeLatest(PRODUCT_VARIANT_UPDATE_STATUS, function*(action) {
    try {
      let success = yield call(productVariantService.updateStatus, action.payload);
      yield put(productVariantActions.updateStatusSuccess(success));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateBulkStatus() {
  yield takeLatest(PRODUCT_VARIANT_UPDATE_BULK_STATUS, function*(action) {
    try {
      let success = yield call(productVariantService.updateBulkStatus, action.payload);
      yield put(productVariantActions.updateBulkStatusSuccess(success));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* refreshImages() {
  yield takeLatest(PRODUCT_VARIANT_REFRESH_IMAGES, function*(action) {
    try {
      yield call(productVariantService.refreshImages, action.payload);
      yield put(productVariantActions.refreshImagesSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateAttributes() {
  yield takeLatest(PRODUCT_VARIANT_UPDATE_ATTRIBUTES, function*(action) {
    try {
      let productVariant = yield call(
        productVariantService.updateAttrs,
        action.payload.variantId,
        action.payload.attrs
      );
      yield put(productVariantActions.saveSuccess(productVariant));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateName() {
  yield takeLatest(PRODUCT_VARIANT_UPDATE_NAME, function*(action) {
    try {
      let productVariant = yield call(
        productVariantService.updateName,
        action.payload.variantId,
        action.payload.values
      );
      yield put(productVariantActions.saveSuccess(productVariant));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* productVariantSagas() {
  yield all([
    searchProduct(),
    load(),
    saveVariants(),
    countSelection(),
    updatePrices(),
    updateStatus(),
    updateBulkStatus(),
    refreshImages(),
    updateAttributes(),
    updateName()
  ]);
}
