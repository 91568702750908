const displayValidationErrors = (form, payload) => {
  let errors = flattenObject(payload.entityErrors);
  Object.keys(errors).forEach(key => {
    let obj = {};
    obj[key] = {
      value: form.getFieldValue(key),
      errors: errors[key].map(e => new Error(e))
    };
    form.setFields(obj);
  });
  return payload.globalErrors;
};

const flattenObject = obj => {
  var toReturn = {};

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;

    if (typeof obj[i] == 'object' && obj[i] !== null && !Array.isArray(obj[i])) {
      var flatObject = flattenObject(obj[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = obj[i];
    }
  }
  return toReturn;
};

export { displayValidationErrors };
