import { call, takeLatest, all, put } from 'redux-saga/effects';
import { 
  INVOICES_SEARCH, 
  INVOICE_VIEW, 
  INVOICE_PAID,
  INVOICE_VIEW_LOADED, 
  INVOICE_APPROVE 
} from 'redux/actions/invoice/action_types';
import * as invoiceService from 'redux/services/invoice';
import * as  listActions from 'redux/actions/list';
import * as  invoiceActions from 'redux/actions/invoice';
import * as apiErrorActions from 'redux/actions/apiError';

function* searchInvoices() {
  yield takeLatest(INVOICES_SEARCH, function*(action) {
      let orderResponse = yield call(invoiceService.searchInvoices, action.payload);
      yield put(listActions.listLoaded(orderResponse));
  });
}

function* findOne() {
  yield takeLatest(INVOICE_VIEW, function*(action) {
    let invResponse = yield call(invoiceService.findOne, action.payload);
    yield put({
      type: INVOICE_VIEW_LOADED,
      payload: invResponse
    });
  });
}

function* approveInvoice() {
  yield takeLatest(INVOICE_APPROVE, function*(action) {
    try {
      let invoice = yield call(invoiceService.approveInvoice, action.payload);
      yield put(invoiceActions.invoiceCreateUpdateOk(invoice));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* paidInvoice() {
  yield takeLatest(INVOICE_PAID, function*(action) {
    try {
      let invoice = yield call(invoiceService.markInvoicePaid, action.payload);
      yield put(invoiceActions.invoiceCreateUpdateOk(invoice));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* InvoiceSagas() {
  yield all([searchInvoices(), findOne(), approveInvoice(), paidInvoice()]);
}
