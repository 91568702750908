import { ROLE_VIEW, ROLE_VIEW_LOADED, ROLE_LIST_ALL, ROLE_LISTED_ALL, ROLE_UNLOAD } from './action_types';

const findRoleById = payload => ({
  type: ROLE_VIEW,
  payload: payload
});

const foundRoleById = payload => ({
  type: ROLE_VIEW_LOADED,
  payload: payload
});

const findAllRoles = () => ({
  type: ROLE_LIST_ALL,
  payload: {}
});

const foundAllRoles = payload => ({
  type: ROLE_LISTED_ALL,
  payload: payload
});

const unload = () => ({
  type: ROLE_UNLOAD,
  payload: {}
});

export { findRoleById, foundRoleById, findAllRoles, foundAllRoles, unload };
