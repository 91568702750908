import React, { Component } from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, DatePicker, TreeSelect, Select } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './productform.module.less';

const TextArea = Input.TextArea;
const { Option } = Select;

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  disabledDate = current => current && current < moment().endOf('day');

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.newTo) {
          values.newTo = values.newTo
            .hour(0)
            .minutes(0)
            .seconds(0)
            .toISOString();
        }
        this.props.update(values);
      }
    });
  };

  treeData = cat => {
    let opts = cat.children.flatMap(c => this.treeData(c));
    return {
      title: cat.category.name,
      value: cat.category.id,
      key: cat.category.id,
      children: opts,
      disabled: cat.category.isAnchor
    };
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let p = this.props.product;
    let createMode = p.id === 0;
    let newTo = p.newTo ? moment(p.newTo) : null;
    return (
      <div className={styles.form}>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: p.id
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  initialValue: p.name,
                  rules: [
                    {
                      required: true,
                      message: 'Name is required.'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="SKU">
                {getFieldDecorator('sku', {
                  initialValue: p.sku,
                  rules: [
                    {
                      required: true,
                      message: 'SKU is required.'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="New to">
                {getFieldDecorator('newTo', {
                  rules: [],
                  initialValue: newTo
                })(<DatePicker disabledDate={this.disabledDate} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={16}>
              <Form.Item label="Variant name template">
                {getFieldDecorator('variantNameTemplate', {
                  initialValue: p.variantNameTemplate,
                  rules: [
                    {
                      required: true,
                      message: 'Variant name template is required.'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Categories">
                {getFieldDecorator('categories', {
                  initialValue: p.categories,
                  rules: [
                    {
                      required: true,
                      message: 'Category is required.'
                    }
                  ]
                })(
                  <TreeSelect
                    treeData={this.props.categoriesLoaded ? [this.treeData(this.props.categories)] : []}
                    multiple={true}
                    treeCheckable={true}
                  />
                )}
              </Form.Item>
            </Col>
            {createMode && (
              <Col sm={24} md={8}>
                <Form.Item label="Attribute">
                  {getFieldDecorator('attributes', {
                    initialValue: p.attributes,
                    rules: [
                      {
                        required: true,
                        message: 'Attribute is required.'
                      }
                    ]
                  })(
                    <Select mode="multiple">
                      {this.props.attributes.map(a => (
                        <Option key={a.id}>
                          {a.label} - {a.code}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col sm={24} md={8}>
              <Form.Item label="Tax Class">
                {getFieldDecorator('taxClassId', {
                  initialValue: p.taxClassId,
                  rules: [
                    {
                      required: true,
                      message: 'Tax class is required.'
                    }
                  ]
                })(
                  <Select>
                    {this.props.taxClasses
                      .filter(t => t.type.id === 'PRODUCT')
                      .map(t => (
                        <Option key={t.id}>{t.name}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Short Description">
                {getFieldDecorator('shortDescription', {
                  initialValue: p.shortDescription,
                  rules: [
                    {
                      required: false,
                      message: 'Short description is required.'
                    }
                  ]
                })(<TextArea />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Description">
                {getFieldDecorator('description', {
                  initialValue: p.description,
                  rules: [
                    {
                      required: false,
                      message: 'Description is required.'
                    }
                  ]
                })(<TextArea />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Meta Title">
                {getFieldDecorator('metaTitle', {
                  initialValue: p.metaTitle,
                  rules: [
                    {
                      required: false,
                      message: 'Meta title is required.'
                    }
                  ]
                })(<TextArea />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Meta Description">
                {getFieldDecorator('metaDescription', {
                  initialValue: p.metaDescription,
                  rules: [
                    {
                      required: false,
                      message: 'Meta description is required.'
                    }
                  ]
                })(<TextArea />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={24}>
              <Form.Item label="Meta Keywords">
                {getFieldDecorator('metaKeywords', {
                  initialValue: p.metaKeywords,
                  rules: [
                    {
                      required: false,
                      message: 'Meta keywords is required.'
                    }
                  ]
                })(<TextArea />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {createMode ? 'Save' : 'Update'}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: 'productForm' })(ProductForm);
