// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Space, Popconfirm, Button, Row, Col, Tooltip } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as storeGroupActions from 'redux/actions/storeGroup';
import * as storeGroupService from 'redux/services/storeGroup';
import * as listActions from 'redux/actions/list';
import * as userSelector from 'redux/selectors/user';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toDateString } from 'Utils/utils';
import style from './listStoreGroup.module.less';

const Fragment = React.Fragment;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyCount: 0
    };
  }

  componentWillUnmount() {
    this.props.unload();
  }

  deleteGroup = id => {
    (async () => {
      await storeGroupService.remove(id);
      this.setState({
        keyCount: this.state.keyCount + 1
      });
    })();
  };

  render() {
    let tableAction = !this.props.isUserContentCreator && (
      <Fragment>
        <Link type="primary" to={'/customers/storeGroup/create/'}>
          <Tooltip placement="rightTop" title="Create Store Group">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Link>
      </Fragment>
    );

    return (
      <div className={style.container}>
        <Row>
          <Col span={24} className={style.accountInfoButtonsContainer}>
            <WrapperTable
              title="Store Group List"
              key={this.state.keyCount}
              filters={filters}
              columns={this.columns}
              tableAction={tableAction}
              data={this.props.listData}
              api={this.props.searchStoreGroups}
            />
          </Col>
        </Row>
      </div>
    );
  }
  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'DESC',
      sorter: true
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      sorter: true,
      render: r => toDateString(r)
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedOn',
      sorter: true,
      render: r => toDateString(r)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <Space>
          <Link to={`/customers/storeGroup/view/${id}/`}>view</Link>
          <Popconfirm
            title="Do you want to delete this group?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.deleteGroup(id)}
          >
            <Button type="link">delete</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];
}

const filters = [
  {
    field: 'name',
    type: 'TEXT'
  }
];

const mapDispatchToProps = {
  searchStoreGroups: storeGroupActions.search,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state),
    isUserContentCreator: userSelector.isUserContentCreator(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
