import { CUSTOMER_VIEW_LOADED, CUSTOMER_CREATE_UPDATE_OK, CUSTOMER_UNLOAD } from '../actions/customer/action_types';

const initialStore = {
  isLoaded: false,
  customer: {}
};

const loaded = (state, customer) => {
  return Object.assign({}, state, {
    isLoaded: true,
    customer: customer
  });
};

const submitedOk = (state, customer) => {
  return Object.assign({}, state, {
    isLoaded: false,
    customer: customer
  });
};

const unload = (state, payload) => Object.assign({}, state, initialStore);

export default function customerReducer(state = initialStore, action) {
  switch (action.type) {
    case CUSTOMER_VIEW_LOADED:
      return loaded(state, action.payload);
    case CUSTOMER_CREATE_UPDATE_OK:
      return submitedOk(state, action.payload);
    case CUSTOMER_UNLOAD:
      return unload(state, initialStore);
    default:
      return state;
  }
}
