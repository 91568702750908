import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Modal, Select, DatePicker } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './statusForm.module.less';

class StatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
    this.tagOption = null;
  }

  formatDate = date => date ? date.format('YYYY-MM-DD') + 'T00:00:00.000Z' : null;

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let vals = Object.assign({}, values, {
          newTo: this.formatDate(values.newTo),
          tagStartDate: this.formatDate(values.tagStartDate),
          tagEndDate: this.formatDate(values.tagEndDate)
        });
        console.log(vals);
        this.props.onSubmit(vals);
      }
    });
  };

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        this.props.hideScreenLoader();
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={true}
        title="Update Status"
        okText="Update"
        onOk={this.handleSubmit}
        onCancel={this.props.onCancel}
        className={styles.statusForm}
        maskClosable={false}
      >
        <Row gutter={24}>
          <Col sm={24} md={8}>
            <Form.Item label="Enabled">
              {getFieldDecorator('enabled', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(
                <Select style={{ width: '100%' }}>
                  <Select.Option key="true">Yes</Select.Option>
                  <Select.Option key="false">No</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Stock Status">
              {getFieldDecorator('stockStatus', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(
                <Select style={{ width: '100%' }}>
                  <Select.Option key="IN_STOCK">In Stock</Select.Option>
                  <Select.Option key="OUT_OF_STOCK">Out of Stock</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="New To">
              {getFieldDecorator('newTo', {
                rules: [
                  {
                    required: false
                  }
                ]
              })(<DatePicker />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} md={8}>
            <Form.Item label="Tag Option">
              {getFieldDecorator('tag', {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={(value) => this.tagOption = value}>
                  <Select.Option key=""></Select.Option>
                  <Select.Option key="OPTION_1">Option 1</Select.Option>
                  <Select.Option key="OPTION_2">Option 2</Select.Option>
                  <Select.Option key="OPTION_3">Option 3</Select.Option>
                  <Select.Option key="OPTION_4">Option 4</Select.Option>
                  <Select.Option key="OPTION_5">Option 5</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Tag Start Date">
              {getFieldDecorator('tagStartDate', {
                rules: [
                  {
                    required: this.tagOption,
                    message: 'Required'
                  }
                ]
              })(<DatePicker />)}
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Tag End Date">
              {getFieldDecorator('tagEndDate', {
                rules: [
                  {
                    required: false
                  }
                ]
              })(<DatePicker />)}
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default Form.create({})(StatusForm);
