import { call, put, takeLatest, all } from 'redux-saga/effects';
import { HOMEPAGE_CONTENT_LOAD, HOMEPAGE_CONTENT_LOADED } from '../actions/homepage/action_types';
import * as homepageService from '../services/homepage';

function* fetchHomepageContent() {
  yield takeLatest(HOMEPAGE_CONTENT_LOAD, function*(action) {
    let homepageContent = yield call(homepageService.fetchHomepageContent);
    yield put({
      type: HOMEPAGE_CONTENT_LOADED,
      payload: homepageContent
    });
  });
}

export default function* homepageSagas() {
  yield all([fetchHomepageContent()]);
}
