export const COUPONS_LOAD = 'COUPONS_LOAD';
export const COUPON_VIEW = 'COUPON_VIEW';
export const COUPON_VIEW_LOADED = 'COUPON_VIEW_LOADED';
export const COUPON_ACTIVATE = 'COUPON_ACTIVATE';
export const COUPON_INACTIVATE = 'COUPON_INACTIVATE';
export const COUPON_CREATE = 'COUPON_CREATE';
export const COUPON_UPDATE = 'COUPON_UPDATE';
export const COUPON_DELETE = 'COUPON_DELETE';
export const COUPON_CREATE_UPDATE_OK = 'COUPON_CREATE_UPDATE_OK';
export const COUPON_UNLOAD = 'COUPON_UNLOAD';
