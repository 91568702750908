import {
  CUSTOMER_GRP_VIEW,
  CUSTOMER_GRP_VIEW_LOADED,
  CUSTOMER_GRP_LIST_ALL,
  CUSTOMER_GRP_LISTED_ALL,
  CUSTOMER_GRP_UNLOAD
} from './action_types';

const findById = payload => ({
  type: CUSTOMER_GRP_VIEW,
  payload: payload
});

const foundById = payload => ({
  type: CUSTOMER_GRP_VIEW_LOADED,
  payload: payload
});

const findAll = () => ({
  type: CUSTOMER_GRP_LIST_ALL,
  payload: {}
});

const foundAll = payload => ({
  type: CUSTOMER_GRP_LISTED_ALL,
  payload: payload
});

const unload = () => ({
  type: CUSTOMER_GRP_UNLOAD,
  payload: {}
});

export { findById, foundById, findAll, foundAll, unload };
