import React, { Fragment, Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Select, DatePicker, Input } from 'antd';

const { Option } = Select;

export default class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operator: props.operator || this.filterOperator[0].id
    };
  }

  filterOperator = [
    { id: 'EQUAL', label: 'Equal' },
    { id: 'NOT_EQUAL', label: 'Not Equal' },
    { id: 'LESS_THAN', label: 'Less Than' },
    { id: 'GREATER_THAN', label: 'Greater Than' },
    { id: 'BETWEEN', label: 'Between' }
  ];

  onOperatorChange = value => {
    this.setState({
      operator: value
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let inputs =
      this.state.operator === 'BETWEEN' ? (
        <Fragment>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator(`${this.props.field}.values[0]`, {
                rules: []
              })(<DatePicker placeholder={`${this.props.field}`} style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator(`${this.props.field}.values[1]`, {
                rules: []
              })(<DatePicker placeholder={`${this.props.field} (excluded)`} style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
        </Fragment>
      ) : (
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.value`, {
              rules: []
            })(<DatePicker placeholder={`${this.props.field}`} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
      );

    return (
      <Row gutter={24}>
        <Col span={8}>
          {getFieldDecorator(`${this.props.field}._field`, {
            initialValue: this.props.field
          })(<Input type="hidden" />)}
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.operator`, {
              initialValue: this.props.operator ? this.props.operator : this.filterOperator[0].id,
              rules: [{ required: true }]
            })(
              <Select onChange={this.onOperatorChange} style={{ width: '100%' }}>
                {this.filterOperator.map(item => (
                  <Option key={item.id}>{item.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {inputs}
      </Row>
    );
  }
}
