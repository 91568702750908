import { combineReducers } from 'redux';
import configReducer from './config';
import homepageReducer from './homepage';
import userReducer from './user';
import categoryReducer from './category';
import menuReducer from './menu';
import tableListReducer from './tableList';
import customerReducer from './customer';
import employeeReducer from './employee';
import roleReducer from './role';
import taxClassReducer from './taxClass';
import customerGroupReducer from './customerGroup';
import addressReducer from './address';
import orderReducer from './order';
import invoiceReducer from './invoice';
import attributeReducer from './attribute';
import productReducer from './product';
import couponReducer from './coupon';
import productVariant from './productVariant';
import staticPages from './staticPages';
import imageSlider from './imageSlider';
import phone from './phone';
import phoneBrand from './phoneBrand';
import priceOverrideReducer from './priceOverride';
import salesCommReducer from './salesComm';
import homepageWidgetReducer from './homepage/widgets';

const reducer = combineReducers({
  config: configReducer,
  homepage: homepageReducer,
  user: userReducer,
  category: categoryReducer,
  menu: menuReducer,
  list: tableListReducer,
  customer: customerReducer,
  employee: employeeReducer,
  role: roleReducer,
  taxClass: taxClassReducer,
  customerGrp: customerGroupReducer,
  address: addressReducer,
  order: orderReducer,
  invoice: invoiceReducer,
  attribute: attributeReducer,
  product: productReducer,
  coupon: couponReducer,
  productVariant: productVariant,
  staticPage: staticPages,
  imageSlider: imageSlider,
  phone: phone,
  phoneBrand: phoneBrand,
  priceOverride: priceOverrideReducer,
  salesComm: salesCommReducer,
  homepageWidgets: homepageWidgetReducer
});

export default reducer;
