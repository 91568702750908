import React from 'react';

export const ScreenLoaderContext = React.createContext({
  showLoader: () => {},
  hideLoader: () => {}
});
export const withScreenLoader = function(Component) {
  return class extends React.Component {
    render() {
      return (
        <ScreenLoaderContext.Consumer>
          {({ showLoader, hideLoader }) => (
            <Component showScreenLoader={showLoader} hideScreenLoader={hideLoader} {...this.props} />
          )}
        </ScreenLoaderContext.Consumer>
      );
    }
  };
};
