// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as orderActions from 'redux/actions/order';
import * as listActions from 'redux/actions/list';
import { Link } from 'react-router-dom';
import { toPrice, toDateString } from 'Utils/utils';
import { WrapperTable } from 'components';
import * as queryString from 'query-string';

const Fragment = React.Fragment;

class OrderList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    const columns = [
      {
        title: 'Order Id',
        dataIndex: 'code',
        defaultSortOrder: 'descend',
        sorter: true
      },
      {
        title: 'Order Date',
        dataIndex: 'orderDateTime',
        sorter: true,
        render: d => toDateString(d)
      },
      {
        title: 'Name',
        dataIndex: 'customerFirstName',
        render: (customerFirstName, obj) => {
          return `${customerFirstName} ${obj.customerLastName}`;
        },
        sorter: true
      },
      {
        title: 'Shipping Method',
        dataIndex: 'shippingMethod',
        render: (shippingMethod, obj) => {
          return `${shippingMethod.name}`;
        },
        sorter: true
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status, obj) => {
          return `${status.name}`;
        },
        sorter: true
      },
      {
        title: 'Order Total',
        dataIndex: 'total',
        render: total => toPrice(total),
        sorter: true
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Fragment>
            <Link to={`/sales/orders/${id}/?showArchive=${this.props.showArchive}`}>view</Link>
          </Fragment>
        )
      }
    ];
    const defaultFilters = this.props.showArchive
      ? {
          archive: {
            _field: 'archive',
            operator: 'IS',
            value: 'archived'
          }
        }
      : {};
    return (
      <div>
        <Row style={{ marginBottom: '16px' }}>
          <Col span={24}>
            <WrapperTable
              title="Order List"
              filters={filters}
              key={this.props.showArchive}
              defaultFilters={defaultFilters}
              columns={columns}
              data={this.props.listData}
              api={this.props.searchOrders}
            />
          </Col>
        </Row>
        {!this.props.showArchive && (
          <Row>
            <Col span={24}>
              <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                If you want to see archived orders, please <Link to="/sales/orders?showArchive=true">click here</Link>
              </span>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const filters = [
  {
    field: 'orderDate',
    type: 'DATE',
    operator: 'BETWEEN'
  },
  {
    field: 'total',
    type: 'NUMBER',
    operator: 'BETWEEN'
  },
  {
    field: 'code',
    type: 'TEXT'
  },
  {
    field: 'customerFirstName',
    type: 'TEXT'
  },
  {
    field: 'customerLastName',
    type: 'TEXT'
  },
  {
    field: 'shippingMethod',
    type: 'OPTION',
    options: [
      { id: 'STANDARD_SHIPPING', name: 'Standard Shipping' },
      { id: 'EXPEDITED_SHIPPING', name: 'Expedited Shipping' },
      { id: 'EXPRESS_SHIPPING', name: 'Express Shipping' }
    ]
  },
  {
    field: 'status',
    type: 'OPTION',
    options: [
      { id: 'ON_HOLD', name: 'On Hold' },
      { id: 'PENDING', name: 'Pending' },
      { id: 'PROCESSING', name: 'Processing' },
      { id: 'PROCESSED', name: 'Processed' },
      { id: 'SHIPPED', name: 'Shipped' },
      { id: 'COMPLETED', name: 'Completed' },
      { id: 'CANCELLED', name: 'Cancelled' }
    ]
  },
  {
    field: 'customerEmail',
    type: 'TEXT'
  }
];

const mapDispatchToProps = {
  searchOrders: orderActions.watchSearchOrders,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state),
    showArchive: queryString.parse(props.location.search).showArchive === 'true'
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
