import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, Descriptions, Tooltip, List, Modal, Button } from 'antd';
import { Spinner, Navbar } from 'components';
import * as couponSelector from 'redux/selectors/coupon';
import * as couponActions from 'redux/actions/coupon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COUPON_CREATE_UPDATE_OK } from 'redux/actions/coupon/action_types';
import { withEventBus } from 'context/eventbus';

const Fragment = React.Fragment;
const { confirm } = Modal;

class CouponView extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(COUPON_CREATE_UPDATE_OK, action => {
        this.props.fineOne(this.props.match.params.id);
      })
    );
    this.props.fineOne(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateStatus = (id, status) => {
    if (status === 'INACTIVE') {
      this.props.activate(id);
    } else {
      this.props.inActivate(id);
    }
  };

  onStatusUpdate = () => {
    var status = this.props.coupon.status;
    var id = this.props.coupon.id;
    let updateStatus = this.updateStatus;
    confirm({
      title: 'Confirm',
      content: 'Are you sure to ' + status.name,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateStatus(id, status.id);
      },
      onCancel() {}
    });
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      var c = this.props.coupon;
      content = (
        <Fragment>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Code">{c.code}</Descriptions.Item>
            <Descriptions.Item label="Start date">{c.startDate}</Descriptions.Item>
            <Descriptions.Item label="End date">{c.endDate}</Descriptions.Item>
            <Descriptions.Item span={3} label="description">
              {c.description}
            </Descriptions.Item>
            <Descriptions.Item label="Status">{c.status.name}</Descriptions.Item>
            <Descriptions.Item label="Maximum use">{c.maxUse}</Descriptions.Item>
            <Descriptions.Item label="Max use per Customer">{c.maxUsePerCustomer}</Descriptions.Item>
            <Descriptions.Item label="Discount Rate Type">{c.discountRateType.name}</Descriptions.Item>
            <Descriptions.Item label="Discount Value">{c.discountValue}</Descriptions.Item>
            <Descriptions.Item label="Coupon For">{c.couponFor.name}</Descriptions.Item>
            <Descriptions.Item label="Created By">
              {c.createdBy && c.createdBy.name ? c.createdBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Created On">{c.createdOn}</Descriptions.Item>
            <Descriptions.Item label="Updated By">
              {c.updatedBy && c.updatedBy.name ? c.updatedBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Updated On">{c.updatedOn}</Descriptions.Item>
          </Descriptions>
          {c.appliesToList.length !== 0 ? (
            <List
              header={
                <div>
                  <h2>Apply to {c.couponFor.name}</h2>
                </div>
              }
              bordered
              dataSource={c.appliesToList}
              renderItem={item => <List.Item>{item.name}</List.Item>}
            />
          ) : (
            <div></div>
          )}
        </Fragment>
      );
    }

    return (
      <div>
        <Navbar title={this.props.coupon.code}>
          <Button type="primary" shape="circle" onClick={this.onStatusUpdate}>
            <Tooltip
              placement="rightTop"
              title={
                this.props.coupon.status
                  ? this.props.coupon.status.id === 'ACTIVE'
                    ? 'Deactivate Coupon'
                    : 'Activate Coupon'
                  : ''
              }
            >
              <FontAwesomeIcon
                icon={this.props.coupon.status && this.props.coupon.status.id === 'ACTIVE' ? 'times' : 'check'}
              />
            </Tooltip>
          </Button>
          <Divider type="vertical" />
          <Link type="primary" shape="circle" to={'/marketing/coupons/create/'}>
            <Tooltip placement="rightTop" title="Create Coupons">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={`/marketing/coupons/edit/${this.props.match.params.id}/`}>
            <Tooltip placement="rightTop" title="Edit Customer">
              <FontAwesomeIcon icon="edit" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/marketing/coupons/'}>
            <Tooltip placement="rightTop" title="List Customers">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>

        {content}
      </div>
    );
  }
}

const mapDispatchToProps = {
  fineOne: couponActions.findOne,
  unload: couponActions.unload,
  activate: couponActions.activate,
  inActivate: couponActions.inactivate
};

const mapStateToProps = (state, props) => {
  return {
    coupon: couponSelector.getCoupon(state),
    isLoaded: couponSelector.isLoaded(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(CouponView));
