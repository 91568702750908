import {
  TAX_CLS_VIEW,
  TAX_CLS_VIEW_LOADED,
  TAX_CLS_LIST_ALL,
  TAX_CLS_LISTED_ALL,
  TAX_CLS_UNLOAD
} from './action_types';

const findById = payload => ({
  type: TAX_CLS_VIEW,
  payload: payload
});

const foundById = payload => ({
  type: TAX_CLS_VIEW_LOADED,
  payload: payload
});

const findAll = () => ({
  type: TAX_CLS_LIST_ALL,
  payload: {}
});

const foundAll = payload => ({
  type: TAX_CLS_LISTED_ALL,
  payload: payload
});

const unload = payload => ({
  type: TAX_CLS_UNLOAD,
  payload: {}
});

export { findById, foundById, findAll, foundAll, unload };
