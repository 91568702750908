// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Tooltip } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as phoneActions from 'redux/actions/phone';
import * as listActions from 'redux/actions/list';
import * as userSelector from 'redux/selectors/user';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './phoneList.module.less';

const Fragment = React.Fragment;

class PhoneList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    let tableAction = !this.props.isUserContentCreator && (
      <Fragment>
        <Link type="primary" to={'/catalog/phone/create/'}>
          <Tooltip placement="rightTop" title="Create Phone">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Link>
      </Fragment>
    );

    return (
      <div className={style.container}>
        <Row>
          <Col span={24} className={style.accountInfoButtonsContainer}>
            <WrapperTable
              title="Phone List"
              filters={filters}
              columns={this.columns}
              tableAction={tableAction}
              data={this.props.listData}
              api={this.props.searchEmployees}
            />
          </Col>
        </Row>
      </div>
    );
  }
  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'DESC',
      sorter: true
    },
    {
      title: 'Short Name',
      dataIndex: 'shortName',
      sorter: true
    },
    {
      title: 'Url',
      dataIndex: 'url'
    },
    {
      title: 'Discontinued',
      dataIndex: 'discontinued',
      render: discontinued => {
        return discontinued ? 'Yes' : 'No';
      },
      sorter: true
    },
    {
      title: 'Show in Latest Devices',
      dataIndex: 'latest',
      render: latest => {
        return latest ? 'Yes' : 'No';
      },
      sorter: true
    },
    {
      title: 'Sort Order',
      dataIndex: 'sortOrder',
      sorter: true
    },
    {
      title: 'Year of launch',
      dataIndex: 'yearOfLaunch',
      sorter: true
    },
    {
      title: 'Group',
      dataIndex: 'group',
      render: group => group && group.name,
      sortKey: 'groupId',
      sorter: true
    },
    {
      title: 'Type',
      dataIndex: 'deviceType',
      sorter: true
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <Fragment>
          <Link to={`/catalog/phone/${id}/`}>view</Link>
          <Divider type="vertical" />
          {!this.props.isUserContentCreator && <Link to={`/catalog/phone/edit/${id}/`}>edit</Link>}
        </Fragment>
      )
    }
  ];
}

const filters = [
  {
    field: 'name',
    type: 'TEXT'
  },
  {
    field: 'shortName',
    type: 'TEXT'
  },
  {
    field: 'discontinued',
    type: 'YESNO'
  },
  {
    field: 'latest',
    type: 'YESNO'
  }
];

const mapDispatchToProps = {
  searchEmployees: phoneActions.search,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state),
    isUserContentCreator: userSelector.isUserContentCreator(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneList);
