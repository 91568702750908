import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, Button, Row, Col, Select } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './custForm.module.less';

class CustForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.submitHandle(values);
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    var editMode = this.props.data.id !== 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="First name" className={styles.row}>
              {getFieldDecorator('firstName', {
                initialValue: this.props.data.firstName,
                rules: [{ required: true, message: 'First Name is required' }]
              })(<Input placeholder="First Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Middle name" className={styles.row}>
              {getFieldDecorator('middleName', {
                initialValue: this.props.data.middleName,
                rules: []
              })(<Input placeholder="Middle name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Last name" className={styles.row}>
              {getFieldDecorator('lastName', {
                initialValue: this.props.data.lastName,
                rules: [{ required: true, message: 'Last name is required' }]
              })(<Input placeholder="Last Name" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Email" className={styles.row}>
              {getFieldDecorator('email', {
                initialValue: this.props.data.email,
                rules: [
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'This is not valid E-mail!' }
                ]
              })(<Input placeholder="Email" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="HST\/GST Number" className={styles.row}>
              {getFieldDecorator('hstNumber', {
                initialValue: this.props.data.hstNumber,
                rules: [{ required: true, message: 'HST/GST Number is required' }]
              })(<Input placeholder="HST/GST Number" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Preferred Doc Type" className={styles.row}>
              {getFieldDecorator('preferredDocType', {
                initialValue: this.props.data.preferredDocType.id,
                rules: [{ required: true, message: 'Select a Preferred Doc Type' }]
              })(
                <Select placeholder="Preferred Doc Type">
                  {[
                    { id: 'FULL_INVOICE', name: 'Full Invoice' },
                    { id: 'DISPATCH_NOTE', name: 'Dispatch Note' }
                  ].map(p => (
                    <Select.Option key={p} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Order Type" className={styles.row}>
              {getFieldDecorator('orderType', {
                initialValue: this.props.data.orderType.id,
                rules: [{ required: true, message: 'Select a Order Type' }]
              })(
                <Select placeholder="Order Type">
                  {[
                    { id: 'REGULAR', name: 'Regular' },
                    { id: 'MANUAL', name: 'Manual' }
                  ].map(p => (
                    <Select.Option key={p} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tax Class" className={styles.row}>
              {getFieldDecorator('taxClassId', {
                initialValue: this.props.data.taxClassId.id,
                rules: [{ required: true, message: 'Select a Tax class' }]
              })(
                <Select placeholder="Tax Class">
                  {this.props.taxClasses
                    .filter(p => p.type.id === 'CUSTOMER')
                    .map(p => (
                      <Select.Option key={p} value={p.id}>
                        {p.name}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Group" className={styles.row}>
              {getFieldDecorator('groupId', {
                initialValue: this.props.data.groupId.id,
                rules: [{ required: true, message: 'Select a Group' }]
              })(
                <Select placeholder="Group">
                  {this.props.customerGroups.map(p => (
                    <Select.Option key={p} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label="Default Disc" className={styles.row}>
              {getFieldDecorator('defaultDisc', {
                initialValue: this.props.data.defaultDisc,
                rules: [{ required: true, message: 'Default discount required' }]
              })(<InputNumber placeholder="Default Discount" style={{ width: '95%' }} />)}
            </Form.Item>
          </Col>
          {!editMode ? (
            <Col span={8}>
              <Form.Item label="Referred By" className={styles.row}>
                {getFieldDecorator('referredBy', {
                  initialValue: this.props.data.referredBy,
                  rules: []
                })(<Input placeholder="Referred By" />)}
              </Form.Item>
            </Col>
          ) : (
            <div></div>
          )}
          {!editMode ? (
            <Row gutter={24}>
              <Col span={24}>
                <h2>Billing Address</h2>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.name', {
                    rules: []
                  })(<Input placeholder="Name" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.company', {
                    rules: []
                  })(<Input placeholder="Company Name" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.street1', {
                    rules: [{ required: true, message: 'Street 1 is required' }]
                  })(<Input placeholder="Street 1" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.street2', {
                    rules: []
                  })(<Input placeholder="Street 2" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.city', {
                    rules: [{ required: true, message: 'City is required' }]
                  })(<Input placeholder="City" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.postalCode', {
                    rules: [{ required: true, message: 'Postal Code is required' }]
                  })(<Input placeholder="Postal Code" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.provinceId', {
                    rules: [{ required: true, message: 'Select a province' }]
                  })(
                    <Select placeholder="Province">
                      {this.props.provinces.map(p => (
                        <Select.Option key={p.id} value={p.id}>
                          {p.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.row}>
                  {getFieldDecorator('defaultBillingAddress.phoneNo', {
                    rules: [{ required: false }]
                  })(<Input placeholder="Phone No" />)}
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <div></div>
          )}
        </Row>
        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            {!editMode ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'custForm' })(CustForm);
