import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider } from 'antd';
import * as imageSliderSelector from 'redux/selectors/imageSlider';
import * as imageSliderActions from 'redux/actions/imageSlider';
import { Spinner, Navbar } from 'components';
import SlideForm from '../Form/slideForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SLIDER_CREATE_SUCCESS } from 'redux/actions/imageSlider/action_types';
import { withEventBus } from 'context/eventbus';

class ImageSlideEdit extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.findByUid(this.props.match.params.id);

    this.subscriptions.push(
      this.props.eventBusSubscribe(SLIDER_CREATE_SUCCESS, action => {
        this.props.history.push(`/options/slides/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.update(formData);
  };

  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    let slide = this.props.slide;
    let content;

    if (slide) {
      content = (
        <div>
          <Navbar title={'Edit Slide'}>
            <Link type="primary" to={`/options/slides/${this.props.match.params.id}/`}>
              <Tooltip placement="rightTop" title="view Slide">
                <FontAwesomeIcon icon="search" />
              </Tooltip>
            </Link>
            <Divider type="vertical" />
            <Link type="primary" to={'/options/slides/'}>
              <Tooltip placement="top" title="List Slides">
                <FontAwesomeIcon icon="th-list" />
              </Tooltip>
            </Link>
          </Navbar>
          <div style={{ padding: 16 }}>
            <SlideForm
              key={0}
              submitHandle={this.submitHandle}
              eventBusSubscribe={this.props.eventBusSubscribe}
              data={slide}
            ></SlideForm>
          </div>
        </div>
      );
    } else {
      content = <Spinner />;
    }

    return <div>{content} </div>;
  }
}

const mapDispatchToProps = {
  findByUid: imageSliderActions.find,
  update: imageSliderActions.update,
  unload: imageSliderActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    slide: imageSliderSelector.get(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(ImageSlideEdit));
