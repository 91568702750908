import { LIST_LOADED, LIST_UNLOADED } from '../actions/list/action_types';

const initialStore = {
  isLoading: true,
  data: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20
  }
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoading: false,
    data: payload.results,
    pagination: {
      total: payload.totalFilteredRecords,
      current: payload.page + 1,
      pageSize: payload.pageSize
    }
  });

const unload = (state, payload) => Object.assign({}, state, initialStore);

export default function customerList(state = initialStore, action) {
  switch (action.type) {
    case LIST_LOADED:
      return load(state, action.payload);
    case LIST_UNLOADED:
      return unload(state, initialStore);
    default:
      return state;
  }
}
