import {
  CUSTOMER_GRP_VIEW_LOADED,
  CUSTOMER_GRP_LISTED_ALL,
  CUSTOMER_GRP_UNLOAD
} from '../actions/customerGroup/action_types';

const initialStore = {
  isLoaded: false,
  custGrp: {},
  custGrps: []
};

const load = (state, obj) =>
  Object.assign({}, state, {
    isLoaded: true,
    custGrp: obj,
    custGrps: []
  });

const loadAll = (state, array) =>
  Object.assign({}, state, {
    isLoaded: true,
    custGrp: {},
    custGrps: array
  });

export default function customerGroup(state = initialStore, action) {
  switch (action.type) {
    case CUSTOMER_GRP_VIEW_LOADED:
      return load(state, action.payload);
    case CUSTOMER_GRP_LISTED_ALL:
      return loadAll(state, action.payload);
    case CUSTOMER_GRP_UNLOAD:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
