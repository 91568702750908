import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, InputNumber, Switch, TreeSelect, Select } from 'antd';
import * as categorySelectors from 'redux/selectors/category';
import * as categoryActions from 'redux/actions/category';
import { withEventBus, withScreenLoader } from 'context';

class LatestProducts extends Component {
  componentDidMount() {
    this.props.loadAllCategories();
  }

  componentWillUnmount() {
    this.props.unloadCategories();
  }

  treeData = cat => {
    let opts = cat.children.flatMap(c => this.treeData(c));
    return {
      title: cat.category.name,
      value: cat.category.id,
      key: cat.category.id,
      children: opts,
      disabled: cat.category.level === 0
    };
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.save(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: 0
          })(<Input type="hidden" />)}
          {getFieldDecorator('type', {
            initialValue: 'LATEST_PRODUCTS'
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'Title is required.'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="Display Title">
                {getFieldDecorator('displayTitle', {
                  valuePropName: 'checked',
                  initialValue: true,
                  rules: [
                    {
                      required: true,
                      message: 'Display Title is required.'
                    }
                  ]
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="Order">
                {getFieldDecorator('order', {
                  rules: [
                    {
                      required: true,
                      message: 'Order is required.'
                    }
                  ]
                })(<InputNumber min={0} precision={0} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Category">
                {getFieldDecorator('attributes.categoryId', {
                  rules: [
                    {
                      required: true,
                      message: 'Category is required.'
                    }
                  ]
                })(<TreeSelect treeData={this.props.categoriesLoaded ? [this.treeData(this.props.categories)] : []} />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="Limit">
                {getFieldDecorator('attributes.limit', {
                  rules: [
                    {
                      required: true,
                      message: 'Limit is required.'
                    }
                  ]
                })(
                  <Select>
                    <Select.Option value="4">4</Select.Option>
                    <Select.Option value="8">8</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadAllCategories: categoryActions.load,
  unloadCategories: categoryActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    categories: categorySelectors.getCategories(state),
    categoriesLoaded: categorySelectors.isLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(Form.create()(LatestProducts))));
