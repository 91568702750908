import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Select, Input } from 'antd';

const { Option } = Select;

export default class BooleanFilter extends Component {
  filterOperator = [{ id: 'IS', label: 'Is' }];

  valueOptions = [
    { id: 'true', label: 'True' },
    { id: 'false', label: 'False' }
  ];

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={24}>
        <Col span={8}>
          {getFieldDecorator(`${this.props.field}._field`, {
            initialValue: this.props.field
          })(<Input type="hidden" />)}
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.operator`, {
              initialValue: this.props.operator ? this.props.opeator : this.filterOperator[0].id,
              rules: [{ required: true }]
            })(
              <Select style={{ width: '100%' }}>
                {this.filterOperator.map(item => (
                  <Option key={item.id}>{item.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${this.props.field}.value`, {
              rules: []
            })(
              <Select placeholder={this.props.field} style={{ width: '100%' }}>
                {this.valueOptions.map((item, key) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}
