import React, { Component } from 'react';
import { Table, Button, Tooltip } from 'antd';
import { Navbar } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterForm from './Filter/filterForm';
import { withEventBus } from 'context/eventbus';
import { LIST_LOADED, LIST_RELOAD } from 'redux/actions/list/action_types';
import style from './table.module.less';

class WrapperTable extends Component {
  constructor(props) {
    super(props);
    let sortOrder = this.props.columns.find(c => c.defaultSortOrder);
    let defSortOrder = sortOrder ? { [sortOrder.dataIndex]: this.translateSortOrder(sortOrder.defaultSortOrder) } : {};
    let defFilters = props.defaultFilters ? props.defaultFilters : {};
    this.state = {
      showForm: false,
      filters: defFilters,
      defaultFilters: defFilters,
      loaded: false,
      pageSize: 50,
      page: 1,
      sortOrder: defSortOrder
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(LIST_LOADED, action => {
        this.setState({
          loaded: true
        });
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(LIST_RELOAD, action => {
        this.stateUpdatedOnFilter();
      })
    );

    this.props.api(this.getParams());
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reload = () => {
    this.stateUpdatedOnFilter();
  };

  getParams = () => {
    return {
      page: this.state.page - 1,
      pageSize: this.state.pageSize,
      sorts: this.state.sortOrder,
      filters: this.state.filters
    };
  };

  getSortParams = sorter =>
    sorter.field
      ? {
          [this.getSortKey(sorter)]: this.translateSortOrder(sorter.order)
        }
      : {};

  getSortKey = sorter => (sorter.column && sorter.column.sortKey ? sorter.column.sortKey : sorter.field);

  translateSortOrder = sortOrder => (sortOrder === 'ascend' || sortOrder === 'ASC' ? 'ASC' : 'DESC');

  handleTableChange = (pagination, filters, sorter) => {
    let sortOrder = this.getSortParams(sorter);
    this.setState(
      {
        sortOrder: sortOrder,
        page: pagination.current
      },
      () => this.stateUpdatedOnFilter()
    );
  };

  stateUpdatedOnFilter = () => {
    this.props.api(this.getParams());
  };

  onSearch = f => {
    this.setState(
      {
        filters: Object.assign({}, f, this.state.defaultFilters),
        page: 1
      },
      this.stateUpdatedOnFilter
    );
  };

  showFilter = () => {
    this.setState({ showForm: this.state.showForm ? false : true });
  };

  render() {
    return (
      <div className={style.container}>
        <Navbar title={this.props.title}>
          {this.props.tableAction}
          {this.props.filters && (
            <div className={style.filterButton}>
              <Button shape="circle" onClick={this.showFilter}>
                <Tooltip title="Search">
                  <FontAwesomeIcon icon="filter" />
                </Tooltip>
              </Button>
            </div>
          )}
        </Navbar>
        {this.state.showForm && this.props.filters && (
          <FilterForm filters={this.props.filters} onSearch={this.onSearch}></FilterForm>
        )}
        <Table
          columns={this.props.columns}
          rowKey={record => record.id}
          dataSource={this.state.loaded ? this.props.data.data : []}
          pagination={this.props.data.pagination}
          loading={!this.state.loaded || this.props.data.isLoading}
          bordered
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default withEventBus(WrapperTable);
