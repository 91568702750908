import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './menu.module.less';
const { SubMenu } = Menu;

export default class AdminMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectedKeys: ['dashboard']
    };
  }

  componentDidMount() {
    let paths = window.location.pathname.split('/').filter(p => p !== '');
    if (paths.length !== 0) {
      this.setState({
        defaultSelectedKeys: paths
      });
    }
  }

  renderSubMenu = (subMenu, children) => {
    return (
      <SubMenu
        key={subMenu.key}
        title={
          <span>
            {subMenu.icon && <FontAwesomeIcon icon={subMenu.icon} />}
            <span>{subMenu.label}</span>
          </span>
        }
      >
        {children.map(c => this.renderMenuItem(c.item))}
      </SubMenu>
    );
  };

  renderMenuItem = menuItem => {
    return (
      <Menu.Item key={menuItem.key}>
        <Link to={menuItem.url}>
          {menuItem.icon && <FontAwesomeIcon icon={menuItem.icon} />}
          <span className="nav-text">{menuItem.label}</span>
        </Link>
      </Menu.Item>
    );
  };

  renderMenu = () => {
    return this.props.items.map(item =>
      item.children !== null && item.children.length > 0
        ? this.renderSubMenu(item.item, item.children)
        : this.renderMenuItem(item.item)
    );
  };

  render() {
    return (
      <Menu
        className={styles.menu}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={this.state.defaultSelectedKeys}
        defaultOpenKeys={this.state.defaultSelectedKeys}
      >
        {this.renderMenu()}
      </Menu>
    );
  }
}
