import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, Modal, Button, Descriptions, Tooltip } from 'antd';
import { Spinner, Navbar } from 'components';
import { AddressView } from 'components';
import * as customerSelector from 'redux/selectors/customer';
import * as customerActions from 'redux/actions/customer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CUSTOMER_CREATE_UPDATE_OK } from 'redux/actions/customer/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './customerview.module.less';

const { confirm } = Modal;

class CustomerView extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_CREATE_UPDATE_OK, action => {
        this.props.findByCustomerId(this.props.match.params.id);
      })
    );
    this.props.findByCustomerId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateStatus = (id, status) => {
    if (status === 'VERIFICATION_PENDING') {
      this.props.activate(id);
    } else if (status === 'INACTIVE') {
      this.props.activate(id);
    } else {
      this.props.inActivate(id);
    }
  };

  onStatusUpdate = () => {
    var status = this.props.customer.status;
    var id = this.props.customer.id;
    let updateStatus = this.updateStatus;
    confirm({
      title: 'Confirm',
      content: 'Are you sure to change status of customer?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateStatus(id, status.id);
      },
      onCancel() {}
    });
  };

  render() {
    let content;
    let status;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      var c = this.props.customer;
      status = this.props.customer.status;
      content = (
        <Descriptions bordered size="small">
          <Descriptions.Item label="First Name">{c.firstName}</Descriptions.Item>
          <Descriptions.Item label="Middle Name">{c.middleName}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{c.lastName}</Descriptions.Item>
          <Descriptions.Item label="Email">{c.email}</Descriptions.Item>
          <Descriptions.Item label="Status">{c.status.name}</Descriptions.Item>
          <Descriptions.Item label="Referred By">{c.referredBy}</Descriptions.Item>
          <Descriptions.Item label="Tax Class">{c.taxClassId.name}</Descriptions.Item>
          <Descriptions.Item label="Customer Group">{c.groupId.name}</Descriptions.Item>
          <Descriptions.Item label="Role">{c.roleId.name}</Descriptions.Item>
          <Descriptions.Item label="HST/GST Number">{c.hstNumber}</Descriptions.Item>
          <Descriptions.Item label="Order Type">{c.orderType.name}</Descriptions.Item>
          <Descriptions.Item label="Preferred Doc Type">{c.preferredDocType.name}</Descriptions.Item>
          <Descriptions.Item label="Default Disc" span={3}>
            {c.defaultDisc}
          </Descriptions.Item>

          <Descriptions.Item label="Default Shipping Address" span={3}>
            <AddressView address={this.props.customer.defaultShippingAddress} />
          </Descriptions.Item>
          <Descriptions.Item label="Default Billing Address" span={3}>
            <AddressView address={this.props.customer.defaultBillingAddress} />
          </Descriptions.Item>

          <Descriptions.Item label="Created By">
            {c.createdBy && c.createdBy.name ? c.createdBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Created On">{c.createdOn}</Descriptions.Item>
          <Descriptions.Item label="Updated By">
            {c.updatedBy && c.updatedBy.name ? c.updatedBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Updated On">{c.updatedOn}</Descriptions.Item>
        </Descriptions>
      );
    }

    return (
      <div className={styles.container}>
        <Navbar title={this.props.customer.code}>
          <Button type="primary" shape="circle" onClick={this.onStatusUpdate}>
            <Tooltip
              placement="rightTop"
              title={status ? (status.id === 'ACTIVE' ? 'Deactivate Customer' : 'Activate Customer') : ''}
            >
              <FontAwesomeIcon icon={status && status.id === 'ACTIVE' ? 'times' : 'check'} />
            </Tooltip>
          </Button>
          <Divider type="vertical" />
          <Link type="primary" shape="circle" to={'/customers/create/'}>
            <Tooltip placement="rightTop" title="Create Customer">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={`/customers/edit/${this.props.match.params.id}/`}>
            <Tooltip placement="rightTop" title="Edit Customer">
              <FontAwesomeIcon icon="edit" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/customers/allCustomers/'}>
            <Tooltip placement="rightTop" title="List Customers">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>

        {content}
      </div>
    );
  }
}

const mapDispatchToProps = {
  findByCustomerId: customerActions.findCustomerById,
  unload: customerActions.customerUnload,
  activate: customerActions.customerActivate,
  inActivate: customerActions.customerInactivate
};

const mapStateToProps = (state, props) => {
  return {
    customer: customerSelector.getCustomer(state),
    isLoaded: customerSelector.isLoaded(state),
    status: customerSelector.getStatus(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(CustomerView));
