import { call, put, takeLatest, all } from 'redux-saga/effects';
import { PAGE_LOAD, PAGE_UPDATE, PAGE_CREATE } from '../actions/staticPages/action_types';
import * as pageService from '../services/staticPages';
import * as pageActions from 'redux/actions/staticPages';
import * as apiErrorActions from 'redux/actions/apiError';

function* fetchPage() {
  yield takeLatest(PAGE_LOAD, function*(action) {
    try {
      let pages = yield call(pageService.fetchAll);
      yield put(pageActions.loaded(pages));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updatePage() {
  yield takeLatest(PAGE_UPDATE, function*(action) {
    try {
      let page = yield call(pageService.update, action.payload);
      yield put(pageActions.pageCreateUpdateSucess(page));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* createPage() {
  yield takeLatest(PAGE_CREATE, function*(action) {
    try {
      let page = yield call(pageService.create, action.payload);
      yield put(pageActions.pageCreateUpdateSucess(page));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* staticPagesSagas() {
  yield all([fetchPage(), updatePage(), createPage()]);
}
