export const PRODUCT_VARIANT_SEARCH = 'PRODUCT_VARIANT_SEARCH';
export const PRODUCT_VARIANT_VIEW = 'PRODUCT_VARIANT_VIEW';
export const PRODUCT_VARIANT_VIEW_LOADED = 'PRODUCT_VARIANT_VIEW_LOADED';
export const PRODUCT_VARIANT_UNLOAD = 'PRODUCT_VARIANT_UNLOAD';
export const PRODUCT_VARIANT_SAVE_ALL = 'PRODUCT_VARIANT_SAVE_ALL';
export const PRODUCT_VARIANT_SAVE_SUCCESS = 'PRODUCT_VARIANT_SAVE_SUCCESS';
export const PRODUCT_VARIANT_SELECTION_COUNT = 'PRODUCT_VARIANT_SELECTION_COUNT';
export const PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS = 'PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS';
export const PRODUCT_VARIANT_UPDATE_PRICES = 'PRODUCT_VARIANT_UPDATE_PRICES';
export const PRODUCT_VARIANT_UPDATE_PRICES_SUCCESS = 'PRODUCT_VARIANT_UPDATE_PRICES_SUCCESS';
export const PRODUCT_VARIANT_UPDATE_STATUS = 'PRODUCT_VARIANT_UPDATE_STATUS';
export const PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS = 'PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS';
export const PRODUCT_VARIANT_UPDATE_BULK_STATUS = 'PRODUCT_VARIANT_UPDATE_BULK_STATUS';
export const PRODUCT_VARIANT_UPDATE_BULK_STATUS_SUCCESS = 'PRODUCT_VARIANT_UPDATE_BULK_STATUS_SUCCESS';
export const PRODUCT_VARIANT_REFRESH_IMAGES = 'PRODUCT_VARIANT_REFRESH_IMAGES';
export const PRODUCT_VARIANT_REFRESH_IMAGES_SUCCESS = 'PRODUCT_VARIANT_REFRESH_IMAGES_SUCCESS';
export const PRODUCT_VARIANT_UPDATE_ATTRIBUTES = 'PRODUCT_VARIANT_UPDATE_ATTRIBUTES';
export const PRODUCT_VARIANT_UPDATE_NAME = 'PRODUCT_VARIANT_UPDATE_NAME';
