import { fetchApi } from 'Utils/utils';

let fetchAll = function() {
  return fetchApi.get('/api/v1/pages/all/');
};

let update = function(payload) {
  return fetchApi.put(`/api/v1/pages/${payload.id}/`, payload);
};

let create = function(payload) {
  return fetchApi.post('/api/v1/pages/', payload);
};

export { fetchAll, update, create };
