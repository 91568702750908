export const getContent = store => (store.phoneBrand ? store.phoneBrand : {});
export const get = store => {
  return getContent(store).phoneBrand;
};
export const isLoaded = store => {
  let content = getContent(store);
  return content.isLoaded != null && content.isLoaded;
};
export const getAll = store => (isAllLoaded(store) ? getContent(store).phoneBrands : []);

export const isAllLoaded = store => {
  let content = getContent(store);
  return content.isAllLoaded != null && content.isAllLoaded;
};
