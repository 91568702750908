import { call, takeLatest, all, put } from 'redux-saga/effects';
import {
  ORDERS_LOAD,
  VIEW_ORDER_LOAD,
  VIEW_ORDER_LOADED,
  ORDER_CANCEL,
  ORDER_HOLD,
  ORDER_CANCEL_HOLD
} from 'redux/actions/order/action_types';
import * as orderService from 'redux/services/order';
import * as listActions from 'redux/actions/list';
import * as orderActions from 'redux/actions/order';
import * as apiErrorActions from 'redux/actions/apiError';

function* searchOrders() {
  yield takeLatest(ORDERS_LOAD, function*(action) {
    let orderResponse = yield call(orderService.searchOrders, action.payload);
    yield put(listActions.listLoaded(orderResponse));
  });
}

function* findOneOrder() {
  yield takeLatest(VIEW_ORDER_LOAD, function*(action) {
    let orderResponse = yield call(orderService.findOne, action.payload);
    yield put({
      type: VIEW_ORDER_LOADED,
      payload: orderResponse
    });
  });
}

function* cancelOrder() {
  yield takeLatest(ORDER_CANCEL, function*(action) {
    try {
      yield call(orderService.cancelOrder, action.payload);
      yield put(orderActions.updateSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* cancelHold() {
  yield takeLatest(ORDER_CANCEL_HOLD, function*(action) {
    try {
      yield call(orderService.cancelHold, action.payload);
      yield put(orderActions.updateSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* holdOrder() {
  yield takeLatest(ORDER_HOLD, function*(action) {
    try {
      yield call(orderService.holdOrder, action.payload);
      yield put(orderActions.updateSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* orderSagas() {
  yield all([searchOrders(), findOneOrder(), cancelOrder(), holdOrder(), cancelHold()]);
}
