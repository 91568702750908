import { fetchApi } from 'Utils/utils';

let loadByCustomerId = function(payload) {
  return fetchApi.get(`/api/v1/priceOverride/customerId/${payload.customerId}/`);
};

let load = function(payload) {
  return fetchApi.get(`/api/v1/priceOverride/${payload.id}/`);
};

let update = function(payload) {
  if (payload.id !== 0) {
    return fetchApi.put(`/api/v1/priceOverride/${payload.id}/`, payload);
  } else {
    return fetchApi.post('/api/v1/priceOverride/', payload);
  }
};

let deleteById = function(payload) {
  return fetchApi.delete(`/api/v1/priceOverride/${payload.id}/`);
};

export { load, loadByCustomerId, update, deleteById };
