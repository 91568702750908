import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Select, Button, DatePicker, Table, Input } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import { SALES_COMM_LOAD_REPORT_SUCCESS } from 'redux/actions/salesComm/action_types';
import * as salesCommActions from 'redux/actions/salesComm';
import * as salesCommSelectors from 'redux/selectors/salesComm';
import * as userSelectors from 'redux/selectors/user';
import { Link } from 'react-router-dom';
import { toDateString } from 'Utils/utils';
import styles from './report.module.less';

const { RangePicker } = DatePicker;
const { Option } = Select;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_LOAD_REPORT_SUCCESS, action => {
        this.props.hideScreenLoader();
      })
    );
    if (!this.props.isSalesRep) {
      this.props.loadAllReps();
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let payload = Object.assign({}, values);
        delete payload['dateRange'];
        payload['fromDate'] = values.dateRange[0].format('YYYY-MM-DD');
        payload['toDate'] = values.dateRange[1].format('YYYY-MM-DD');
        this.props.showScreenLoader();
        this.props.loadReport(payload);
      }
    });
  };

  columns = [
    {
      title: '#',
      dataIndex: 'code',
      render: (value, row, idx) => idx + 1
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoiceCode'
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      render: value => toDateString(value)
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName'
    },
    {
      title: 'Order #',
      dataIndex: 'orderCode'
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      render: value => (value === 'UNPAID' ? 'Pending' : 'Received')
    },
    {
      title: 'Payment Date',
      dataIndex: 'invoicePaymentDate',
      render: value => toDateString(value)
    },
    {
      title: 'Inv Amount',
      dataIndex: 'totalInvoiceAmt',
      render: value => '$' + value.toFixed(2)
    },
    {
      title: 'Commission Amt',
      dataIndex: 'totalCommissionAmt',
      render: value => '$' + value.toFixed(2)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: id => <Link to={`/salesCommission/commission/${id}/`}>Details</Link>
    }
  ];

  render() {
    const { getFieldDecorator } = this.props.form;
    const dataSource = this.props.report;
    return (
      <div className={styles.form}>
        <Form onSubmit={this.handleSubmit} layout="inline">
          <Row gutter={24}>
            <Col span={24}>
              <h2 className="page-title">Sales Commission Report</h2>
            </Col>
            <Col span={24}>
              {!this.props.isSalesRep && (
                <Form.Item label="Sales Rep">
                  {getFieldDecorator('salesRepId', {
                    rules: [
                      {
                        required: true,
                        message: 'Sales Rep is required.'
                      }
                    ]
                  })(
                    <Select style={{ width: '200px' }}>
                      {this.props.allSalesReps.map(s => (
                        <Option key={s.id}>
                          {s.firstName} {s.lastName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              {this.props.isSalesRep &&
                getFieldDecorator('salesRepId', { initialValue: this.props.userId })(<Input type="hidden" />)}
              <Form.Item label="Date">
                {getFieldDecorator('dateRange', {
                  rules: [
                    {
                      required: true,
                      message: 'Date range is required.'
                    }
                  ]
                })(
                  <RangePicker
                    style={{ maxWidth: '300px' }}
                    renderExtraFooter={() => <p>Be careful when selecting long ranges.</p>}
                  />
                )}
              </Form.Item>
              <Form.Item label="Status">
                {getFieldDecorator('status', {
                  rules: [
                    {
                      required: true,
                      message: 'Status is required.'
                    }
                  ]
                })(
                  <Select style={{ width: '200px' }}>
                    <Option key="PAID">Payment Received</Option>
                    <Option key="UNPAID">Payment Pending</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Show Report
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          className={styles.commissionTable}
          pagination={false}
          rowKey="id"
          columns={this.columns}
          dataSource={dataSource}
          footer={() => (
            <div className={styles.footer}>
              <p>
                Total Invoice Amt: $
                {dataSource
                  .map(d => d.totalInvoiceAmt)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}
              </p>
              <p>
                Total Invoice Amt: $
                {dataSource
                  .map(d => d.totalCommissionAmt)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}
              </p>
            </div>
          )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadReport: salesCommActions.loadReport,
  loadAllReps: salesCommActions.loadAllReps,
  unload: salesCommActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    report: salesCommSelectors.getReport(state),
    allSalesReps: salesCommSelectors.getSalesReps(state),
    isSalesRep: userSelectors.isSalesRep(state),
    userId: userSelectors.getUserId(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withScreenLoader(withEventBus(Form.create({})(Report))));
