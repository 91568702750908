import {
  SLIDER_SEARCH,
  SLIDER_VIEW,
  SLIDER_VIEW_LOADED,
  SLIDER_CREATE,
  SLIDER_UPDATE,
  SLIDER_CREATE_SUCCESS,
  SLIDER_UNLOAD,
  SLIDER_DELETE,
  SLIDER_DELETE_SUCCESS
} from './action_types';

const search = payload => ({
  type: SLIDER_SEARCH,
  payload: payload
});

const find = payload => ({
  type: SLIDER_VIEW,
  payload: payload
});

const loaded = payload => ({
  type: SLIDER_VIEW_LOADED,
  payload: payload
});

const create = payload => ({
  type: SLIDER_CREATE,
  payload: payload
});

const update = payload => ({
  type: SLIDER_UPDATE,
  payload: payload
});

const deleteSlide = id => ({
  type: SLIDER_DELETE,
  payload: { id: id }
});

const deleteSlideSuccess = () => ({
  type: SLIDER_DELETE_SUCCESS,
  payload: {}
});

const slideCreateSuccess = payload => ({
  type: SLIDER_CREATE_SUCCESS,
  payload: payload
});

const unload = payload => ({
  type: SLIDER_UNLOAD,
  payload: {}
});

export { search, find, loaded, create, slideCreateSuccess, unload, update, deleteSlide, deleteSlideSuccess };
