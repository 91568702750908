import { call, put, takeLatest, all } from 'redux-saga/effects';
import { MENU_LOAD, MENU_UPDATE, MENU_CREATE, MENU_DELETE, MENU_IMAGE_UPLOAD } from '../actions/menu/action_types';
import * as menuService from '../services/menu';
import * as menuActions from 'redux/actions/menu';
import * as apiErrorActions from 'redux/actions/apiError';

function* fetchMenu() {
  yield takeLatest(MENU_LOAD, function*(action) {
    try {
      let menus = yield call(menuService.fetchAll);
      yield put(menuActions.menuLoaded(menus));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateMenu() {
  yield takeLatest(MENU_UPDATE, function*(action) {
    try {
      let menu = yield call(menuService.updateMenu, action.payload);
      yield put(menuActions.menuCreateUpdateSucess(menu));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* createMenu() {
  yield takeLatest(MENU_CREATE, function*(action) {
    try {
      let menu = yield call(menuService.createMenu, action.payload);
      yield put(menuActions.menuCreateUpdateSucess(menu));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* uploadImage() {
  yield takeLatest(MENU_IMAGE_UPLOAD, function*(action) {
    try {
      let menu = yield call(menuService.uploadImage, action.payload);
      yield put(menuActions.menuCreateUpdateSucess(menu));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* deleteMenu() {
  yield takeLatest(MENU_DELETE, function*(action) {
    try {
      let menu = yield call(menuService.deleteMenu, action.payload);
      yield put(menuActions.deleteMenuSuccess(menu));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* menuSagas() {
  yield all([fetchMenu(), updateMenu(), createMenu(), deleteMenu(), uploadImage()]);
}
