import { fetchApi } from 'Utils/utils';

let createEmployee = function(params) {
  return fetchApi.post('/api/v1/employee/', params);
};

let updateEmployee = function(params) {
  return fetchApi.put(`/api/v1/employee/${params.id}/`, params);
};

let updateToActive = function(id) {
  return fetchApi.put(`/api/v1/employee/${id}/activate/`);
};

let updateToInactive = function(id) {
  return fetchApi.put(`/api/v1/employee/${id}/inactivate/`);
};

let searchEmployee = function(params) {
  return fetchApi.post('/api/v1/employee/search/', params);
};

let findEmployeeById = function(id) {
  return fetchApi.get(`/api/v1/employee/${id}/`);
};

let resetPassword = function(id, password) {
  return fetchApi.put('/api/v1/employee/resetPassword/', { id: id, password: password, confirmPassword: password });
};

export {
  createEmployee,
  updateEmployee,
  searchEmployee,
  findEmployeeById,
  updateToActive,
  updateToInactive,
  resetPassword
};
