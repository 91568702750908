import { COUPON_VIEW_LOADED, COUPON_CREATE_UPDATE_OK, COUPON_UNLOAD } from '../actions/coupon/action_types';

const initialStore = {
  isLoaded: false,
  coupon: {}
};

const loaded = (state, coupon) => {
  return Object.assign({}, state, {
    isLoaded: true,
    coupon: coupon
  });
};

const unload = (state, payload) => Object.assign({}, state, initialStore);

export default function couponReducer(state = initialStore, action) {
  switch (action.type) {
    case COUPON_VIEW_LOADED:
      return loaded(state, action.payload);
    case COUPON_CREATE_UPDATE_OK:
      return loaded(state, action.payload);
    case COUPON_UNLOAD:
      return unload(state, initialStore);
    default:
      return state;
  }
}
