import reducer from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { eventBus } from 'context/eventbus';

import * as sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const arrayActions = store => next => action => {
  if (!Array.isArray(action)) return next(action);
  return action.map(a => store.dispatch(a));
};

const actionPublisher = store => next => action => {
  let result = next(action);
  eventBus.next(action);
  return result;
};
const initialStore = {};

const store = createStore(
  reducer,
  initialStore,
  composeWithDevTools(applyMiddleware(arrayActions, sagaMiddleware, actionPublisher))
);

sagas.run(sagaMiddleware);

export default store;
