import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider } from 'antd';
import * as couponSelector from 'redux/selectors/coupon';
import * as couponActions from 'redux/actions/coupon';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as customerGroupSelector from 'redux/selectors/customerGroup';
import { Navbar, Spinner } from 'components';
import CouponForm from '../Form/couponForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COUPON_CREATE_UPDATE_OK } from 'redux/actions/coupon/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './couponEdit.module.less';

class CouponEdit extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isCustGrpLoaded) {
      this.props.findAllCustGrp();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(COUPON_CREATE_UPDATE_OK, action => {
        this.props.history.push(`/marketing/coupons/${this.props.match.params.id}/`);
      })
    );
    this.props.findByCustomerId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unloadCustGrp();
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  submitHandle = formData => {
    if (formData.id !== 0) this.props.update(formData);
    else this.props.create(formData);
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <CouponForm
          key={this.props.coupon.id}
          data={this.props.coupon}
          customerGroups={this.props.allCustGrp}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
        ></CouponForm>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={'Edit ' + this.props.coupon.code}>
          <Link type="primary" to={'/marketing/coupons/create/'}>
            <Tooltip placement="top" title="Create Coupon">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={`/marketing/coupons/${this.props.match.params.id}/`}>
            <Tooltip placement="top" title="View Coupon">
              <FontAwesomeIcon icon="search" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/marketing/coupons/'}>
            <Tooltip placement="top" title="List Coupons">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  findByCustomerId: couponActions.findOne,
  findAllCustGrp: customerGroupActions.findAll,
  update: couponActions.update,
  unload: couponActions.unload,
  unloadCustGrp: customerGroupActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: couponSelector.isLoaded(state),
    isCustGrpLoaded: customerGroupSelector.isLoaded(state),
    coupon: couponSelector.getCoupon(state),
    allCustGrp: customerGroupSelector.getAllCustomerGrp(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(CouponEdit));
//
