// @flow
import React, { Component } from 'react';
import { Spinner, ScreenLoader } from 'components';
import styles from './app.module.less';
import './components/font-awesome-lib';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import * as pages from '../pages';
import { connect } from 'react-redux';
import * as configActions from 'redux/actions/config';
import * as configSelectors from 'redux/selectors/config';
import { Layout } from 'antd';
import { Menu, Header } from './components';
import * as userActions from 'redux/actions/user';
import * as userSelectors from 'redux/selectors/user';
import { ScreenLoaderContext } from 'context/screenLoader';
const { Content, Sider } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false
    };
  }

  componentDidMount() {
    this.props.loadConfig();
  }

  logout = () => {
    this.props.logout();
  };

  showLoader = () => this.setState({ showLoader: true });
  hideLoader = () => this.setState({ showLoader: false });

  render() {
    if (!this.props.isConfigLoaded) {
      return <Spinner />;
    } else if (!this.props.isUserLoggedIn) {
      return <pages.Login />;
    } else {
      return (
        <Layout className={styles.App}>
          <Router>
            <ScreenLoaderContext.Provider value={{ showLoader: this.showLoader, hideLoader: this.hideLoader }}>
              <ScrollToTop>
                <Sider
                  breakpoint="lg"
                  collapsedWidth="0"
                  width="300"
                  style={{
                    overflowY: 'scroll',
                    height: '100vh'
                  }}
                >
                  <div className={styles.logo}>
                    <img src={this.props.logo} alt="wplus" />
                  </div>
                  <Menu items={this.props.menu} />
                </Sider>
                <Layout className={styles.layout}>
                  <Header user={this.props.user} logout={this.logout} />
                  <Content>
                    <div className="auiContentWrapper">
                      <div className="auiContentContainer">
                        <Switch>
                          <Route path="/" exact component={pages.Homepage} />

                          <Route path="/options/menu/" component={pages.Menu} />
                          <Route path="/options/pages/" component={pages.StaticPage} />

                          <Route path="/customers/storeGroup/create/" exact component={pages.StoreGroup.Add} />
                          <Route path="/customers/storeGroup/view/:groupId/" exact component={pages.StoreGroup.View} />
                          <Route path="/customers/storeGroup/" exact component={pages.StoreGroup.List} />

                          <Route path="/customers/allCustomers/" exact component={pages.CustomerList} />
                          <Route path="/customers/nowOnline/" exact component={pages.UserSessionList} />
                          <Route path="/customers/create/" exact component={pages.CustomerAdd} />
                          <Route path="/customers/addresses/" exact component={pages.CustomerAddressList} />
                          <Route path="/customers/edit/:id/" exact component={pages.CustomerEdit} />
                          <Route path="/customers/:id/" exact component={pages.CustomerView} />

                          <Route path="/employees/" exact component={pages.EmployeeList} />
                          <Route path="/employees/create/" exact component={pages.EmployeeAdd} />
                          <Route path="/employees/edit/:id/" component={pages.EmployeeEdit} />
                          <Route path="/employees/:id/" component={pages.EmployeeView} />

                          <Route path="/sales/orders/" exact component={pages.OrderList} />
                          <Route path="/sales/orders/:id/" exact component={pages.OrderView} />

                          <Route path="/sales/invoices/" exact component={pages.InvoiceList} />
                          <Route path="/sales/invoices/:id/" exact component={pages.InvoiceView} />
                          <Route path="/sales/invoices/approve/:id/" exact component={pages.InvoiceApprove} />

                          <Route path="/catalog/categories/" component={pages.Category} />

                          <Route path="/catalog/attributes/edit/:id/:type/" component={pages.AttributeEdit} />
                          <Route path="/catalog/attributes/create/:type/" component={pages.AttributeAdd} />
                          <Route path="/catalog/attributes/:id/:type/" component={pages.AttributeView} />
                          <Route path="/catalog/attributes/" exact component={pages.AttributeList} />

                          <Route path="/catalog/products/" exact component={pages.ProductList} />
                          <Route path="/catalog/products/create/" exact component={pages.ProductAdd} />
                          <Route path="/catalog/products/:id/" exact component={pages.ProductView} />
                          <Route path="/catalog/products/edit/:id/" exact component={pages.ProductEdit} />
                          <Route path="/catalog/productVariants/" exact component={pages.ProductVariantList} />

                          <Route path="/catalog/productStatus/" exact component={pages.ProductStatusUpdate} />
                          <Route path="/catalog/backorderProducts/" exact component={pages.BackOrderList} />

                          <Route path="/catalog/deviceGroup/create/" exact component={pages.DeviceGroup.Add} />
                          <Route path="/catalog/deviceGroup/view/:groupId/" exact component={pages.DeviceGroup.View} />
                          <Route path="/catalog/deviceGroup/edit/:groupId/" exact component={pages.DeviceGroup.Edit} />
                          <Route path="/catalog/deviceGroup/" exact component={pages.DeviceGroup.List} />

                          <Route path="/catalog/phone/" exact component={pages.PhoneList} />
                          <Route path="/catalog/phone/create/" exact component={pages.PhoneAdd} />
                          <Route path="/catalog/phone/edit/:id/" exact component={pages.PhoneEdit} />
                          <Route path="/catalog/phone/:id/" exact component={pages.PhoneView} />

                          <Route path="/catalog/phoneBrand/" exact component={pages.PhoneBrandList} />
                          <Route path="/catalog/phoneBrand/create/" exact component={pages.PhoneBrandAdd} />
                          <Route path="/catalog/phoneBrand/edit/:id/" exact component={pages.PhoneBrandEdit} />
                          <Route path="/catalog/phoneBrand/:id/" exact component={pages.PhoneBrandView} />

                          <Route
                            path="/catalog/productVariants/product/:productId/variant/:variantId/"
                            component={pages.ProductVariantView}
                          />
                          <Route
                            path="/catalog/productVariants/product/:productId/create/variants/"
                            component={pages.ProductVariantCreate}
                          />
                          <Route path="/catalog/productVariants/refreshImages/" exact component={pages.RefreshImages} />

                          <Route path="/options/slides/" exact component={pages.ImageSliderList} />
                          <Route path="/options/slides/edit/:id/" exact component={pages.ImageSlideEdit} />
                          <Route path="/options/slides/create/" exact component={pages.ImageSlideAdd} />
                          <Route path="/options/slides/:id/" component={pages.ImageSlideView} />
                          <Route path="/options/homepageWidgets/" exact component={pages.HomepageWidgets.List} />
                          <Route
                            path="/options/homepageWidgets/edit/:id/"
                            exact
                            component={pages.HomepageWidgets.Edit}
                          />
                          <Route
                            path="/options/homepageWidgets/create/:type/"
                            exact
                            component={pages.HomepageWidgets.Add}
                          />

                          <Route path="/marketing/coupons/create/" component={pages.CouponAdd} />
                          <Route path="/marketing/coupons/edit/:id/" component={pages.CouponEdit} />
                          <Route path="/marketing/coupons/:id/" component={pages.CouponView} />
                          <Route path="/marketing/coupons/" exact component={pages.CouponList} />

                          <Route path="/marketing/priceOverride/" exact component={pages.PriceOverride} />
                          <Route
                            path="/marketing/priceOverride/customer/:customerId/"
                            exact
                            component={pages.PriceOverride}
                          />
                          <Route
                            path="/marketing/priceOverride/add/customer/:customerId/"
                            exact
                            component={pages.AddPriceOverride}
                          />
                          <Route
                            path="/marketing/priceOverride/edit/customer/:customerId/price/:priceId/"
                            exact
                            component={pages.EditPriceOverride}
                          />
                          <Route path="/marketing/updateProductPrice/" exact component={pages.ProductPriceUpdate} />
                          <Route path="/marketing/abandonedCarts/" exact component={pages.AbandonedCartList} />

                          <Route
                            path="/salescommission/assignedCustomers/"
                            exact
                            component={pages.SalesComm.Customer}
                          />
                          <Route path="/salescommission/rates/" exact component={pages.SalesComm.Rates} />
                          <Route path="/salescommission/commissions/" exact component={pages.SalesComm.Report} />
                          <Route path="/salescommission/commission/:id/" exact component={pages.SalesComm.Details} />

                          <Route path="/reports/backOrderProducts/" exact component={pages.Reports.BackOrderProducts} />
                          <Route
                            path="/reports/customerOrder/count/"
                            exact
                            component={pages.Reports.CustomerOrderCount}
                          />
                          <Route path="/reports/dormantCustomers/" exact component={pages.Reports.DormantCustomers} />

                          <Route component={pages.PageNotFound} />
                        </Switch>
                      </div>
                    </div>
                  </Content>
                </Layout>
              </ScrollToTop>
            </ScreenLoaderContext.Provider>
            {this.state.showLoader && <ScreenLoader />}
          </Router>
        </Layout>
      );
    }
  }
}

const mapDispatchToProps = {
  loadConfig: configActions.loadConfig,
  logout: userActions.userLogout
};

const mapStateToProps = (state, ownProps) => ({
  isConfigLoaded: configSelectors.isConfigLoaded(state),
  isUserLoggedIn: userSelectors.isUserLoggedIn(state),
  user: userSelectors.getUser(state),
  logo: configSelectors.getLogo(state),
  menu: configSelectors.getMenu(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

const ScrollToTop = withRouter(
  class extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }
    render() {
      return this.props.children;
    }
  }
);
