import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Modal,
  Space,
  Input,
  InputNumber,
  Row,
  Col,
  DatePicker,
  Button,
  Table,
  Tag,
  Form,
  Select,
  Descriptions
} from 'antd';
import { customerOrderCountReport, customerOrderDetailedReport } from 'redux/services/customerOrderReport';
import { fetchProvinces } from 'redux/services/address';
import { loadAllReps } from 'redux/services/salesComm';
import { GlobalError } from 'components';
import { handleErrors } from 'Utils/errorHandler';
import { toPrice, toDateString, toDateWithoutTimeString } from 'Utils/utils';
import { useHistory } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export default () => {
  const disabledDate = current => current > moment().endOf('day');
  const [form] = Form.useForm();
  const [reportData, setReportData] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [salesReps, setSalesRep] = useState([]);
  const [sorter, setSorter] = useState({});
  const [searchText, setSearchText] = useState({});
  const [globalErrors, setGlobalErrors] = useState([]);
  const history = useHistory();
  const [request, setRequest] = useState({});
  const [details, setDetails] = useState(null);

  useEffect(() => {
    (async () => {
      let provinces = await fetchProvinces();
      let salesReps = await loadAllReps();
      setProvinces(provinces);
      setSalesRep(salesReps);
    })();
  }, [setProvinces, setSalesRep]);

  const loadReport = values => {
    let selectedDate = values.dateRange;
    let fromDate = toDateString(selectedDate[0]).split(' ')[0];
    let toDate = toDateString(selectedDate[1]).split(' ')[0];
    (async () => {
      try {
        let request = {
          eShipperId: parseValue(values.eShipperId),
          noOfOrdersFrom: parseValue(values.noOfOrdersFrom),
          noOfOrdersTo: parseValue(values.noOfOrdersTo),
          phone: parseValue(values.phone),
          company: parseValue(values.company),
          email: parseValue(values.email),
          provinceId: parseValue(values.provinceId),
          name: parseValue(values.name),
          salesRepId: parseValue(values.salesRepId),
          street1: parseValue(values.street1),
          city: parseValue(values.city),
          postalCode: parseValue(values.postalCode),
          startDate: fromDate,
          endDate: toDate
        };
        setRequest(request);
        let response = await customerOrderCountReport(request);
        setReportData(
          response.map((d, idx) =>
            Object.assign({}, d, {
              id: idx + 1,
              invoiceSubTotal: toPrice(d.invoiceSubTotal),
              invoiceSubTotalLastYr: toPrice(d.invoiceSubTotalLastYr),
              invoiceTotal: toPrice(d.invoiceTotal),
              invoiceTotalLastYr: toPrice(d.invoiceTotalLastYr),
              invoiceTaxes: toPrice(d.invoiceTaxes),
              invoiceTaxesLastYr: toPrice(d.invoiceTaxesLastYr),
              invoiceAmt: toPrice(d.invoiceTotal - d.invoiceTaxes),
              invoiceAmtLastYr: toPrice(d.invoiceTotalLastYr - d.invoiceTaxesLastYr)
            })
          )
        );
      } catch (err) {
        setGlobalErrors(handleErrors(err, history));
      }
    })();
  };

  const loadDetails = async (customerId, eShipperId) => {
    let requestToSend = { startDate: request.startDate, endDate: request.endDate, customerId, eShipperId };
    let details = await customerOrderDetailedReport(requestToSend);
    setDetails(details);
  };

  const parseValue = v => (v && (v.length > 0 || Number.isInteger(v)) ? v : null);

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEntner={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: text =>
      searchText[dataIndex] ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText[dataIndex]]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(Object.assign({}, searchText, { [dataIndex]: selectedKeys[0] }));
  };

  const handleReset = (dataIndex, clearFilters) => {
    clearFilters();
    let cleared = Object.assign({}, searchText);
    delete cleared[dataIndex];
    setSearchText(cleared);
  };

  const strColumn = (title, key) => ({
    title: title,
    dataIndex: key,
    key: key,
    sorter: (a, b) => (a[key] ? a[key].localeCompare(b[key]) : -1),
    sortOrder: sorter.columnKey === key && sorter.order,
    ...getColumnSearchProps(key, title)
  });

  const numColumn = (title, key) => ({
    title: title,
    dataIndex: key,
    key: key,
    sorter: (a, b) => (a[key] ? a[key] - b[key] : -1),
    sortOrder: sorter.columnKey === key && sorter.order,
    ...getColumnSearchProps(key, title)
  });

  const comparingColumn = (title, key, otherKey) => ({
    title: title,
    dataIndex: key,
    key: key,
    sorter: (a, b) => (a[key] ? a[key] - b[key] : -1),
    sortOrder: sorter.columnKey === key && sorter.order,
    render: (text, record) => renderCompared(Number(text), Number(record[otherKey]))
  });

  const renderCompared = (n1, n2) => (
    <Tag color={n1 >= n2 ? 'success' : 'error'} style={{ padding: '4px', fontWeight: 'bold', fontSize: '14px' }}>
      {n1}
    </Tag>
  );

  const columns = [
    numColumn('#', 'id'),
    strColumn('First Name', 'firstName'),
    strColumn('Middle Name', 'middleName'),
    strColumn('Last Name', 'lastName'),
    numColumn('Customer Id', 'eshipperId'),
    strColumn('Company', 'company'),
    strColumn('Phone', 'phone'),
    comparingColumn('# Orders', 'noOfOrders', 'noOfOrdersLastYr'),
    comparingColumn('# Orders (last yr)', 'noOfOrdersLastYr', 'noOfOrders'),
    comparingColumn('Amt (excl. taxes)', 'invoiceAmt', 'invoiceAmtLastYr'),
    comparingColumn('Amt (last yr excl. taxes)', 'invoiceAmtLastYr', 'invoiceAmt'),
    comparingColumn('Qty', 'totalInvoicedUnits', 'totalInvoicedUnitsLastYr'),
    comparingColumn('Qty (last yr)', 'totalInvoicedUnitsLastYr', 'totalInvoicedUnits'),
    comparingColumn('Lines', 'totalInvoicedItems', 'totalInvoicedItemsLastYr'),
    comparingColumn('Lines (last yr)', 'totalInvoicedItemsLastYr', 'totalInvoicedItems'),
    strColumn('Street 1', 'street1'),
    strColumn('Province', 'province'),
    strColumn('Customer Since', 'customerSince'),
    {
      title: 'Actions',
      dataIndex: 'productVariantId',
      key: 'productVariantId',
      render: (_, row) => (
        <Fragment>
          <Button onClick={() => loadDetails(row.customerId, row.eshipperId)}>View details</Button>
        </Fragment>
      )
    }
  ];

  const handleTableChange = (_pagination, _filters, sorter) => {
    setSorter(sorter);
  };

  return (
    <div>
      <h2 className="page-title">Customer order count Report</h2>
      <GlobalError errors={globalErrors} />
      <Form form={form} onFinish={loadReport}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              name="dateRange"
              label="Date range"
              rules={[{ required: true, message: 'Date range is required.' }]}
            >
              <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" disabledDate={disabledDate} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="company" label="Company">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Form.Item name="provinceId" label="Province">
              <Select>
                {provinces &&
                  provinces.map(p => (
                    <Select.Option value={p.id} key={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="street1" label="Street">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="postalCode" label="Postal Code">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Form.Item name="salesRepId" label="Sales Rep">
              <Select>
                {salesReps &&
                  salesReps.map(p => (
                    <Select.Option value={p.id} key={p.id}>
                      {p.firstName} {p.lastName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="eShipperId" label="Customer Id">
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="noOfOrdersFrom" label="Number of orders (from)">
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="noOfOrdersTo" label="Number of orders (to)">
              <InputNumber min={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={2}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Load Report
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {reportData && (
            <Table
              rowKey="id"
              columns={columns}
              dataSource={reportData}
              onChange={handleTableChange}
              pagination={{ pageSize: 500 }}
              sticky={true}
              scroll={{ x: true, y: 'calc(100vh - 210px)' }}
            />
          )}
        </Col>
      </Row>
      {details && (
        <Modal
          footer={null}
          visible={true}
          onCancel={() => setDetails(null)}
          centered
          width={1000}
          title="Detailed Report"
        >
          {details.length === 0 && <h2>No orders found.</h2>}
          {details.length > 0 && (
            <>
              <h2>Customer Details</h2>
              <Descriptions bordered column={2}>
                <Descriptions.Item label="Name">
                  {details[0].customerFirstName} {details[0].customerMiddleName} {details[0].customerLastName}
                </Descriptions.Item>
                <Descriptions.Item label="Email">{details[0].customerEmail}</Descriptions.Item>
                <Descriptions.Item label="Phone">{details[0].customerPhone}</Descriptions.Item>
                <Descriptions.Item label="Company">{details[0].companyName}</Descriptions.Item>
                <Descriptions.Item label="Customer Id">{details[0].eshipperId}</Descriptions.Item>
                <Descriptions.Item label="Province">{details[0].province}</Descriptions.Item>
                <Descriptions.Item label="Customer Since">{details[0].customerSince}</Descriptions.Item>
                <Descriptions.Item label="No. of orders">{details.length}</Descriptions.Item>
              </Descriptions>
              <h2>Order Details</h2>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Order Date</th>
                    <th>Order Code</th>
                    <th>Invoice Code</th>
                    <th>Invoice Amt</th>
                  </tr>
                </thead>
                <tbody>
                  {details.map(d => (
                    <tr>
                      <td>{toDateWithoutTimeString(d.orderDate)}</td>
                      <td>{d.orderCode}</td>
                      <td>{d.invoiceCode}</td>
                      <td style={{ textAlign: 'right' }}>${toPrice(d.invoiceTotal - d.invoiceTaxes)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      ${toPrice(details.map(d => d.invoiceTotal - d.invoiceTaxes).reduce((a, c) => a + c, 0))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};
