import {
  PRICE_OVERRIDE_LOAD,
  PRICE_OVERRIDE_LOADED,
  PRICE_OVERRIDE_BY_CUSTOMER_LOAD,
  PRICE_OVERRIDE_BY_CUSTOMER_LOADED,
  PRICE_OVERRIDE_UPDATE,
  PRICE_OVERRIDE_UPDATE_SUCCESS,
  PRICE_OVERRIDE_DELETE,
  PRICE_OVERRIDE_DELETE_SUCCESS,
  PRICE_OVERRIDE_UNLOAD
} from './action_types';

const loadByCustomerId = customerId => ({
  type: PRICE_OVERRIDE_BY_CUSTOMER_LOAD,
  payload: { customerId: customerId }
});

const loadedByCustomerId = payload => ({
  type: PRICE_OVERRIDE_BY_CUSTOMER_LOADED,
  payload: payload
});

const unload = () => ({
  type: PRICE_OVERRIDE_UNLOAD,
  payload: {}
});

const update = payload => ({
  type: PRICE_OVERRIDE_UPDATE,
  payload: payload
});

const updateSuccess = payload => ({
  type: PRICE_OVERRIDE_UPDATE_SUCCESS,
  payload: payload
});

const deleteById = overrideId => ({
  type: PRICE_OVERRIDE_DELETE,
  payload: { id: overrideId }
});

const deleteSuccess = () => ({
  type: PRICE_OVERRIDE_DELETE_SUCCESS,
  payload: {}
});

const load = id => ({
  type: PRICE_OVERRIDE_LOAD,
  payload: { id: id }
});

const loaded = payload => ({
  type: PRICE_OVERRIDE_LOADED,
  payload: payload
});

export { load, loaded, loadByCustomerId, loadedByCustomerId, unload, update, updateSuccess, deleteById, deleteSuccess };
