import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Table, Popconfirm, Button } from 'antd';
import Add from './components/Add';
import { withEventBus, withScreenLoader } from 'context';
import {
  SALES_COMM_ASSIGN_CUSTOMER_SUCCESS,
  SALES_COMM_REMOVE_CUSTOMER_SUCCESS,
  SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS
} from 'redux/actions/salesComm/action_types';
import * as salesCommActions from 'redux/actions/salesComm';
import * as salesCommSelectors from 'redux/selectors/salesComm';
import * as userSelectors from 'redux/selectors/user';
import styles from './customer.module.less';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { toDateString } from 'Utils/utils';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      salesRepId: props.isSalesRep ? props.userId : '',
      showForm: false
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_ASSIGN_CUSTOMER_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.setState({ showForm: false });
        this.props.loadRepCustomers(this.state.salesRepId);
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_REMOVE_CUSTOMER_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.props.loadRepCustomers(this.state.salesRepId);
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS, action => {
        this.props.hideScreenLoader();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        this.props.hideScreenLoader();
        const { form } = this.formRef.props;
        let globalErrors = displayValidationErrors(form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
    if (this.props.isSalesRep) {
      this.changeSalesRep(this.props.userId);
    } else {
      this.props.loadAllReps();
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  changeSalesRep = value => {
    this.setState({
      salesRepId: value
    });
    this.props.showScreenLoader();
    this.props.loadRepCustomers(value);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.showScreenLoader();
        this.props.assignCustomer(this.state.salesRepId, values.customerId);
      }
    });
  };

  columns = () => {
    let cols = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName'
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Assigned By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (val, row) => row.createdBy.name
      },
      {
        title: 'Assigned On',
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: d => toDateString(d)
      }
    ];
    if (!this.props.isSalesRep) {
      cols.push({
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (val, row) => (
          <Popconfirm title="Sure to remove?" onConfirm={() => this.handleRemove(row.id)}>
            <a href="/">Remove</a>
          </Popconfirm>
        )
      });
    }
    return cols;
  };

  handleRemove = id => {
    this.props.showScreenLoader();
    this.props.removeCustomer(id);
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const dataSource = this.props.customers;
    return (
      <div className={styles.customer}>
        <h2 className="page-title">Sales Rep Customers</h2>
        {!this.props.isSalesRep && (
          <Row gutter={24}>
            <Col md={8} sm={24}>
              <Select size="large" className={styles.selectSalesRep} onChange={this.changeSalesRep}>
                {this.props.salesReps.map(s => (
                  <Select.Option key={s.id}>
                    {s.firstName} {s.lastName} -{s.code}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            {this.state.salesRepId !== '' && (
              <Col md={4} sm={24}>
                <Button size="large" type="primary" onClick={() => this.setState({ showForm: true })}>
                  Assign Customer
                </Button>
              </Col>
            )}
          </Row>
        )}
        {this.state.salesRepId !== '' && (
          <Table
            className={styles.customersTable}
            pagination={false}
            rowKey="id"
            columns={this.columns()}
            dataSource={dataSource}
          />
        )}
        {!this.props.isSalesRep && this.state.salesRepId !== '' && (
          <Add
            wrappedComponentRef={this.saveFormRef}
            handleSubmit={this.handleSubmit}
            handleClose={() => this.setState({ showForm: false })}
            visible={this.state.showForm}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadAllReps: salesCommActions.loadAllReps,
  loadRepCustomers: salesCommActions.loadRepCustomers,
  assignCustomer: salesCommActions.assignCustomer,
  removeCustomer: salesCommActions.removeCustomer,
  unload: salesCommActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    salesReps: salesCommSelectors.getSalesReps(state),
    customers: salesCommSelectors.getCustomers(state),
    isSalesRep: userSelectors.isSalesRep(state),
    userId: userSelectors.getUserId(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withScreenLoader(withEventBus(Customer)));
