import {
  PHONE_LOAD,
  PHONE_LOADED,
  PHONE_UNLOADED,
  PHONE_UPDATE,
  PHONE_SEARCH,
  PHONE_IMAGE_SMALL_UPLOAD,
  PHONE_IMAGE_LARGE_UPLOAD,
  PHONE_CREATE_UPDATE_SUCCESS,
  PHONE_CREATE,
  PHONE_LOAD_ALL,
  PHONE_LOADED_ALL
} from './action_types';

const find = payload => ({
  type: PHONE_LOAD,
  payload: payload
});

const phoneLoaded = payload => ({
  type: PHONE_LOADED,
  payload: payload
});

const unload = () => ({
  type: PHONE_UNLOADED,
  payload: {}
});

const update = payload => ({
  type: PHONE_UPDATE,
  payload: payload
});

const uploadSmallImage = payload => ({
  type: PHONE_IMAGE_SMALL_UPLOAD,
  payload: payload
});

const uploadLargeImage = payload => ({
  type: PHONE_IMAGE_LARGE_UPLOAD,
  payload: payload
});

const phoneCreateUpdateSucess = payload => ({
  type: PHONE_CREATE_UPDATE_SUCCESS,
  payload: payload
});

const create = payload => ({
  type: PHONE_CREATE,
  payload: payload
});

const search = payload => ({
  type: PHONE_SEARCH,
  payload: payload
});

const loadAll = payload => ({
  type: PHONE_LOAD_ALL,
  payload: payload
});

const loadedAll = payload => ({
  type: PHONE_LOADED_ALL,
  payload: payload
});

export {
  find,
  phoneLoaded,
  unload,
  update,
  phoneCreateUpdateSucess,
  search,
  create,
  uploadSmallImage,
  uploadLargeImage,
  loadAll,
  loadedAll
};
