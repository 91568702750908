import {
  SALES_COMM_LOAD_ALL_REPS,
  SALES_COMM_LOAD_ALL_REPS_SUCCESS,
  SALES_COMM_UNLOAD,
  SALES_COMM_LOAD_REP_CUSTOMERS,
  SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS,
  SALES_COMM_ASSIGN_CUSTOMER,
  SALES_COMM_ASSIGN_CUSTOMER_SUCCESS,
  SALES_COMM_REMOVE_CUSTOMER,
  SALES_COMM_REMOVE_CUSTOMER_SUCCESS,
  SALES_COMM_LOAD_ALL_RATES,
  SALES_COMM_LOAD_ALL_RATES_SUCCESS,
  SALES_COMM_REMOVE_RATE,
  SALES_COMM_REMOVE_RATE_SUCCESS,
  SALES_COMM_ADD_PRODUCT_RATE,
  SALES_COMM_ADD_PRODUCT_RATE_SUCCESS,
  SALES_COMM_LOAD_REPORT,
  SALES_COMM_LOAD_REPORT_SUCCESS,
  SALES_COMM_LOAD_COMMISSION_DETAILS,
  SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS
} from './action_types';

const loadAllReps = () => ({
  type: SALES_COMM_LOAD_ALL_REPS,
  payload: {}
});

const allRepsLoaded = payload => ({
  type: SALES_COMM_LOAD_ALL_REPS_SUCCESS,
  payload: payload
});

const loadRepCustomers = salesRepId => ({
  type: SALES_COMM_LOAD_REP_CUSTOMERS,
  payload: { salesRepId: salesRepId }
});

const loadRepCustomersSuccess = payload => ({
  type: SALES_COMM_LOAD_REP_CUSTOMERS_SUCCESS,
  payload: payload
});

const assignCustomer = (salesRepId, customerId) => ({
  type: SALES_COMM_ASSIGN_CUSTOMER,
  payload: {
    salesRepId: salesRepId,
    customerId: customerId
  }
});

const assignCustomerSuccess = payload => ({
  type: SALES_COMM_ASSIGN_CUSTOMER_SUCCESS,
  payload: payload
});

const removeCustomer = id => ({
  type: SALES_COMM_REMOVE_CUSTOMER,
  payload: { id: id }
});

const removeCustomerSuccess = () => ({
  type: SALES_COMM_REMOVE_CUSTOMER_SUCCESS,
  payload: {}
});

const unload = () => ({
  type: SALES_COMM_UNLOAD,
  payload: {}
});

const loadAllRates = () => ({
  type: SALES_COMM_LOAD_ALL_RATES,
  payload: {}
});

const loadAllRatesSuccess = payload => ({
  type: SALES_COMM_LOAD_ALL_RATES_SUCCESS,
  payload: payload
});

const removeRate = id => ({
  type: SALES_COMM_REMOVE_RATE,
  payload: { id: id }
});

const removeRateSuccess = () => ({
  type: SALES_COMM_REMOVE_RATE_SUCCESS,
  payload: {}
});

const addProductRate = payload => ({
  type: SALES_COMM_ADD_PRODUCT_RATE,
  payload: payload
});

const addProductRateSuccess = payload => ({
  type: SALES_COMM_ADD_PRODUCT_RATE_SUCCESS,
  payload: payload
});

const loadReport = payload => ({
  type: SALES_COMM_LOAD_REPORT,
  payload: payload
});

const loadReportSuccess = payload => ({
  type: SALES_COMM_LOAD_REPORT_SUCCESS,
  payload: payload
});

const loadCommissionDetails = id => ({
  type: SALES_COMM_LOAD_COMMISSION_DETAILS,
  payload: { id: id }
});

const loadCommissionDetailsSuccess = payload => ({
  type: SALES_COMM_LOAD_COMMISSION_DETAILS_SUCCESS,
  payload: payload
});

export {
  loadAllReps,
  allRepsLoaded,
  loadRepCustomers,
  loadRepCustomersSuccess,
  assignCustomer,
  assignCustomerSuccess,
  removeCustomer,
  removeCustomerSuccess,
  loadAllRates,
  loadAllRatesSuccess,
  removeRate,
  removeRateSuccess,
  addProductRate,
  addProductRateSuccess,
  loadReport,
  loadReportSuccess,
  loadCommissionDetails,
  loadCommissionDetailsSuccess,
  unload
};
