import React, { Component } from 'react';
import Autocomplete from '../Autocomplete';

class Customer extends Component {
  render() {
    return (
      <Autocomplete
        searchUrl="/api/v1/customer/autoComplete/"
        placeholder="Search Customer"
        onChange={this.props.onChange}
      />
    );
  }
}

export default Customer;
