import { call, put, takeLatest, all } from 'redux-saga/effects';
import { 
    PHONE_BRAND_LOAD, 
    PHONE_BRAND_SEARCH, 
    PHONE_BRAND_UPDATE,
    PHONE_BRAND_ALL_LOAD, 
    PHONE_BRAND_CREATE,
    PHONE_BRAND_IMAGE_UPLOAD 
} from '../actions/phoneBrand/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as phoneBrandService from '../services/phoneBrand';
import * as phoneBrandActions from 'redux/actions/phoneBrand';
import * as listActions from 'redux/actions/list';

function* search() {
  yield takeLatest(PHONE_BRAND_SEARCH, function*(action) {
    try {
      let phones = yield call(phoneBrandService.search, action.payload);
      yield put(listActions.listLoaded(phones));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findAll() {
  yield takeLatest(PHONE_BRAND_ALL_LOAD, function*(action) {
    try {
      let phones = yield call(phoneBrandService.findAll);
      yield put(phoneBrandActions.phoneBrandAllLoaded(phones));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findById() {
  yield takeLatest(PHONE_BRAND_LOAD, function*(action) {
    try {
      let phone = yield call(phoneBrandService.find, action.payload);
      yield put(phoneBrandActions.phoneBrandLoaded(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}


function* uploadImage() {
  yield takeLatest(PHONE_BRAND_IMAGE_UPLOAD, function*(action) {
    try {
      let phone = yield call(phoneBrandService.uploadImage, action.payload);
      yield put(phoneBrandActions.phoneBrandCreateUpdateSucess(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* save() {
  yield takeLatest(PHONE_BRAND_CREATE, function*(action) {
    try {
      let phone = yield call(phoneBrandService.create, action.payload);
      yield put(phoneBrandActions.phoneBrandCreateUpdateSucess(phone));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* update() {
    yield takeLatest(PHONE_BRAND_UPDATE, function*(action) {
      try {
        let phone = yield call(phoneBrandService.update, action.payload);
        yield put(phoneBrandActions.phoneBrandCreateUpdateSucess(phone));
      } catch (err) {
        yield put(apiErrorActions.handle(err));
      }
    });
  }

export default function* imageSliderSagas() {
  yield all([search(), findAll(),  findById(), save(), update(),uploadImage()]);
}
