import { fetchApi } from 'Utils/utils';

let search = function(payload) {
  return fetchApi.post('/api/v1/devices/group/search/', payload);
};

let create = function(payload) {
  return fetchApi.fileUpload('/api/v1/devices/group/', payload);
};

let update = function(groupId, payload) {
  return fetchApi.fileUpload(`/api/v1/devices/group/${groupId}/`, payload);
};

let remove = function(id) {
  return fetchApi.delete(`/api/v1/devices/group/${id}/`);
};

let find = function(id) {
  return fetchApi.get(`/api/v1/devices/group/${id}/`);
};

let findAll = function() {
  return fetchApi.get('/api/v1/devices/group/');
};

export { create, update, search, remove, find, findAll };
