export const ATTRIBUTE_VIEW = 'ATTRIBUTE_VIEW';
export const ATTRIBUTE_VIEW_LOADED = 'ATTRIBUTE_VIEW_LOADED';
export const ATTRIBUTE_CREATE = 'ATTRIBUTE_CREATE';
export const ATTRIBUTE_UPDATE = 'ATTRIBUTE_UPDATE';
export const ATTRIBUTE_DELETE = 'ATTRIBUTE_DELETE';
export const ATTRIBUTE_SUBMIT_OK = 'ATTRIBUTE_SUBMIT_OK';
export const ATTRIBUTE_UNLOAD = 'ATTRIBUTE_UNLOAD';
export const ATTRIBUTE_REMOVE_LIST_ITEM = 'ATTRIBUTE_REMOVE_LIST_ITEM';
export const ATTRIBUTE_ADD_LIST_ITEM = 'ATTRIBUTE_ADD_LIST_ITEM';
export const ATTRIBUTE_LIST = 'ATTRIBUTE_LIST';
export const ATTRIBUTE_LOAD_ALL = 'ATTRIBUTE_LOAD_ALL';
export const ATTRIBUTE_ALL_LOADED = 'ATTRIBUTE_ALL_LOADED';
export const ATTRIBUTE_PHONE_BRAND_LOAD_ALL = 'ATTRIBUTE_PHONE_BRAND_LOAD_ALL';
export const ATTRIBUTE_PHONE_BRAND_ALL_LOADED = 'ATTRIBUTE_PHONE_BRAND_ALL_LOADED';
export const ATTRIBUTE_LOAD_PHONES_BY_BRAND = 'ATTRIBUTE_LOAD_PHONES_BY_BRAND';
export const ATTRIBUTE_LOADED_PHONES_BY_BRAND = 'ATTRIBUTE_LOADED_PHONES_BY_BRAND';
