import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tooltip, Row, Col, Input, Alert, Button, InputNumber, Select, Descriptions } from 'antd';
import { INVOICE_CREATE_UPDATE_OK } from 'redux/actions/invoice/action_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as invoiceSelectors from 'redux/selectors/invoice';
import * as invoiceActions from 'redux/actions/invoice';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { AddressView, Spinner, Navbar } from 'components';
import { withEventBus } from 'context/eventbus';
import { toPrice } from 'Utils/utils';
import style from '../View/invoiceview.module.less';

const { Option } = Select;

class InvoiceApprove extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.findByUid(this.props.match.params.id);
    this.subscriptions.push(
      this.props.eventBusSubscribe(INVOICE_CREATE_UPDATE_OK, action => {
        this.props.history.push(`/sales/invoices/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.approve(formData);
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <div>
          <Descriptions bordered column={4} size="small">
            <Descriptions.Item label="Order Id">{this.props.invoice.orderCode}</Descriptions.Item>
            <Descriptions.Item label="Customer Name">
              {this.props.invoice.customer.firstName} {this.props.invoice.customer.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Invoice Date">
              {new Date(this.props.invoice.createdOn).toLocaleDateString('en-US')}
            </Descriptions.Item>
            <Descriptions.Item label="Id">{this.props.invoice.code}</Descriptions.Item>
            <Descriptions.Item label="Status">{this.props.invoice.status.name}</Descriptions.Item>
            <Descriptions.Item label="Payment Method">
              {this.props.invoice.paymentMethod == null ? '' : this.props.invoice.paymentMethod.name}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Method">{this.props.invoice.shippingMethod}</Descriptions.Item>
            <Descriptions.Item label="Bulk Discount">{this.props.invoice.bulkDiscount}</Descriptions.Item>
            <Descriptions.Item span={4} label="Shipping Description">
              {this.props.invoice.shippingDescription}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Billing Address">
              <AddressView address={this.props.invoice.billingAddress} />
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Shipping Address">
              <AddressView address={this.props.invoice.shippingAddress} />
            </Descriptions.Item>
            <Descriptions.Item span={4} label="Notes">
              {this.props.invoice.notes}
            </Descriptions.Item>
          </Descriptions>
          <WrappedShipmentForm
            key={this.props.invoice.id}
            type={this.props.match.params.type}
            shipmentMethod={this.props.invoice.orderShippingMethod}
            submitHandle={this.submitHandle}
            eventBusSubscribe={this.props.eventBusSubscribe}
            data={this.props.invoice}
          ></WrappedShipmentForm>
        </div>
      );
    }
    return (
      <div>
        <Navbar title={'Approve Invoice'}>
          <Link type="primary" to={'/sales/invoices/'}>
            <Tooltip placement="top" title="List Invoices">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

class ShipmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  shipmentOptions = [
    { id: 'STANDARD_SHIPPING', label: 'Standard Shipping' },
    { id: 'EXPEDITED_SHIPPING', label: 'Expedited Shipping' },
    { id: 'EXPRESS_SHIPPING', label: 'Express Shipping' }
  ];

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.submitHandle(values);
      }
    });
  };

  validateTrackingNumber = (rule, value, callback) => {
    if (rule.index === 0 && value === null) {
      callback('Tracking number is required');
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    var defaultShippingMethod = this.props.shipmentMethod !== null ? this.props.shipmentMethod.id : null;
    const formItems = this.props.data.shipments.map((k, index) => (
      <Row gutter={24} key={k.id}>
        {getFieldDecorator(`shipments[${index}].id`, {
          initialValue: k.id
        })(<Input type="hidden" placeholder="Id" />)}
        <Col span={4}>
          <Form.Item label="Name" required={true}>
            {getFieldDecorator(`shipments[${index}].name`, {
              initialValue: k.name,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter name.'
                }
              ]
            })(<Input placeholder="name" />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Tracking" required={true}>
            {getFieldDecorator(`shipments[${index}].tracking`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: k.tracking,
              rules: [
                {
                  validator: this.validateTrackingNumber,
                  index: index
                }
              ]
            })(<Input placeholder="tracking" />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Method" required={true}>
            {getFieldDecorator(`shipments[${index}].method`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: defaultShippingMethod,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter method number.'
                }
              ]
            })(
              <Select onChange={this.onOperatorChange} style={{ width: '100%' }}>
                {this.shipmentOptions.map((item, key) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item required={true} label="Amount">
            {getFieldDecorator(`shipments[${index}].amount`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: k.amount,
              rules: [
                {
                  required: true,
                  message: 'Enter shipment amount.'
                }
              ]
            })(<InputNumber placeholder="amount" style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item required={true} label="Width  inch">
            {getFieldDecorator(`shipments[${index}].width`, {
              initialValue: k.width,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: 'Enter Shipment width.'
                }
              ]
            })(<InputNumber placeholder="width" style={{ width: '100%' }} addonAfter="Inch" />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item required={true} label="Length inch">
            {getFieldDecorator(`shipments[${index}].length`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: k.length,
              rules: [
                {
                  required: true,
                  message: 'Enter shipment length.'
                }
              ]
            })(<InputNumber placeholder="length" style={{ width: '100%' }} addonBefore="Inch" />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item required={true} label="Height inch">
            {getFieldDecorator(`shipments[${index}].height`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: k.height,
              rules: [
                {
                  required: true,
                  message: 'Enter shipment height.'
                }
              ]
            })(<InputNumber placeholder="height" style={{ width: '100%' }} addonBefore="Inch" />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item required={true} label="Weight lbs">
            {getFieldDecorator(`shipments[${index}].weight`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: k.weight,
              rules: [
                {
                  required: true,
                  message: 'Enter shipment weight.'
                }
              ]
            })(<InputNumber placeholder="weight" style={{ width: '100%' }} addonBefore="lbs" />)}
          </Form.Item>
        </Col>
      </Row>
    ));
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('invoiceId', {
          initialValue: this.props.data.id
        })(<Input type="hidden" placeholder="invoiceId" />)}
        <Row gutter={24}>
          <Col span={24}>
            {this.state.globalErrors.map(error => (
              <Alert message={error} type="error" />
            ))}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>Shipment Items for Invoice</Col>
        </Row>
        {formItems}
        <Row gutter={24}>
          <Col span={18} />
          <Col span={6}>
            <table className={style.table}>
              <tbody>
                <tr className={style.row}>
                  <td>Sub Total</td>
                  <td className={style.total}>{toPrice(this.props.data.subTotal)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Shipping Amt</td>
                  <td className={style.total}>{toPrice(this.props.data.shippingAmt)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Tax</td>
                  <td className={style.total}>{toPrice(this.props.data.taxes)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Total</td>
                  <td className={style.total}>{toPrice(this.props.data.total)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Approve
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedShipmentForm = Form.create({ name: 'shipmentForm' })(ShipmentForm);

const mapDispatchToProps = {
  findByUid: invoiceActions.findOne,
  unload: invoiceActions.unload,
  approve: invoiceActions.approve
};

const mapStateToProps = (state, props) => {
  return {
    invoice: invoiceSelectors.get(state),
    isLoaded: invoiceSelectors.isLoaded(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(InvoiceApprove));
