import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner, Navbar } from 'components';
import { Descriptions, List, Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toPrice } from 'Utils/utils';
import * as productVariantSelectors from 'redux/selectors/productVariant';
import * as productVariantActions from 'redux/actions/productVariant';
import * as productSelectors from 'redux/selectors/product';
import * as productActions from 'redux/actions/product';
import * as phoneSelectors from 'redux/selectors/phone';
import * as phoneActions from 'redux/actions/phone';
import * as attributeSelectors from 'redux/selectors/attribute';
import * as attributeActions from 'redux/actions/attribute';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as customerGroupSelector from 'redux/selectors/customerGroup';
import * as userSelector from 'redux/selectors/user';
import { toDateString } from 'Utils/utils';
import StatusForm from './components/StatusForm';
import AttributesEditForm from './components/AttributesEditForm';
import NameUpdateForm from './components/NameUpdateForm';
import { withScreenLoader, withEventBus } from 'context';
import {
  PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS,
  PRODUCT_VARIANT_SAVE_SUCCESS
} from 'redux/actions/productVariant/action_types';
import styles from './productvariantview.module.less';

class ProductVariantView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formVisible: false,
      showEditAttrForm: false,
      showEditNameForm: false
    };
    this.subscriptions = [];
  }
  componentDidMount() {
    this.props.load(this.props.productId, this.props.variantId);
    this.props.loadAttrs(this.props.productId);
    this.props.loadCategories(this.props.productId);
    this.props.loadAllPhoneBrands();
    this.props.findAllCustGrp();
    this.props.loadAllPhones();

    const refreshView = () => {
      this.setState({
        formVisible: false,
        showEditAttrForm: false,
        showEditNameForm: false
      });
      this.props.hideScreenLoader();
      this.props.load(this.props.productId, this.props.variantId);
      this.props.loadAttrs(this.props.productId);
      this.props.loadCategories(this.props.productId);
    };
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS, action => refreshView())
    );
    this.subscriptions.push(this.props.eventBusSubscribe(PRODUCT_VARIANT_SAVE_SUCCESS, action => refreshView()));
  }

  componentWillUnmount() {
    this.props.unload();
    this.props.unloadProduct();
    this.props.unloadCustGrp();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleSubmit = values => {
    let payload = Object.assign({}, values, {
      productId: this.props.productId,
      variantId: this.props.variantId
    });
    this.props.updateStatus(payload);
    this.props.showScreenLoader();
  };

  handleEditAttributes = values => {
    this.props.showScreenLoader();
    this.props.updateAttributes(this.props.variantId, values);
  };

  handleEditName = values => {
    this.props.showScreenLoader();
    this.props.updateName(this.props.variantId, values);
  };

  getPhone = () => {
    let phone = this.props.productVariant.attributes && this.props.productVariant.attributes['phone'];
    if (!phone || phone.length === 0) {
      return {};
    }
    return this.props.allPhones.find(p => p.id === phone[0]) || {};
  };

  renderPhone = (pv, item) => {
    if (pv.attributes[item.code].length === 0) {
      return this.renderListItem(item, '');
    }
    let phone = this.props.allPhones.find(p => p.id === pv.attributes[item.code][0]);
    if (!phone) {
      return this.renderListItem(item, '');
    }
    return this.renderListItem(item, phone.name);
  };

  renderAttr = (pv, item) => {
    return item.code === 'phone'
      ? this.renderPhone(pv, item)
      : this.renderListItem(item, pv.attributes[item.code].join(', '));
  };

  renderListItem = (item, value) => {
    return (
      <List.Item>
        {item.label} ({item.code}) =&gt; {value}
      </List.Item>
    );
  };

  render() {
    let content = <Spinner />;
    if (this.props.isLoaded && this.props.isProductLoaded && this.props.isCustGrpLoaded) {
      let pv = this.props.productVariant;
      let p = this.props.product;
      let custGrp = this.props.allCustGrp.reduce((map, obj) => {
        map[obj.id] = obj.name;
        return map;
      }, {});
      content = (
        <div>
          <Navbar title={pv.name}>
            {!this.props.isUserContentCreator && (
              <Button
                shape="circle"
                onClick={() =>
                  this.setState({
                    formVisible: true
                  })
                }
              >
                <Tooltip placement="top" title="Update Status">
                  <FontAwesomeIcon icon="edit" />
                </Tooltip>
              </Button>
            )}
            <Button shape="circle" onClick={() => this.props.history.push('/catalog/productVariants/')}>
              <Tooltip placement="top" title="List Product Variants">
                <FontAwesomeIcon icon="th-list" />
              </Tooltip>
            </Button>
          </Navbar>
          <Descriptions bordered column={2} size="small" title="Details">
            <Descriptions.Item label="Variant Name">
              {pv.name}
              <Button
                className={styles.attrEditBtn}
                shape="circle"
                onClick={() => this.setState({ showEditNameForm: true })}
              >
                <Tooltip placement="top" title="Update Status">
                  <FontAwesomeIcon icon="edit" />
                </Tooltip>
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label="Product Name">{p.name}</Descriptions.Item>
            <Descriptions.Item label="Variant Number">{pv.variantNumber}</Descriptions.Item>
            <Descriptions.Item label="Product Url">{p.url}</Descriptions.Item>
            <Descriptions.Item label="Variant Sku">{pv.sku}</Descriptions.Item>
            <Descriptions.Item label="Product Sku">{p.sku}</Descriptions.Item>
            <Descriptions.Item label="Status">{pv.stockStatus}</Descriptions.Item>
            <Descriptions.Item label="Enabled">{pv.enabled ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Price">${pv.price}</Descriptions.Item>
            <Descriptions.Item label="Special Price">{pv.specialPrice ? '$' + pv.specialPrice : '-'}</Descriptions.Item>
            <Descriptions.Item label="Special Price from">{toDateString(pv.specialPriceFrom)}</Descriptions.Item>
            <Descriptions.Item label="Special Price to">{toDateString(pv.specialPriceTo)}</Descriptions.Item>
            <Descriptions.Item label="Special Price Type">{pv.specialPriceType}</Descriptions.Item>
            <Descriptions.Item label="Weight">{pv.weight}</Descriptions.Item>
            <Descriptions.Item label="New To">{pv.newTo}</Descriptions.Item>
            <Descriptions.Item label="Tag Option">{pv.tag}</Descriptions.Item>
            <Descriptions.Item label="Tag Start Date">{toDateString(pv.tagStartDate)}</Descriptions.Item>
            <Descriptions.Item label="Tag End Date">{toDateString(pv.tagEndDate)}</Descriptions.Item>
            <Descriptions.Item span={2} label="Description">
              {pv.description}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Short Description">
              {pv.shortDescription}
            </Descriptions.Item>
          </Descriptions>

          {Object.keys(pv.tierPrices).length > 0 && (
            <Descriptions bordered column={1} size="small" title="Tier Prices">
              {Object.keys(pv.tierPrices).map(tp => (
                <Descriptions.Item key={tp} label={custGrp[tp]}>
                  <div className={styles.bulkPricing}>
                    {pv.tierPrices[tp].map((p, idx) => (
                      <div key={idx} className={styles.bulkPricingUnit}>
                        <div className={styles.qty}>{p.qty}</div>
                        <div>{toPrice(p.price)}</div>
                      </div>
                    ))}
                  </div>
                </Descriptions.Item>
              ))}
            </Descriptions>
          )}
          <List
            className="view-data-list"
            header="Categories"
            bordered
            dataSource={this.props.categories}
            renderItem={item => <List.Item>{item.name}</List.Item>}
          />
          <List
            className="view-data-list"
            header={
              this.props.isUserContentCreator ? (
                'Attributes'
              ) : (
                <div>
                  Attributes
                  <Button
                    className={styles.attrEditBtn}
                    shape="circle"
                    onClick={() => this.setState({ showEditAttrForm: true })}
                  >
                    <Tooltip placement="top" title="Update Status">
                      <FontAwesomeIcon icon="edit" />
                    </Tooltip>
                  </Button>
                </div>
              )
            }
            bordered
            dataSource={this.props.attrs.filter(a => pv.attributes[a.code] !== undefined)}
            renderItem={item => this.renderAttr(pv, item)}
          />

          <Descriptions bordered column={1} size="small" title="Meta Information">
            <Descriptions.Item label="Meta Title">{pv.metaTitle}</Descriptions.Item>
            <Descriptions.Item label="Meta Keywords">{pv.metaKeywords}</Descriptions.Item>
            <Descriptions.Item label="Meta Description">{pv.metaDescription}</Descriptions.Item>
          </Descriptions>

          <Descriptions bordered column={2} size="small" title="Audit Information">
            <Descriptions.Item label="Created By">
              {pv.createdBy && pv.createdBy.name ? pv.createdBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Created On">{toDateString(pv.createdOn)}</Descriptions.Item>
            <Descriptions.Item label="Updated By">
              {pv.updatedBy && pv.updatedBy.name ? pv.updatedBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Updated On">{toDateString(pv.updatedOn)}</Descriptions.Item>
          </Descriptions>
          <List
            className="view-data-list"
            header="Images"
            bordered
            dataSource={pv.assets}
            renderItem={item => (
              <List.Item>
                <img src={item.path} alt={item.title} className={styles.productImg} />
              </List.Item>
            )}
          />
        </div>
      );
    }
    return (
      <div id="print-mount" className={styles.productView}>
        {content}
        {this.state.formVisible && (
          <StatusForm
            onCancel={() => this.setState({ formVisible: false })}
            onSubmit={this.handleSubmit}
            eventBusSubscribe={this.props.eventBusSubscribe}
            hideScreenLoader={this.props.hideScreenLoader}
          />
        )}
        {this.state.showEditAttrForm && (
          <AttributesEditForm
            onCancel={() => this.setState({ showEditAttrForm: false })}
            onSubmit={this.handleEditAttributes}
            eventBusSubscribe={this.props.eventBusSubscribe}
            hideScreenLoader={this.props.hideScreenLoader}
            attrs={this.props.attrs}
            phoneBrands={this.props.allPhoneBrands}
            phones={this.props.phones}
            currentPhone={this.getPhone()}
            onPhoneBrandChange={this.props.loadPhonesByBrandIds}
            attrValues={this.props.productVariant.attributes ? this.props.productVariant.attributes : {}}
          />
        )}
        {this.state.showEditNameForm && (
          <NameUpdateForm
            onCancel={() => this.setState({ showEditNameForm: false })}
            onSubmit={this.handleEditName}
            eventBusSubscribe={this.props.eventBusSubscribe}
            hideScreenLoader={this.props.hideScreenLoader}
            name={this.props.productVariant ? this.props.productVariant.name : ''}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  load: productVariantActions.load,
  unload: productVariantActions.unload,
  unloadProduct: productActions.unload,
  loadAttrs: productActions.loadAttrs,
  loadCategories: productActions.loadCategories,
  findAllCustGrp: customerGroupActions.findAll,
  unloadCustGrp: customerGroupActions.unload,
  updateStatus: productVariantActions.updateStatus,
  loadAllPhoneBrands: attributeActions.loadAllPhoneBrands,
  loadPhonesByBrandIds: attributeActions.loadPhonesByBrandIds,
  loadAllPhones: phoneActions.loadAll,
  updateAttributes: productVariantActions.updateAttributes,
  updateName: productVariantActions.updateName
};

const mapStateToProps = (state, ownProps) => ({
  productVariant: productVariantSelectors.getProductVariant(state),
  product: productSelectors.getProduct(state),
  isLoaded: productVariantSelectors.isLoaded(state),
  productId: ownProps.match.params.productId,
  variantId: ownProps.match.params.variantId,
  isProductLoaded: productSelectors.isLoaded(state),
  categories: productSelectors.getCategories(state),
  attrs: productSelectors.getAttrs(state),
  allCustGrp: customerGroupSelector.getAllCustomerGrp(state),
  isCustGrpLoaded: customerGroupSelector.isLoaded(state),
  isUserContentCreator: userSelector.isUserContentCreator(state),
  allPhones: phoneSelectors.getAll(state),
  allPhoneBrands: attributeSelectors.getAllPhoneBrands(state),
  phones: attributeSelectors.getPhones(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(withScreenLoader(withEventBus(ProductVariantView)));
