// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tableListSelector from 'redux/selectors/tableList';
import { Row, Col, Button } from 'antd';
import * as productAction from 'redux/actions/product';
import * as listActions from 'redux/actions/list';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toDateString } from 'Utils/utils';
import { WrapperTable } from 'components';

const Fragment = React.Fragment;

class ProductList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    let tableAction = (
      <Button type="primary" onClick={() => this.props.history.push('/catalog/products/create/')}>
        <FontAwesomeIcon icon="plus-square" style={{ marginRight: '8px' }} />
        Add Product
      </Button>
    );

    return (
      <div>
        <Row>
          <Col span={24}>
            <WrapperTable
              title="Product List"
              filters={filters}
              columns={columns}
              data={this.props.listData}
              api={this.props.searchProduct}
              tableAction={tableAction}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
const columns = [
  {
    title: 'Sku',
    dataIndex: 'sku',
    sorter: true
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true
  },
  {
    title: 'Url',
    dataIndex: 'url'
  },
  {
    title: 'Created On',
    dataIndex: 'createdOn',
    sorter: true,
    defaultSortOrder: 'descend',
    render: d => toDateString(d)
  },
  {
    title: 'Updated On',
    dataIndex: 'updatedOn',
    sorter: true,
    render: d => toDateString(d)
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: id => (
      <Fragment>
        <Link to={`/catalog/products/${id}/`}>view</Link>
      </Fragment>
    )
  }
];

const filters = [
  {
    field: 'sku',
    type: 'TEXT'
  },
  {
    field: 'name',
    type: 'TEXT'
  },
  {
    field: 'url',
    type: 'TEXT'
  },
  {
    field: 'Enabled',
    type: 'YESNO'
  }
];

const mapDispatchToProps = {
  searchProduct: productAction.searchProduct,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
