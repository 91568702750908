import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Row, Col, Select, InputNumber, Button, DatePicker, Modal } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import { TierPrices, ProductVariantSelector, parseSelectionCriteria } from 'components';
import moment from 'moment';
import * as productVariantSelector from 'redux/selectors/productVariant';
import * as productVariantActions from 'redux/actions/productVariant';
import * as productSelectors from 'redux/selectors/product';
import * as productActions from 'redux/actions/product';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as customerGroupSelector from 'redux/selectors/customerGroup';
import * as attributeSelectors from 'redux/selectors/attribute';
import * as attributeActions from 'redux/actions/attribute';
import * as homepageActions from 'redux/actions/homepage';
import {
  PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS,
  PRODUCT_VARIANT_UPDATE_PRICES_SUCCESS
} from 'redux/actions/productVariant/action_types';

import styles from './productPriceUpdate.module.less';

const RangePicker = DatePicker.RangePicker;
const confirm = Modal.confirm;

class ProductPriceUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      productId: 0,
      productName: ''
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.findAllCustGrp();
    this.props.loadAllPhoneBrands();
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.confirmChanges();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_VARIANT_UPDATE_PRICES_SUCCESS, action => {
        this.props.setMessages({ successMsg: `Total of ${action.payload.updated} products prices change.` });
        this.props.hideScreenLoader();
        this.props.history.push('/');
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unloadProduct();
    this.props.unloadCustGrp();
    this.props.unloadAttrs();
  }

  confirmChanges = () => {
    confirm({
      title: 'Do you want to update prices?',
      content: `This action will update total of ${this.props.selectionCount} product. Do you want to continue?`,
      onOk: () => {
        this.props.showScreenLoader();
        this.parseFormAndCall(this.props.updatePrices);
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.parseFormAndCall(payload => {
      this.props.showScreenLoader();
      this.props.countSelection(payload.selectionCriteria);
    });
  };

  parseFormAndCall = cb => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values['price'] <= 0 && values['specialPrice'] <= 0 && !values['tierPrices']) {
          this.setState({
            globalErrors: ['Atleast 1 price type should be provided.']
          });
          return;
        }
        let payload = Object.assign({}, values);
        parseSelectionCriteria(payload, this.props.attributes, this.state.productId);
        if (payload['specialPriceRange']) {
          const range = payload['specialPriceRange'];
          delete payload['specialPriceRange'];
          payload['specialPriceFrom'] = range[0]
            .hour(0)
            .minutes(0)
            .seconds(0)
            .toISOString();

          payload['specialPriceTo'] = range[1]
            .hour(0)
            .minutes(0)
            .seconds(0)
            .toISOString();
        }
        this.setState({
          globalErrors: []
        });
        cb(payload);
      }
    });
  };

  handleProductChange = (value, option) => {
    if (value && value.trim() !== '') {
      this.setState({
        productId: value,
        productName: option.children
      });
      this.props.loadAttrs(value);
    }
  };

  disabledSpecialDate = current => {
    return current && current < moment().startOf('day');
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.productPriceUpdate}>
        <h2 className="page-title">
          Update Product Price{this.state.productName ? `: ${this.state.productName}` : ''}
        </h2>
        {this.state.globalErrors.length > 0 && (
          <Alert
            type="error"
            showIcon
            description={
              <ul>
                {this.state.globalErrors.map((e, idx) => (
                  <li key={idx}>{e}</li>
                ))}
              </ul>
            }
          />
        )}
        <Form onSubmit={this.handleSubmit}>
          <ProductVariantSelector
            attributes={this.props.attributes}
            handleProductChange={this.handleProductChange}
            productId={this.state.productId}
            getFieldDecorator={getFieldDecorator}
            form={this.props.form}
            allPhoneBrands={this.props.allPhoneBrands}
            phones={this.props.phones}
            loadPhonesByBrandIds={this.props.loadPhonesByBrandIds}
          />
          {this.state.productId !== 0 && (
            <React.Fragment>
              <h2 className="page-title">Price Information</h2>
              <Row gutter={24}>
                <Col sm={24}>
                  <Form.Item label="Price">
                    {getFieldDecorator('price', {
                      initialValue: 0.0,
                      rules: [
                        {
                          type: 'number',
                          asyncValidator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                              if (value < 0) {
                                reject('Should be greater than or equal 0.0');
                              } else {
                                resolve();
                              }
                            });
                          }
                        }
                      ]
                    })(<InputNumber min={0} precision={2} />)}
                  </Form.Item>
                </Col>
              </Row>
              <h2 className="page-title">Special Price</h2>
              <Row gutter={24}>
                <Col sm={24} md={5}>
                  <Form.Item label="Price">
                    {getFieldDecorator('specialPrice', {
                      initialValue: 0.0,
                      rules: [
                        {
                          type: 'number',
                          asyncValidator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                              if (value < 0) {
                                reject('Should be greater than or equal 0.0');
                              } else {
                                resolve();
                              }
                            });
                          }
                        }
                      ]
                    })(<InputNumber min={0} precision={2} />)}
                  </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                  <Form.Item label="Date Range">
                    {getFieldDecorator('specialPriceRange', {
                      rules: [
                        {
                          required: this.props.form.getFieldValue('specialPrice') > 0,
                          message: 'Required'
                        }
                      ]
                    })(<RangePicker disabledDate={this.disabledSpecialDate} />)}
                  </Form.Item>
                </Col>
                <Col sm={24} md={6}>
                  <Form.Item label="Price Type">
                    {getFieldDecorator('specialPriceType', {
                      rules: [
                        {
                          required: this.props.form.getFieldValue('specialPrice') > 0,
                          message: 'Required'
                        }
                      ]
                    })(
                      <Select>
                        <Select.Option key="SALE">Sale</Select.Option>
                        <Select.Option key="CLEARANCE">Clearance</Select.Option>
                        <Select.Option key="INTRODUCTORY_OFFER">Introductory Offer</Select.Option>
                        <Select.Option key="LIMITED_STOCK">Limited Stock</Select.Option>
                        <Select.Option key="OPTION1">Option 1</Select.Option>
                        <Select.Option key="OPTION2">Option 2</Select.Option>
                        <Select.Option key="OPTION3">Option 3</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <h2 className="page-title">Tier Prices</h2>
              {this.props.allCustGrp.length > 0 && (
                <TierPrices allCustGrp={this.props.allCustGrp} getFieldDecorator={getFieldDecorator} />
              )}
              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadAttrs: productActions.loadAttrs,
  findAllCustGrp: customerGroupActions.findAll,
  loadAllPhoneBrands: attributeActions.loadAllPhoneBrands,
  loadPhonesByBrandIds: attributeActions.loadPhonesByBrandIds,
  unloadProduct: productActions.unload,
  unloadCustGrp: customerGroupActions.unload,
  unloadAttrs: attributeActions.unload,
  countSelection: productVariantActions.countSelection,
  updatePrices: productVariantActions.updatePrices,
  setMessages: homepageActions.setMessages
};

const mapStateToProps = (state, ownProps) => {
  return {
    attributes: productSelectors.getAttrs(state),
    allCustGrp: customerGroupSelector.getAllCustomerGrp(state),
    allPhoneBrands: attributeSelectors.getAllPhoneBrands(state),
    phones: attributeSelectors.getPhones(state),
    selectionCount: productVariantSelector.getSelectionCount(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(Form.create({})(ProductPriceUpdate))));
