import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Row, Col, Modal, Input } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './statusForm.module.less';

class NameUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      name: props.name
    };
    this.subscriptions = [];
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        this.props.hideScreenLoader();
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={true}
        title="Update Name"
        okText="Update"
        onOk={this.handleSubmit}
        onCancel={this.props.onCancel}
        className={styles.statusForm}
        maskClosable={false}
      >
        <Row gutter={24}>
          <Col sm={24} md={24}>
            <Alert type="warning" message="Caution: This name will be overridden if you regenerate descriptions." />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} md={24}>
            <Form.Item label="Variant Name">
              {getFieldDecorator('name', {
                initialValue: this.state.name,
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(<Input.TextArea rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default Form.create({})(NameUpdateForm);
