import React from 'react';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

let wplusEventBus = window.wplusEventBus;
if (wplusEventBus == null) {
  wplusEventBus = window.wplusEventBus = new Subject();
}

export const EventBusContext = React.createContext(wplusEventBus);
export const eventBus = wplusEventBus;
const eventBusSubscribe = (actionType, callback) => {
  let filteredSource = eventBus.pipe(filter(ev => ev.type === actionType));
  return filteredSource.subscribe(callback);
};
export const withEventBus = function(Component) {
  return class extends React.Component {
    render() {
      return (
        <EventBusContext.Consumer>
          {context => <Component eventBusSubscribe={eventBusSubscribe} {...this.props} />}
        </EventBusContext.Consumer>
      );
    }
  };
};
