import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as menuSelectors from 'redux/selectors/menu';
import * as menuActions from 'redux/actions/menu';
import * as categorySelectors from 'redux/selectors/category';
import * as categoryActions from 'redux/actions/category';
import * as attrSelectors from 'redux/selectors/attribute';
import * as attrActions from 'redux/actions/attribute';
import { MenuView, MenuEdit } from './components';
import { MENU_CREATE_UPDATE_SUCCESS, MENU_DELETE_SUCCESS } from 'redux/actions/menu/action_types';
import { Spinner } from 'components';
import { Tree, Row, Col, Button, Modal } from 'antd';
import { withScreenLoader, withEventBus } from 'context';
import styles from './menu.module.less';

const { TreeNode } = Tree;
const { confirm } = Modal;

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      mode: 'view'
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.menuLoad();
      this.props.loadAllCategories();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(MENU_CREATE_UPDATE_SUCCESS, action => {
        this.props.menuLoad();
        this.setState({
          selected: action.payload
        });
        this.toggleMode();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(MENU_DELETE_SUCCESS, action => {
        this.props.menuLoad();
        this.setState({
          selected: null,
          mode: 'view'
        });
        this.props.hideScreenLoader();
      })
    );
  }

  componentWillUnmount() {
    this.props.unloadMenus();
    this.props.unloadCategories();
    this.props.unloadAttr();
  }

  submitForm = values => {
    if (values.id === 0) {
      this.props.create(values);
    } else {
      this.props.update(values);
    }
  };

  uploadImage = formData => {
    this.props.uploadImage(formData);
  };

  deleteMenu = id => {
    confirm({
      title: 'Confirm',
      content: 'Are you sure to delete menu?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.showScreenLoader();
        this.props.deleteMenu(id);
      },
      onCancel() {}
    });
  };

  createNew = () => {
    this.setState({
      selected: {
        id: 0,
        newMarker: true,
        showViewAll: true,
        enabled: true,
        order: 0,
        itemType: ''
      },
      mode: 'edit'
    });
  };

  toggleMode = () => {
    this.setState({
      mode: this.state.mode === 'view' ? 'edit' : 'view'
    });
  };

  renderTreeNode = node => {
    let c = node.menu;
    return (
      <TreeNode title={c.name} key={c.id}>
        {node.children.map(n => this.renderTreeNode(n))}
      </TreeNode>
    );
  };

  generateTreeNodes = nodes => {
    var tree = [];
    nodes.map((item, key) => tree.push(this.renderTreeNode(item)));
    return tree;
  };

  updateSelected = key => {
    let first = key.shift();
    let menuList = [...this.props.menus];
    do {
      let menu = menuList.shift();
      if (menu.menu.id === first) {
        this.setState({
          selected: menu.menu,
          mode: 'view'
        });
        break;
      }
      menuList = menuList.concat(menu.children);
    } while (menuList.length !== 0);
  };

  onNodeClick = key => {
    if (this.state.selected && key[0] === this.state.selected.id) {
      return;
    }
    let updateSelected = this.updateSelected;
    if (this.state.mode === 'edit') {
      confirm({
        title: 'Confirm',
        content: 'Do you want to proceed without saving?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          updateSelected(key);
        },
        onCancel() {}
      });
    } else {
      updateSelected(key);
    }
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      let selected = this.state.selected != null ? this.state.selected : this.props.menus[0].menu;
      content = (
        <Row gutter={24}>
          <Col md={8} lg={6}>
            <div className={styles.treeView}>
              <div className={styles.buttonContainer}>
                <h2 className="page-title">Manage Menus</h2>
                <Button type="primary" size="small" onClick={this.createNew}>
                  Create New
                </Button>
              </div>
              <Tree
                className={styles.tree}
                showLine
                onSelect={this.onNodeClick}
                defaultExpandedKeys={[this.props.menus.menu]}
              >
                {this.generateTreeNodes(this.props.menus)}
              </Tree>
            </div>
          </Col>
          <Col md={16} lg={18}>
            {this.state.mode === 'view' && (
              <MenuView
                key={selected.id}
                deleteMenu={this.deleteMenu}
                eventBusSubscribe={this.props.eventBusSubscribe}
                menu={selected}
                uploadImage={this.uploadImage}
                toggleMode={this.toggleMode}
              />
            )}
            {this.state.mode === 'edit' && (
              <MenuEdit
                key={selected.id}
                menu={selected}
                menus={this.props.menus}
                toggleMode={this.toggleMode}
                eventBusSubscribe={this.props.eventBusSubscribe}
                onSubmit={this.submitForm}
                categories={this.props.categories}
                phoneBrands={this.props.allPhoneBrands}
                phones={this.props.phones}
                loadAllPhoneBrands={this.props.loadAllPhoneBrands}
                loadPhonesByBrandIds={this.props.loadPhonesByBrandIds}
                unload={this.props.unloadAttr}
              />
            )}
          </Col>
        </Row>
      );
    }
    return <div className={styles.category}>{content}</div>;
  }
}

const mapDispatchToProps = {
  menuLoad: menuActions.menuLoad,
  unloadMenus: menuActions.unload,
  deleteMenu: menuActions.deleteMenu,
  update: menuActions.update,
  create: menuActions.create,
  uploadImage: menuActions.uploadImage,
  loadAllCategories: categoryActions.load,
  unloadCategories: categoryActions.unload,
  loadAllPhoneBrands: attrActions.loadAllPhoneBrands,
  loadPhonesByBrandIds: attrActions.loadPhonesByBrandIds,
  unloadAttr: attrActions.unload
};

const mapStateToProps = (state, ownProps) => ({
  isLoaded: menuSelectors.isLoaded(state),
  menus: menuSelectors.getMenus(state),
  isUpdated: menuSelectors.isUpdated(state),
  categories: categorySelectors.getCategories(state),
  allPhoneBrands: attrSelectors.getAllPhoneBrands(state),
  phones: attrSelectors.getPhones(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(Menu)));
