import {
  CUSTOMERS_LOAD,
  CUSTOMERS_LOADED,
  CUSTOMER_VIEW,
  CUSTOMER_VIEW_LOADED,
  CUSTOMER_ACTIVATE,
  CUSTOMER_INACTIVATE,
  CUSTOMER_CREATE,
  CUSTOMER_UPDATE,
  CUSTOMER_CREATE_UPDATE_OK,
  CUSTOMER_UNLOAD
} from './action_types';

const watchSearchCustomers = payload => ({
  type: CUSTOMERS_LOAD,
  payload: payload
});

const searchCustomersResult = payload => ({
  type: CUSTOMERS_LOADED,
  payload: payload
});

const findCustomerById = payload => ({
  type: CUSTOMER_VIEW,
  payload: payload
});

const foundCustomerById = payload => ({
  type: CUSTOMER_VIEW_LOADED,
  payload: payload
});

const customerActivate = id => ({
  type: CUSTOMER_ACTIVATE,
  payload: id
});

const customerInactivate = id => ({
  type: CUSTOMER_INACTIVATE,
  payload: id
});

const customerCreate = payload => ({
  type: CUSTOMER_CREATE,
  payload: payload
});

const customerUpdate = payload => ({
  type: CUSTOMER_UPDATE,
  payload: payload
});

const customerCreateUpdateOk = payload => ({
  type: CUSTOMER_CREATE_UPDATE_OK,
  payload: payload
});

const customerUnload = () => ({
  type: CUSTOMER_UNLOAD,
  payload: {}
});

export {
  watchSearchCustomers,
  searchCustomersResult,
  findCustomerById,
  foundCustomerById,
  customerActivate,
  customerInactivate,
  customerCreateUpdateOk,
  customerCreate,
  customerUpdate,
  customerUnload
};
