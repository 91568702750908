import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, InputNumber, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as deviceGroupService from 'redux/services/deviceGroup';
import { GlobalError } from 'components';
import { handleErrors } from 'Utils/errorHandler';
import { useHistory, useParams } from 'react-router-dom';

export default props => {
  const [form] = Form.useForm();
  const [globalErrors, setGlobalErrors] = useState([]);
  const [image, setImage] = useState(null);
  const history = useHistory();
  const { groupId } = useParams();
  const [deviceGroup, setDeviceGroup] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        let response = await deviceGroupService.find(groupId);
        setDeviceGroup(response);
      } catch (err) {
        handleErrors(err, history, form);
      }
    })();
  }, [form, groupId, history]);

  const handleSubmit = values => {
    (async () => {
      try {
        const formData = new FormData();
        formData.append('id', groupId);
        formData.append('name', values.name);
        formData.append('sortOrder', values.sortOrder);
        if (image !== null) {
          formData.append('image', image);
        }
        let group = await deviceGroupService.update(groupId, formData);
        history.push(`/catalog/deviceGroup/view/${group.id}`);
      } catch (err) {
        setGlobalErrors(handleErrors(err, history, form));
      }
    })();
  };

  const beforeUpload = file => {
    setImage(file);
    return false;
  };

  return (
    <div>
      <h2 className="page-title">Edit Device Group</h2>
      {deviceGroup && (
        <>
          <GlobalError errors={globalErrors} />
          <Form form={form} onFinish={handleSubmit} initialValues={deviceGroup}>
            <Row gutter={24}>
              <Col md={8} sm={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, type: 'string', pattern: /^[a-zA-Z0-9 ]*$/, message: 'Please enter group name.' },
                    {
                      min: 2,
                      message: 'Min of 2 chars required.'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={8} sm={24}>
                <Form.Item
                  name="sortOrder"
                  label="Sort Order"
                  rules={[{ required: true, message: 'Please provide a sort order' }]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col sm={24} md={6}>
                File:{' '}
                <Upload name="image" multiple={false} beforeUpload={beforeUpload}>
                  <Button>
                    <UploadOutlined />
                    Click to upload
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col sm={24}>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};
