import {
  PRODUCT_VARIANT_SEARCH,
  PRODUCT_VARIANT_VIEW,
  PRODUCT_VARIANT_VIEW_LOADED,
  PRODUCT_VARIANT_UNLOAD,
  PRODUCT_VARIANT_SAVE_ALL,
  PRODUCT_VARIANT_SAVE_SUCCESS,
  PRODUCT_VARIANT_SELECTION_COUNT,
  PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS,
  PRODUCT_VARIANT_UPDATE_PRICES,
  PRODUCT_VARIANT_UPDATE_PRICES_SUCCESS,
  PRODUCT_VARIANT_UPDATE_STATUS,
  PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS,
  PRODUCT_VARIANT_UPDATE_BULK_STATUS,
  PRODUCT_VARIANT_UPDATE_BULK_STATUS_SUCCESS,
  PRODUCT_VARIANT_REFRESH_IMAGES,
  PRODUCT_VARIANT_REFRESH_IMAGES_SUCCESS,
  PRODUCT_VARIANT_UPDATE_ATTRIBUTES,
  PRODUCT_VARIANT_UPDATE_NAME
} from './action_types';

const search = payload => ({
  type: PRODUCT_VARIANT_SEARCH,
  payload: payload
});

const load = (productId, variantId) => ({
  type: PRODUCT_VARIANT_VIEW,
  payload: {
    productId: productId,
    variantId: variantId
  }
});

const loaded = payload => ({
  type: PRODUCT_VARIANT_VIEW_LOADED,
  payload: payload
});

const unload = () => ({
  type: PRODUCT_VARIANT_UNLOAD,
  payload: {}
});

const saveVariants = payload => ({
  type: PRODUCT_VARIANT_SAVE_ALL,
  payload: payload
});

const saveSuccess = payload => ({
  type: PRODUCT_VARIANT_SAVE_SUCCESS,
  payload: payload
});

const countSelection = payload => ({
  type: PRODUCT_VARIANT_SELECTION_COUNT,
  payload: payload
});

const countSelectionSuccess = payload => ({
  type: PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS,
  payload: payload
});

const updatePrices = payload => ({
  type: PRODUCT_VARIANT_UPDATE_PRICES,
  payload: payload
});

const updatePricesSuccess = payload => ({
  type: PRODUCT_VARIANT_UPDATE_PRICES_SUCCESS,
  payload: payload
});

const updateStatus = payload => ({
  type: PRODUCT_VARIANT_UPDATE_STATUS,
  payload: payload
});

const updateStatusSuccess = payload => ({
  type: PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS,
  payload: payload
});

const updateBulkStatus = payload => ({
  type: PRODUCT_VARIANT_UPDATE_BULK_STATUS,
  payload: payload
});

const updateBulkStatusSuccess = payload => ({
  type: PRODUCT_VARIANT_UPDATE_BULK_STATUS_SUCCESS,
  payload: payload
});

const refreshImages = payload => ({
  type: PRODUCT_VARIANT_REFRESH_IMAGES,
  payload: payload
});

const refreshImagesSuccess = () => ({
  type: PRODUCT_VARIANT_REFRESH_IMAGES_SUCCESS,
  payload: {}
});

const updateAttributes = (variantId, attrs) => ({
  type: PRODUCT_VARIANT_UPDATE_ATTRIBUTES,
  payload: {
    variantId: variantId,
    attrs: attrs
  }
});

const updateName = (variantId, values) => ({
  type: PRODUCT_VARIANT_UPDATE_NAME,
  payload: {
    variantId: variantId,
    values: values
  }
});

export {
  search,
  load,
  loaded,
  unload,
  saveVariants,
  saveSuccess,
  countSelection,
  countSelectionSuccess,
  updatePrices,
  updatePricesSuccess,
  updateStatus,
  updateStatusSuccess,
  updateBulkStatus,
  updateBulkStatusSuccess,
  refreshImages,
  refreshImagesSuccess,
  updateAttributes,
  updateName
};
