import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, Button, Row, Col, DatePicker, Upload } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './slideForm.less';
import moment from 'moment';

const { Dragger } = Upload;

class SlideForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    const formData = new FormData();
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.id === 0) {
          formData.append('id', '0');
          formData.append('slide', this.state.image);
          formData.append('forwardLink', values.forwardLink);
          formData.append('startDate', values.startDate.format('YYYY-MM-DD'));
          formData.append('endDate', values.endDate.format('YYYY-MM-DD'));
          formData.append('order', values.order);
          formData.append('version', values.version);
          this.props.submitHandle(formData);
        } else {
          values.startDate = values.startDate.format('YYYY-MM-DD');
          values.endDate = values.endDate.format('YYYY-MM-DD');
          this.props.submitHandle(values);
        }
      }
    });
  };

  render() {
    const props = {
      name: 'slide',
      multiple: false,
      onRemove: file => {
        this.setState({
          image: null
        });
      },
      beforeUpload: file => {
        this.setState({
          image: file
        });
        return false;
      }
    };

    var editMode = this.props.data.id !== 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item label="Link To" className={styles.row}>
              {getFieldDecorator('forwardLink', {
                initialValue: this.props.data.forwardLink,
                rules: [{ required: true, message: 'Link is required' }]
              })(<Input placeholder="Link" />)}
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Order" className={styles.row}>
              {getFieldDecorator('order', {
                initialValue: this.props.data.order,
                rules: [{ required: true, message: 'Order is required' }]
              })(<InputNumber placeholder="Order" />)}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Start Date" className={styles.row}>
              {getFieldDecorator('startDate', {
                initialValue: moment(this.props.data.startDate),
                rules: [{ required: true, message: 'Start date is required' }]
              })(<DatePicker placeholder="End Date" />)}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="End Date" className={styles.row}>
              {getFieldDecorator('endDate', {
                initialValue: moment(this.props.data.endDate),
                rules: [{ required: true, message: 'End date is required' }]
              })(<DatePicker placeholder="End Date" />)}
            </Form.Item>
          </Col>
        </Row>
        {this.props.data.id === 0 ? (
          <Row gutter={24}>
            <Col span={24}>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Upload Image. Uploading company product files only</p>
              </Dragger>
            </Col>
          </Row>
        ) : (
          <div></div>
        )}
        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            {!editMode ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'slideForm' })(SlideForm);
