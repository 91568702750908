export const ADDRESS_LOAD = 'ADDRESS_LOAD';
export const ADDRESS_LOADED = 'ADDRESS_LOADED';
export const ADDRESS_UNLOAD = 'ADDRESS_UNLOAD';
export const ADDRESS_LOAD_PROVINCES = 'ADDRESS_LOAD_PROVINCES';
export const ADDRESS_LOADED_PROVINCES = 'ADDRESS_LOADED_PROVINCES';
export const ADDRESS_SAVE = 'ADDRESS_SAVE';
export const ADDRESS_SAVED = 'ADDRESS_SAVED';
export const ADDRESSES_LOAD = 'ADDRESSES_LOAD';
export const ADDRESSES_LOADED = 'ADDRESSES_LOADED';
export const ADDRESS_UPDATE_ESHIPPER_ID = 'ADDRESS_UPDATE_ESHIPPER_ID';
export const ADDRESS_SUBMITTED_OK = 'ADDRESS_SUBMITTED_OK';
