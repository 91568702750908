import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popconfirm, Row, Col, Divider, Descriptions, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { AddressView, Spinner, Navbar } from 'components';
import * as orderSelectors from 'redux/selectors/order';
import * as orderActions from 'redux/actions/order';
import { toPrice, toDateString } from 'Utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withEventBus } from 'context';
import { ORDER_UPDATE_SUCCESS } from 'redux/actions/order/action_types';
import * as queryString from 'query-string';
import { wrapUrlWithAuthToken } from 'Utils/utils';
import style from './orderview.module.less';

class OrderView extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.findOrderByUid(this.props.match.params.id);
    this.subscriptions.push(
      this.props.eventBusSubscribe(ORDER_UPDATE_SUCCESS, action => {
        this.props.findOrderByUid(this.props.match.params.id);
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadProducts = lines => {
    if (lines)
      return lines.map((item, key) => (
        <tr key={key} className={style.row}>
          <td className={style.product}> {key + 1}</td>
          <td className={style.product}> {item.name}</td>
          <td className={style.cell}> {item.sku}</td>
          <td className={style.total}> {item.price}</td>
          <td className={style.number}> {item.qtyOrdered}</td>
          <td className={style.total}> {toPrice(item.subTotal)}</td>
        </tr>
      ));
  };

  generateOrderView = (o, lines) => {
    return (
      <div>
        <Descriptions bordered column={2} size="small">
          <Descriptions.Item label="Customer Name">
            {o.customerFirstName} {o.customerLastName}
          </Descriptions.Item>
          <Descriptions.Item label="Customer Email">{o.customerEmail}</Descriptions.Item>
          <Descriptions.Item label="Order Date Time">{toDateString(o.orderDateTime)}</Descriptions.Item>
          <Descriptions.Item label="Status">{o.status.name}</Descriptions.Item>
          <Descriptions.Item label="Payment Method">{o.paymentMethod.name}</Descriptions.Item>
          <Descriptions.Item label="Shipping Method">{o.shippingMethod.name}</Descriptions.Item>
          <Descriptions.Item label="Weight">{o.weight}</Descriptions.Item>
          <Descriptions.Item label="Coupon Code">{o.couponCode}</Descriptions.Item>
          <Descriptions.Item span={2} label="Shipping Description">
            {o.shippingDescription}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Billing Address">
            <AddressView key={o.billingAddress.id} address={o.billingAddress} />
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Shipping Address">
            <AddressView address={o.shippingAddress} key={o.shippingAddress.id} />
          </Descriptions.Item>

          <Descriptions.Item span={2} label="Notes">
            {o.notes}
          </Descriptions.Item>
          <Descriptions.Item label="Created By">
            {o.createdBy && o.createdBy.name ? o.createdBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Created On">{toDateString(o.createdOn)}</Descriptions.Item>
          <Descriptions.Item label="Updated By">
            {o.updatedBy && o.updatedBy.name ? o.updatedBy.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Updated On">{toDateString(o.updatedOn)}</Descriptions.Item>
        </Descriptions>
        <Row gutter={24}>
          <Col span={24}>
            <table className={style.table}>
              <caption>Status Hsitory</caption>
              <thead>
                <tr className={style.row}>
                  <th className={style.header}>Status</th>
                  <th className={style.header}>Date Time</th>
                  <th className={style.header}>Comment</th>
                  <th className={style.header}>Visible To User</th>
                  <th className={style.header}>User</th>
                </tr>
              </thead>
              <tbody>
                {o.statusHistory.map((item, key) => (
                  <tr key={key} className={style.row}>
                    <td className={style.product}> {item.status}</td>
                    <td className={style.product}> {toDateString(item.dateTime)}</td>
                    <td className={style.product}> {item.comment}</td>
                    <td className={style.product}> {item.visibleToUser ? 'Yes' : 'No'}</td>
                    <td className={style.product}> {item.userId !== null ? item.userId.name : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <table className={style.table}>
              <thead>
                <tr className={style.row}>
                  <th className={style.header}>#</th>
                  <th className={style.header}>PRODUCT NAME</th>
                  <th>SKU</th>
                  <th className={style.header_number}>PRICE</th>
                  <th className={style.header_number}>QTY</th>
                  <th className={style.header_number}>SUBTOTAL</th>
                </tr>
              </thead>
              <tbody>{lines}</tbody>
            </table>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={20} />
          <Col span={4}>
            <table className={style.table}>
              <tbody>
                <tr className={style.row}>
                  <td>Sub Total</td>
                  <td className={style.total}>{toPrice(o.subTotal)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Shipping Amt</td>
                  <td className={style.total}>{toPrice(o.shippingAmt)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Tax</td>
                  <td className={style.total}>{toPrice(o.taxes)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Discount</td>
                  <td className={style.total}>{toPrice(o.discountAmt)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Shipping Disc</td>
                  <td className={style.total}>{toPrice(o.shippingDisc)}</td>
                </tr>
                <tr className={style.row}>
                  <td>Total</td>
                  <td className={style.total}>{toPrice(o.total)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    let order = this.props.order;
    let lines = this.loadProducts(order.items);
    let content;
    if (order.code) {
      let statusId = order.status.id;
      content = (
        <div>
          <Navbar title={order.code}>
            {!this.props.showArchive && (
              <>
                {statusId === 'ON_HOLD' && (
                  <Popconfirm title="Sure to cancel hold?" onConfirm={() => this.props.cancelHold(order.id)}>
                    <Tooltip placement="top" title="Cancel Hold">
                      <FontAwesomeIcon icon="angle-double-right" />
                    </Tooltip>
                  </Popconfirm>
                )}
                {statusId === 'PENDING' && (
                  <Popconfirm title="Sure to put on hold?" onConfirm={() => this.props.holdOrder(order.id)}>
                    <Tooltip placement="top" title="Hold Order">
                      <FontAwesomeIcon icon="hand-paper" />
                    </Tooltip>
                  </Popconfirm>
                )}
                {statusId !== 'CANCELLED' && statusId !== 'COMPLETED' && statusId !== 'SHIPPED' && (
                  <Popconfirm title="Sure to cancel?" onConfirm={() => this.props.orderCancel(order.id)}>
                    <Tooltip placement="top" title="Cancel Order">
                      <FontAwesomeIcon icon="ban" />
                    </Tooltip>
                  </Popconfirm>
                )}
              </>
            )}
            <Link type="primary" to={`/sales/orders/?showArchive=${this.props.showArchive}`}>
              <Tooltip placement="top" title="List Orders">
                <FontAwesomeIcon icon="th-list" />
              </Tooltip>
            </Link>
            <a
              href={wrapUrlWithAuthToken(`/api/v1/order/print/?id=${order.id}`)}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="-1"
            >
              <Tooltip placement="top" title="Print Order">
                <FontAwesomeIcon icon="print" />
              </Tooltip>
            </a>
          </Navbar>
          {this.generateOrderView(order, lines)}
        </div>
      );
    } else {
      content = <Spinner />;
    }
    return <div id="print-mount">{content}</div>;
  }
}

const mapDispatchToProps = {
  findOrderByUid: orderActions.watchFindOne,
  orderCancel: orderActions.orderCancel,
  cancelHold: orderActions.cancelHold,
  holdOrder: orderActions.holdOrder
};

const mapStateToProps = (state, ownProps) => ({
  order: orderSelectors.get(state),
  showArchive: queryString.parse(ownProps.location.search).showArchive === 'true'
});

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(OrderView));
