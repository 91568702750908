import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Divider, Select } from 'antd';
import { DisplayField } from 'components';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './empForm.module.less';

class EmpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.submitHandle(values);
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  render() {
    var editMode = this.props.data.id !== 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="First name" className={styles.row}>
              {getFieldDecorator('firstName', {
                initialValue: this.props.data.firstName,
                rules: [{ required: true, message: 'First Name is required' }]
              })(<Input placeholder="First Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Last name" className={styles.row}>
              {getFieldDecorator('lastName', {
                initialValue: this.props.data.lastName,
                rules: [{ required: true, message: 'Last name is required' }]
              })(<Input placeholder="Last Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Role" className={styles.row}>
              {getFieldDecorator('roleId', {
                initialValue: this.props.data.role.id,
                rules: [{ required: true, message: 'Select a Role' }]
              })(
                <Select placeholder="Role">
                  {this.props.roles.map(p => (
                    <Select.Option key={p} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Email" className={styles.row}>
              {getFieldDecorator('email', {
                initialValue: this.props.data.email,
                rules: [
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'This is not valid E-mail!' }
                ]
              })(<Input placeholder="Email" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            SIGN-IN INFORMATION
            <Divider />
          </Col>
        </Row>
        {!editMode ? (
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="User name" className={styles.row}>
                {getFieldDecorator('username', {
                  initialValue: this.props.data.username,
                  rules: [{ required: true, message: 'Email is required' }]
                })(<Input placeholder="User Name" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Password" className={styles.row}>
                {getFieldDecorator('password', {
                  initialValue: this.props.data.password,
                  rules: [
                    {
                      required: true,
                      message: 'Please re-confirm your password!'
                    }
                  ]
                })(<Input.Password placeholder="Password" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Confirm Password" className={styles.row}>
                {getFieldDecorator('confirmPassword', {
                  initialValue: this.props.data.confirmPassword,
                  rules: [
                    {
                      required: true,
                      message: 'Please re-confirm your password!'
                    },
                    {
                      validator: this.compareToFirstPassword
                    }
                  ]
                })(<Input.Password placeholder="Confirm Password" />)}
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={24}>
            <Col span={8}>
              <DisplayField label="User Name" value={this.props.data.username} />
            </Col>
            <Col span={8}>
              <DisplayField label="Status" value={this.props.data.status.name} />
            </Col>
          </Row>
        )}

        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            {!editMode ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'empForm' })(EmpForm);
