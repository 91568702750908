import { fetchApi } from 'Utils/utils';


let createCustomer = function(params) {
  return fetchApi.post('/api/v1/customer/', params);
};

let updateCustomer = function(params) {
  return fetchApi.put(`/api/v1/customer/${params.id}/`, params);
};

let searchCustomer = function(params) {
  return fetchApi.post('/api/v1/customer/search/', params);
};

let findCustomerById = function(id) {
  return fetchApi.get(`/api/v1/customer/${id}/`);
};

let updateToActive = function(id) {
  return fetchApi.put(`/api/v1/customer/${id}/activate/`);
};

let updateToInactive = function(id) {
  return fetchApi.put(`/api/v1/customer/${id}/inactivate/`);
};

export { createCustomer,updateCustomer,searchCustomer, findCustomerById, updateToActive, updateToInactive }
