import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider, Descriptions, Row, Col } from 'antd';
import { Spinner, Navbar, ImageUploader } from 'components';
import * as phoneBrandSelector from 'redux/selectors/phoneBrand';
import * as userSelector from 'redux/selectors/user';
import * as phoneBrandActions from 'redux/actions/phoneBrand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PHONE_BRAND_CREATE_UPDATE_SUCCESS } from 'redux/actions/phoneBrand/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './phoneBrandView.module.less';

class PhoneBrandView extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.find(this.props.match.params.id);
    this.subscriptions.push(
      this.props.eventBusSubscribe(PHONE_BRAND_CREATE_UPDATE_SUCCESS, action => {
        this.props.find(this.props.match.params.id);
      })
    );
  }

  componentWillUnmount() {
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  uploadImage = data => {
    this.props.uploadImage(data);
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      var c = this.props.phoneBrand;
      content = (
        <Row gutter={24}>
          <Col span={8}>
            <ImageUploader
              key={c.id}
              id={c.id}
              data={c.asset}
              uploadImage={this.uploadImage}
              eventBusSubscribe={this.props.eventBusSubscribe}
            ></ImageUploader>
          </Col>
          <Col span={16}>
            <Descriptions column={2} bordered size="small">
              <Descriptions.Item label="Name">{c.name}</Descriptions.Item>
              <Descriptions.Item label="Short name">{c.shortName}</Descriptions.Item>
              <Descriptions.Item label="Url">{c.url}</Descriptions.Item>
              <Descriptions.Item label="Discontinued">{c.discontinued ? 'Yes' : 'No'}</Descriptions.Item>
              <Descriptions.Item label="Created By">
                {c.createdBy && c.createdBy.name ? c.createdBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Created On">{c.createdOn}</Descriptions.Item>
              <Descriptions.Item label="Updated By">
                {c.updatedBy && c.updatedBy.name ? c.updatedBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Updated On">{c.updatedOn}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      );
    }

    return (
      <div className={styles.container}>
        <Navbar title={this.props.phoneBrand.name}>
          {!this.props.isUserContentCreator && (
            <Fragment>
              <Link type="primary" shape="circle" to={'/catalog/phoneBrand/create/'}>
                <Tooltip placement="rightTop" title="Create Phone">
                  <FontAwesomeIcon icon="plus-square" />
                </Tooltip>
              </Link>
              <Divider type="vertical" />
              <Link type="primary" to={`/catalog/phoneBrand/edit/${this.props.match.params.id}/`}>
                <Tooltip placement="rightTop" title="Edit Phone">
                  <FontAwesomeIcon icon="edit" />
                </Tooltip>
              </Link>
              <Divider type="vertical" />
            </Fragment>
          )}
          <Link type="primary" to={'/catalog/phoneBrand/'}>
            <Tooltip placement="rightTop" title="List Phone">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = {
  find: phoneBrandActions.find,
  uploadImage: phoneBrandActions.uploadImage,
  unload: phoneBrandActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: phoneBrandSelector.isLoaded(state),
    phoneBrand: phoneBrandSelector.get(state),
    isUserContentCreator: userSelector.isUserContentCreator(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(PhoneBrandView));
