import { fetchApi } from 'Utils/utils';

let findById = function(id) {
  return fetchApi.get(`/api/v1/customerGroup/${id}/`);
};

let findAll = function() {
  return fetchApi.get('/api/v1/customerGroup/all/');
};

export { findById, findAll }
