import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import * as phoneBrandSelector from 'redux/selectors/phoneBrand';
import * as phoneBrandActions from 'redux/actions/phoneBrand';
import { PHONE_BRAND_CREATE_UPDATE_SUCCESS } from 'redux/actions/phoneBrand/action_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar } from 'components';
import PhoneBrandForm from '../Form/phoneBrandForm';
import { withEventBus } from 'context/eventbus';
import styles from './phoneBrand.module.less';

class PhoneBrandAdd extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(PHONE_BRAND_CREATE_UPDATE_SUCCESS, action => {
        this.props.history.push(`/catalog/phoneBrand/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.create(formData);
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    var phone = {
      id: 0,
      version: 0,
      discontinued: false
    };
    return (
      <div className={styles.container}>
        <Navbar title={'Create Phone'}>
          <Link type="primary" to={'/catalog/phone/'}>
            <Tooltip placement="top" title="List Phone">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>
          <PhoneBrandForm
            key={0}
            submitHandle={this.submitHandle}
            eventBusSubscribe={this.props.eventBusSubscribe}
            data={phone}
          ></PhoneBrandForm>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  create: phoneBrandActions.create,
  unload: phoneBrandActions.unload,
  fianAllBrands: phoneBrandActions.findAll
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: phoneBrandSelector.isLoaded(state),
    phone: phoneBrandSelector.get(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(PhoneBrandAdd));
