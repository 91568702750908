import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
  ADDRESS_LOAD,
  ADDRESS_LOAD_PROVINCES,
  ADDRESS_SAVE,
  ADDRESSES_LOAD,
  ADDRESS_UPDATE_ESHIPPER_ID
} from 'redux/actions/address/action_types';
import * as addressService from 'redux/services/address';
import * as userSelectors from 'redux/selectors/user';
import * as addressActions from 'redux/actions/address';
import * as addressSelectors from 'redux/selectors/address';
import * as listActions from 'redux/actions/list';
import * as apiErrorActions from 'redux/actions/apiError';

function* fetchAddresses() {
  yield takeLatest(ADDRESS_LOAD, function*(action) {
    let customerId = yield select(userSelectors.getUserId);
    let addresses = yield call(addressService.fetchAddresses, customerId);
    yield put(addressActions.loaded(addresses));
  });
}

function* fetchProvinces() {
  yield takeLatest(ADDRESS_LOAD_PROVINCES, function*(action) {
    let provinces = yield select(addressSelectors.getProvinces);
    if (provinces.length === 0) {
      provinces = yield call(addressService.fetchProvinces);
    }
    yield put(addressActions.loadedProvinces(provinces));
  });
}

function* saveAddress() {
  yield takeLatest(ADDRESS_SAVE, function*(action) {
    let customerId = yield select(userSelectors.getUserId);
    let addresses = yield call(addressService.save, Object.assign({}, action.payload, { customerId: customerId }));
    yield put(addressActions.saved(addresses));
  });
}

function* searchAddresses() {
  yield takeLatest(ADDRESSES_LOAD, function*(action) {
    try {
      let addresses = yield call(addressService.searchAddresses, action.payload);
      yield put(listActions.listLoaded(addresses));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* updateEShipperId() {
  yield takeLatest(ADDRESS_UPDATE_ESHIPPER_ID, function*(action) {
    try {
      yield call(addressService.updateEShipperId, action.payload);
      yield put(addressActions.submittedOk());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* addressSagas() {
  yield all([fetchAddresses(), fetchProvinces(), saveAddress(), searchAddresses(), updateEShipperId()]);
}
