import { fetchApi } from 'Utils/utils';

const URL_PREFIX = '/api/v1/reports/customerOrder';

const customerOrderCountReport = function(params) {
  return fetchApi.post(`${URL_PREFIX}/count/`, params);
};

const customerOrderDetailedReport = function(params) {
  return fetchApi.post(`${URL_PREFIX}/details/`, params);
};

const findRecentOrders = function(customerId, eShipperId) {
  return fetchApi.get(`${URL_PREFIX}/recentOrders/${customerId}/${eShipperId}/`);
};

export { customerOrderCountReport, customerOrderDetailedReport, findRecentOrders };
