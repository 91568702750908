const getStore = store => store.attribute || {};
export const foundOne = store =>  {
    return getStore(store).attribute
};
export const isLoaded = store => {
    let attribute = getStore(store);
    return attribute.isLoaded != null && attribute.isLoaded;
};
export const getAll = store => getStore(store).all;
export const getAllPhoneBrands = store => getStore(store).allPhoneBrands;
export const getPhones = store => getStore(store).phones;
