export const PHONE_LOAD = 'PHONE_LOAD';
export const PHONE_LOADED = 'PHONE_LOADED';
export const PHONE_SEARCH = 'PHONE_SEARCH';
export const PHONE_IMAGE_SMALL_UPLOAD = 'PHONE_IMAGE_SMALL_UPLOAD';
export const PHONE_IMAGE_LARGE_UPLOAD = 'PHONE_IMAGE_LARGE_UPLOAD';
export const PHONE_UNLOADED = 'PHONE_UNLOADED';
export const PHONE_UPDATE = 'PHONE_UPDATE';
export const PHONE_CREATE = 'PHONE_CREATE';
export const PHONE_CREATE_UPDATE_SUCCESS = 'PHONE_CREATE_UPDATE_SUCCESS';
export const PHONE_LOAD_ALL = 'PHONE_LOAD_ALL';
export const PHONE_LOADED_ALL = 'PHONE_LOADED_ALL';
