import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  SLIDER_SEARCH,
  SLIDER_VIEW,
  SLIDER_CREATE,
  SLIDER_UPDATE,
  SLIDER_DELETE
} from '../actions/imageSlider/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as imageSliderService from '../services/imageSlider';
import * as imageSliderActions from 'redux/actions/imageSlider';
import * as listActions from 'redux/actions/list';

function* searchSlides() {
  yield takeLatest(SLIDER_SEARCH, function*(action) {
    try {
      let slides = yield call(imageSliderService.search, action.payload);
      yield put(listActions.listLoaded(slides));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findById() {
  yield takeLatest(SLIDER_VIEW, function*(action) {
    try {
      let slide = yield call(imageSliderService.find, action.payload);
      yield put(imageSliderActions.loaded(slide));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* saveSlide() {
  yield takeLatest(SLIDER_CREATE, function*(action) {
    try {
      let slide = yield call(imageSliderService.create, action.payload);
      yield put(imageSliderActions.slideCreateSuccess(slide));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateSlide() {
  yield takeLatest(SLIDER_UPDATE, function*(action) {
    try {
      let slide = yield call(imageSliderService.update, action.payload);
      yield put(imageSliderActions.slideCreateSuccess(slide));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* deleteSlide() {
  yield takeLatest(SLIDER_DELETE, function*(action) {
    try {
      yield call(imageSliderService.deleteSlide, action.payload);
      yield put(imageSliderActions.deleteSlideSuccess());
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* imageSliderSagas() {
  yield all([searchSlides(), findById(), saveSlide(), updateSlide(), deleteSlide()]);
}
