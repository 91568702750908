import React, { useState } from 'react';
import { Button, Form, Input, Row, Col, InputNumber, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as deviceGroupService from 'redux/services/deviceGroup';
import { GlobalError } from 'components';
import { handleErrors } from 'Utils/errorHandler';
import { useHistory } from 'react-router-dom';

export default props => {
  const [form] = Form.useForm();
  const [globalErrors, setGlobalErrors] = useState([]);
  const [image, setImage] = useState(null);
  const history = useHistory();

  const handleSubmit = values => {
    (async () => {
      try {
        if (image === null) {
          Modal.error({
            title: 'Image Upload',
            content: 'Please provide an image.'
          });
          return;
        }
        const formData = new FormData();
        formData.append('id', 0);
        formData.append('name', values.name);
        formData.append('sortOrder', values.sortOrder);
        formData.append('image', image);
        let deviceGroup = await deviceGroupService.create(formData);
        history.push(`/catalog/deviceGroup/view/${deviceGroup.id}`);
      } catch (err) {
        setGlobalErrors(handleErrors(err, history, form));
      }
    })();
  };

  const beforeUpload = file => {
    setImage(file);
    return false;
  };

  return (
    <div>
      <h2 className="page-title">Add Device Group</h2>
      <GlobalError errors={globalErrors} />
      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col md={8} sm={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, type: 'string', pattern: /^[a-zA-Z0-9 ]*$/, message: 'Please enter group name.' },
                {
                  min: 2,
                  message: 'Min of 2 chars required.'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={8} sm={24}>
            <Form.Item
              name="sortOrder"
              label="Sort Order"
              rules={[{ required: true, message: 'Please provide a sort order' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24} md={6}>
            File:{' '}
            <Upload name="image" multiple={false} beforeUpload={beforeUpload}>
              <Button>
                <UploadOutlined />
                Click to upload
              </Button>
            </Upload>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
