// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Space, Popconfirm, Button, Row, Col, Tooltip, Modal } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as deviceGroupActions from 'redux/actions/deviceGroup';
import * as deviceGroupService from 'redux/services/deviceGroup';
import * as listActions from 'redux/actions/list';
import * as userSelector from 'redux/selectors/user';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toDateString } from 'Utils/utils';
import style from './listDeviceGroup.module.less';

const Fragment = React.Fragment;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyCount: 0
    };
  }

  componentWillUnmount() {
    this.props.unload();
  }

  deleteGroup = id => {
    (async () => {
      try {
        await deviceGroupService.remove(id);
        this.setState({
          keyCount: this.state.keyCount + 1
        });
      } catch (err) {
        Modal.error({
          title: 'Error',
          content: 'Device group is still in use.'
        });
      }
    })();
  };

  render() {
    let tableAction = (
      <Fragment>
        <Link type="primary" to={'/catalog/deviceGroup/create/'}>
          <Tooltip placement="rightTop" title="Create Device Group">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Link>
      </Fragment>
    );

    return (
      <div className={style.container}>
        <Row>
          <Col span={24} className={style.accountInfoButtonsContainer}>
            <WrapperTable
              title="Device Group List"
              key={this.state.keyCount}
              filters={filters}
              columns={this.columns}
              tableAction={tableAction}
              data={this.props.listData}
              api={this.props.searchdeviceGroups}
            />
          </Col>
        </Row>
      </div>
    );
  }
  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'DESC',
      sorter: true
    },
    {
      title: 'Sort Order',
      dataIndex: 'sortOrder',
      sorter: true
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      sorter: true,
      render: r => toDateString(r)
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedOn',
      sorter: true,
      render: r => toDateString(r)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <Space>
          <Link to={`/catalog/deviceGroup/view/${id}/`}>view</Link>
          <Link to={`/catalog/deviceGroup/edit/${id}/`}>edit</Link>
          <Popconfirm
            title="Do you want to delete this group?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.deleteGroup(id)}
          >
            <Button type="link">delete</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];
}

const filters = [
  {
    field: 'name',
    type: 'TEXT'
  }
];

const mapDispatchToProps = {
  searchdeviceGroups: deviceGroupActions.search,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state),
    isUserContentCreator: userSelector.isUserContentCreator(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
