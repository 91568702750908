import { CATEGORY_LOADED, CATEGORY_UNLOAD } from 'redux/actions/category/action_types';

const initialStore = {
  loaded: false,
  categories: {}
};

const loaded = (state, categories) =>
  Object.assign({}, state, {
    loaded: true,
    categories: categories
  });

export default function userReducer(state = initialStore, action) {
  switch (action.type) {
    case CATEGORY_LOADED:
      return loaded(state, action.payload);
    case CATEGORY_UNLOAD:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
