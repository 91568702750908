import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from 'components';
import { Alert, Popconfirm, Space, Table, Button, Row, Col } from 'antd';
import { handleErrors } from 'Utils/errorHandler';
import * as storeGroupService from 'redux/services/storeGroup';

export default props => {
  const [storeGroup, setStoreGroup] = useState({});
  const [count, setCount] = useState(0);
  const [globalErrors, setGlobalErrors] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const history = useHistory();
  const { groupId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        let storeGroup = await storeGroupService.find(groupId);
        setStoreGroup(storeGroup);
      } catch (err) {
        setGlobalErrors(handleErrors(err, history));
      }
    })();
  }, [count, groupId, history]);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Do you want to remove this customer?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => removeCustomer(record.id)}
          >
            <Button type="link">Remove</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  const removeCustomer = custId => {
    (async () => {
      if (custId) {
        try {
          await storeGroupService.removeCustomer(groupId, custId);
          setCount(count + 1);
        } catch (err) {
          setGlobalErrors(handleErrors(err, history));
        }
      }
    })();
  };

  const addCustomer = () => {
    (async () => {
      if (customerId) {
        try {
          await storeGroupService.addCustomer({
            name: storeGroup.name,
            customerId: customerId,
            id: groupId
          });
          setCustomerId(null);
          setCount(count + 1);
        } catch (err) {
          setGlobalErrors(handleErrors(err, history));
        }
      }
    })();
  };

  return (
    <div>
      <h2 className="page-title">View Store Group: {storeGroup.name}</h2>
      {globalErrors.length > 0 && (
        <Alert
          type="error"
          showIcon
          description={
            <ul>
              {globalErrors.map((e, idx) => (
                <li key={idx}>{e}</li>
              ))}
            </ul>
          }
        />
      )}
      <Row gutter={24} style={{ marginTop: '24px' }}>
        <Col md={8} sm={24}>
          <Autocomplete.Customer onChange={setCustomerId} />
        </Col>
        <Col md={8} sm={24}>
          <Button type="primary" onClick={addCustomer}>
            Add Customer
          </Button>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '24px' }}>
        <Col sm={24}>
          <Table key={count} dataSource={storeGroup.customerIds} columns={columns} rowKey="id" />
        </Col>
      </Row>
    </div>
  );
};
