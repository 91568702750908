import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Input, InputNumber, Alert } from 'antd';
import { ProductVariantSelector, parseSelectionCriteria } from 'components';
import { withEventBus, withScreenLoader } from 'context';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { PRICE_OVERRIDE_UPDATE_SUCCESS, PRICE_OVERRIDE_LOADED } from 'redux/actions/priceOverride/action_types';
import { ERROR_422 } from 'redux/actions/apiError/action_types';

class PriceOverrideForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      productId: 0,
      productName: ''
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.loadAllPhoneBrands();
    if (this.props.priceId !== 0) {
      this.props.showScreenLoader();
      this.props.loadPriceOverride(this.props.priceId);
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
        this.props.hideScreenLoader();
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRICE_OVERRIDE_UPDATE_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.props.setMessages(['Price override created successfully.']);
        this.props.history.push(`/marketing/priceOverride/customer/${this.props.customerId}/`);
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRICE_OVERRIDE_LOADED, action => {
        this.props.hideScreenLoader();
        this.setState({
          productId: action.payload.productId,
          productName: action.payload.productName
        });
        this.props.loadAttrs(action.payload.productId);
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unloadProduct();
    this.props.unloadAttrs();
  }

  handleProductChange = (value, option) => {
    if (value && value.trim() !== '') {
      this.setState({
        productId: value,
        productName: option.children
      });
      this.props.loadAttrs(value);
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = Object.assign({}, values);
        if (payload.price <= 0) {
          this.setState({
            globalErrors: ['Price should be greater than 0.0']
          });
          return;
        } else {
          this.setState({
            globalErrors: []
          });
        }
        parseSelectionCriteria(payload, this.props.attributes, this.state.productId);
        this.props.showScreenLoader();
        this.props.update(payload);
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const createMode = this.props.priceId === 0;
    const priceOverride = this.props.priceOverride;
    return (
      <div>
        <h2 className="page-title">
          {createMode ? 'Add Price Override: ' : 'Edit Price Override: '} {this.state.productName}
        </h2>
        {this.state.globalErrors.length > 0 && (
          <Alert
            type="error"
            showIcon
            description={
              <ul>
                {this.state.globalErrors.map((e, idx) => (
                  <li key={idx}>{e}</li>
                ))}
              </ul>
            }
          />
        )}
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: this.props.priceId
          })(<Input type="hidden" />)}
          {getFieldDecorator('customerId', {
            initialValue: this.props.customerId
          })(<Input type="hidden" />)}
          <ProductVariantSelector
            attributes={this.props.attributes}
            handleProductChange={this.handleProductChange}
            productId={this.state.productId}
            getFieldDecorator={getFieldDecorator}
            form={this.props.form}
            allPhoneBrands={this.props.allPhoneBrands}
            phones={this.props.phones}
            loadPhonesByBrandIds={this.props.loadPhonesByBrandIds}
            disableVariantNumber={true}
            attributeValues={this.props.priceOverride.attributes}
            initialPhoneBrands={this.props.priceOverride.phoneBrands}
          />
          {this.state.productId !== 0 && (
            <React.Fragment>
              <Row gutter={16}>
                <Col sm={24} md={6}>
                  <Form.Item label="Price">
                    {getFieldDecorator('price', {
                      initialValue: priceOverride.price,
                      rules: [
                        {
                          required: true,
                          message: 'Price is required.'
                        }
                      ]
                    })(<InputNumber min={0} precision={2} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {createMode ? 'Create' : 'Update'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Form>
      </div>
    );
  }
}

export default withScreenLoader(withEventBus(Form.create({})(PriceOverrideForm)));
