import Add from './Add/addDeviceGroup';
import List from './List/listDeviceGroup';
import View from './View/viewDeviceGroup';
import Edit from './Edit/editDeviceGroup';

export default {
  Add: Add,
  Edit: Edit,
  View: View,
  List: List
};
