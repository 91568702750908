import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Modal, Input, Descriptions } from 'antd';

class UpdateCustomerId extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const address = this.props.address;
    return (
      <Modal
        visible={this.props.visible}
        destroyOnClose={true}
        onCancel={this.props.handleClose}
        title="Update Customer Id"
        okText="Update"
        onOk={this.props.handleSubmit}
      >
        <Form>
          {address && (
            <Row gutter={24}>
              <Col sm={24} md={24}>
                <Descriptions bordered size="small" column={1}>
                  <Descriptions.Item label="Name">{address.name}</Descriptions.Item>
                  <Descriptions.Item label="Company">{address.company}</Descriptions.Item>
                  <Descriptions.Item label="Street 1">{address.street1}</Descriptions.Item>
                  <Descriptions.Item label="Street 2">{address.street2}</Descriptions.Item>
                  <Descriptions.Item label="City">{address.city}</Descriptions.Item>
                  <Descriptions.Item label="Province">{address.province}</Descriptions.Item>
                  <Descriptions.Item label="Postal Code">{address.postalCode}</Descriptions.Item>
                  <Descriptions.Item label="Phone No">{address.phoneNo}</Descriptions.Item>
                </Descriptions>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item label="Customer Id">
                  {getFieldDecorator('eShipperId', {
                    initialValue: address.eShipperId,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Customer ID is required.'
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create({})(UpdateCustomerId);
