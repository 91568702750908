import { PAGE_LOADED, PAGE_UNLOADED, PAGE_CREATE_UPDATE_SUCCESS } from 'redux/actions/staticPages/action_types';

const initialStore = {
  loaded: false,
  updated: false,
  pages: []
};

const loaded = (state, pages) =>
  Object.assign({}, state, {
    loaded: true,
    pages: pages
  });

export default function pagesReducer(state = initialStore, action) {
  switch (action.type) {
    case PAGE_LOADED:
      return loaded(state, action.payload);
    case PAGE_CREATE_UPDATE_SUCCESS:
      return loaded(state, []);
    case PAGE_UNLOADED:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
