import {
  MENU_LOADED,
  MENU_UNLOADED
} from 'redux/actions/menu/action_types';

const initialStore = {
  loaded: false,
  updated: false,
  menus: []
};

const loaded = (state, menus) =>
  Object.assign({}, state, {
    loaded: true,
    menus: menus
  });

export default function menuReducer(state = initialStore, action) {
  switch (action.type) {
    case MENU_LOADED:
      return loaded(state, action.payload);
    case MENU_UNLOADED:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}
