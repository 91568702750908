import React, { Component } from 'react';
import Autocomplete from '../Autocomplete';

class Product extends Component {
  render() {
    return (
      <Autocomplete
        searchUrl="/api/v1/product/autoComplete/"
        placeholder="Search Product"
        onChange={this.props.onChange}
        disabled={this.props.disabled}
      />
    );
  }
}

export default Product;
