import { fetchApi } from 'Utils/utils';

let fetchAll = function() {
  return fetchApi.get('/api/v1/menuItem/all/');
};

let updateMenu = function(payload) {
  return fetchApi.put(`/api/v1/menuItem/${payload.id}/`, payload);
};

let deleteMenu = function(payload) {
  return fetchApi.delete(`/api/v1/menuItem/${payload}/`);
};

let createMenu = function(payload) {
  return fetchApi.post('/api/v1/menuItem/', payload);
};

let uploadImage = function(payload) {
  return fetchApi.fileUpload('/api/v1/menuItem/upload-asset/', payload);
};

export { fetchAll, updateMenu, createMenu,deleteMenu, uploadImage };
