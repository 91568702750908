import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import * as phoneSelector from 'redux/selectors/phone';
import * as phoneActions from 'redux/actions/phone';
import * as phoneBrandSelector from 'redux/selectors/phoneBrand';
import * as phoneBrandActions from 'redux/actions/phoneBrand';
import { PHONE_CREATE_UPDATE_SUCCESS } from 'redux/actions/phone/action_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner, Navbar } from 'components';
import PhoneForm from '../Form/phoneForm';
import { withEventBus } from 'context/eventbus';
import styles from './phoneAdd.module.less';

class PhoneAdd extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.fianAllBrands();
    this.subscriptions.push(
      this.props.eventBusSubscribe(PHONE_CREATE_UPDATE_SUCCESS, action => {
        this.props.history.push(`/catalog/phone/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.create(formData);
  };

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  render() {
    let content;
    var phone = {
      id: 0,
      version: 0,
      discontinued: false,
      latest: true
    };
    if (!this.props.isAllBrandLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <PhoneForm
          key={0}
          brands={this.props.allBrands}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={phone}
        ></PhoneForm>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={'Create Phone'}>
          <Link type="primary" to={'/catalog/phone/'}>
            <Tooltip placement="top" title="List Phone">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  create: phoneActions.create,
  unload: phoneActions.unload,
  fianAllBrands: phoneBrandActions.findAll
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: phoneSelector.isLoaded(state),
    phone: phoneSelector.get(state),
    allBrands: phoneBrandSelector.getAll(state),
    isAllBrandLoaded: phoneBrandSelector.isAllLoaded(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(PhoneAdd));
