import { fetchApi } from 'Utils/utils';

let fetchAll = function() {
  return fetchApi.get('/api/v1/category/all/');
};

let update = function(cat) {
  if (cat.id === 0) {
    return fetchApi.post('/api/v1/category/', cat);
  } else {
    return fetchApi.put(`/api/v1/category/${cat.id}/`, cat);
  }
};

let uploadImage = function(payload) {
  return fetchApi.fileUpload('/api/v1/category/upload-asset/', payload);
};

export { fetchAll, update, uploadImage };
