import React, { Component } from 'react';
import _ from 'lodash';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, InputNumber, Switch, Select } from 'antd';

class SelectedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 8
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.save(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          {getFieldDecorator('id', {
            initialValue: 0
          })(<Input type="hidden" />)}
          {getFieldDecorator('type', {
            initialValue: 'SELECTED_PRODUCTS'
          })(<Input type="hidden" />)}
          <Row gutter={24}>
            <Col sm={24} md={8}>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'Title is required.'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="Display Title">
                {getFieldDecorator('displayTitle', {
                  valuePropName: 'checked',
                  initialValue: true,
                  rules: [
                    {
                      required: true,
                      message: 'Display Title is required.'
                    }
                  ]
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="Order">
                {getFieldDecorator('order', {
                  rules: [
                    {
                      required: true,
                      message: 'Order is required.'
                    }
                  ]
                })(<InputNumber min={0} precision={0} />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item label="Limit">
                {getFieldDecorator('attributes.limit', {
                  initialValue: this.state.limit,
                  rules: [
                    {
                      required: true,
                      message: 'Limit is required.'
                    }
                  ]
                })(
                  <Select onChange={val => this.setState({ limit: val })}>
                    <Select.Option key="4">4</Select.Option>
                    <Select.Option key="8">8</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            {_.range(this.state.limit).map(idx => (
              <Col sm={24} md={6} key={idx}>
                <Form.Item label={`Variant Number: ${idx + 1}`}>
                  {getFieldDecorator(`attributes.variantNumbers.${idx}`, {
                    rules: [
                      {
                        required: true,
                        message: 'Variant Number is required.'
                      }
                    ]
                  })(<InputNumber min={1} precision={0} />)}
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create()(SelectedProducts);
