import { HOMEPAGE_WIDGETS_LOADED, HOMEPAGE_WIDGETS_UNLOAD } from 'redux/actions/homepage/widgets/action_types';

const initialStore = {
  loaded: false,
  widget: {}
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    widget: payload
  });

const unload = (state, payload) => Object.assign({}, state, initialStore);

export default function widgetReducer(state = initialStore, action) {
  switch (action.type) {
    case HOMEPAGE_WIDGETS_LOADED:
      return load(state, action.payload);
    case HOMEPAGE_WIDGETS_UNLOAD:
      return unload(state, action.payload);
    default:
      return state;
  }
}
