import {
  PRODUCT_VARIANT_VIEW_LOADED,
  PRODUCT_VARIANT_UNLOAD,
  PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS
} from 'redux/actions/productVariant/action_types';

const initialStore = {
  isLoaded: false,
  productVariant: {},
  selectionCount: 0
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    productVariant: payload
  });

const selectionCountSuccess = (state, payload) =>
  Object.assign({}, state, {
    selectionCount: payload.count
  });

export default function product(state = initialStore, action) {
  switch (action.type) {
    case PRODUCT_VARIANT_VIEW_LOADED:
      return load(state, action.payload.variant);
    case PRODUCT_VARIANT_UNLOAD:
      return Object.assign({}, state, initialStore);
    case PRODUCT_VARIANT_SELECTION_COUNT_SUCCESS:
      return selectionCountSuccess(state, action.payload);
    default:
      return state;
  }
}
