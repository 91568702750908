import React, { Component } from 'react';
import { Spinner } from 'components';
import styles from './screenLoader.module.less';

class ScreenLoader extends Component {
  render() {
    return (
      <div className={styles.screenLoader}>
        <Spinner />
      </div>
    );
  }
}

export default ScreenLoader;
