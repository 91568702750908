import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner, Navbar } from 'components';
import { Modal, Descriptions, List, Row, Col, Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NO_IMG_URL } from 'Utils/constants';
import * as userSelector from 'redux/selectors/user';
import * as productSelectors from 'redux/selectors/product';
import * as productActions from 'redux/actions/product';
import { PRODUCT_UPDATE_ATTRIBUTES_SUCCESS } from 'redux/actions/product/action_types';
import * as taxClassActions from 'redux/actions/taxClass';
import * as taxClassSelector from 'redux/selectors/taxClass';
import * as attributeActions from 'redux/actions/attribute';
import * as attributeSelectors from 'redux/selectors/attribute';
import { toDateString } from 'Utils/utils';
import AttributesEditForm from './components/AttributesEditForm';
import { withEventBus, withScreenLoader } from 'context';
import style from './productview.module.less';

const { confirm } = Modal;

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditAttrForm: false
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.refreshView();
    this.props.findAllTaxCls();
    this.props.loadAllAttrs();
    this.props.loadAllPhoneBrands();
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_UPDATE_ATTRIBUTES_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.setState({
          showEditAttrForm: false
        });
        this.refreshView();
      })
    );
  }

  componentWillUnmount() {
    this.props.unload();
    this.props.unloadTaxCls();
    this.props.unloadAttributes();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  refreshView = () => {
    this.props.load(this.props.productId);
    this.props.loadAttrs(this.props.productId);
    this.props.loadCategories(this.props.productId);
  };

  handleRegenerateDesc = () => {
    confirm({
      title: 'Regenerate Descriptions',
      content:
        'Do you want to regenerate variant descriptions? If number of variants is large for this product it can' +
        ' have performance impact.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.regenerateDesc(this.props.productId);
      },
      onCancel: () => {}
    });
  };

  render() {
    let content = <Spinner />;
    if (this.props.isLoaded) {
      let p = this.props.product;
      let imgUrl = p.asset ? p.asset.path : NO_IMG_URL;
      let taxClass = this.props.allTaxCls.find(t => t.id === p.taxClassId);
      if (taxClass) {
        taxClass = taxClass.name;
      } else {
        taxClass = '';
      }
      content = (
        <div>
          <Navbar title={p.name}>
            {!this.isUserContentCreator && (
              <React.Fragment>
                <Button type="primary" onClick={() => this.props.history.push(`/catalog/products/edit/${p.id}/`)}>
                  <FontAwesomeIcon icon="edit" style={{ marginRight: '8px' }} />
                  Edit Product
                </Button>
                <Button
                  type="primary"
                  onClick={() => this.props.history.push(`/catalog/productVariants/product/${p.id}/create/variants/`)}
                >
                  <FontAwesomeIcon icon="edit" style={{ marginRight: '8px' }} />
                  Generate Product Variants
                </Button>
                <Button type="dashed" onClick={this.handleRegenerateDesc}>
                  <FontAwesomeIcon icon="sync-alt" style={{ marginRight: '8px' }} />
                  Regenerate Desc
                </Button>
                <Button onClick={() => this.props.history.push('/catalog/products/create/')}>
                  <FontAwesomeIcon icon="plus-square" style={{ marginRight: '8px' }} />
                  Add Product
                </Button>
              </React.Fragment>
            )}
            <Button shape="circle" onClick={() => this.props.history.push('/catalog/products/')}>
              <Tooltip placement="top" title="List Products">
                <FontAwesomeIcon icon="th-list" />
              </Tooltip>
            </Button>
          </Navbar>
          <Row>
            <Col span={6} className={style.productImg}>
              <img src={imgUrl} alt={p.name} />
            </Col>
            <Col span={18}>
              <Descriptions bordered column={2} size="small" title="Details">
                <Descriptions.Item label="Code">{p.id}</Descriptions.Item>
                <Descriptions.Item label="Name">{p.name}</Descriptions.Item>
                <Descriptions.Item label="Url">{p.url}</Descriptions.Item>
                <Descriptions.Item label="Sku">{p.sku}</Descriptions.Item>
                <Descriptions.Item label="Variant Name" span={2}>
                  {p.variantNameTemplate}
                </Descriptions.Item>
                <Descriptions.Item label="Tax Class">{taxClass}</Descriptions.Item>
                <Descriptions.Item label="New To">{p.newTo}</Descriptions.Item>
                <Descriptions.Item span={2} label="Description">
                  {p.description}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Short Description">
                  {p.shortDescription}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <List
            className="view-data-list"
            header="Categories"
            bordered
            dataSource={this.props.categories}
            renderItem={item => <List.Item>{item.name}</List.Item>}
          />
          <List
            className="view-data-list"
            header={
              this.props.isUserContentCreator ? (
                'Attributes'
              ) : (
                <div>
                  Attributes
                  <Button
                    className={style.attrEditBtn}
                    shape="circle"
                    onClick={() => this.setState({ showEditAttrForm: true })}
                  >
                    <Tooltip placement="top" title="Update Status">
                      <FontAwesomeIcon icon="edit" />
                    </Tooltip>
                  </Button>
                </div>
              )
            }
            bordered
            dataSource={this.props.attrs}
            renderItem={item => <List.Item>{item.label}</List.Item>}
          />
          <Descriptions bordered column={1} size="small" title="Meta Information">
            <Descriptions.Item label="Meta Title">{p.metaTitle}</Descriptions.Item>
            <Descriptions.Item label="Meta Keywords">{p.metaKeywords}</Descriptions.Item>
            <Descriptions.Item label="Meta Description">{p.metaDescription}</Descriptions.Item>
          </Descriptions>
          <Descriptions bordered column={2} size="small" title="Audit Information">
            <Descriptions.Item label="Created By">
              {p.createdBy && p.createdBy.name ? p.createdBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Created On">{toDateString(p.createdOn)}</Descriptions.Item>
            <Descriptions.Item label="Updated By">
              {p.updatedBy && p.updatedBy.name ? p.updatedBy.name : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Updated On">{toDateString(p.updatedOn)}</Descriptions.Item>
          </Descriptions>
        </div>
      );
    }
    return (
      <div id="print-mount" className={style.productView}>
        {content}
        {this.state.showEditAttrForm && (
          <AttributesEditForm
            attrs={this.props.attrs}
            onCancel={() =>
              this.setState({
                showEditAttrForm: false
              })
            }
            allAttributes={this.props.allAttrs}
            phoneBrands={this.props.allPhoneBrands}
            phones={this.props.phones}
            onPhoneBrandChange={this.props.loadPhonesByBrandIds}
            product={this.props.product}
            onSubmit={this.props.updateAttributes}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  load: productActions.load,
  unload: productActions.unload,
  loadAttrs: productActions.loadAttrs,
  loadCategories: productActions.loadCategories,
  findAllTaxCls: taxClassActions.findAll,
  unloadTaxCls: taxClassActions.unload,
  regenerateDesc: productActions.regenerateDesc,
  loadAllAttrs: attributeActions.loadAll,
  loadAllPhoneBrands: attributeActions.loadAllPhoneBrands,
  loadPhonesByBrandIds: attributeActions.loadPhonesByBrandIds,
  unloadAttributes: attributeActions.unload,
  updateAttributes: productActions.updateAttrs
};

const mapStateToProps = (state, ownProps) => ({
  product: productSelectors.getProduct(state),
  isLoaded: productSelectors.isLoaded(state),
  productId: ownProps.match.params.id,
  categories: productSelectors.getCategories(state),
  attrs: productSelectors.getAttrs(state),
  allAttrs: attributeSelectors.getAll(state),
  isUserContentCreator: userSelector.isUserContentCreator(state),
  allTaxCls: taxClassSelector.getAllTaxClass(state),
  allPhoneBrands: attributeSelectors.getAllPhoneBrands(state),
  phones: attributeSelectors.getPhones(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(withScreenLoader(ProductView)));
