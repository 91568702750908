import { call, put, takeLatest, all } from 'redux-saga/effects';
import { TAX_CLS_VIEW, TAX_CLS_LIST_ALL } from '../actions/taxClass/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as taxClassService from '../services/taxClass';
import * as taxClassActions from 'redux/actions/taxClass';

function* findAll() {
  yield takeLatest(TAX_CLS_LIST_ALL, function*(action) {
    try {
      let taxClasses = yield call(taxClassService.findAll, action.payload);
      yield put(taxClassActions.foundAll(taxClasses));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findById() {
  yield takeLatest(TAX_CLS_VIEW, function*(action) {
    try {
      let taxClass = yield call(taxClassService.findById, action.payload);
      yield put(taxClassActions.foundById(taxClass));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

export default function* taxClassSagas() {
  yield all([findAll(), findById()]);
}
