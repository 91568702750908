import { call, put, takeLatest, all } from 'redux-saga/effects';
import { CUSTOMER_GRP_VIEW, CUSTOMER_GRP_LIST_ALL } from '../actions/customerGroup/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as customerGroupService from '../services/customerGroup';
import * as customerGroupActions from 'redux/actions/customerGroup';

function* findAll() {
  yield takeLatest(CUSTOMER_GRP_LIST_ALL, function*(action) {
    try {
      let customerGroups = yield call(customerGroupService.findAll, action.payload);
      yield put(customerGroupActions.foundAll(customerGroups));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findById() {
  yield takeLatest(CUSTOMER_GRP_VIEW, function*(action) {
    try {
      let customerGroup = yield call(customerGroupService.findById, action.payload);
      yield put(customerGroupActions.foundById(customerGroup));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

export default function* taxClassSagas() {
  yield all([findAll(), findById()]);
}
