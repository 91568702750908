import { fetchApi } from 'Utils/utils';

let fetchAddresses = function(customerId) {
  return fetchApi.get(`/api/v1/address/customer/${customerId}/`);
};

let fetchProvinces = function() {
  return fetchApi.get('/api/v1/address/getProvinces/');
};

let save = function(payload) {
  return fetchApi.post('/api/v1/address/', payload);
};

let searchAddresses = function(params) {
  return fetchApi.post('/api/v1/address/search/', params);
};

let updateEShipperId = function(params) {
  return fetchApi.post('/api/v1/address/updateEShipperId/', params);
};

export { fetchAddresses, fetchProvinces, save, searchAddresses, updateEShipperId };
