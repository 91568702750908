import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip, Divider } from 'antd';
import * as customerSelector from 'redux/selectors/customer';
import * as customerActions from 'redux/actions/customer';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as customerGroupSelector from 'redux/selectors/customerGroup';
import * as taxClassActions from 'redux/actions/taxClass';
import * as taxClassSelector from 'redux/selectors/taxClass';
import { Navbar, Spinner } from 'components';
import CustForm from '../Form/custForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CUSTOMER_CREATE_UPDATE_OK } from 'redux/actions/customer/action_types';
import { withEventBus } from 'context/eventbus';
import styles from './customerEdit.module.less';

class CustomerEdit extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isTaxesLoaded) {
      this.props.findAllTaxCls();
    }
    if (!this.props.isCustGrpLoaded) {
      this.props.findAllCustGrp();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(CUSTOMER_CREATE_UPDATE_OK, action => {
        this.props.history.push(`/customers/${this.props.match.params.id}/`);
      })
    );
    this.props.findByCustomerId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unloadCustGrp();
    this.props.unloadTaxCls();
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  submitHandle = formData => {
    if (formData.id !== 0) this.props.update(formData);
    else this.props.create(formData);
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <CustForm
          key={this.props.customer.id}
          taxClasses={this.props.allTaxCls}
          customerGroups={this.props.allCustGrp}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={this.props.customer}
        ></CustForm>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={'Edit ' + this.props.customer.code}>
          <Link type="primary" to={'/customers/create/'}>
            <Tooltip placement="top" title="Create Customer">
              <FontAwesomeIcon icon="plus-square" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={`/customers/${this.props.match.params.id}/`}>
            <Tooltip placement="top" title="View Customer">
              <FontAwesomeIcon icon="search" />
            </Tooltip>
          </Link>
          <Divider type="vertical" />
          <Link type="primary" to={'/customers/allCustomers/'}>
            <Tooltip placement="top" title="List Customer">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  findByCustomerId: customerActions.findCustomerById,
  findAllCustGrp: customerGroupActions.findAll,
  findAllTaxCls: taxClassActions.findAll,
  update: customerActions.customerUpdate,
  unload: customerActions.customerUnload,
  unloadTaxCls: taxClassActions.unload,
  unloadCustGrp: customerGroupActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: customerSelector.isLoaded(state),
    isTaxesLoaded: taxClassSelector.isLoaded(state),
    isCustGrpLoaded: customerGroupSelector.isLoaded(state),
    customer: customerSelector.getCustomer(state),
    allCustGrp: customerGroupSelector.getAllCustomerGrp(state),
    allTaxCls: taxClassSelector.getAllTaxClass(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(CustomerEdit));
