import { fetchApi } from 'Utils/utils';

let search = function(params) {
  return fetchApi.post('/api/v1/imageSlider/search/', params);
};

let find = function(id) {
  return fetchApi.get(`/api/v1/imageSlider/${id}/`);
};

let create = function(payload) {
  return fetchApi.fileUpload('/api/v1/imageSlider/', payload);
};

let update = function(payload) {
  return fetchApi.put(`/api/v1/imageSlider/${payload.id}/`, payload);
};

let deleteSlide = function(payload) {
  return fetchApi.delete(`/api/v1/imageSlider/${payload.id}/`);
};

export { search, find, create, update, deleteSlide };
