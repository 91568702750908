import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from '../Form/form';
import * as productVariantActions from 'redux/actions/productVariant';
import * as productSelectors from 'redux/selectors/product';
import * as priceOverrideActions from 'redux/actions/priceOverride';
import * as priceOverrideSelectors from 'redux/selectors/priceOverride';
import * as productActions from 'redux/actions/product';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as attributeSelectors from 'redux/selectors/attribute';
import * as attributeActions from 'redux/actions/attribute';
import * as homepageActions from 'redux/actions/homepage';

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  render() {
    return <Form customerId={this.props.customerId} priceId={0} {...this.props} />;
  }
}

const mapDispatchToProps = {
  loadAttrs: productActions.loadAttrs,
  loadAllPhoneBrands: attributeActions.loadAllPhoneBrands,
  loadPhonesByBrandIds: attributeActions.loadPhonesByBrandIds,
  unloadProduct: productActions.unload,
  unloadCustGrp: customerGroupActions.unload,
  unloadAttrs: attributeActions.unload,
  countSelection: productVariantActions.countSelection,
  update: priceOverrideActions.update,
  setMessages: homepageActions.setMessages
};

const mapStateToProps = (state, props) => {
  return {
    customerId: props.match.params.customerId,
    attributes: productSelectors.getAttrs(state),
    allPhoneBrands: attributeSelectors.getAllPhoneBrands(state),
    phones: attributeSelectors.getPhones(state),
    priceOverride: priceOverrideSelectors.getPriceOverride(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
