import { fetchApi } from 'Utils/utils';

let create = function(params) {
  return fetchApi.post('/api/v1/coupon/', params);
};

let update = function(params) {
  return fetchApi.put(`/api/v1/coupon/${params.id}/`, params);
};

let activate = function(id) {
  return fetchApi.put(`/api/v1/coupon/${id}/activate/`, {});
};

let inactivate = function(id) {
  return fetchApi.put(`/api/v1/coupon/${id}/inactivate/`, {});
};

let list = function(params) {
  return fetchApi.post('/api/v1/coupon/search/', params);
};

let findOne = function(id) {
  return fetchApi.get(`/api/v1/coupon/${id}/`);
};

let remove = function(id) {
  return fetchApi.delete(`/api/v1/coupon/${id}/`);
};

export { create, list, update, findOne, activate, inactivate, remove };
