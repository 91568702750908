import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styles from './generictableview.module.less';

class GenericTableView extends Component {
  render() {
    return (
      <div className={styles.genericview}>
        {this.props.viewObj.map((v, idx) => (
          <Table key={idx} viewObj={v} />
        ))}
      </div>
    );
  }
}

class Table extends Component {
  render() {
    let v = this.props.viewObj;
    return (
      <div>
        {v.title && <div className={styles.title}>{this.props.viewObj.title}</div>}
        <Row className={styles.table}>
          {v.fields.map((f, idx) => (
            <TableCell key={idx} field={f} />
          ))}
        </Row>
      </div>
    );
  }
}
class TableCell extends Component {
  render() {
    let colSpan = this.props.field.span ? this.props.field.span : { xs: 24, sm: 12, md: 8 };
    return (
      <Col {...colSpan} className={styles.cell}>
        <div className={styles.fieldLabel}>{this.props.field.label}</div>
        <div className={styles.fieldValue}>{this.props.field.value}</div>
      </Col>
    );
  }
}

export default GenericTableView;
