import {
  ATTRIBUTE_VIEW,
  ATTRIBUTE_VIEW_LOADED,
  ATTRIBUTE_DELETE,
  ATTRIBUTE_CREATE,
  ATTRIBUTE_UPDATE,
  ATTRIBUTE_SUBMIT_OK,
  ATTRIBUTE_UNLOAD,
  ATTRIBUTE_LIST,
  ATTRIBUTE_REMOVE_LIST_ITEM,
  ATTRIBUTE_ADD_LIST_ITEM,
  ATTRIBUTE_LOAD_ALL,
  ATTRIBUTE_ALL_LOADED,
  ATTRIBUTE_PHONE_BRAND_LOAD_ALL,
  ATTRIBUTE_PHONE_BRAND_ALL_LOADED,
  ATTRIBUTE_LOAD_PHONES_BY_BRAND,
  ATTRIBUTE_LOADED_PHONES_BY_BRAND
} from './action_types';

const list = payload => ({
  type: ATTRIBUTE_LIST,
  payload: payload
});

const create = payload => ({
  type: ATTRIBUTE_CREATE,
  payload: payload
});

const update = payload => ({
  type: ATTRIBUTE_UPDATE,
  payload: payload
});

const remove = payload => ({
  type: ATTRIBUTE_DELETE,
  payload: payload
});

const removeAttribute = payload => ({
  type: ATTRIBUTE_REMOVE_LIST_ITEM,
  payload: payload
});

const addAttribute = payload => ({
  type: ATTRIBUTE_ADD_LIST_ITEM,
  payload: payload
});

const findOne = payload => ({
  type: ATTRIBUTE_VIEW,
  payload: payload
});

const foundOne = payload => ({
  type: ATTRIBUTE_VIEW_LOADED,
  payload: payload
});

const unload = () => ({
  type: ATTRIBUTE_UNLOAD,
  payload: {}
});

const attributeSubmitOk = payload => ({
  type: ATTRIBUTE_SUBMIT_OK,
  payload: payload
});

const loadAll = () => ({
  type: ATTRIBUTE_LOAD_ALL,
  payload: {}
});

const allLoaded = payload => ({
  type: ATTRIBUTE_ALL_LOADED,
  payload: payload
});

const loadAllPhoneBrands = () => ({
  type: ATTRIBUTE_PHONE_BRAND_LOAD_ALL,
  payload: {}
});

const allPhoneBrandsLoaded = payload => ({
  type: ATTRIBUTE_PHONE_BRAND_ALL_LOADED,
  payload: payload
});

const loadedPhonesByBrandIds = payload => ({
  type: ATTRIBUTE_LOADED_PHONES_BY_BRAND,
  payload: payload
});

const loadPhonesByBrandIds = brandIds => ({
  type: ATTRIBUTE_LOAD_PHONES_BY_BRAND,
  payload: { brandIds: brandIds }
});

export {
  list,
  findOne,
  foundOne,
  create,
  update,
  unload,
  remove,
  attributeSubmitOk,
  loadAll,
  allLoaded,
  loadAllPhoneBrands,
  allPhoneBrandsLoaded,
  loadedPhonesByBrandIds,
  loadPhonesByBrandIds,
  removeAttribute,
  addAttribute
};
