import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, Button, Row, Col, Select, DatePicker } from 'antd';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './couponForm.module.less';

const { TextArea } = Input;
const Fragment = React.Fragment;

class CouponForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      applyTo: 'ALL'
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let payload = Object.assign({}, values);
        if (!payload.appliesToList) {
          payload.appliesToList = [];
        }
        if (payload.startDate) {
          payload.startDate = payload.startDate.format('YYYY-MM-DD');
        }
        if (payload.endDate) {
          payload.endDate = payload.endDate.format('YYYY-MM-DD');
        }
        this.props.submitHandle(payload);
      }
    });
  };

  onCouponForChange = e => {
    this.setState({
      applyTo: e
    });
  };

  render() {
    var editMode = this.props.data.id !== 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        {getFieldDecorator('id', {
          initialValue: this.props.data.id
        })(<Input type="hidden" />)}
        {getFieldDecorator('version', {
          initialValue: this.props.data.version
        })(<Input type="hidden" />)}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Code" className={styles.row}>
              {getFieldDecorator('code', {
                initialValue: this.props.data.code,
                rules: [{ required: true, message: 'Code is required' }]
              })(<Input placeholder="Code" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Start Date">
              {getFieldDecorator('startDate', {
                initialValue: moment(this.props.data.startDate),
                rules: [{ required: true, message: 'Start Date is required' }]
              })(<DatePicker placeholder="Start Date" style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="End Date">
              {getFieldDecorator('endDate', {
                initialValue: moment(this.props.data.endDate),
                rules: [{ required: true, message: 'End Date is required' }]
              })(<DatePicker placeholder="End Date" style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Description" className={styles.row}>
              {getFieldDecorator('description', {
                initialValue: this.props.data.description,
                rules: []
              })(<TextArea placeholder="Description" rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Maximun use" className={styles.row}>
              {getFieldDecorator('maxUse', {
                initialValue: this.props.data.maxUse,
                rules: [{ required: true, message: 'Maximun use required' }]
              })(<InputNumber placeholder="Maximum Uses" style={{ width: '95%' }} />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Maximun use per customer" className={styles.row}>
              {getFieldDecorator('maxUsePerCustomer', {
                initialValue: this.props.data.maxUsePerCustomer,
                rules: [{ required: true, message: 'Maximun use per customer required' }]
              })(<InputNumber placeholder="Maximun use per customer" style={{ width: '95%' }} />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Discount Rate Type" className={styles.row}>
              {getFieldDecorator('discountRateType', {
                initialValue: this.props.data.discountRateType.id,
                rules: [{ required: true, message: 'Select a discount rate type' }]
              })(
                <Select placeholder="Discount Rate Type">
                  {[
                    { id: 'PRODUCT_PRICE_DISCOUNT', name: 'Product Price Discount' },
                    { id: 'FIXED_AMOUNT_WHOLE_CART', name: 'Fixed Amount on cart' },
                    { id: 'FREE_SHIPPING', name: 'Free Shipping' }
                  ].map(p => (
                    <Select.Option key={p} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Coupon Value" className={styles.row}>
              {getFieldDecorator('discountValue', {
                initialValue: this.props.data.discountValue,
                rules: [{ required: true, message: 'Coupon value is required' }]
              })(<InputNumber placeholder="Coupon Value" style={{ width: '95%' }} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          {!editMode && (
            <Fragment>
              <Col span={8}>
                <Form.Item label="Coupon For">
                  {getFieldDecorator('couponFor', {
                    initialValue: this.props.data.couponFor.id,
                    rules: [{ required: true, message: 'Select a Coupon For' }]
                  })(
                    <Select placeholder="Coupon For" onChange={this.onCouponForChange}>
                      {[
                        { id: 'ALL', name: 'All' },
                        { id: 'GROUP', name: 'Customer Group' }
                      ].map(p => (
                        <Select.Option key={p} value={p.id}>
                          {p.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {this.state.applyTo === 'GROUP' ? (
                <Col span={8}>
                  <Form.Item label="Group" className={styles.row}>
                    {getFieldDecorator('appliesToList', {
                      initialValue: this.props.data.appliesToList,
                      rules: [{ required: true, message: 'Select a Group' }]
                    })(
                      <Select placeholder="Group" mode="multiple">
                        {this.props.customerGroups.map(p => (
                          <Select.Option key={p} value={p.id}>
                            {p.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              ) : (
                <div></div>
              )}
            </Fragment>
          )}
        </Row>
        <Form.Item className={styles.row}>
          <Button type="primary" htmlType="submit">
            {!editMode ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'couponForm' })(CouponForm);
