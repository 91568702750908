import React, { Component } from 'react';
import { Autocomplete } from 'components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Modal } from 'antd';

class Add extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={this.props.visible}
        destroyOnClose={true}
        onCancel={this.props.handleClose}
        title="Assign Customer"
        okText="Create"
        onOk={this.props.handleSubmit}
      >
        <Form>
          <Row gutter={24}>
            <Col sm={24} md={10}>
              <Form.Item label="Customer">
                {getFieldDecorator('customerId', {
                  rules: [
                    {
                      required: true,
                      message: 'Customer is required.'
                    }
                  ]
                })(<Autocomplete.Customer />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({})(Add);
