import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as homepageSelectors from 'redux/selectors/homepage';
import * as homepageActions from 'redux/actions/homepage';
import { Alert } from 'antd';
import { Spinner } from 'components';
import styles from './homepage.module.less';

class Homepage extends Component {
  componentDidMount() {
    if (!this.props.isHomepageContentLoaded) {
      this.props.loadHomepageContent();
    }
  }

  componentWillUnmount() {
    this.props.unsetMessages();
  }

  render() {
    let content;
    if (this.props.isHomepageContentLoaded) {
      content = (
        <div>
          <h2 className="page-title">Dashboard</h2>
          <h5>Coming soon...</h5>
        </div>
      );
    } else {
      content = <Spinner />;
    }
    return (
      <div>
        {this.props.messages.errorMsg && (
          <Alert message={this.props.messages.errorMsg} type="error" showIcon className={styles.alertMsg} />
        )}
        {this.props.messages.infoMsg && (
          <Alert message={this.props.messages.infoMsg} type="info" showIcon className={styles.alertMsg} />
        )}
        {this.props.messages.warnMsg && (
          <Alert message={this.props.messages.warnMsg} type="warning" showIcon className={styles.alertMsg} />
        )}
        {this.props.messages.successMsg && (
          <Alert message={this.props.messages.successMsg} type="success" showIcon className={styles.alertMsg} />
        )}
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadHomepageContent: homepageActions.loadHomepageContent,
  unsetMessages: homepageActions.unsetMessages
};

const mapStateToProps = (state, ownProps) => ({
  isHomepageContentLoaded: homepageSelectors.isHomepageContentLoaded(state),
  messages: homepageSelectors.getMessages(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage);
