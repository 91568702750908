import { fetchApi } from 'Utils/utils';

let find = function(payload) {
  return fetchApi.get(`/api/v1/phone/brand/${payload}/`);
};

let findAll = function() {
  return fetchApi.get('/api/v1/phone/brand/all/');
};

let update = function(payload) {
  return fetchApi.put(`/api/v1/phone/brand/${payload.id}/`, payload);
};

let search = function(payload) {
  return fetchApi.post('/api/v1/phone/brand/search/',payload);
};

let create = function(payload) {
  return fetchApi.post('/api/v1/phone/brand/', payload);
};

let uploadImage = function(payload) {
  return fetchApi.fileUpload('/api/v1/phone/brand/upload-asset/', payload);
};

export { find, update, create,search, findAll,uploadImage };