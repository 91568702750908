// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Tooltip, Popconfirm } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as couponActions from 'redux/actions/coupon';
import * as listActions from 'redux/actions/list';
import { COUPON_CREATE_UPDATE_OK } from 'redux/actions/coupon/action_types';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { withEventBus } from 'context/eventbus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './couponlist.module.less';

const Fragment = React.Fragment;

class CouponList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(COUPON_CREATE_UPDATE_OK, action => {
        this.reload();
      })
    );

    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.props.unload();
  }

  handleDelete = id => {
    this.props.remove(id);
  };

  reload = () => {
    this.props.reload();
  };

  render() {
    const columns = [
      {
        title: 'Coupon Id',
        dataIndex: 'code',
        defaultSortOrder: 'descend',
        sorter: true
      },
      {
        title: 'Coupon For',
        dataIndex: 'couponFor',
        render: (couponFor, obj) => {
          return `${couponFor.name}`;
        },
        sorter: true
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        sorter: true
      },
      {
        title: 'Discount Rate Type',
        dataIndex: 'discountRateType',
        render: (index, obj) => {
          return `${index.name}`;
        },
        sorter: true
      },
      {
        title: 'Discount Value',
        dataIndex: 'discountValue',
        sorter: true
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status, obj) => {
          return `${status.name}`;
        },
        sorter: true
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Fragment>
            <Link to={`/marketing/coupons/${id}/`}>view</Link>
            <Divider type="vertical" />
            <Link to={`/marketing/coupons/edit/${id}/`}>edit</Link>
            <Divider type="vertical" />
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(id)}>
              <a href="/">delete</a>
            </Popconfirm>
          </Fragment>
        )
      }
    ];
    let tableAction = (
      <Fragment>
        <Link shape="circle" type="primary" to={'/marketing/coupons/create/'}>
          <Tooltip placement="rightTop" title="Create Coupon">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Link>
      </Fragment>
    );
    return (
      <div className={style.container}>
        <Row>
          <Col span={24}>
            <WrapperTable
              title="Coupon List"
              filters={filters}
              tableAction={tableAction}
              columns={columns}
              data={this.props.listData}
              api={this.props.searchCoupon}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const filters = [
  {
    field: 'code',
    type: 'TEXT'
  },
  {
    field: 'couponFor',
    type: 'TEXT'
  },
  {
    field: 'status',
    type: 'TEXT'
  },
  {
    field: 'description',
    type: 'TEXT'
  },
  {
    field: 'startDate',
    type: 'DATE'
  },
  {
    field: 'endDate',
    type: 'DATE'
  },
  {
    field: 'discountRateType',
    type: 'OPTIONS'
  },
  {
    field: 'discountValue',
    type: 'NUMBER'
  },
  {
    field: 'status',
    type: 'OPTION',
    options: [
      { id: 'ACTIVE', name: 'Active' },
      { id: 'INACTIVE', name: 'Inactive' },
      { id: 'VERIFICATION_PENDING', name: 'Verification Pending' }
    ]
  }
];

const mapDispatchToProps = {
  remove: couponActions.remove,
  searchCoupon: couponActions.search,
  reload: listActions.reload,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(CouponList));
