import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar } from 'components';
import { Button, Tooltip, Row, Col, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withEventBus, withScreenLoader } from 'context';
import { HOMEPAGE_WIDGETS_SAVE_SUCCESS } from 'redux/actions/homepage/widgets/action_types';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import * as widgetActions from 'redux/actions/homepage/widgets';
import { LatestProductsForm, SelectedProductsForm, StaticImagesForm } from './components';
import styles from './add.module.less';

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(HOMEPAGE_WIDGETS_SAVE_SUCCESS, action => {
        this.props.history.push('/options/homepageWidgets/');
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  renderForm = type => {
    switch (type) {
      case 'LATEST_PRODUCTS':
        return <LatestProductsForm save={this.props.save} />;
      case 'SELECTED_PRODUCTS':
        return <SelectedProductsForm save={this.props.save} />;
      case 'STATIC_IMAGES':
        return <StaticImagesForm save={this.props.saveStaticImages} />;
      default:
        return <div>Widget type unknown.</div>;
    }
  };

  render() {
    return (
      <div className={styles.addForm}>
        <Navbar title="Create New Widget">
          <Button shape="circle" onClick={() => this.props.history.push('/options/homepageWidgets/')}>
            <Tooltip placement="top" title="List Widgets">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Button>
        </Navbar>
        <Row gutter={24}>
          <Col span={24}>
            {this.state.globalErrors.map(error => (
              <Alert key={error} message={error} type="error" />
            ))}
          </Col>
        </Row>
        {this.renderForm(this.props.widgetType)}
      </div>
    );
  }
}

const mapDispatchToProps = {
  save: widgetActions.save,
  saveStaticImages: widgetActions.saveStaticImages
};

const mapStateToProps = (state, props) => {
  return {
    widgetType: props.match.params.type
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withScreenLoader(withEventBus(Add)));
