import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'components';
import { CategoryEdit, CategoryView } from './components';
import { CATEGORY_UPDATE_SUCCESS } from 'redux/actions/category/action_types';
import { Tree, Row, Col, Button, Modal } from 'antd';
import * as categorySelectors from 'redux/selectors/category';
import * as categoryActions from 'redux/actions/category';
import { withEventBus } from 'context/eventbus';
import styles from './category.module.less';

const { TreeNode } = Tree;
const { confirm } = Modal;

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      mode: 'view'
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.loadAllCategories();
    }
    this.subscriptions.push(
      this.props.eventBusSubscribe(CATEGORY_UPDATE_SUCCESS, action => {
        this.props.loadAllCategories();
        this.toggleMode();
        this.setState({
          selected: action.payload
        });
      })
    );
  }

  componentWillUnmount() {
    this.props.unloadCategories();
  }

  uploadImage = formData => {
    this.props.uploadImage(formData);
  };

  createNew = () => {
    this.setState({
      selected: {
        id: 0
      },
      mode: 'edit'
    });
  };

  toggleMode = () => {
    this.setState({
      mode: this.state.mode === 'view' ? 'edit' : 'view'
    });
  };

  renderTreeNode = node => {
    let c = node.category;
    return (
      <TreeNode title={c.name} key={c.id}>
        {node.children.map(n => this.renderTreeNode(n))}
      </TreeNode>
    );
  };

  updateSelected = key => {
    let first = key.shift();
    let catList = [this.props.categories];
    do {
      let cat = catList.shift();
      if (cat.category.id === first) {
        this.setState({
          selected: cat.category,
          mode: 'view'
        });
        break;
      }
      catList = catList.concat(cat.children);
    } while (catList.length !== 0);
  };

  onSelect = key => {
    if (this.state.selected && key[0] === this.state.selected.id) {
      return;
    }
    let updateSelected = this.updateSelected;
    if (this.state.mode === 'edit') {
      confirm({
        title: 'Confirm',
        content: 'Do you want to proceed without saving?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          updateSelected(key);
        },
        onCancel() {}
      });
    } else {
      updateSelected(key);
    }
  };

  render() {
    let content;
    if (!this.props.isLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      let selected = this.state.selected != null ? this.state.selected : this.props.categories.category;
      content = (
        <div className={styles.category}>
          <Row gutter={24}>
            <Col md={8} lg={6}>
              <div className={styles.treeView}>
                <h2 className="page-title">Manage Categories</h2>
                <Tree
                  className={styles.tree}
                  showLine
                  onSelect={this.onSelect}
                  defaultExpandedKeys={[this.props.categories.category.id]}
                >
                  {this.renderTreeNode(this.props.categories)}
                </Tree>
                <div className={styles.buttonContainer}>
                  <Button type="primary" size="small" onClick={this.createNew} disabled={selected.id === 0}>
                    Create New
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={16} lg={18}>
              {this.state.mode === 'view' && (
                <CategoryView
                  key={selected.id}
                  category={selected}
                  uploadImage={this.uploadImage}
                  eventBusSubscribe={this.props.eventBusSubscribe}
                  toggleMode={this.toggleMode}
                />
              )}
              {this.state.mode === 'edit' && (
                <CategoryEdit
                  key={selected.id}
                  category={selected}
                  categories={this.props.categories}
                  toggleMode={this.toggleMode}
                  update={this.props.updateCategory}
                  eventBusSubscribe={this.props.eventBusSubscribe}
                  history={this.props.history}
                />
              )}
            </Col>
          </Row>
        </div>
      );
    }
    return <div className={styles.category}>{content}</div>;
  }
}

const mapDispatchToProps = {
  loadAllCategories: categoryActions.load,
  unloadCategories: categoryActions.unload,
  updateCategory: categoryActions.update,
  uploadImage: categoryActions.uploadImage
};

const mapStateToProps = (state, ownProps) => ({
  isLoaded: categorySelectors.isLoaded(state),
  categories: categorySelectors.getCategories(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(withRouter(Category)));
