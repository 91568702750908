import {
  ATTRIBUTE_VIEW_LOADED,
  ATTRIBUTE_UNLOAD,
  ATTRIBUTE_SUBMIT_OK,
  ATTRIBUTE_ALL_LOADED,
  ATTRIBUTE_PHONE_BRAND_ALL_LOADED,
  ATTRIBUTE_LOADED_PHONES_BY_BRAND
} from 'redux/actions/attribute/action_types';

const initialStore = {
  isLoaded: false,
  attribute: {},
  all: [],
  allPhoneBrands: [],
  phones: []
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    attribute: payload
  });

const submit = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    attribute: payload
  });

const allLoaded = (state, payload) =>
  Object.assign({}, state, {
    all: payload
  });

const allPhoneBrandsLoaded = (state, payload) =>
  Object.assign({}, state, {
    allPhoneBrands: payload
  });

const phonesLoadedByBrand = (state, payload) =>
  Object.assign({}, state, {
    phones: payload
  });

export default function attribute(state = initialStore, action) {
  switch (action.type) {
    case ATTRIBUTE_VIEW_LOADED:
      return load(state, action.payload);
    case ATTRIBUTE_UNLOAD:
      return load(state, initialStore);
    case ATTRIBUTE_SUBMIT_OK:
      return submit(state, action.payload);
    case ATTRIBUTE_ALL_LOADED:
      return allLoaded(state, action.payload);
    case ATTRIBUTE_PHONE_BRAND_ALL_LOADED:
      return allPhoneBrandsLoaded(state, action.payload);
    case ATTRIBUTE_LOADED_PHONES_BY_BRAND:
      return phonesLoadedByBrand(state, action.payload);
    default:
      return state;
  }
}
