import React, { useState } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import * as storeGroupService from 'redux/services/storeGroup';
import { GlobalError, Autocomplete } from 'components';
import { handleErrors } from 'Utils/errorHandler';
import { useHistory } from 'react-router-dom';

export default props => {
  const [form] = Form.useForm();
  const [globalErrors, setGlobalErrors] = useState([]);
  const history = useHistory();

  const handleSubmit = values => {
    (async () => {
      try {
        let storeGroup = await storeGroupService.create(values);
        history.push(`/customers/storeGroup/view/${storeGroup.id}`);
      } catch (err) {
        setGlobalErrors(handleErrors(err, history, form));
      }
    })();
  };

  return (
    <div>
      <h2 className="page-title">Add Store Group</h2>
      <GlobalError errors={globalErrors} />
      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col md={8} sm={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, type: 'string', pattern: /^[a-zA-Z0-9 ]*$/, message: 'Please enter group name.' },
                {
                  min: 2,
                  message: 'Min of 2 chars required.'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={8} sm={24}>
            <Form.Item
              name="customerId"
              label="Customer"
              rules={[{ required: true, message: 'Please select a customer' }]}
            >
              <Autocomplete.Customer />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={24}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
