import { displayValidationErrors, getGlobalErrors } from './validationHelper';

const handleError = err => {
  console.error(err);
  switch (err.status) {
    case 401:
      return '/login';
    case 403:
      return '/not-authorized';
    case 404:
      return '/page-not-found';
    case 422:
      return null;
    default:
      return '/unknown-error';
  }
};

const handleErrorAndRedirect = (err, history) => {
  let url = handleError(err);
  if (url) {
    history.push(url);
  }
  return err.status;
};

const handleFormSubmitError = (form, err, history) => {
  let status = handleErrorAndRedirect(err, history);
  if (status === 422) {
    return displayValidationErrors(form, err.response);
  } else {
    return [];
  }
};

const handleErrors = (err, history, form) => {
  let globalErrors = [];
  if (form) {
    globalErrors = handleFormSubmitError(form, err, history);
  } else {
    let status = handleErrorAndRedirect(err, history);
    if (status === 422) {
      globalErrors = getGlobalErrors(err);
    }
  }
  return globalErrors;
};

export { handleErrors };
