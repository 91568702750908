import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar } from 'components';
import { Button, Tooltip, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withEventBus } from 'context/eventbus';
import * as productSelectors from 'redux/selectors/product';
import * as productActions from 'redux/actions/product';
import * as productVariantActions from 'redux/actions/productVariant';
import * as customerGroupActions from 'redux/actions/customerGroup';
import * as customerGroupSelector from 'redux/selectors/customerGroup';
import * as attributeSelectors from 'redux/selectors/attribute';
import * as attributeActions from 'redux/actions/attribute';
import { PRODUCT_GENERATE_VARIANTS_SUCCESS } from 'redux/actions/product/action_types';
import { PRODUCT_VARIANT_SAVE_SUCCESS } from 'redux/actions/productVariant/action_types';
import { VariantGeneratorSteps, AttributeSelector, VariantsInformation } from './components';

import styles from './productVariantCreate.module.less';

class ProductVariantCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      currentStep: 0,
      generatedVariants: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.loadProduct(this.props.productId);
    this.props.loadAttrs(this.props.productId);
    this.props.findAllCustGrp();
    this.props.loadAllPhoneBrands();
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_GENERATE_VARIANTS_SUCCESS, action => {
        if (action.payload.length === 0) {
          this.setState({
            globalErrors: ['All requested variants already exists.']
          });
        } else {
          this.setState({ currentStep: 1, generatedVariants: action.payload.map(p => p.attributes) });
        }
      })
    );
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_VARIANT_SAVE_SUCCESS, action => {
        this.props.history.push('/catalog/products/');
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unloadProduct();
    this.props.unloadCustGrp();
    this.props.unloadAttrs();
  }

  generateAttributes = values => {
    let payload = Object.assign({}, values);
    this.setState({
      globalErrors: []
    });
    this.props.generateVariants(
      Object.assign(
        {},
        {
          productId: this.props.productId,
          attributes: payload
        }
      )
    );
  };

  onPhoneBrandChange = phoneBrandIds => this.props.loadPhonesByBrandIds(phoneBrandIds);

  render() {
    return (
      <div className={styles.productVariantCreate}>
        <Navbar title="Product Variant Generator">
          <Button shape="circle" onClick={() => this.props.history.push('/catalog/productVariants/')}>
            <Tooltip placement="top" title="List Product Variants">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Button>
        </Navbar>
        <VariantGeneratorSteps current={this.state.currentStep} />
        {this.state.globalErrors.length > 0 && (
          <Alert
            type="warning"
            showIcon
            description={
              <ul>
                {this.state.globalErrors.map((e, idx) => (
                  <li key={idx}>{e}</li>
                ))}
              </ul>
            }
          />
        )}
        {this.state.currentStep === 0 && (
          <AttributeSelector
            generateAttributes={this.generateAttributes}
            attributes={this.props.attrs}
            phoneBrands={this.props.allPhoneBrands}
            phones={this.props.phones}
            onPhoneBrandChange={this.onPhoneBrandChange}
          />
        )}
        {this.state.currentStep === 1 && (
          <VariantsInformation
            productId={this.props.productId}
            attributes={this.props.attrs}
            phones={this.props.phones}
            generatedVariants={this.state.generatedVariants}
            allCustGrp={this.props.allCustGrp.filter(c => !c.applyToAll)}
            saveVariants={this.props.saveVariants}
            eventBusSubscribe={this.props.eventBusSubscribe}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadProduct: productActions.load,
  unloadProduct: productActions.unload,
  loadAttrs: productActions.loadAttrs,
  findAllCustGrp: customerGroupActions.findAll,
  unloadCustGrp: customerGroupActions.unload,
  loadAllPhoneBrands: attributeActions.loadAllPhoneBrands,
  loadPhonesByBrandIds: attributeActions.loadPhonesByBrandIds,
  unloadAttrs: attributeActions.unload,
  generateVariants: productActions.generateVariants,
  saveVariants: productVariantActions.saveVariants
};

const mapStateToProps = (state, ownProps) => {
  return {
    productId: ownProps.match.params.productId,
    product: productSelectors.getProduct(state),
    isProductLoaded: productSelectors.isLoaded(state),
    attrs: productSelectors.getAttrs(state),
    allCustGrp: customerGroupSelector.getAllCustomerGrp(state),
    isCustGrpLoaded: customerGroupSelector.isLoaded(state),
    allPhoneBrands: attributeSelectors.getAllPhoneBrands(state),
    phones: attributeSelectors.getPhones(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(ProductVariantCreate));
