import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  COUPONS_LOAD,
  COUPON_VIEW,
  COUPON_CREATE,
  COUPON_UPDATE,
  COUPON_ACTIVATE,
  COUPON_INACTIVATE,
  COUPON_DELETE
} from '../actions/coupon/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as couponService from '../services/coupon';
import * as couponActions from 'redux/actions/coupon';
import * as listActions from 'redux/actions/list';

function* searchCoupon() {
  yield takeLatest(COUPONS_LOAD, function*(action) {
    try {
      let coupons = yield call(couponService.list, action.payload);
      yield put(listActions.listLoaded(coupons));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findCouponById() {
  yield takeLatest(COUPON_VIEW, function*(action) {
    try {
      let coupon = yield call(couponService.findOne, action.payload);
      yield put(couponActions.get(coupon));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* saveCoupon() {
  yield takeLatest(COUPON_CREATE, function*(action) {
    try {
      let coupon = yield call(couponService.create, action.payload);
      yield put(couponActions.createUpdateOk(coupon));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateCoupon() {
  yield takeLatest(COUPON_UPDATE, function*(action) {
    try {
      let coupon = yield call(couponService.update, action.payload);
      yield put(couponActions.createUpdateOk(coupon));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateToActivate() {
  yield takeLatest(COUPON_ACTIVATE, function*(action) {
    try {
      let coupon = yield call(couponService.activate, action.payload);
      yield put(couponActions.createUpdateOk(coupon));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* updateToInactivate() {
  yield takeLatest(COUPON_INACTIVATE, function*(action) {
    try {
      let coupon = yield call(couponService.inactivate, action.payload);
      yield put(couponActions.createUpdateOk(coupon));
    } catch (err) {
      apiErrorActions.handle(err);
    }
  });
}

function* removeCoupon() {
  yield takeLatest(COUPON_DELETE, function*(action) {
    try {
      let coupon = yield call(couponService.remove, action.payload);
      yield put(couponActions.createUpdateOk(coupon));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* couponSagas() {
  yield all([
    searchCoupon(),
    findCouponById(),
    updateToActivate(),
    updateToInactivate(),
    saveCoupon(),
    updateCoupon(),
    removeCoupon()
  ]);
}
