import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  ATTRIBUTE_CREATE,
  ATTRIBUTE_UPDATE,
  ATTRIBUTE_DELETE,
  ATTRIBUTE_VIEW,
  ATTRIBUTE_LIST,
  ATTRIBUTE_LOAD_ALL,
  ATTRIBUTE_REMOVE_LIST_ITEM,
  ATTRIBUTE_ADD_LIST_ITEM,
  ATTRIBUTE_PHONE_BRAND_LOAD_ALL,
  ATTRIBUTE_LOAD_PHONES_BY_BRAND
} from '../actions/attribute/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as attributeService from '../services/attribute';
import * as attributeActions from 'redux/actions/attribute';
import * as listActions from 'redux/actions/list';

function* createAttribute() {
  yield takeLatest(ATTRIBUTE_CREATE, function*(action) {
    try {
      let attributes = yield call(attributeService.create, action.payload);
      yield put(attributeActions.attributeSubmitOk(attributes));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateAttribute() {
  yield takeLatest(ATTRIBUTE_UPDATE, function*(action) {
    try {
      let attributes = yield call(attributeService.update, action.payload);
      yield put(attributeActions.attributeSubmitOk(attributes));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* removeAttribute() {
  yield takeLatest(ATTRIBUTE_DELETE, function*(action) {
    try {
      let attributes = yield call(attributeService.remove, action.payload);
      yield put(attributeActions.attributeSubmitOk(attributes));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* removeAttributeItem() {
  yield takeLatest(ATTRIBUTE_REMOVE_LIST_ITEM, function*(action) {
    try {
      let attributes = yield call(attributeService.removeListAttribute, action.payload);
      yield put(attributeActions.attributeSubmitOk(attributes));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* addAttributeItem() {
  yield takeLatest(ATTRIBUTE_ADD_LIST_ITEM, function*(action) {
    try {
      let attributes = yield call(attributeService.addListAttribute, action.payload);
      yield put(attributeActions.attributeSubmitOk(attributes));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* listAttribute() {
  yield takeLatest(ATTRIBUTE_LIST, function*(action) {
    try {
      let attributes = yield call(attributeService.list, action.payload);
      yield put(listActions.listLoaded(attributes));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* findOne() {
  yield takeLatest(ATTRIBUTE_VIEW, function*(action) {
    let attribute = yield call(attributeService.findOneByType, action.payload);
    yield put(attributeActions.foundOne(attribute));
  });
}

function* loadAll() {
  yield takeLatest(ATTRIBUTE_LOAD_ALL, function*(action) {
    let attributes = yield call(attributeService.loadAll);
    yield put(attributeActions.allLoaded(attributes));
  });
}

function* loadAllPhoneBrands() {
  yield takeLatest(ATTRIBUTE_PHONE_BRAND_LOAD_ALL, function*(action) {
    let phoneBrands = yield call(attributeService.loadAllPhoneBrands);
    yield put(attributeActions.allPhoneBrandsLoaded(phoneBrands));
  });
}

function* loadPhonesByBrand() {
  yield takeLatest(ATTRIBUTE_LOAD_PHONES_BY_BRAND, function*(action) {
    let phones = yield call(attributeService.loadPhonesByBrandIds, action.payload);
    yield put(attributeActions.loadedPhonesByBrandIds(phones));
  });
}

export default function* attributeSagas() {
  yield all([
    createAttribute(),
    updateAttribute(),
    removeAttribute(),
    listAttribute(),
    findOne(),
    loadAll(),
    loadAllPhoneBrands(),
    loadPhonesByBrand(),
    removeAttributeItem(),
    addAttributeItem()
  ]);
}
