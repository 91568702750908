import {
  COUPONS_LOAD,
  COUPON_VIEW,
  COUPON_VIEW_LOADED,
  COUPON_ACTIVATE,
  COUPON_INACTIVATE,
  COUPON_CREATE,
  COUPON_UPDATE,
  COUPON_CREATE_UPDATE_OK,
  COUPON_UNLOAD,
  COUPON_DELETE
} from './action_types';

const search = payload => ({
  type: COUPONS_LOAD,
  payload: payload
});

const findOne = payload => ({
  type: COUPON_VIEW,
  payload: payload
});

const get = payload => ({
  type: COUPON_VIEW_LOADED,
  payload: payload
});

const activate = id => ({
  type: COUPON_ACTIVATE,
  payload: id
});

const inactivate = id => ({
  type: COUPON_INACTIVATE,
  payload: id
});

const remove = id => ({
  type: COUPON_DELETE,
  payload: id
});

const create = payload => ({
  type: COUPON_CREATE,
  payload: payload
});

const update = payload => ({
  type: COUPON_UPDATE,
  payload: payload
});

const createUpdateOk = payload => ({
  type: COUPON_CREATE_UPDATE_OK,
  payload: payload
});

const unload = () => ({
  type: COUPON_UNLOAD,
  payload: {}
});

export { search, findOne, get, activate, inactivate, remove, create, update, createUpdateOk, unload };
