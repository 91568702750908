import React from 'react';
import { Steps } from 'antd';
import styles from './variantGeneratorSteps.module.less';

const Step = Steps.Step;

export default class VariantGeneratorSteps extends React.Component {
  getSteps = () => ['Select Attributes', 'Variants Info'];

  render() {
    return (
      <div className={styles.variantGeneratorSteps}>
        <Steps current={this.props.current}>
          {this.getSteps().map((step, idx) => (
            <Step key={idx} title={step} />
          ))}
        </Steps>
      </div>
    );
  }
}
