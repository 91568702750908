import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styles from './navbar.module.less';

class Navbar extends Component {
  render() {
    return (
      <Row className={styles.menuBar}>
        <Col span={8} className={styles.titleContainer}>
          <h3 className={styles.title}>{this.props.title}</h3>
        </Col>
        <Col span={16} className={styles.actions}>
          <div className={styles.actionsItems}>{this.props.children}</div>
        </Col>
      </Row>
    );
  }
}

export default Navbar;
