import { CONFIG_LOADED } from '../actions/config/action_types';

const initialStore = {
  loaded: false,
  menu: [],
  logo: 'https://www.wplus.ca/media/logo/stores/1/wpluslogonew.png'
};

const configLoaded = (state, payload) =>
  Object.assign({}, state, {
    loaded: true,
    menu: payload.menu,
    logo: payload.logo
  });

export default function configReducer(state = initialStore, action) {
  switch (action.type) {
    case CONFIG_LOADED:
      return configLoaded(state, action.payload);
    default:
      return state;
  }
}
