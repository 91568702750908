export const getCustomerContent = store => (store.customer ? store.customer : {});
export const getCustomer = store => {
  return getCustomerContent(store).customer;
};
export const isLoaded = store => {
  let content = getCustomerContent(store);
  return content.isLoaded != null && content.isLoaded;
};
export const getStatus = store => {
  return store.customer.customer.status != null && store.customer.customer.status.id;
};
