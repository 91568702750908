import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import * as employeeSelector from 'redux/selectors/employee';
import * as employeeActions from 'redux/actions/employee';
import * as roleActions from 'redux/actions/role';
import * as roleSelector from 'redux/selectors/role';
import { EMPLOYEE_CREATE_UPDATE_OK } from 'redux/actions/employee/action_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner, Navbar } from 'components';
import EmpForm from '../Form/empForm';
import { withEventBus } from 'context/eventbus';
import styles from './employeeAdd.module.less';

class EmployeeAdd extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.props.findAllRoles();
    this.subscriptions.push(
      this.props.eventBusSubscribe(EMPLOYEE_CREATE_UPDATE_OK, action => {
        this.props.history.push(`/employees/${action.payload.id}/`);
      })
    );
  }

  submitHandle = formData => {
    this.props.create(formData);
  };

  componentWillUnmount() {
    this.props.roleUnload();
    this.props.unload();
  }

  render() {
    let content;
    var emp = {
      id: 0,
      role: {}
    };
    if (!this.props.isRolesLoaded) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else {
      content = (
        <EmpForm
          key={0}
          roles={this.props.allroles}
          submitHandle={this.submitHandle}
          eventBusSubscribe={this.props.eventBusSubscribe}
          data={emp}
        ></EmpForm>
      );
    }
    return (
      <div className={styles.container}>
        <Navbar title={'Create Employee'}>
          <Link type="primary" to={'/employees/'}>
            <Tooltip placement="top" title="List Employee">
              <FontAwesomeIcon icon="th-list" />
            </Tooltip>
          </Link>
        </Navbar>
        <div style={{ padding: 16 }}>{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  findByEmployeeId: employeeActions.findEmployeeById,
  findAllRoles: roleActions.findAllRoles,
  update: employeeActions.employeeUpdate,
  create: employeeActions.employeeCreate,
  unload: employeeActions.employeeUnload,
  roleUnload: roleActions.unload
};

const mapStateToProps = (state, props) => {
  return {
    isLoaded: employeeSelector.isLoaded(state),
    employee: employeeSelector.getEmployee(state),
    allroles: roleSelector.getAllRoles(state),
    isRolesLoaded: roleSelector.isLoaded(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEventBus(EmployeeAdd));
