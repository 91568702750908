import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  EMPLOYEES_LOAD,
  EMPLOYEE_VIEW,
  EMPLOYEE_CREATE,
  EMPLOYEE_UPDATE,
  EMPLOYEE_INACTIVATE,
  EMPLOYEE_ACTIVATE
} from '../actions/employee/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as employeeService from '../services/employee';
import * as employeeActions from 'redux/actions/employee';
import * as listActions from 'redux/actions/list';

function* searchEmployees() {
  yield takeLatest(EMPLOYEES_LOAD, function*(action) {
    try {
      let employees = yield call(employeeService.searchEmployee, action.payload);
      yield put(listActions.listLoaded(employees));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findEmployeeById() {
  yield takeLatest(EMPLOYEE_VIEW, function*(action) {
    try {
      let employee = yield call(employeeService.findEmployeeById, action.payload);
      yield put(employeeActions.foundEmployeeById(employee));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* saveEmployee() {
  yield takeLatest(EMPLOYEE_CREATE, function*(action) {
    try {
      let employee = yield call(employeeService.createEmployee, action.payload);
      yield put(employeeActions.employeeCreatedUpdatedOk(employee));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateEmployee() {
  yield takeLatest(EMPLOYEE_UPDATE, function*(action) {
    try {
      let employee = yield call(employeeService.updateEmployee, action.payload);
      yield put(employeeActions.employeeCreatedUpdatedOk(employee));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateToActivate() {
  yield takeLatest(EMPLOYEE_ACTIVATE, function*(action) {
    try {
      let employee = yield call(employeeService.updateToActive, action.payload);
      yield put(employeeActions.employeeStutusUpdatedOk(employee));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* updateToInactivate() {
  yield takeLatest(EMPLOYEE_INACTIVATE, function*(action) {
    try {
      let employee = yield call(employeeService.updateToInactive, action.payload);
      yield put(employeeActions.employeeStutusUpdatedOk(employee));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

export default function* employeeSagas() {
  yield all([
    searchEmployees(),
    findEmployeeById(),
    saveEmployee(),
    updateEmployee(),
    updateToActivate(),
    updateToInactivate()
  ]);
}
