import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  CUSTOMERS_LOAD,
  CUSTOMER_VIEW,
  CUSTOMER_CREATE,
  CUSTOMER_UPDATE,
  CUSTOMER_ACTIVATE,
  CUSTOMER_INACTIVATE
} from '../actions/customer/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as customerService from '../services/customer';
import * as customerActions from 'redux/actions/customer';
import * as listActions from 'redux/actions/list';

function* searchCustomer() {
  yield takeLatest(CUSTOMERS_LOAD, function*(action) {
    try {
      let customers = yield call(customerService.searchCustomer, action.payload);
      yield put(listActions.listLoaded(customers));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* findCustomerById() {
  yield takeLatest(CUSTOMER_VIEW, function*(action) {
    try {
      let customer = yield call(customerService.findCustomerById, action.payload);
      yield put(customerActions.foundCustomerById(customer));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* saveCustomer() {
  yield takeLatest(CUSTOMER_CREATE, function*(action) {
    try {
      let customer = yield call(customerService.createCustomer, action.payload);
      yield put(customerActions.customerCreateUpdateOk(customer));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateCustomer() {
  yield takeLatest(CUSTOMER_UPDATE, function*(action) {
    try {
      let customer = yield call(customerService.updateCustomer, action.payload);
      yield put(customerActions.customerCreateUpdateOk(customer));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* updateToActivate() {
  yield takeLatest(CUSTOMER_ACTIVATE, function*(action) {
    try {
      let customer = yield call(customerService.updateToActive, action.payload);
      yield put(customerActions.customerCreateUpdateOk(customer));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

function* updateToInactivate() {
  yield takeLatest(CUSTOMER_INACTIVATE, function*(action) {
    try {
      let customer = yield call(customerService.updateToInactive, action.payload);
      yield put(customerActions.customerCreateUpdateOk(customer));
    } catch (err) {
      apiErrorActions.handle(err);
    }
  });
}

export default function* customerSagas() {
  yield all([
    searchCustomer(),
    findCustomerById(),
    updateToActivate(),
    updateToInactivate(),
    saveCustomer(),
    updateCustomer()
  ]);
}
