import { fetchApi } from 'Utils/utils';

const search = payload => {
  return fetchApi.post('/api/v1/homepage/widgets/search/', payload);
};

const save = payload => {
  return fetchApi.post('/api/v1/homepage/widgets/', payload);
};

const update = payload => {
  return fetchApi.put(`/api/v1/homepage/widgets/${payload.id}/`, payload);
};

const remove = payload => {
  return fetchApi.delete(`/api/v1/homepage/widgets/${payload.id}/`);
};

const load = payload => {
  return fetchApi.get(`/api/v1/homepage/widgets/${payload.id}/`);
};

const saveStaticImages = payload => {
  return fetchApi.fileUpload('/api/v1/homepage/widgets/staticImages/', payload);
};

export { search, save, update, remove, load, saveStaticImages };
