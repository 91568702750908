import {
	EMPLOYEES_LOAD,
	EMPLOYEES_LOADED,
	EMPLOYEE_VIEW,
	EMPLOYEE_VIEW_LOADED,
	EMPLOYEE_CREATE_UPDATE_OK,
  EMPLOYEE_STAUS_UPDATE_OK,
	EMPLOYEE_CREATE,
	EMPLOYEE_UPDATE,
	EMPLOYEE_UNLOAD,
	EMPLOYEE_ACTIVATE,
	EMPLOYEE_INACTIVATE
} from './action_types';

const watchSearchEmployees = payload => ({
  type: EMPLOYEES_LOAD,
  payload: payload
});

const searchEmployeesResult = payload => ({
  type: EMPLOYEES_LOADED,
  payload: payload
});

const findEmployeeById = payload => ({
  type: EMPLOYEE_VIEW,
  payload: payload
});

const foundEmployeeById = payload => ({
  type: EMPLOYEE_VIEW_LOADED,
  payload: payload
});

const employeeCreate = payload => ({
  type: EMPLOYEE_CREATE,
  payload: payload
});

const employeeCreatedUpdatedOk = payload => ({
  type: EMPLOYEE_CREATE_UPDATE_OK,
  payload: payload
});

const employeeStutusUpdatedOk = payload => ({
  type: EMPLOYEE_STAUS_UPDATE_OK,
  payload: payload
});

const employeeUpdate = payload => ({
  type: EMPLOYEE_UPDATE,
  payload: payload
});

const employeeUnload = () => ({
	  type: EMPLOYEE_UNLOAD,
	  payload: {}
});

const employeeActivate = (id) => ({
	  type: EMPLOYEE_ACTIVATE,
	  payload: id
});

const employeeInactivate = (id) => ({
	  type: EMPLOYEE_INACTIVATE,
	  payload: id
});


export {
	watchSearchEmployees,
	searchEmployeesResult,
	findEmployeeById,
	foundEmployeeById,
	employeeCreate,
	employeeCreatedUpdatedOk,
  employeeStutusUpdatedOk,
	employeeUpdate,
	employeeUnload,
	employeeActivate,
	employeeInactivate
};
