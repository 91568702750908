import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faLock,
  faSearch,
  faEnvelopeOpen,
  faShoppingCart,
  faEnvelope,
  faAngleDoubleRight,
  faMapMarkedAlt,
  faPhone,
  faSignOutAlt,
  faSyncAlt,
  faCheckSquare,
  faThList,
  faEdit,
  faPlusSquare,
  faCheck,
  faTimes,
  faFilter,
  faTrash,
  faPrint,
  faMoneyCheckAlt,
  faBan,
  faHandPaper
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faUser,
  faLock,
  faSearch,
  faEnvelopeOpen,
  faShoppingCart,
  faEnvelope,
  faAngleDoubleRight,
  faMapMarkedAlt,
  faPhone,
  faSignOutAlt,
  faSyncAlt,
  faCheckSquare,
  faThList,
  faEdit,
  faPlusSquare,
  faCheck,
  faTimes,
  faFilter,
  faTrash,
  faPrint,
  faMoneyCheckAlt,
  faBan,
  faHandPaper
);
