import React, { Component } from 'react';
import { Button, Row, Col, Descriptions } from 'antd';
import ReactQuill from 'react-quill'; // ES6
import styles from './view.module.less';
import 'react-quill/dist/quill.snow.css';

class View extends Component {
  render() {
    return (
      <div className={styles.view} style={{ padding: 8 }}>
        <div className="actionBar">
          <Button type="primary" onClick={this.props.toggleMode}>
            Edit
          </Button>
        </div>
        <h2 className="page-title">{this.props.page.title}</h2>
        <Row gutter={24}>
          <Col xs={24} sm={14} md={24}>
            <Descriptions bordered size="large" column={2}>
              <Descriptions.Item label="Title">{this.props.page.title}</Descriptions.Item>
              <Descriptions.Item label="URL">{this.props.page.url}</Descriptions.Item>
              <Descriptions.Item label="Created By">
                {this.props.page.createdBy && this.props.page.createdBy.name ? this.props.page.createdBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Created On">{this.props.page.createdOn}</Descriptions.Item>
              <Descriptions.Item label="Updated By">
                {this.props.page.updatedBy && this.props.page.updatedBy.name ? this.props.page.updatedBy.name : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Updated On">{this.props.page.updatedOn}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={14} md={24}>
            <ReactQuill theme="bubble" readOnly value={this.props.page.body} onChange={this.handleChange} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default View;
