import { 
  PHONE_BRAND_LOADED, 
  PHONE_BRAND_UNLOADED, 
  PHONE_BRAND_ALL_LOADED 
} from 'redux/actions/phoneBrand/action_types';

const initialStore = {
  isLoaded: false,
  phoneBrand: {},
  isAllLoaded: false,
  phoneBrands: {}
};

const load = (state, payload) =>
  Object.assign({}, state, {
    isLoaded: true,
    phoneBrand: payload
  });

const loadAll = (state, payload) =>
  Object.assign({}, state, {
    isAllLoaded: true,
    phoneBrands: payload
  });

export default function phoneSlide(state = initialStore, action) {
  switch (action.type) {
    case PHONE_BRAND_LOADED:
      return load(state, action.payload);
    case PHONE_BRAND_ALL_LOADED:  
      return loadAll(state, action.payload);
    case PHONE_BRAND_UNLOADED:
      return Object.assign({}, state, initialStore);
    default:
      return state;
  }
}