import { call, put, takeLatest, all } from 'redux-saga/effects';
import { STORE_GROUP_SEARCH } from '../actions/storeGroup/action_types';
import * as apiErrorActions from 'redux/actions/apiError';
import * as storeGroupService from '../services/storeGroup';
import * as listActions from 'redux/actions/list';

function* search() {
  yield takeLatest(STORE_GROUP_SEARCH, function*(action) {
    try {
      let storeGroups = yield call(storeGroupService.search, action.payload);
      yield put(listActions.listLoaded(storeGroups));
    } catch (err) {
      yield apiErrorActions.handle(err);
    }
  });
}

export default function* storeGroupSagas() {
  yield all([search()]);
}
