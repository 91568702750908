import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Alert, Modal, Row, Col, Select, Button, Input } from 'antd';
import { withEventBus, withScreenLoader } from 'context';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './attributesEditForm.module.less';

const { Option } = Select;

class AttributesEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: [],
      attrsToDelete: [],
      attrsToAdd: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        this.props.hideScreenLoader();
        let errors = {
          globalErrors: action.payload.globalErrors || [],
          entityErrors: action.payload.entityErrors.attributes || {}
        };
        let globalErrors = displayValidationErrors(this.props.form, errors);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onCheckboxChange = val => {
    this.setState({
      attrsToDelete: val
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let attrsCode = {};
        this.props.allAttributes.forEach(a => (attrsCode[a.code] = a.id));
        let attrs = this.state.attrsToDelete.map(a => Object.assign({ attributeId: a, action: 'REMOVE' }));
        if (values.attr) {
          attrs.push(
            ...Object.keys(values.attr).map(a =>
              Object.assign({ attributeId: attrsCode[a], defaultValue: values.attr[a] || '', action: 'ADD' })
            )
          );
        }
        if (attrs.length !== 0) {
          let obj = Object.assign({
            id: this.props.product.id,
            variantNameTemplate: values.variantNameTemplate,
            attributes: attrs
          });
          this.props.showScreenLoader();
          this.props.onSubmit(obj);
        } else {
          this.props.onCancel();
        }
      }
    });
  };

  renderText = (getFieldDecorator, a) => {
    return (
      <Col sm={24} key={a.code}>
        <Form.Item label={a.label}>
          {getFieldDecorator(`attr.${a.code}`, {
            rules: [
              {
                required: a.required,
                message: `${a.label} is required.`
              }
            ]
          })(<Input />)}
        </Form.Item>
      </Col>
    );
  };

  renderList = (getFieldDecorator, a) => {
    return (
      <Col sm={24} key={a.code}>
        <Form.Item label={a.label}>
          {getFieldDecorator(`attr.${a.code}`, {
            rules: [
              {
                required: a.required,
                message: 'Select atleast 1 option.'
              }
            ]
          })(
            <Select style={{ width: '100%' }} showSearch optionFilterProp="children">
              {a.value.map((v, idx) => (
                <Option value={v} key={idx}>
                  {v}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
    );
  };

  loadPhones = () => {
    this.props.form.setFields({
      phone: {
        value: []
      }
    });
    let phoneBrandIds = this.props.form.getFieldValue('phoneBrand');
    if (phoneBrandIds) this.props.onPhoneBrandChange([phoneBrandIds]);
  };

  renderPhone = (getFieldDecorator, attr) => {
    return (
      <React.Fragment key={attr.code}>
        <Col sm={24} md={24} key="phoneBrand">
          <Form.Item label="Phone Brand">
            <Row gutter={4}>
              <Col span={18}>
                {getFieldDecorator('attr.phoneBrand', {
                  rules: [
                    {
                      required: attr.required,
                      message: 'Select atleast 1 option.'
                    }
                  ]
                })(
                  <Select style={{ width: '100%' }} showSearch optionFilterProp="children">
                    {this.props.phoneBrands.map(pb => (
                      <Option value={pb.id} key={pb.id}>
                        {pb.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
              <Col span={6}>
                <Button onClick={this.loadPhones}>Load Phones</Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col sm={24} md={24} key="phone">
          <Form.Item label="Phone">
            {getFieldDecorator('attr.phone', {
              rules: [
                {
                  required: attr.required,
                  message: 'Select atleast 1 option.'
                }
              ]
            })(
              <Select style={{ width: '100%' }} showSearch optionFilterProp="children">
                {this.props.phones.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </React.Fragment>
    );
  };

  renderSelector = (getFieldDecorator, attr) => {
    switch (attr.type.id) {
      case 'LIST':
        return this.renderList(getFieldDecorator, attr);
      case 'PHONE':
        return this.renderPhone(getFieldDecorator, attr);
      default:
        return this.renderText(getFieldDecorator, attr);
    }
  };

  addAttributes = val => {
    this.setState({
      attrsToAdd: val
    });
  };

  render() {
    let attrs = this.props.attrs.sort((a, b) => a.order - b.order);
    let allAttrs = {};
    this.props.allAttributes.forEach(a => (allAttrs[a.id] = a));
    let existingAttrs = attrs.map(a => a.code);
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={true}
        title="Update Attributes"
        okText="Update"
        onOk={this.handleSubmit}
        onCancel={this.props.onCancel}
        className={styles.editForm}
        maskClosable={false}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Alert
              className={styles.alerts}
              type="warning"
              message="Be careful!"
              description={`Excessive use of this operation can corrupt data.
              Please use this option when no customer is online as it can take time.
              Please remember to regenerate product variant names.`}
              showIcon
            />
          </Col>
        </Row>
        {this.state.globalErrors.map(error => (
          <Row>
            <Col span={24}>
              <Alert className={styles.alert} key={error} message={error} type="error" />
            </Col>
          </Row>
        ))}
        <Row gutter={24}>
          <Col sm={24}>
            <Form.Item label="Variant name template">
              {getFieldDecorator('variantNameTemplate', {
                rules: [
                  {
                    required: true,
                    message: 'Template is required.'
                  }
                ],
                initialValue: this.props.product.variantNameTemplate
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <p className={styles.heading}>Select attributes to delete</p>
        <Checkbox.Group style={{ width: '100%' }} onChange={this.onCheckboxChange}>
          <Row gutter={24}>
            {attrs.map(a => (
              <Col key={a.code} span={12}>
                <Checkbox value={a.id}>{a.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <p className={styles.heading}>Select attributes to add</p>
        <Row gutter={24}>
          <Col sm={24}>
            <Select allowSearch mode="multiple" style={{ width: '100%' }} onChange={this.addAttributes}>
              {this.props.allAttributes
                .filter(a => existingAttrs.indexOf(a.code) === -1)
                .map(a => (
                  <Select.Option key={a.id}>{a.label}</Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
        <p className={styles.heading}>Default Values</p>
        <Row gutter={24}>{this.state.attrsToAdd.map(add => this.renderSelector(getFieldDecorator, allAttrs[add]))}</Row>
      </Modal>
    );
  }
}

export default withScreenLoader(withEventBus(Form.create({})(AttributesEditForm)));
