import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as listActions from 'redux/actions/list';
import {
  HOMEPAGE_WIDGETS_SEARCH,
  HOMEPAGE_WIDGETS_REMOVE,
  HOMEPAGE_WIDGETS_SAVE,
  HOMEPAGE_WIDGETS_LOAD,
  HOMEPAGE_WIDGETS_SAVE_STATIC_IMAGES
} from 'redux/actions/homepage/widgets/action_types';
import * as widgetService from 'redux/services/homepage/widgets';
import * as widgetActions from 'redux/actions/homepage/widgets';
import * as apiErrorActions from 'redux/actions/apiError';

function* search() {
  yield takeLatest(HOMEPAGE_WIDGETS_SEARCH, function*(action) {
    try {
      let search = yield call(widgetService.search, action.payload);
      yield put(listActions.listLoaded(search));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* load() {
  yield takeLatest(HOMEPAGE_WIDGETS_LOAD, function*(action) {
    try {
      let widget = yield call(widgetService.load, action.payload);
      yield put(widgetActions.loaded(widget));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* save() {
  yield takeLatest(HOMEPAGE_WIDGETS_SAVE, function*(action) {
    try {
      let widget;
      if (action.payload.id !== 0) {
        widget = yield call(widgetService.update, action.payload);
      } else {
        widget = yield call(widgetService.save, action.payload);
      }
      yield put(widgetActions.saveSuccess(widget));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* saveStaticImages() {
  yield takeLatest(HOMEPAGE_WIDGETS_SAVE_STATIC_IMAGES, function*(action) {
    try {
      let widget = yield call(widgetService.saveStaticImages, action.payload);
      yield put(widgetActions.saveSuccess(widget));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

function* remove() {
  yield takeLatest(HOMEPAGE_WIDGETS_REMOVE, function*(action) {
    try {
      yield call(widgetService.remove, action.payload);
      yield put(widgetActions.saveSuccess(null));
    } catch (err) {
      yield put(apiErrorActions.handle(err));
    }
  });
}

export default function* widgetSagas() {
  yield all([search(), load(), remove(), save(), saveStaticImages()]);
}
