// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Tooltip, Popconfirm, Dropdown, Menu, Alert } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as attributeActions from 'redux/actions/attribute';
import * as listActions from 'redux/actions/list';
import { ATTRIBUTE_SUBMIT_OK } from 'redux/actions/attribute/action_types';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import { Link } from 'react-router-dom';
import { WrapperTable } from 'components';
import { withEventBus } from 'context/eventbus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Fragment = React.Fragment;

class AttributeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ATTRIBUTE_SUBMIT_OK, action => {
        this.reload();
      })
    );

    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  componentWillUnmount() {
    this.props.unload();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleDelete = id => {
    this.props.removeAttribute(id);
  };

  reload = () => {
    this.props.reload();
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Link to={'/catalog/attributes/create/STRING/'}>String attribute</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to={'/catalog/attributes/create/LIST/'}>List attribute</Link>
        </Menu.Item>
        {/**
        <Menu.Item key="2">
          <Link to={'/catalog/attributes/create/LONG/'}>Number attribute</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to={'/catalog/attributes/create/DOUBLE/'}>Decimal attribute</Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to={'/catalog/attributes/create/YES_NO/'}>Yes no attribute</Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to={'/catalog/attributes/create/DATE/'}>Date attribute</Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to={'/catalog/attributes/create/DATETIME/'}>DateTime attribute</Link>
        </Menu.Item>
        **/}
      </Menu>
    );

    const columns = [
      {
        title: 'Attribute Id',
        dataIndex: 'code',
        sorter: true
      },
      {
        title: 'Label',
        dataIndex: 'label',
        sorter: true
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: type => {
          if (type) return <span>{type.name}</span>;
          else return '';
        },
        sorter: true
      },
      {
        title: 'Is unique',
        dataIndex: 'unique',
        render: unique => <span>{unique ? 'Yes' : 'No'}</span>
      },
      {
        title: 'Used in Filters',
        dataIndex: 'useInFiltering',
        render: useInFiltering => <span>{useInFiltering ? 'Yes' : 'No'}</span>
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (id, row) => {
          if (id)
            return (
              <Fragment>
                <Link to={`/catalog/attributes/${id}/${row.type.id}/`}>view</Link>
                <Divider type="vertical" />
                <Link to={`/catalog/attributes/edit/${id}/${row.type.id}/`}>edit</Link>
                <Divider type="vertical" />
                {row.type.id === 'PHONE' ? (
                  ''
                ) : (
                  <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(row.id)}>
                    <a href="/">delete</a>
                  </Popconfirm>
                )}
              </Fragment>
            );
        }
      }
    ];
    let tableAction = (
      <Fragment>
        <Dropdown type="primary" overlay={menu}>
          <Tooltip placement="rightTop" title="Create attribute">
            <FontAwesomeIcon icon="plus-square" />
          </Tooltip>
        </Dropdown>
      </Fragment>
    );

    let globalErrorAlert = this.state.globalErrors.map((p, index) => (
      <Alert key={index} type="error" message={p}></Alert>
    ));

    return (
      <div>
        {globalErrorAlert}
        <Row>
          <Col span={24}>
            <WrapperTable
              title="Attribute List"
              filters={filters}
              columns={columns}
              tableAction={tableAction}
              data={this.props.listData}
              api={this.props.searchAttribute}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const filters = [
  {
    field: 'code',
    type: 'TEXT'
  },
  {
    field: 'label',
    type: 'TEXT'
  },
  {
    field: 'type',
    type: 'OPTION',
    options: [
      { id: 'STRING', name: 'Active' },
      { id: 'DOUBLE', name: 'Decimal' },
      { id: 'LONG', name: 'Number' },
      { id: 'LIST', name: 'List' },
      { id: 'YES_NO', name: 'Yes No' },
      { id: 'DATE', name: 'Date' },
      { id: 'DATETIME', name: 'Data and Time' },
      { id: 'PHONE', name: 'Phone' }
    ]
  }
];

const mapDispatchToProps = {
  searchAttribute: attributeActions.list,
  removeAttribute: attributeActions.remove,
  unload: listActions.listUnload,
  reload: listActions.reload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(AttributeList));
