import { 
  INVOICES_SEARCH, 
  INVOICE_VIEW, 
  INVOICE_VIEW_LOADED, 
  INVOICE_APPROVE,
  INVOICE_PAID,
  INVOICE_CREATE_UPDATE_OK,
  INVOICE_UNLOAD 
} from './action_types';

const searchInvoice = payload => ({
  type: INVOICES_SEARCH,
  payload: payload
});

const findOne = payload => ({
  type: INVOICE_VIEW,
  payload: payload
});

const foundOne = payload => ({
  type: INVOICE_VIEW_LOADED,
  payload: payload
});

const approve = payload => ({
  type: INVOICE_APPROVE,
  payload: payload
});

const paid = payload => ({
  type: INVOICE_PAID,
  payload: payload
});

const invoiceCreateUpdateOk = payload => ({
  type: INVOICE_CREATE_UPDATE_OK,
  payload: payload
});

const unload = () => ({
  type: INVOICE_UNLOAD,
  payload: {}
});

export { searchInvoice,  findOne, foundOne, invoiceCreateUpdateOk, approve, paid, unload };
