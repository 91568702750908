// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Divider, Popconfirm } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as imageSliderAction from 'redux/actions/imageSlider';
import * as listActions from 'redux/actions/list';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WrapperTable } from 'components';
import { SLIDER_DELETE_SUCCESS } from 'redux/actions/imageSlider/action_types';
import { withEventBus } from 'context';

const Fragment = React.Fragment;

class ImageSliderList extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(SLIDER_DELETE_SUCCESS, action => {
        this.props.reload();
      })
    );
  }

  render() {
    const columns = [
      {
        title: 'Type',
        dataIndex: 'asset.type'
      },
      {
        title: 'Forward Link',
        dataIndex: 'forwardLink',
        sorter: true
      },
      {
        title: 'Order',
        dataIndex: 'order',
        sorter: true
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        sorter: true
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        sorter: true
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Fragment>
            <Link to={`/options/slides/${id}/`}>View</Link>
            <Divider type="vertical" />
            <Link to={`/options/slides/edit/${id}/`}>Edit</Link>
            <Divider type="vertical" />
            <Popconfirm title="Sure to delete?" onConfirm={() => this.props.remove(id)}>
              <a href="/">Delete</a>
            </Popconfirm>
          </Fragment>
        )
      }
    ];

    let tableAction = (
      <Fragment>
        <Button type="primary" onClick={() => this.props.history.push('/options/slides/create/')}>
          <FontAwesomeIcon icon="plus-square" style={{ marginRight: '8px' }} />
          Add Slide
        </Button>
      </Fragment>
    );

    return (
      <div>
        <Row>
          <Col span={24}>
            <WrapperTable
              title="Image Slider List"
              filters={filters}
              columns={columns}
              data={this.props.listData}
              api={this.props.searchInvoice}
              tableAction={tableAction}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const filters = [
  {
    field: 'startDate',
    type: 'DATE'
  },
  {
    field: 'endDate',
    type: 'DATE'
  }
];

const mapDispatchToProps = {
  searchInvoice: imageSliderAction.search,
  unload: listActions.listUnload,
  reload: listActions.reload,
  remove: imageSliderAction.deleteSlide
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withEventBus(ImageSliderList));
