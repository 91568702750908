import {
  MENU_LOAD,
  MENU_LOADED,
  MENU_DELETE,
  MENU_DELETE_SUCCESS,
  MENU_UNLOADED,
  MENU_UPDATE,
  MENU_CREATE_UPDATE_SUCCESS,
  MENU_IMAGE_UPLOAD,
  MENU_CREATE
} from './action_types';

const menuLoad = () => ({
  type: MENU_LOAD,
  payload: {}
});

const menuLoaded = payload => ({
  type: MENU_LOADED,
  payload: payload
});

const unload = () => ({
  type: MENU_UNLOADED,
  payload: {}
});

const update = payload => ({
  type: MENU_UPDATE,
  payload: payload
});

const deleteMenu = payload => ({
  type: MENU_DELETE,
  payload: payload
});

const deleteMenuSuccess = payload => ({
  type: MENU_DELETE_SUCCESS,
  payload: payload
});

const menuCreateUpdateSucess = payload => ({
  type: MENU_CREATE_UPDATE_SUCCESS,
  payload: payload
});

const uploadImage = payload => ({
  type: MENU_IMAGE_UPLOAD,
  payload: payload
});

const create = payload => ({
  type: MENU_CREATE,
  payload: payload
});

export {
  menuLoad,
  menuLoaded,
  deleteMenu,
  deleteMenuSuccess,
  unload,
  update,
  menuCreateUpdateSucess,
  create,
  uploadImage
};
