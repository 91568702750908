import React, { Component } from 'react';
import { Button, Row, Col, Alert } from 'antd';
import { GenericTableView, ImageUploader } from 'components';
import { ERROR_422 } from 'redux/actions/apiError/action_types';
import { displayValidationErrors } from 'Utils/legacyValidationHelper';
import styles from './view.module.less';

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      globalErrors: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(ERROR_422, action => {
        let globalErrors = displayValidationErrors(this.props.form, action.payload);
        if (globalErrors.length > 0) {
          this.setState({
            globalErrors: globalErrors
          });
        }
      })
    );
  }

  convertToViewObj = c => [
    {
      fields: [
        {
          label: 'Name',
          value: c.name
        },
        {
          label: 'Url',
          value: c.url
        },
        {
          label: 'Status',
          value: c.status === 'ENABLED' ? 'Enabled' : 'Disabled'
        },
        {
          label: 'Anchor',
          value: c.isAnchor ? 'Yes' : 'No'
        },
        {
          label: 'Description',
          value: c.description,
          span: { sm: 24 }
        }
      ]
    },
    {
      title: 'Audit Information',
      fields: [
        {
          label: 'Created On',
          value: c.createdOn
        },
        {
          label: 'Created By',
          value: c.createdBy && c.createdBy.name ? c.createdBy.name : ''
        },
        {
          label: 'Updated On',
          value: c.updatedOn
        },
        {
          label: 'Updated By',
          value: c.updatedBy && c.updatedBy.name ? c.updatedBy.name : ''
        }
      ]
    }
  ];
  render() {
    // let imgUrl = this.props.category.asset ? this.props.category.asset.path : NO_IMG_URL;
    return (
      <div className={styles.view}>
        <div className="actionBar">
          {this.props.category.parentId && (
            <Button type="primary" onClick={this.props.toggleMode}>
              Edit
            </Button>
          )}
        </div>
        <h2 className="page-title">{this.props.category.name}</h2>
        <Row gutter={24}>
          <Col span={24}>
            {this.state.globalErrors.map(error => (
              <Alert key={error} message={error} type="error" />
            ))}
          </Col>
          <Col xs={24} sm={10} md={8} className={styles.categoryImg}>
            <ImageUploader
              id={this.props.category.id}
              key={this.props.category.id}
              uploadImage={this.props.uploadImage}
              data={this.props.category.image}
              eventBusSubscribe={this.props.eventBusSubscribe}
            ></ImageUploader>
          </Col>
          <Col xs={24} sm={14} md={16}>
            <GenericTableView viewObj={this.convertToViewObj(this.props.category)} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default View;
