// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tableListSelector from 'redux/selectors/tableList';
import { Row, Col, Button } from 'antd';
import * as productVariantAction from 'redux/actions/productVariant';
import * as listActions from 'redux/actions/list';
import { WrapperTable } from 'components';
import { PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS } from 'redux/actions/productVariant/action_types';
import { withEventBus, withScreenLoader } from 'context';

const Fragment = React.Fragment;

class BackOrderList extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      this.props.eventBusSubscribe(PRODUCT_VARIANT_UPDATE_STATUS_SUCCESS, action => {
        this.props.hideScreenLoader();
        this.props.reloadTable();
      })
    );
  }
  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.props.unload();
  }

  putInStock = (productId, variantId) => {
    let payload = {
      productId: productId,
      variantId: variantId,
      stockStatus: 'IN_STOCK',
      enabled: true
    };
    this.props.showScreenLoader();
    this.props.updateStatus(payload);
  };

  disableProduct = (productId, variantId) => {
    let payload = {
      productId: productId,
      variantId: variantId,
      stockStatus: 'IN_STOCK',
      enabled: false
    };
    this.props.showScreenLoader();
    this.props.updateStatus(payload);
  };

  render() {
    const columns = [
      {
        title: 'Variant No',
        dataIndex: 'variantNumber',
        sorter: true
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true
      },
      {
        title: 'Sku',
        dataIndex: 'sku'
      },
      {
        title: 'Action',
        dataIndex: 'id',
        render: (id, row) => (
          <Fragment>
            <Button onClick={() => this.putInStock(row.productId, row.id)}>Back In-Stock</Button>
            <Button
              type="danger"
              style={{ marginLeft: '8px' }}
              onClick={() => this.disableProduct(row.productId, row.id)}
            >
              Disable
            </Button>
          </Fragment>
        )
      }
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <WrapperTable
              title="Backorder products List"
              defaultFilters={defaultFilters}
              filters={filters}
              columns={columns}
              data={this.props.listData}
              api={this.props.search}
              tableAction={tableAction}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

let tableAction = <Fragment></Fragment>;

const defaultFilters = {
  enabled: {
    _field: 'enabled',
    value: true,
    operator: 'EQUAL'
  },
  stockStatus: {
    _field: 'stockStatus',
    value: 'IN_STOCK',
    operator: 'NOT_EQUAL'
  }
};

const filters = [
  {
    field: 'variantNumber',
    type: 'NUMBER',
    operator: 'EQUAL'
  },
  {
    field: 'name',
    type: 'TEXT',
    operator: 'CONTAIN'
  },
  {
    field: 'sku',
    type: 'TEXT',
    operator: 'CONTAIN'
  }
];

const mapDispatchToProps = {
  search: productVariantAction.search,
  unload: listActions.listUnload,
  updateStatus: productVariantAction.updateStatus,
  reloadTable: listActions.reload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScreenLoader(withEventBus(BackOrderList)));
