import moment from 'moment-timezone';

const createChunks = function(list, size) {
  if (list == null) {
    return [];
  }
  let index = 0;
  let chunks = [];
  while (index < list.length) {
    chunks.push(list.slice(index, index + size));
    index += size;
  }
  return chunks;
};
const toPrice = p => (isNaN(p) ? p : p.toFixed(2));
const toDateString = d => (d ? moment.tz(d, 'America/Toronto').format('YYYY-MM-DD HH:mm:ss z') : d);
const toDateWithoutTimeString = d => (d ? moment.tz(d, 'America/Toronto').format('YYYY-MM-DD') : d);
const productStatusToStr = status => {
  switch (status) {
    case 'IN_STOCK':
      return 'In Stock';
    case 'OUT_OF_STOCK':
      return 'Out of Stock';
    case 'ARRIVING_SOON':
      return 'Arriving Soon';
    default:
      return 'Unknown';
  }
};
const getAuthHeader = () => {
  let token = localStorage.getItem('token');
  return token ? `Bearer: ${token}` : '';
};
const wrapUrlWithAuthToken = url => {
  return (
    `${window.MY_APP_API_URL}${url}` + (url.includes('?') ? '&' : '') + `id_token=${localStorage.getItem('token')}`
  );
};

const fetchGet = function(url) {
  return fetch(window.MY_APP_API_URL + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthHeader()
    }
  })
    .then(res => handleErrors(res))
    .then(res => fetchResponseHandler(res));
};

const fetchPost = function(url, payload) {
  return fetch(window.MY_APP_API_URL + url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthHeader()
    },
    body: JSON.stringify(payload)
  })
    .then(res => handleErrors(res))
    .then(res => fetchResponseHandler(res));
};

const fetchPostFormData = function(url, payload) {
  return fetch(window.MY_APP_API_URL + url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      Authorization: getAuthHeader()
    },
    body: payload
  })
    .then(res => handleErrors(res))
    .then(res => fetchResponseHandler(res));
};

const fetchPut = function(url, payload) {
  return fetch(window.MY_APP_API_URL + url, {
    method: 'PUT',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthHeader()
    },
    body: JSON.stringify(payload)
  })
    .then(res => handleErrors(res))
    .then(res => fetchResponseHandler(res));
};

const fetchDelete = function(url, payload) {
  return fetch(window.MY_APP_API_URL + url, {
    method: 'DELETE',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthHeader()
    },
    body: JSON.stringify(payload)
  })
    .then(res => handleErrors(res))
    .then(res => fetchResponseHandler(res));
};

const handleErrors = function(res) {
  if (res.status === 401) {
    throw new ApiError(res.status, res.statusText);
  }
  if (res.status !== 200) {
    return res.json().then(resp => {
      throw new ApiError(res.status, resp);
    });
  } else {
    return res.json();
  }
};

class ApiError extends Error {
  constructor(status, response) {
    super('ApiError');
    this.status = status;
    this.response = response;
  }
}

const fetchResponseHandler = function(res) {
  if (res.status === 'OK') {
    return res.response;
  } else {
    throw Error(res.msg);
  }
};

const isObjectEmpty = function(obj) {
  return Object.getOwnPropertyNames(obj).length >= 1;
};

const fetchApi = { get: fetchGet, post: fetchPost, put: fetchPut, delete: fetchDelete, fileUpload: fetchPostFormData };
export {
  createChunks,
  fetchApi,
  toPrice,
  toDateString,
  toDateWithoutTimeString,
  productStatusToStr,
  isObjectEmpty,
  wrapUrlWithAuthToken
};
