import React, { Component } from 'react';
import { Autocomplete } from 'components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Modal, InputNumber, Select } from 'antd';

class Add extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={this.props.visible}
        destroyOnClose={true}
        onCancel={this.props.handleClose}
        title="Add Commission Rate"
        okText="Add Product"
        onOk={this.props.handleSubmit}
      >
        <Form>
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Product">
                {getFieldDecorator('productId', {
                  rules: [
                    {
                      required: true,
                      message: 'Product is required.'
                    }
                  ]
                })(<Autocomplete.Product />)}
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Rate">
                {getFieldDecorator('rate', {
                  rules: [
                    {
                      required: true,
                      message: 'Rate is required.'
                    }
                  ]
                })(<InputNumber min={0} precision={2} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item label="Rate Type">
                {getFieldDecorator('rateType', {
                  rules: [
                    {
                      required: true,
                      message: 'Rate Type is required.'
                    }
                  ]
                })(
                  <Select>
                    <Select.Option key="FIXED">Fixed</Select.Option>
                    <Select.Option key="PERCENT">Percent</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({})(Add);
