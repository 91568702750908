export const EMPLOYEES_LOAD = 'EMPLOYEES_LOAD';
export const EMPLOYEES_LOADED = 'EMPLOYEES_LOADED';
export const EMPLOYEE_VIEW = 'EMPLOYEE_VIEW';
export const EMPLOYEE_VIEW_LOADED = 'EMPLOYEE_VIEW_LOADED';
export const EMPLOYEE_CREATE = 'EMPLOYEE_CREATE';
export const EMPLOYEE_CREATE_UPDATE_OK = 'EMPLOYEE_CREATE_UPDATE_OK';
export const EMPLOYEE_STAUS_UPDATE_OK = 'EMPLOYEE_STAUS_UPDATE_OK';
export const EMPLOYEE_UPDATE = 'EMPLOYEE_UPDATE';
export const EMPLOYEE_UNLOAD = 'EMPLOYEE_UNLOAD';
export const EMPLOYEE_ACTIVATE = 'EMPLOYEE_ACTIVATE';
export const EMPLOYEE_INACTIVATE = 'EMPLOYEE_INACTIVATE';
